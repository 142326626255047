<template>
    <div class="team-management-panel__team-panel">
        <search-panel
            :key="unitId"
            :show-append="showAppend"
            :unit-id="unitId"
            :project-code="projectCode"
            @on-search="handleSearch"
            @on-after-append="() => getTeamData(false)"
        />

        <a-list
            :split="false"
            :loading="loading"
            :data-source="teamList"
            class="team-management-panel__team-list"
        >
            <template #header>
                <statistics-list :enter-num="enterNum" :leave-num="leaveNum" />
            </template>

            <template #renderItem="item">
                <li
                    :key="item[rowKey]"
                    :class="{ checked: isChecked(item) }"
                    class="team-management-panel__team-list-item"
                    @click.stop="handleManuallyCheck(item)"
                >
                    <div class="team-management-panel__dot" />
                    <span class="team-management-panel__team-list-text">
                        {{ item.teamName }}({{ item.memberNum || 0 }})
                    </span>

                    <template v-if="readOnly">
                        <a-button type="link" @click.stop="() => handleViewTeam(item.id)">查看</a-button>
                    </template>
                    <template v-else>
                        <a-button type="link" @click.stop="() => handleEditTeam(item.id)">编辑</a-button>
                    </template>
                </li>
            </template>
        </a-list>
        <custom-portal :to="personnelPortalTarget">
            <personnel-panel
                :timestamp="timestamp"
                :team-id="checkedKey"
                :read-only="readOnly"
                :pre-member-num="checkedMemberNum"
                :project-code="projectCode"
                @on-num-change="handleMemberNumChange"
            />
        </custom-portal>

        <custom-mutiple-modal ref="mutiple-modal" @on-ok="() => getTeamData()" />
    </div>
</template>
<script>
import { List as AList, Button as AButton } from 'ant-design-vue'
import CustomPortal from '@components/CustomPortalVue/Portal'
import CustomMutipleModal from '@/weights/CustomMutipleModal'

import PersonnelPanel from '../PersonnelPanel'
import StatisticsList from '../StatisticsList'
import SearchPanel from './SearchPanel'

import { find } from '4d-space-bag'
import { filterParams } from '@utils/search'
import { isEmpty } from '@utils'

import { EVENT_TYPE_NORMAL, INTERFACE_TYPE_MODAL } from '@/constant/enum'

import eventMixin from '@/mixins/createPage/event'

import getTeamDataService from '@service/teamManagement/getTeamDataService'

const ROW_KEY = 'id'

// 生成新的时间戳
const generateTimestamp = () => new Date().getTime()

export default {
    name: 'TeamPanel',
    components: {
        AList,
        AButton,
        SearchPanel,
        CustomPortal,
        StatisticsList,
        PersonnelPanel,
        CustomMutipleModal
    },
    mixins: [eventMixin],
    props: {
        preTeamNum: Number,
        unitId: String,
        readOnly: Boolean,
        projectCode: String,
        personnelPortalTarget: String
    },
    data() {
        return {
            // 搜索文本（暂存）
            searchText: undefined,
            // 班组项的 `rowKey` 配置
            rowKey: ROW_KEY,

            // 班组列表及相关数据
            teamList: [], // 班组列表数据
            enterNum: 0, // 进场班组数
            leaveNum: 0, // 退场班组数

            // 是否正在获取数据
            loading: false,
            // 当前选中的班组项
            checkedItem: {},
            // 时间戳（用以强制让 `人员面板` 刷新数据）
            timestamp: generateTimestamp()
        }
    },
    computed: {
        // 当前选中单位项的 `rowKey`
        checkedKey() {
            return this.checkedItem[ROW_KEY]
        },
        // 当前选中单位项的人员数量
        checkedMemberNum() {
            return +this.checkedItem.memberNum || 0
        },
        // 是否显示 `新增班组` 按钮
        showAppend() {
            return !this.readOnly && !isEmpty(this.unitId)
        }
    },
    watch: {
        projectCode: {
            handler() {
                this.getTeamData()
            },
            immediate: true
        },
        unitId() {
            this.getTeamData()
        }
    },
    methods: {
        /**
         * 获取班组列表及相关数据
         *
         * @param {boolean} auto 是否需要自动选中班组项
         */
        async getTeamData(auto = true) {
            try {
                this.loading = true

                const _params = filterParams({
                    unitId: this.unitId,
                    teamName: this.searchText,
                    projectCode: this.projectCode
                })

                const result = await getTeamDataService(_params) || {}

                const teamList = result.teams || []
                // eslint-disable-next-line
                Object.assign(this, { ...result, teamList })

                // 调用获取班组列表数据之后的回调处理
                this.handleTeamsCallback(teamList, auto)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
         * 处理获取班组列表数据之后的回调处理
         *
         * @param {Team[]} newTeams 新班组列表数据
         * @param {boolean} auto 是否自动选中班组项
         */
        handleTeamsCallback(newTeams, auto = true) {
            /**
             * `单位面板` 中的班组数量与最新拿到的班组数量不一致时
             * 触发 `on-num-change` 事件 告诉 `单位面板` 需要更新数据
             */
            const newTeamNum = newTeams.length
            if (this.preTeamNum !== newTeams.length) {
                this.$emit('on-num-change', newTeamNum)
            }

            const checkedKey = this.checkedKey
            const findFn = item => checkedKey === item[ROW_KEY]
            if (auto || !find(newTeams, findFn)) {
                /**
                 * 需要自动选中班组项 或者 找不到 `checkedKey` 对应的班组
                 * 调用 `handleAutomaticallyCheck` 自动选中班组项
                 */
                this.handleAutomaticallyCheck(newTeams)
            } else {
                /**
                 * 不需要自动选中班组项 且 可以找到 `checkedKey` 对应的班组
                 *
                 * 每次从后端获取班组列表数据，都需要刷新 `人员面板` 的数据
                 * 以保证上级数据刷新，下级紧接着刷新
                 * 即使下级的数据没有变化，也需要刷新数据
                 */
                this.checkTeamItem(this.checkedItem)
            }
        },
        // 查询
        handleSearch(searchText) {
            this.searchText = searchText
            this.getTeamData(true)
        },
        // 查看
        handleViewTeam(id) {
            // 新建班组事件
            const event = {
                operationType: 20,
                eventType: EVENT_TYPE_NORMAL,
                interfaceType: INTERFACE_TYPE_MODAL,
                permissionCode: 'globalOperation',
                eventCode: 'team_30',
                eventName: '查看'
            }

            this.emitEvent({
                event,
                _params: {
                    id
                }
            })
        },
        // 编辑
        handleEditTeam(id) {
            // 编辑班组事件
            const event = {
                operationType: 20,
                eventType: EVENT_TYPE_NORMAL,
                interfaceType: INTERFACE_TYPE_MODAL,
                permissionCode: 'globalOperation',
                eventCode: 'team_20',
                eventName: '编辑班组'
            }

            this.emitEvent({
                event,
                _params: {
                    id
                }
            })
        },
        /**
         * 处理 `人员面板` `on-member-num` 人员数量改变事件
         * 将拿到的最新人员数量更新至对应班组的（人员数量）统计值上
         * 提示：此处更新只针对当前已选中的班组，未选中的班组需要手动刷新
         */
        handleMemberNumChange(newMemberNum = 0) {
            const { checkedKey, checkedItem, teamList = [] } = this
            const index = teamList.findIndex(item => checkedKey === item[ROW_KEY])

            this.$set(checkedItem, 'memberNum', newMemberNum)
            this.$set(this.teamList, index, checkedItem)
        },
        // 手动选中（点击）班组项
        handleManuallyCheck(item) {
            if (this.checkedKey !== item[ROW_KEY]) {
                this.checkTeamItem(item)
            }
        },
        /**
         * 自动选中班组项
         * 提示：此处是自动选中班组列表中的第一项
         */
        handleAutomaticallyCheck([firstItem = {}]) {
            this.checkTeamItem(firstItem)
        },
        // 选中班组项
        checkTeamItem(item) {
            this.checkedItem = item
            // 生成新的时间戳，告诉 `人员面板` 需要更新数据
            this.timestamp = generateTimestamp()
        },
        // 判断当前班组项是否被选中
        isChecked(item) {
            return this.checkedKey === item[ROW_KEY]
        }
    }
}
</script>
