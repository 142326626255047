<template>
    <div>
        <image-view
            v-if="componentType == IMAGE_UPLOAD_TYPE"
            :image-list="files"
        />
        <file-view
            v-else-if="componentType == FILE_UPLOAD_TYPE"
            :files="files"
        />
        <check-list-modal
            v-else-if="componentType == CHECK_LIST_TYPE"
            :value="value"
            :is-readonly="true"
        />
        <div v-else-if="isDescription">{{ realValue }}</div>
        <a-input v-else disabled :value="realValue" />
    </div>
</template>

<script>
import { Input } from 'ant-design-vue'

import FileView from '@weights/FileView'
import ImageView from '@weights/ImageView'
import CheckListModal from '@weights/CheckListModal'

import { EMPTY_VALUE_PLACEHOLDER } from '@config'

import {
    SELECT_TYPE,
    FILE_UPLOAD_TYPE,
    IMAGE_UPLOAD_TYPE,
    MONEY_TYPE,
    DATE_RANGE_PICKER_TYPE,
    DATE_TIME_RANGE_PICKER_TYPE,
    CHECK_LIST_TYPE
} from '../../parser/types'

import { isEmpty, hasOwnProperty, isTrueBooleanPattern, empty as emptyFilter } from '@utils'
import { amountFilter, rangeStringFilter } from '@utils/filter'
import imageOrFileStringToArray from '@utils/imageOrFileStringToArray'

import getDictListService from '@service/getDictListService'
import getDictListByTagService from '@service/getDictListByTagService'

export default {
    name: 'ReadonlyItem',
    components: {
        FileView,
        ImageView,
        CheckListModal,
        AInput: Input
    },
    props: {
        value: {
            // type: String
            type: [Number, String]
        },
        fieldCode: {
            type: String,
            default: ''
        },
        componentType: {
            type: String,
            default: ''
        },
        unit: {
            type: String,
            default: ''
        },
        isDescription: {
            type: Boolean,
            default: false
        },
        dataTag: {
            type: String
        }
    },
    data() {
        return {
            dicList: [],
            files: [],
            FILE_UPLOAD_TYPE,
            IMAGE_UPLOAD_TYPE,
            CHECK_LIST_TYPE
        }
    },
    computed: {
        realValue: {
            get() {
                const { value } = this
                if (isEmpty(value)) {
                    return EMPTY_VALUE_PLACEHOLDER
                }

                const strategy = {
                    [SELECT_TYPE]() {
                        const findFn = item => String(item.dataValue) === String(value)
                        return this.dicList?.find(findFn)?.dataName || value
                    },

                    [MONEY_TYPE]() {
                        return amountFilter(value, this.unit ?? void 0)
                    },

                    [DATE_RANGE_PICKER_TYPE]: () => rangeStringFilter(value),

                    [DATE_TIME_RANGE_PICKER_TYPE]: () => rangeStringFilter(value),

                    default() {
                        return emptyFilter(value, this.unit)
                    }
                }

                if (!hasOwnProperty(strategy, this.componentType)) {
                    return strategy.default.call(this)
                }

                return strategy[this.componentType].call(this)
            },
            set(v) {
                this.$emit('input', v)
                this.$emit('change', v)
                this.$emit('blur')
            }
        }
    },
    watch: {
        value: {
            handler() {
                switch (this.componentType) {
                    case SELECT_TYPE:
                        this.getList()
                        break
                    case FILE_UPLOAD_TYPE:
                    case IMAGE_UPLOAD_TYPE:
                        this.formatFileTypeVaule()
                        break
                    default:
                        break
                }
            },
            immediate: true
        }
    },
    created() {
        switch (this.componentType) {
            case SELECT_TYPE:
                this.getList()
                break
            case FILE_UPLOAD_TYPE:
            case IMAGE_UPLOAD_TYPE:
                this.formatFileTypeVaule()
                break
            default:
                break
        }
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        async getList(params) {
            try {
                const { dataTag } = this
                let list = []

                if (dataTag) {
                    list = await getDictListByTagService({
                        dataTag,
                        dataType: this.fieldCode,
                        ...params
                    })
                } else {
                    list = await getDictListService({
                        dataType: this.fieldCode,
                        ...params
                    })
                }
                // 只获取激活的枚举
                this.dicList = list.filter(item => isTrueBooleanPattern(item.state))
            } catch (e) {
                console.log('-> e', e)
            }
        },
        formatFileTypeVaule() {
            this.files = imageOrFileStringToArray(this.value)
        }
    }
}
</script>

<style lang="scss"></style>
