/**
 * 鉴权指令
 * @param Vue
 * @param options
 */

export default {
    // 获取 按钮权限
    inserted() {
        // el, bind, vNode
        /* const buttonRole = vNode.context.$store.getters.buttonRole || {}
        // 获取按钮值
        const roleName = bind.value
        // 判断是否有权限
        if (!buttonRole[roleName]) {
            el.remove()
        }*/
    }
}
