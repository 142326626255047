<template>
    <div class="create-page__custom-file-upload-description-list-wrapper">
        <!-- COMBINATION_COMPONENT_TYPE_CARD -->
        <template v-if="needCard">
            <custom-card v-bind="cardNeedProps" ref="card">
                <file-upload-description-list ref="form" v-bind="fileUploadDescriptionNeedProps" title="" />
            </custom-card>
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_PAGE -->
        <template v-else-if="needPageHeader">
            <weight-page-header v-bind="pageHeaderNeedProps" :title="title" />
            <file-upload-description-list ref="form" v-bind="fileUploadDescriptionNeedProps" title="" />
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_NOMAL -->
        <template v-else>
            <file-upload-description-list ref="form" v-bind="fileUploadDescriptionNeedProps" />
        </template>
    </div>
</template>

<script>
import CustomCard from '@weights/CustomCard'
import WeightPageHeader from '@weights/PageHeader'
import FileUploadDescriptionList from '@weights/FileUploadDescriptionList'

import childrenRefreshMixin from '@mixins/createPage/childrenRefresh'
import getFormData from '@mixins/createPage/formData'
import eventMixin from '@mixins/createPage/event'
import buttonMixin from '@mixins/createPage/button'

import { getPropFields, getPropsInNeed, getPropsInCardNeed, getPropsInPageHeaderNeed } from '@utils/pageCreate'

import {
    BOOLEAN_PATTERN_TRUE,
    BOOLEAN_PATTERN_FALSE,
    COMBINATION_COMPONENT_TYPE_CARD,
    COMBINATION_COMPONENT_TYPE_PAGE
} from '@constant/enum'

const FILE_UPLOAD_DESCRIPTION_PROP_FIELDS = getPropFields(FileUploadDescriptionList)

export default {
    name: 'CreatePageCustomFileUploadDescriptionList',
    components: {
        CustomCard,
        WeightPageHeader,
        FileUploadDescriptionList
    },
    mixins: [
        childrenRefreshMixin, getFormData, eventMixin, buttonMixin
    ],
    props: {
        combinationComponentType: {
            type: Number,
            default: COMBINATION_COMPONENT_TYPE_CARD
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        eventList: {
            type: Array,
            default: () => []
        },
        extraTitle: {
            type: Object,
            default: () => ({})
        },
        // 展示边框
        needBordered: {
            type: Number,
            default: BOOLEAN_PATTERN_TRUE
        },
        fieldList: {
            type: Array,
            default: () => []
        },
        field: {
            type: String,
            default: ''
        },
        columns: {
            type: Number,
            default: 1
        },
        options: {
            type: Object,
            default() {
                return {
                    submitBtn: false,
                    resetBtn: false
                }
            }
        },
        emits: {
            type: Object,
            default: () => ({})
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        align: Number,
        colon: Number,
        fixed: Number,
        needCardBordered: {
            type: Number,
            default: BOOLEAN_PATTERN_FALSE
        }
    },
    computed: {
        needCard() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_CARD
        },
        needPageHeader() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_PAGE
        },
        cardNeedProps() {
            return this.needCard ? getPropsInCardNeed(this.$props) : null
        },
        pageHeaderNeedProps() {
            return this.needPageHeader ? getPropsInPageHeaderNeed(this.$props) : null
        },
        fileUploadDescriptionNeedProps() {
            return getPropsInNeed(this.$props, FILE_UPLOAD_DESCRIPTION_PROP_FIELDS)
        }
    },
    methods: {
        async handleDataRefresh(data) {
            const res = await this.fieldDataRefresh(data)
            this.$refs['card']?.changeLocalData(res)
        },
        async fieldDataRefresh(data) {
            return await this.$refs['form'].fieldDataRefresh(data)
        },
        getFormData() {
            return this.$refs['form'].getFormData()
        }
    }
}
</script>
