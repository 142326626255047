<template>
    <a-cascader
        v-model="areaValue"
        change-on-select
        :options="list"
        :disabled="disabled"
        placeholder="请选择地区"
        @blur="handleBlur"
    />
</template>

<script>
import { Cascader as ACascader } from 'ant-design-vue'

import { toMapAndTree } from '@utils/convert'

import getAreaTreeService from '@service/getAreaTreeService'

export default {
    name: 'AddressSelect',
    components: {
        ACascader
    },
    props: {
        disabled: Boolean,
        value: {
            type: [String, Number],
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            list: [],
            map: new Map()
        }
    },
    computed: {
        areaValue: {
            get() {
                if (this.value) {
                    return [this.value]
                }
                return undefined
            },
            set(val) {
                this.$emit('input', val[val.length - 1])
                this.$emit('change', val[val.length - 1])
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        async getList() {
            try {
                const { areaCode } = this.$store.getters.globalData
                const data = await getAreaTreeService({
                    areaCode
                })
                const { tree: list, map } = toMapAndTree(data || [], 'label', 'id')
                Object.assign(this, {
                    list: Object.freeze(list),
                    map: Object.freeze(map)
                })
            } catch (e) {
                console.log('-> e', e)
            }
        }
    }
}
</script>

<style></style>
