/**
 * 数据类型（TXT：文本，TXT_AREA：文本域，富文本：RICH_TXT，NUM：数字，MONEY：金额，
 * FLOAT：浮点数，DATE：日期，DATE_RANGE：日期范围，DATE_TIME：时间，
 * DATE_TIME_RANGE：时间范围，TEL：座机，PHONE：手机，PID：身份证，CNO：
 * 统一社会信用代码，MAIL：邮箱，ADDRESS：地址，DICT：数据字典，DICT_CASCADE：
 * 级联数据字典，FILE：资料字段，IMAGE：图片，GEO：地图选点）
 * */
import { Tooltip as ATooltip } from 'ant-design-vue'

import FileView from '../FileView'
import TableImageCell from './TableImageCell'

import { CELL_MAX_WIDTH, EMPTY_VALUE_PLACEHOLDER } from '@config'

import { empty } from '@utils'
import imageOrFileStringToArray from '@utils/imageOrFileStringToArray'

import { fontSize } from '@assets/styles/varibles.scss'

// 自定义内容展示
const customRenderMap = {
    IMAGE: (v, h) => {
        let res = imageOrFileStringToArray(v)
        const props = {
            attrs: {
                title: v?.originalFileName,
                src: v?.fileUrl
            },
            props: {
                images: res
            }
        }
        return h(TableImageCell, props)
    },
    // 地址 跳转
    ADDRESS: (v, h) => h(
        'div',
        {
            on: {
                click() {
                    window.open(v)
                }
            }
        },
        v
    ),
    MONEY: v => v,
    NUM: v => v,
    FILE: v => {
        let res = imageOrFileStringToArray(v)
        return res.length
    },
    FILE_VIVE: (v, h) => {
        let res = imageOrFileStringToArray(v)
        const props = {
            props: {
                files: res
            }
        }
        return h(FileView, props)
    },
    TXT: (v, h, column) => {
        if (!v) {
            return empty(v)
        }
        const vLen = v.length
        if (vLen * fontSize > CELL_MAX_WIDTH - 58) {
            // 字符长度大于最大宽度-padding 则显示tooltip
            column.ellipsis = true
            return h(
                ATooltip,
                {
                    props: {
                        title: v,
                        placement: 'topLeft'
                    }
                },
                [v]
            )
        } else {
            return v
        }
    },
    LINK: (v, h) => {
        if (!v) {
            return empty(v)
        }
        return h('a', {
            attrs: {
                href: v,
                target: '_blank'
            }

        }, ['查看'])
    }
}

customRenderMap['TXT_AREA'] = customRenderMap['TXT']
export const customRender = function (dataType) {
    return (v, record, index, column, h) => customRenderMap[dataType]
        ? customRenderMap[dataType](v, h, column)
        : v || EMPTY_VALUE_PLACEHOLDER
}
