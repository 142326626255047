import _ from 'lodash'
import { isEmpty } from '@utils'

export default {
    methods: {
        /**
         * 获取表单数据
         * @returns 表单数据
         */
        getChildrenFormData(pageFApi) {
            const fApiList = this.$store.getters.pageFApiList

            let data = {}

            let index = fApiList.findIndex(fApi => fApi === pageFApi)

            const fields = pageFApi.fields()
            fields.forEach(field => {
                const vm = pageFApi.el(field)
                if (vm && vm.getTabsFormData) {
                    const res = vm.getTabsFormData()

                    data = {
                        ...data,
                        ...res
                    }
                }
            })

            if (index > -1) {
                for (; index < fApiList.length; index++) {
                    data = {
                        ...data,
                        ...this.getOnePageFormData(fApiList[index])
                    }
                }

                return data
            }
            return this.getOnePageFormData(pageFApi)
        },
        /**
         * 获取单个页面的数据
         * @param {*} pageFApi
         * @param {*} childrenField
         * @returns
         */
        getOnePageFormData(pageFApi, childrenField) {
            const nowChildrenField = childrenField || pageFApi.options.childrenField
            // 获取自己的表单数据
            const data = this.getFormData && this.getFormData() || {}

            const fApi = pageFApi

            // 清除空参数，防止覆盖已有的值
            let res = {}
            for (let key in data) {
                if (!isEmpty(data[key])) {
                    res[key] = data[key]
                }
            }

            // 获取子组件表单数据
            if (Array.isArray(nowChildrenField)) {
                nowChildrenField.forEach(field => {
                    const vm = fApi.el(field)
                    if (vm?.getOnePageFormData) {
                        const data = vm.getOnePageFormData(fApi, vm.childrenField)
                        res = {
                            ...res,
                            ...data
                        }
                    }
                })
            }

            return res
        },
        /**
         * 表单验证
         * @returns 返回验证结果
         */
        vaildatorFromData(pageFApi) {

            const fApiList = this.$store.getters.pageFApiList

            let index = fApiList.findIndex(fApi => fApi === pageFApi)

            let data = {
                valid: true,
                fail: {}
            }

            const fields = pageFApi.fields()
            fields.forEach(field => {
                const vm = pageFApi.el(field)
                if (vm && vm.vaildatorTabsForm) {
                    const { valid, fail } = vm.vaildatorTabsForm()

                    if (_.isBoolean(valid) && !valid) {
                        data.valid = valid
                        data.fail = {
                            ...data.fail,
                            ...fail
                        }
                    }
                }
            })

            if (index > -1) {
                for (; index < fApiList.length; index++) {
                    const { valid, fail } = this.vaildatorOnePageFormData(fApiList[index])

                    if (_.isBoolean(valid) && !valid) {
                        data.valid = valid
                        data.fail = {
                            ...data.fail,
                            ...fail
                        }
                    }
                }

                return data
            }
            return this.vaildatorOnePageFormData(pageFApi)

        },
        /**
         * 校验单个pageLayout下formData
         * @param {fapi} pageFApi
         * @param {Array} childrenField
         * @returns
         */
        vaildatorOnePageFormData(pageFApi, childrenField) {
            const nowChildrenField = childrenField || pageFApi.options.childrenField
            // const fApi = this.$store.getters.pageFApi
            const res = {
                valid: true,
                fail: {}
            }

            // 处理自己的验证逻辑
            this.vaildatorForm &&
                this.vaildatorForm((valid, fail) => {
                    res.valid = valid
                    res.fail = {
                        ...res.fail,
                        ...fail
                    }
                })
            // 处理子组件的验证逻辑
            if (Array.isArray(nowChildrenField)) {
                nowChildrenField?.forEach(field => {
                    const vm = pageFApi.el(field)
                    if (vm && vm.vaildatorOnePageFormData) {
                        const { valid, fail = [] } = vm.vaildatorOnePageFormData(pageFApi, vm.childrenField)
                        if (_.isBoolean(valid) && !valid) {
                            res.valid = valid
                            res.fail = {
                                ...res.fail,
                                ...fail
                            }
                        }
                    }
                })
            }

            return res
        }
    }
}
