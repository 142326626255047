/**
 * 工程信息
 */
import { SAFETY_MFG_URL } from '@config'

// 项目信息
export const GET_PROJECT_INFO = {
    url: `${SAFETY_MFG_URL}/project/inside/getByParam`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


// 差异化缴存信息查询
export const GET_MARGIN_DIFFERENTIATION_INFO = {
    url: `${SAFETY_MFG_URL}/margin/quota/inside/getMarginDifferentiation`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

// 选择项目列表
export const GET_CHOOSE_PROJECT_LIST = {
    url: `${SAFETY_MFG_URL}/hfrs-project/page`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}
