<script>
export default {
    name: 'CustomButtonSpace',
    functional: true,
    render(h, ctx) {
        const children = ctx.children

        if (children.length <= 1) return children

        // eslint-disable-next-line
        return h('div', { class: 'custom-button-space' }, children)
    }
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.custom-button-space {
    > .ant-btn + .ant-btn {
        margin-left: $space-sm;
    }
}
</style>
