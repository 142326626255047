<template>
    <page-layout show-loading :loading="loading">
        <create-page
            is-root
            is-global-f-api
            root-type="page"
            :template="template"
            :global-params="requireParams"
        />
    </page-layout>
</template>
<script>
import PageLayout from '@layout/PageLayout'
import CreatePage from '@weights/CreatePage'

import eventMixin from '@mixins/createPage/event'

import { CLEAR_PAGE_F_API } from '@store/mutation-types'

import { isEmpty } from '@utils'

export default {
    name: 'DetailPageTempalte',
    components: {
        CreatePage,
        PageLayout
    },
    mixins: [eventMixin],
    props: {
        pageParams: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            rule: [],
            template: {},
            loading: false,
            requireParams: {}
        }
    },
    computed: {
        routeQuery() {
            return this.$route.query
        },
        layoutCode() {
            return this.routeQuery.layoutCode
        }
    },
    watch: {
        layoutCode: {
            handler(layoutCode) {
                if (isEmpty(layoutCode)) return

                this.init(this.routeQuery)
                this.$store.commit(CLEAR_PAGE_F_API)
            },
            immediate: true
        },
        pageParams: {
            handler(pageParams) {
                if (!pageParams) return
                if (!isEmpty(pageParams.layoutCode)) {
                    this.init(pageParams)
                }
            },
            immediate: true
        }
    },
    methods: {
        async init(params) {
            try {
                this.loading = true

                const event = {
                    eventCode: params.layoutCode,
                    dynamicType: +params.dynamicType,
                    operationType: +params.operationType
                }

                const result = await this.triggerEventServer(event, params)

                this.requireParams = params
                this.template = result.result
            } catch (error) {
                this.$message.error(error.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
