<script>
import { Col, FormModel } from 'ant-design-vue'
import { getComponentFromProp } from 'ant-design-vue/es/_util/props-util'

export default {
    name: 'FormCol',
    props: {
        // label 标签的文本
        label: String,
        // 表单域 model 字段
        prop: String,
        // 表单项栅格占位格数
        span: {
            type: Number,
            default: 6
        },
        // label 布局，同 FormModel.Item labelCol
        labelCol: Object,
        // 直接设置 labelCol.span 值，在 labelCol 有传入时，此项传入则无效
        labelSpan: {
            type: Number,
            default: 4
        },
        // wrapper 布局，同 FormModel.Item wrapperCol
        wrapperCol: Object,
        // 直接设置 wrapperCol.span 值，在 wrapperCol 有传入时，此项传入则无效
        wrapperSpan: {
            type: Number,
            default: 18
        },
        // 给 FormModel.Item 设定自定义 class 类
        formItemClassName: String,
        // 给 FormModel.Item 设定自定义 style 样式
        formItemStyle: [Object, String]
    },
    methods: {
        renderFormItem(h) {
            const {
                labelCol = {
                    span: this.labelSpan
                },
                wrapperCol = {
                    span: this.wrapperSpan
                }
            } = this

            const itemProps = {
                style: this.formItemStyle,
                class: this.formItemClassName,
                props: {
                    labelCol,
                    wrapperCol,
                    prop: this.prop,
                    label: getComponentFromProp(this, 'label')
                }
            }

            return h(FormModel.Item, itemProps, [this.$slots.default])
        }
    },
    render(h) {
        const colProps = {
            props: {
                span: this.span
            }
        }
        return h(Col, colProps, [this.renderFormItem(h)])
    }
}
</script>
