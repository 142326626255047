import axios from '../index'
import { IMPORT_FAIL_DOWNLOAD } from './api'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

/**
 * 导入失败文件查询
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function exportFailDataService(
    payload = {},
    config = {}
) {
    const param = {
        ...IMPORT_FAIL_DOWNLOAD,
        ...config,
        params: payload
    }
    const data = await axios(param)
    if (data.message) {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    } else {
        return data
    }

}
