import axios from './index'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
import { SAFETY_MFG_URL } from '@config'

/**
 * 通过tag获取dict_list
 * @param {} params
 * @returns
 */
export default async function getDictListByTagService(params) {
    const param = {
        url: `${SAFETY_MFG_URL}/dict/dictFilterList`,
        method: 'get',
        headers: {
            'Content-Type':
                'application/x-www-form-urlencoded'
                // 'application/json'
        },
        params,
        timeout: 30000
    }
    const res = await axios(param)
    const data = res.data
    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
