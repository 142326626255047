import PageLayout from '@layout/PageLayout'
import CreatePage from '@weights/CreatePage'

import { ACTIVE_EVENT_URL, LOAD_LAYOUT_URL } from '@config'
import { CLEAR_PAGE_F_API } from '@store/mutation-types'

import { EVENT_OPERATION_TYPE_TABLE } from '@constant/enum'

import { hasOwnProperty } from '@utils'

import commonService from '@service/commonService'

const loadLayoutStrategy = {
    /**
     * 当为 table 请求数据事件，且有 query 参数
     * 将加载事件换为触发事件，且添加对应参数请求，减少请求次数
     */
    async [EVENT_OPERATION_TYPE_TABLE](layoutCode, query) {
        if (!query) {
            return loadLayoutStrategy.default(layoutCode)
        }

        const params = {
            url: ACTIVE_EVENT_URL,
            params: {
                current: 1,
                size: 10,
                ...query,
                eventCode: layoutCode
            }
        }
        return commonService(params) || {}
    },

    async default(layoutCode) {
        const params = {
            url: LOAD_LAYOUT_URL,
            params: {
                layoutCode
            }
        }
        return commonService(params) || {}
    }
}

export default {
    components: {
        CreatePage,
        PageLayout
    },
    data() {
        return {
            template: {},
            loading: true
        }
    },
    watch: {
        '$route.meta.layoutCode': {
            handler(layoutCode) {
                if (!layoutCode) return

                // 清空过去的内容
                this.template = {}
                this.$store.commit(CLEAR_PAGE_F_API)

                this.getLayout(layoutCode)
            },
            immediate: true
        }
    },
    methods: {
        async getLayout(layoutCode) {
            if (!layoutCode) return

            try {
                this.loading = true

                const codes = layoutCode.split('_')
                const eventOperationType = +codes[codes.length - 1]

                let loadLayoutService = loadLayoutStrategy.default
                if (!isNaN(eventOperationType) && hasOwnProperty(loadLayoutStrategy, eventOperationType)) {
                    loadLayoutService = loadLayoutStrategy[eventOperationType]
                }

                // eslint-disable-next-line
                this.template = await loadLayoutService(layoutCode, this.$route.query)
            } catch (error) {
                this.$message.error(error.message)
            } finally {
                this.loading = false
            }
        },
        renderChild() {
            const h = this.$createElement

            if (this.loading) {
                return h('div')
            }

            return h('create-page', {
                props: {
                    isGlobalFApi: true,
                    isRoot: true,
                    rootType: 'page',
                    template: this.template
                }
            })
        }
    },
    render(h) {
        const props = {
            showLoading: true,
            loading: this.loading
        }
        const childs = [this.renderChild()]

        // eslint-disable-next-line
        return h('page-layout', { props }, childs)
    }
}
