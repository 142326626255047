// import axios from './index'
// import { GET_USER_MENU } from './api'
// import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
// import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

// const menu = require('../json/menu.json')

/**
 * 获取用户menus信息
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
// export default async function getMenusService(payload = {}, config = {}) {
export default async function getMenusService() {
    // const param = { ...GET_USER_MENU, ...config, data: payload }
    // const { data } = await axios(param)
    // if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
    //     return data.data
    // } else {
    //     throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    // }

    return Promise.resolve([])
}
