import axios from '@service'
import { GET_REFRESH_TOKEN } from './api'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

/**
 * 获取更新后token
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function getRefreshTokenService(payload = {}, config = {}) {
    const param = {
        ...GET_REFRESH_TOKEN,
        ...config,
        data: payload
    }
    const { data } = await axios(param)
    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
