class Middleware {
    constructor() {
        this.middlewares = []
    }

    use(fn) {
        if (typeof fn !== 'function') {
            throw new TypeError('The parameter `fn` must provide a function!')
        }

        this.middlewares.push(fn)

        return this
    }

    compose(opts) {
        const { middlewares } = this
        const middlewaresCount = middlewares.length

        function dispatch(idx) {
            if (idx >= middlewaresCount) return

            const middleware = middlewares[idx]
            if (!middleware) return

            try {
                return Promise.resolve(middleware(opts, () => dispatch(idx + 1)))
            } catch (e) {
                return Promise.reject(e)
            }
        }

        return dispatch(0)
    }
}

export default Middleware
