import {
    SELECT_TYPE,
    AREA_SELECT_TYPE,
    DATE_RANGE_PICKER_TYPE,
    INDUSTRY_COMPETENT_UNIT,
    DATE_TIME_RANGE_PICKER_TYPE,
    HUMAN_AND_SOCIAL_SUPERVISION_UNIT,
    SALARY_MONTH_SELECT_TYPE,
    CURRENT_AREA_TYPE,
    PROJECT_SEARCH_TYPE,
    UNIT_SEARCH_TYPE,
    BOOLEAN_WHETHER_SELECT_TYPE,
    ORIGINAL_WHETHER_SELECT_TYPE
} from '@weights/CreateForm/parser/types'

export const COMPONENTS = {
    OrgListSelect: () => import('@weights/systemManage/OrgSelect'),
    UserListSelect: () => import('@weights/systemManage/UserListSelect'),
    CustomSelect: () => import('@weights/CreateForm/components/Select'),
    CustomAddress: () => import('@weights/CreateForm/components/Address'),
    DateRangePicker: () => import('@weights/CreateForm/components/DateRangePicker'),
    IndustryCompetentUnit: () => import('@weights/CreateForm/components/IndustryCompetentUnit'),
    HACSupervisionUnitSelect: () => import('@weights/CreateForm/components/HACSupervisionUnitSelect'),
    SalaryMonthSelect: () => import('@weights/CreateForm/components/SalaryMonthSelect'),
    CurrentArea: () => import('@weights/CreateForm/components/CurrentArea'),
    ProjectSelect: () => import('@weights/CreateForm/components/ProjectSelect'),
    UnitSelect: () => import('@weights/CreateForm/components/UnitSelect'),
    WhetherSelect: () => import('@weights/CreateForm/components/WhetherSelect')
}

/**
 * 使用是/否选择器
 *
 * @param {boolean} useOriginalValue 是否使用枚举配置原始数据类型
 */
function useWhetherSelect(useOriginalValue) {
    return function () {
        return {
            Component: 'WhetherSelect',
            props: {
                useOriginalValue,
                allowClear: true,
                fieldCode: this.fieldCode,
                placeholder: `请选择${this.fieldName}`
            }
        }
    }
}

export const STRATEGY = {
    // 字典选择器
    [SELECT_TYPE]() {
        return {
            Component: 'CustomSelect',
            attrs: {
                allowClear: true
            },
            props: {
                fieldCode: this.fieldCode,
                placeholder: `请选择${this.fieldName}`,
                dataTag: this.dataTag
            }
        }
    },

    // 地区级联选择器
    [AREA_SELECT_TYPE]() {
        return {
            Component: 'CustomAddress',
            props: {
                placeholder: `请选择${this.fieldName}`
            }
        }
    },

    /**
     * 是/否选择器
     * 选中值数据类型为 boolean 类型
     */
    [BOOLEAN_WHETHER_SELECT_TYPE]: useWhetherSelect(false),

    /**
     * 是/否选择器
     * 选中值数据类型为枚举值数据类型，即使用原始数据类型
     */
    [ORIGINAL_WHETHER_SELECT_TYPE]: useWhetherSelect(true),


    // 当前地区级联选择器
    [CURRENT_AREA_TYPE]() {
        return {
            Component: 'CurrentArea',
            props: {
                placeholder: `请选择${this.fieldName}`
            }
        }
    },

    // 日期范围选择器
    [DATE_RANGE_PICKER_TYPE]() {
        return {
            Component: 'DateRangePicker',
            props: {
                fieldCode: this.fieldCode
            }
        }
    },

    // 日期时间范围选择器
    [DATE_TIME_RANGE_PICKER_TYPE]() {
        return {
            Component: 'DateRangePicker',
            props: {
                showTime: true,
                fieldCode: this.fieldCode
            }
        }
    },

    // 行业主管单位选择器
    [INDUSTRY_COMPETENT_UNIT]() {
        return {
            Component: 'IndustryCompetentUnit',
            props: {
                fieldCode: this.fieldCode,
                placeholder: `请选择${this.fieldName}`
            }
        }
    },

    // 人社监管单位选择器
    [HUMAN_AND_SOCIAL_SUPERVISION_UNIT]() {
        return {
            Component: 'HACSupervisionUnitSelect',
            props: {
                fieldCode: this.fieldCode,
                placeholder: `请选择${this.fieldName}`
            }
        }
    },

    // 工资月选择器
    [SALARY_MONTH_SELECT_TYPE]() {
        return {
            Component: 'SalaryMonthSelect',
            props: {
                fieldCode: this.fieldCode,
                placeholder: `请选择${this.fieldName}`
            }
        }
    },

    // 工程选择器
    [PROJECT_SEARCH_TYPE]() {
        return {
            Component: 'ProjectSelect',
            props: {
                fieldCode: this.fieldCode,
                placeholder: `请选择${this.fieldName}`
            }
        }
    },

    // 单位选择器
    [UNIT_SEARCH_TYPE]() {
        return {
            Component: 'UnitSelect',
            props: {
                fieldCode: this.fieldCode,
                placeholder: `请选择${this.fieldName}`
            }
        }
    }
}
