<template>
    <a-tree-select
        v-model="innerValue"
        tree-data-simple-mode
        :disabled="disabled"
        :tree-data="areaList"
        :allow-clear="allowClear"
        :placeholder="placeholder"
        :show-search="innerShowSearch"
        :dropdown-style="dropdownStyle"
        :filter-tree-node="filterTreeNode"
        :search-placeholder="searchPlaceholder"
        :tree-default-expanded-keys="treeDefaultExpandedKeys"
    >
        <template v-if="loading" slot="suffixIcon">
            <a-spin class="area-tree-select-spin">
                <a-icon slot="indicator" type="loading" />
            </a-spin>
        </template>

        <template v-if="loading" slot="notFoundContent">
            <a-spin size="small" />
        </template>
    </a-tree-select>
</template>
<script>
import { Spin as ASpin, Icon as AIcon, TreeSelect as ATreeSelect } from 'ant-design-vue'

import { isEmpty, isFunction } from '@utils'

import getAreaTreeService from '@service/getAreaListService'

const DROPDOWN_STYLE = {
    overflow: 'auto',
    maxHeight: '520px'
}

export default {
    name: 'AreaTreeSelect',
    components: {
        ASpin,
        AIcon,
        ATreeSelect
    },
    model: {
        prop: 'value',
        event: 'on-change'
    },
    props: {
        value: String,
        disabled: Boolean,
        placeholder: {
            type: String,
            default: '请选择'
        },
        allowClear: {
            type: Boolean,
            default: true
        },
        showSearch: {
            type: Boolean,
            default: true
        },
        searchPlaceholder: {
            type: String,
            default: '请输入关键词'
        },
        getNodeDisabled: Function
    },
    data() {
        return {
            loading: false,
            areaList: [],

            dropdownStyle: Object.freeze(DROPDOWN_STYLE)
        }
    },
    computed: {
        innerValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('on-change', value)
            }
        },
        innerShowSearch() {
            return this.showSearch && !this.loading
        },
        treeDefaultExpandedKeys() {
            const { value } = this
            return isEmpty(value) ? [] : [value]
        }
    },
    created() {
        this.getAddressList()
    },
    methods: {
        async getAddressList() {
            try {
                this.loading = true

                // eslint-disable-next-line
                const result = await getAreaTreeService()

                this.changeAreaList(this.frmtAreaList(result || []))
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        frmtAreaList(areaList) {
            const { getNodeDisabled } = this

            return areaList.map(node => {
                node.pId = node.pid
                node.id = node.value

                if (isFunction(getNodeDisabled)) {
                    node.disabled = getNodeDisabled(node)
                }

                return node
            })

        },
        changeAreaList(list) {
            this.areaList = Object.freeze(list || [])
        },
        filterTreeNode(searchText, treeNode) {
            return treeNode.data.props.title.includes(searchText)
        }
    }
}
</script>
<style lang="scss">
.area-tree-select {
    &-spin {
        color: inherit;

        .anticon-loading {
            color: inherit;
            font-size: inherit;
        }
    }
}
</style>
