/* eslint-disable */
import _extends from 'babel-runtime/helpers/extends'

// base rc-table 6.10.9
import T from './src/Table'
import Column from 'ant-design-vue/es/vc-table/src/Column'
import ColumnGroup from 'ant-design-vue/es/vc-table/src/ColumnGroup'

import {
    getOptionProps,
    getKey,
    getClass,
    getStyle,
    getEvents,
    getSlotOptions,
    camelize,
    getSlots,
    getListeners,
} from 'ant-design-vue/es/_util/props-util'

import { INTERNAL_COL_DEFINE } from 'ant-design-vue/es/vc-table/src/utils'

let Table = {
    name: 'Table',
    Column,
    ColumnGroup,
    props: T.props,
    methods: {
        getTableNode: function getTableNode() {
            return this.$refs.table.tableNode
        },
        getBodyTable: function getBodyTable() {
            return this.$refs.table.ref_bodyTable
        },
        normalize: function normalize() {
            let _this = this

            let elements =
                arguments.length > 0 && arguments[0] !== undefined
                    ? arguments[0]
                    : []

            let columns = []
            elements.forEach(function (element) {
                if (!element.tag) {
                    return
                }
                let key = getKey(element)
                let style = getStyle(element)
                let cls = getClass(element)
                let props = getOptionProps(element)
                let events = getEvents(element)
                let listeners = {}
                Object.keys(events).forEach(function (e) {
                    let k = 'on-' + e
                    listeners[camelize(k)] = events[e]
                })

                let _getSlots = getSlots(element),
                    children = _getSlots['default'],
                    title = _getSlots.title

                let column = _extends(
                    { title },
                    props,
                    {
                        style,
                        class: cls,
                    },
                    listeners
                )
                if (key) {
                    column.key = key
                }
                if (getSlotOptions(element).isTableColumnGroup) {
                    column.children = _this.normalize(
                        typeof children === 'function' ? children() : children
                    )
                } else {
                    let customRender =
                        element.data &&
                        element.data.scopedSlots &&
                        element.data.scopedSlots['default']
                    column.customRender = column.customRender || customRender
                }
                columns.push(column)
            })
            return columns
        },
    },
    render: function render() {
        let h = arguments[0]
        let $slots = this.$slots,
            normalize = this.normalize

        let props = getOptionProps(this)
        let columns = props.columns || normalize($slots['default'])
        let tProps = {
            props: _extends({}, props, { columns }),
            on: getListeners(this),
            ref: 'table',
        }
        return h(T, tProps)
    },
}

export default Table
export { Column, ColumnGroup, INTERNAL_COL_DEFINE }
