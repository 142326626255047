<template>
    <fragment>
        <information-panel
            title="定额保证金"
            :loading="loading"
            :columns="columns"
            :card-bordered="false"
            class="has-margin-top"
            :data-source="marginQuotaHisVO"
        >
            <custom-button-space v-if="isEntSide" slot="extra">
                <template v-if="reportShowState">
                    <a-button type="primary" @click.stop="handleInitiate">发起备案</a-button>
                </template>

                <template v-if="guaranteeRenewalState">
                    <a-button type="primary" @click.stop="handleInitiateRenewal">发起续保</a-button>
                </template>
            </custom-button-space>

            <template #addonAfter>
                <template v-for="name in lowerLevelPanels">
                    <component
                        :is="name"
                        :key="name"
                        :data-source="getLowerLevelDataSource(name)"
                        @on-refresh="() => getMarginPaymentInfo(id)"
                    />
                </template>
            </template>
        </information-panel>

        <component
            :is="modalName"
            v-bind="modalProp"
            @on-close="() => handleCloseModal()"
            @on-refresh="() => getMarginPaymentInfo(id)"
        />
    </fragment>
</template>
<script>
import { Button as AButton } from 'ant-design-vue'
import Fragment from '@components/Fragment'
import InformationPanel from '@components/InformationPanel'
import CustomButtonSpace from '@components/CustomButtonSpace'

import {
    generateColumns,
    LOWER_LEVEL_PANELS,
    LOWER_LEVEL_PANEL_FACTORY,
    DATA_SOURCE_FIELD_MAPPING,
    SHOW_CONTROL_FIELD_MAPPING
} from './config'

import { SYSTEM_TYPE_ENT_SIDE } from '@constant/enum'

import getMarginPaymentInfoService from '@service/marginManagement/marginPaymentDetail/getMarginPaymentInfoService'

export default {
    name: 'FixedDepositPanel',
    components: {
        AButton,
        Fragment,
        InformationPanel,
        CustomButtonSpace,
        ...LOWER_LEVEL_PANEL_FACTORY,
        InitiateFillingModal: () => import('./InitiateFillingModal'),
        InitiateRenewalModal: () => import('./InitiateRenewalModal')
    },
    props: {
        id: String
    },
    data() {
        return {
            // 请求数据中
            loading: false,

            // 保证金缴纳信息
            marginPaymentInfo: {},

            // 下方为 `发起备案` 弹框相关状态
            modalProp: {}, // 传入弹框的 `props` 传参
            modalName: undefined // 弹框组件名称
        }
    },
    computed: {
        // 基础信息字段配置
        columns() {
            return generateColumns(this.marginPaymentInfo)
        },
        // 基础信息数据
        marginQuotaHisVO() {
            return this.marginPaymentInfo.marginQuotaHisVO || {}
        },
        // 是否显示 `发起备案` 按钮
        reportShowState() {
            return this.marginQuotaHisVO.reportShowState
        },
        // 是否显示 `发起续保` 按钮
        guaranteeRenewalState() {
            return this.marginPaymentInfo.guaranteeRenewalState
        },
        // 系统类型
        systemType() {
            return this.$route.meta.systemType
        },
        // 是否为 企业端
        isEntSide() {
            return this.systemType === SYSTEM_TYPE_ENT_SIDE
        },
        // 需要展示的二级面板组件名称集
        lowerLevelPanels() {
            return LOWER_LEVEL_PANELS.filter(name => this.showLowerLevelPanel(name))
        }
    },
    watch: {
        id: {
            handler(id) {
                this.getMarginPaymentInfo(id)
            },
            immediate: true
        }
    },
    methods: {
        // 获取保证金缴纳信息
        async getMarginPaymentInfo(id) {
            try {
                this.loading = true
                // eslint-disable-next-line
                this.marginPaymentInfo = await getMarginPaymentInfoService({ id }, {}, this.systemType)
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        // 打开弹框
        handleOpenModal(modalName, extraProp = {}) {
            this.modalProp = {
                visible: true,
                ...extraProp
            }
            this.modalName = modalName
        },
        // 关闭弹框
        handleCloseModal() {
            this.$set(this.modalProp, 'visible', false)
        },
        // 发起备案
        handleInitiate() {
            // eslint-disable-next-line
            this.handleOpenModal('InitiateFillingModal', { id: this.id })
        },
        // 发起续保
        handleInitiateRenewal() {
            const { marginQuotaHisVO, marginPaymentInfo } = this
            const { guaranteeVO = {} } = marginPaymentInfo

            const extraProp = {
                backfillInfo: Object.freeze({
                    orderNo: guaranteeVO.orderNo,
                    projectName: marginQuotaHisVO.projectName,
                    amountPayable: marginQuotaHisVO.amountPayable,
                    policyHolderName: guaranteeVO.policyHolderName
                })
            }

            this.handleOpenModal('InitiateRenewalModal', extraProp)
        },
        /**
         * 获取 name 指定的二级面板组件所需源数据
         *
         * @param {string} name 二级面板组件名称
         */
        getLowerLevelDataSource(name) {
            return this.marginPaymentInfo[DATA_SOURCE_FIELD_MAPPING[name]]
        },
        /**
         * 是否显示 name 指定的二级面板组件
         *
         * @param {string} name 二级面板组件名称
         */
        showLowerLevelPanel(name) {
            return this.marginPaymentInfo[SHOW_CONTROL_FIELD_MAPPING[name]]
        }
    }
}
</script>
<style lang="scss">
.fixed-deposit-info-panel {
    &--empty {
        width: 100%;
        height: 120px;
        line-height: 120px;
        color: #999;
        text-align: center;
    }
}
</style>
