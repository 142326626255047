import { empty as emptyFilter } from '@utils'

export const ROW_KEY = 'id'

export const tableColumns = [
    {
        key: 'name',
        dataIndex: 'name',
        title: '姓名',
        width: 120,
        customRender: val => emptyFilter(val)
    },
    {
        key: 'gender',
        dataIndex: 'gender',
        title: '性别',
        width: 80,
        customRender: val => emptyFilter(val)
    },
    {
        key: 'age',
        dataIndex: 'age',
        title: '年龄',
        width: 80,
        customRender: val => emptyFilter(val)
    },
    {
        key: 'phone',
        dataIndex: 'phone',
        title: '联系方式',
        width: 150,
        customRender: val => emptyFilter(val)
    },
    {
        key: 'workTypeText',
        dataIndex: 'workTypeText',
        title: '工种',
        width: 180,
        customRender: val => emptyFilter(val)
    },
    {
        key: 'isContractExist',
        dataIndex: 'isContractExist',
        title: '合同',
        width: 90,
        customRender: val => emptyFilter(val)
    },
    {
        key: 'enterLeaveStateText',
        dataIndex: 'enterLeaveStateText',
        title: '状态',
        width: 80,
        customRender: val => emptyFilter(val)
    },
    {
        key: 'action',
        title: '操作',
        width: 168,
        fixed: 'right',
        scopedSlots: {
            customRender: 'action'
        }
    }
]
