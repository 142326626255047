<template>
    <div class="search-panel">
        <a-form-model :layout="layout" :model="model">
            <a-row :gutter="[30, 30]" class="search-panel__form-col-row">
                <slot />

                <slot
                    v-if="$slots.expansion && expansion"
                    name="expansion"
                />
            </a-row>

            <a-row class="search-panel__control-row">
                <template v-if="$slots.expansion">
                    <a-button
                        type="link"
                        @click="handleExpansion"
                    >
                        {{ expansionText }}

                        <a-icon
                            type="down"
                            :class="{ expansion }"
                            class="search-panel__arrow-icon"
                        />
                    </a-button>
                </template>

                <template v-if="showReset">
                    <a-button
                        type="link"
                        class="search-panel__reset-btn"
                        @click="handleRefresh"
                    >
                        {{ resetText }}
                    </a-button>
                </template>

                <template v-if="showSearch">
                    <a-button :loading="searchLoading" @click="handleSearch">
                        {{ searchText }}
                    </a-button>
                </template>
            </a-row>
        </a-form-model>
    </div>
</template>
<script>
import {
    FormModel as AFormModel,
    Button as AButton,
    Icon as AIcon,
    Row as ARow
} from 'ant-design-vue'

import './style.scss'

export default {
    name: 'ComponentsSearchPanel',
    components: {
        AFormModel,
        AButton,
        AIcon,
        ARow
    },
    props: {
        layout: {
            type: String,
            default: 'horizontal'
        },
        model: {
            type: Object,
            default: () => ({})
        },
        searchText: {
            type: String,
            default: '查询'
        },
        resetText: {
            type: String,
            default: '重置'
        },
        showReset: {
            type: Boolean,
            default: true
        },
        showSearch: {
            type: Boolean,
            default: true
        },
        searchLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expansion: false
        }
    },
    computed: {
        expansionText() {
            return this.expansion ? '收起' : '展开'
        }
    },
    methods: {
        handleSearch() {
            this.$emit('search')
        },
        handleRefresh() {
            this.handleFormReset()
            this.$nextTick(() => this.$emit('refresh'))
        },
        handleExpansion() {
            this.expansion = !this.expansion
        },
        handleFormReset() {
            this.$refs.form?.resetFields()
        }
    }
}
</script>
