import { message } from 'ant-design-vue'

import { isFunction, isUndefined, sortBy } from '@utils'
import { setToken, setRefreshToken, removeToken } from '@utils/auth'
import {
    SET_ROLE,
    SET_TOKEN,
    SET_ROUTES,
    SET_USER_INFO,
    SET_BUTTON_ROLE,
    SET_GLOBAL_DATA,
    SET_SYSTEM_NAME,
    SET_PROJECT_INFO,
    SET_ENTERPRISE_INFO,
    SET_INIT_INFORMATION,
    SET_AUTH_PROPERTIES,

    ADD_PAGE_F_API,
    DELETE_PAGE_F_API
} from './mutation-types'

import asyncRouterMap from '@router/asyncLoadRoutes'

import getUserInfoService from '@service/login/getUserInfoService'
import getInitService from '@service/getInitService'
import getMenusService from '@service/login/getMenusService'
import loginByPasswordService from '@service/login/loginByPasswordService'
import getAuthPropertiesService from '@/service/login/getAuthPropertiesService'
import getTokensByAccessKeyService from '@service/login/getTokensByAccessKeyService'

import { setGbtToken } from '../utils/auth'

const SYSTEM_NAME = process.env.VUE_APP_SYSTEM_NAME

/**
 * 通过roles判断是否与当前用户权限匹配
 * @param route
 * @param roles
 */
function hasPermission() {
    // route, roles
    // return roles[route.code]
    return true
    // TIP 企业没有权限控制，故而此处这样返回
}

/**
 * 格式化 权限为对象
 * @param permissions
 * @returns {{}}
 */
function getRule(permissions) {
    const menuObj = {}

    permissions = permissions || []
    permissions.forEach(item => {
        const { treeList } = item

        if (treeList && treeList.length) {
            Object.assign(menuObj, getRule(treeList))
        }

        menuObj[item.resourceCode] = item
    })

    return menuObj
}

/**
 * 获取要放在路由对象元信息上的 title 内容
 * @param {Object} matchedMenu 匹配到的菜单
 * @param {Object} meta 路由配置时配置的元信息
 */
function getMetaTitle(matchedMenu, meta = {}) {
    return matchedMenu.menuName || meta.title
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(routes, roles = {}) {
    const res = []

    routes.forEach(route => {
        const tmp = {
            ...route
        }

        const matchedMenu = hasPermission(tmp, roles)
        if (matchedMenu) {
            tmp.sort = matchedMenu.sort ?? route.sort ?? 0

            if (!isUndefined(tmp.meta)) {
                tmp.meta.title = getMetaTitle(matchedMenu, tmp.meta)
            } else {
                tmp.meta = {
                    title: getMetaTitle(matchedMenu)
                }
            }

            // 子路由权限
            const children = tmp.children
            if (children && children.length > 0) {
                tmp.children = filterAsyncRouter(children, roles)

                // 不拒绝重写重定向且有配置 redirect 属性
                if (tmp.doNotRewriteRedirect !== false && tmp.redirect) {
                    tmp.redirect = {
                        name: children[0].name
                    }
                }
            }

            res.push(tmp)
        }
    })

    return res.sort(sortBy('sort', true))
}

// 处理后端响应的 tokens
function handleResponseTokens(commit, { tongLoginResponse, oauthLoginResponse } = {}) {
    if (tongLoginResponse) {
        setGbtToken(tongLoginResponse.token)
    }

    const { accessToken } = oauthLoginResponse
    commit(SET_TOKEN, accessToken)
    setToken(accessToken)
    setRefreshToken(oauthLoginResponse.refreshToken)
}

/**
 * 登录
 * @param commit
 * @param loginInfo
 * @returns {Promise<void>}
 */
export async function loginByPassword({ commit }, loginInfo) {
    handleResponseTokens(commit, await loginByPasswordService(loginInfo))
}


/**
 * 处理市民通 app 扫码登录后带来的回调 accessKey
 * 根据 accessKey 获取对应的 tokens
 * 成功获取后，重置之前记录的用户信息及初始化信息等
 *
 * @param commit
 * @param {{ accessKey: string }} query
 */
export async function getTokensByAccessKey({ commit }, query = {}) {
    try {
        handleResponseTokens(commit, await getTokensByAccessKeyService(query))

        commit(SET_USER_INFO, void 0)
        commit(SET_INIT_INFORMATION, void 0)
    } catch (e) {
        message.warning(e.message)
    }
}


async function getUserDetails(commit, state) {
    const { userDetails, enterpriseInfo } = await getUserInfoService()

    if (!userDetails) throw new Error('请求用户信息失败，请重试')

    commit(SET_USER_INFO, userDetails)
    commit(SET_ENTERPRISE_INFO, enterpriseInfo)
    commit(SET_GLOBAL_DATA, {
        ...state.globalData,
        enterpriseName: enterpriseInfo?.enterpriseName,
        socialCreditCode: enterpriseInfo?.socialCreditCode
    })
}

async function getMenu(commit) {
    const menuTreeSet = await getMenusService()

    // if (!menuTreeSet.length) throw new Error('请求页面权限信息失败，请重试')

    const menu = getRule(menuTreeSet)
    commit(SET_ROLE, menu)
    commit(SET_BUTTON_ROLE, {})
}

/**
 * 获取用户信息
 * @param commit
 * @param state
 * @returns {Promise<void>}
 */
export async function getUserInfo({ commit, state }) {
    await Promise.all([getUserDetails(commit, state), getMenu(commit)])
}


/**
 * 登出系统
 * @param commit
 * @param state
 * @returns {Promise<void>}
 */
export async function logOut({ commit }, callback) {
    commit(SET_ROLE, [])
    commit(SET_USER_INFO, null)
    commit(SET_TOKEN, '')
    removeToken()

    if (isFunction(callback)) {
        callback()
        return
    }

    window.location.reload()
}


/**
 * 生成路由表
 * @param commit
 * @param state
 * @param roles
 */
export function generateRoutes({ commit, state }) {
    const role = state.role || {}
    const accessedRouters = filterAsyncRouter(asyncRouterMap, role)

    commit(SET_ROUTES, accessedRouters)

    return accessedRouters
}


/**
 * 获取初始化数据
 */
export async function getInitInfo({ commit }) {
    function changeGlobalData(info = {}) {
        commit(SET_GLOBAL_DATA, {
            areaCode: info.areaCode,
            areaName: info.areaName
        })
    }

    function changeSystemName(name = '', areaName = '') {
        commit(SET_SYSTEM_NAME, name || areaName + SYSTEM_NAME)
    }

    try {
        const initInfo = await getInitService() || {}

        changeGlobalData(initInfo)
        changeSystemName(initInfo.systemName, initInfo.areaName || '')

        commit(SET_INIT_INFORMATION, initInfo)
    } catch (error) {
        console.log(error)
    }
}


/**
 * 获取认证相关配置信息
 */
export async function getAuthProperties({ commit }) {
    try {
        const authProperties = await getAuthPropertiesService() || {}

        commit(SET_AUTH_PROPERTIES, authProperties)
    } catch (error) {
        console.log(error)
    }
}


export function setProjectInfo({ commit }, projectInfo) {
    localStorage.setItem('projectId', projectInfo.id)
    commit(SET_PROJECT_INFO, projectInfo)
}


export function addPageFApi({ commit }, fApi) {
    commit(ADD_PAGE_F_API, fApi)
}


export function deletePageFApi({ commit }, rootType) {
    commit(DELETE_PAGE_F_API, rootType)
}
