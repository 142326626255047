<template>
    <a-radio-group
        v-model="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :name="fieldCode"
    >
        <a-radio
            v-for="item in list"
            :key="item.dataValue"
            :value="item.dataValue"
        >
            {{ item.dataName }}
        </a-radio>
    </a-radio-group>
</template>

<script>
import { Radio as ARadio } from 'ant-design-vue'
import getDictListService from '@service/getDictListService'

export default {
    name: 'DictRadioSelect',
    components: {
        ARadio,
        ARadioGroup: ARadio.Group
    },
    props: {
        value: {
            // type: String
            type: [Number, String]
        },
        fieldCode: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            list: []
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(v) {
                console.log(v)
                this.$emit('input', v)
                this.$emit('change', v)
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        async getList() {
            try {
                this.list = await getDictListService({
                    dataType: this.fieldCode
                })
            } catch (e) {
                console.log(e)
                this.$message.error(e.message)
            }
        }
    }
}
</script>

<style lang="scss"></style>
