import { FILE_LABEL, FILE_KEY, FILE_UPLOAD_TYPE } from './parser/types'

function isObject(value) {
    return Object.prototype.toString.call(value) === '[object Object]'
}

export function deepMerge(prevObj, nextObj) {
    let newObj = {
        ...prevObj
    }
    for (let key in nextObj) {
        // 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
        // 如果obj2[key]没有值或者值不是对象，此时直接替换obj1[key]
        const newValue = newObj[key]
        const nextValue = nextObj[key]

        newObj[key] =
            newValue && isObject(newValue) && nextValue && isObject(nextValue)
                ? deepMerge(newObj[key], nextObj[key])
                : nextObj[key]
    }
    return newObj
}


export function mergeFieldOfFile(fields) {
    let labelIndex = -1
    let keyIndex = -1
    fields.forEach((field, index) => {
        if (field.componentType === FILE_LABEL) {
            labelIndex = index
        }

        if (field.componentType === FILE_KEY) {
            keyIndex = index
        }
    })

    if (labelIndex > -1 && keyIndex > -1) {
        fields[keyIndex].fieldName = fields[labelIndex].defaultValue
        fields.componentType = FILE_UPLOAD_TYPE
        fields.split(labelIndex, 1)
    }
}
