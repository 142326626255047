<!-- 项目信息面板 -->
<template>
    <information-panel
        title="项目信息"
        :loading="loading"
        :columns="columns"
        :data-source="projectInfo"
        :card-bordered="cardBordered"
    />
</template>
<script>
import InformationPanel from '@components/InformationPanel'

import { columns } from './config'

export default {
    name: 'ProjectInfoPanel',
    components: {
        InformationPanel
    },
    props: {
        loadData: {
            type: Function,
            required: true
        },
        cardBordered: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loading: false,
            projectInfo: {},
            columns: Object.freeze(columns)
        }
    },
    watch: {
        loadData: {
            handler(fn) {
                fn && this.getProjectInfo()
            },
            immediate: true
        }
    },
    methods: {
        async getProjectInfo() {
            try {
                this.loading = true

                // eslint-disable-next-line
                this.projectInfo = await this.loadData()
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
