<template>
    <div class="custom-no-data">
        <!-- 自定义内容 -->
        <div v-if="$slots.content" class="custom-no-data--content">
            <slot name="content" />
        </div>
        <!-- 初始化暂无数据内容 -->
        <div v-else class="custom-no-data--default">
            <img :src="icon" alt="" :width="iconWidth" class="custom-no-data--default_img">
            <div class="custom-no-data--default_text" :style="textStyle">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomNoData',
    props: {
        // 默认图标
        icon: {
            type: String,
            default: () => require('@/assets/images/noData/null@2x.png')
        },
        // 图标宽度 默认160
        iconWidth: {
            type: String,
            default: '160px'
        },
        // 文案-默认 暂无数据
        text: {
            type: String,
            default: '暂无数据'
        },
        // 自定义文字样式
        textStyle: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@assets/styles/varibles.scss';

.custom-no-data {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: -10%;
    &--default {
        margin: 0 auto;
        text-align: center;
        &_img {
            display: inline-block;
            margin-bottom: 8px;
        }
        &_text {
            font-size: $font-size;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #969799;
            line-height: 20px;
        }
    }
}
</style>
