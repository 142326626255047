import { INST_TYPE_BANK } from '@/constant/enum'

import {
    INPUT_TYPE,
    TEXTAREA_TYPE,
    BOOLEAN_WHETHER_SELECT_TYPE,
    ORIGINAL_WHETHER_SELECT_TYPE,
    INPUT_NUMBER_TYPE,
    INPUT_DECIMAL_TYPE,
    MONEY_TYPE,
    CNO_TYPE,
    PID_TYPE,
    TEL_TYPE,
    PHONE_TYPE,
    MAIL_TYPE,
    URL_TYPE,
    // RICH_TXT_TYPE,
    MONTH_PICKER_TYPE,
    DATE_PICKER_TYPE,
    DATE_RANGE_PICKER_TYPE,
    DATE_TIME_PICKER_TYPE,
    DATE_TIME_RANGE_PICKER_TYPE,
    SELECT_TYPE,
    RADIO_TYPE,
    CHECKBOX_TYPE,
    AREA_SELECT_TYPE,
    AREA_TREE_SELECT_TYPE,
    FILE_UPLOAD_TYPE,
    IMAGE_UPLOAD_TYPE,
    DESCRIPCTION_TYPE,
    INDUSTRY_COMPETENT_UNIT,
    HUMAN_AND_SOCIAL_SUPERVISION_UNIT,
    SALARY_MONTH_SELECT_TYPE,
    CURRENT_AREA_TYPE,
    TEAM_PERSON_SELECT_TYPE,
    MANAGER_SELECT_TYPE,
    PROJECT_SEARCH_TYPE,
    BANK_SELECT_TYPE,
    BANK_BRAND_SELECT_TYPE
} from './types'
import { deepMerge } from '../utils'
import {
    validateMoney,
    validateInteger,
    validateNumber,
    validateSocialCreditCode,
    validateIDCard,
    validateLandline,
    validatePhoneNumber,
    validateEmail,
    validateUrl
} from '../validate/validates'

import _ from 'lodash'

import { normalFieldParser } from './index'

const format = function (field = {}, data, column, emits, pageRef) {
    let normalConfig = normalFieldParser(
        field,
        this.validate,
        column,
        emits,
        pageRef
    )

    if (!_.isUndefined(data[field.attributeCode])) {
        normalConfig.value = data[field.attributeCode]
    }

    return deepMerge(this.config || {}, normalConfig)
}

/**
 * 使用是/否选择器
 *
 * @param {boolean} useOriginalValue 是否使用枚举配置原始数据类型
 */
function useWhetherSelect(useOriginalValue) {
    return {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }

            const config = {
                type: 'WhetherSelect',
                props: {
                    useOriginalValue,
                    allowClear: Boolean(column.nullAllowed)
                }
            }

            return deepMerge(config || {}, normalConfig)
        }
    }
}

export default {
    // 输入框
    [INPUT_TYPE]: {
        format,
        config: {
            type: 'input'
        }
    },
    // 大输入框
    [TEXTAREA_TYPE]: {
        format,
        config: {
            type: 'input',
            props: {
                type: 'textarea',
                autoSize: {
                    minRows: 3
                }
            }
        }
    },
    // 整数数字框
    [INPUT_NUMBER_TYPE]: {
        format,
        config: {
            type: 'input',
            props: {
                precision: 0
            }
        },
        validate: [
            {
                validator: validateInteger
            }
        ]
    },
    // 小数点数字框
    [INPUT_DECIMAL_TYPE]: {
        format,
        config: {
            type: 'input'
        },
        validate: [
            {
                validator: validateNumber
            }
        ]
    },
    // 金额数字框
    [MONEY_TYPE]: {
        format,
        config: {
            type: 'AmountInput'
        },
        validate: [
            {
                validator: validateMoney
            }
        ]
    },
    // 社会信用代码
    [CNO_TYPE]: {
        format,
        config: {
            type: 'input'
        },
        validate: [
            {
                validator: validateSocialCreditCode
            }
        ]
    },
    // 身份证
    [PID_TYPE]: {
        format,
        config: {
            type: 'input'
        },
        validate: [
            {
                validator: validateIDCard
            }
        ]
    },
    // 座机
    [TEL_TYPE]: {
        format,
        config: {
            type: 'input'
        },
        validate: [
            {
                validator: validateLandline
            }
        ]
    },
    // 国内手机号
    [PHONE_TYPE]: {
        format,
        config: {
            type: 'input'
        },
        validate: [
            {
                validator: validatePhoneNumber
            }
        ]
    },
    // 邮箱
    [MAIL_TYPE]: {
        format,
        config: {
            type: 'input'
        },
        validate: [
            {
                validator: validateEmail
            }
        ]
    },
    // 地址
    [URL_TYPE]: {
        format,
        config: {
            type: 'input'
        },
        validate: [
            {
                validator: validateUrl
            }
        ]
    },
    // 年月日日期 选择框
    [DATE_PICKER_TYPE]: {
        format,
        config: {
            type: 'DatePicker',
            props: {
                format: 'YYYY-MM-DD',
                showTime: false
            }
        }
    },
    // 年月日日期 区间 选择框
    [DATE_RANGE_PICKER_TYPE]: {
        format,
        config: {
            type: 'RangePicker',
            props: {
                format: 'YYYY-MM-DD',
                showTime: false
            }
        }
    },
    // 年月
    [DATE_TIME_PICKER_TYPE]: {
        format,
        config: {
            type: 'DatePicker',
            props: {
                format: 'YYYY-MM-DD HH:mm:ss',
                showTime: true

            }
        }
    },
    [MONTH_PICKER_TYPE]: {
        format,
        config: {
            type: 'MonthPicker',
            props: {
                format: 'YYYY-MM'
            }
        }
    },
    // 年月日时分秒日期
    [DATE_TIME_RANGE_PICKER_TYPE]: {
        format,
        config: {
            type: 'RangePicker',
            props: {
                format: 'YYYY-MM-DD HH:mm:ss',
                showTime: true
            }
        }
    },
    // 下拉框 / 单选框 / 复选框
    [RADIO_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            const config = {
                type: 'CustomRadio'
            }
            return deepMerge(config || {}, normalConfig)
        }
    },
    [CHECKBOX_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            const config = {
                type: 'DictSelect'
            }
            return deepMerge(config || {}, normalConfig)
        }
    },
    [SELECT_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            const config = {
                type: 'DictSelect'
            }
            return deepMerge(config || {}, normalConfig)
        }
    },
    /**
     * 是/否选择器
     * 选中值数据类型为 boolean 类型
     */
    [BOOLEAN_WHETHER_SELECT_TYPE]: useWhetherSelect(false),
    /**
     * 是/否选择器
     * 选中值数据类型为枚举值数据类型，即使用原始数据类型
     */
    [ORIGINAL_WHETHER_SELECT_TYPE]: useWhetherSelect(true),
    // 工资月下拉框
    [SALARY_MONTH_SELECT_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'SalaryMonthSelect'
        }
    },
    // 行业主管单位下拉框
    [INDUSTRY_COMPETENT_UNIT]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'IndustryCompetentUnit'
        }
    },
    // 人社监管单位下拉框
    [HUMAN_AND_SOCIAL_SUPERVISION_UNIT]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'HACSupervisionUnitSelect'
        }
    },
    // 班组人员下拉框
    [TEAM_PERSON_SELECT_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef,
                data
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'TeamPersonSelect'
        }
    },
    // 经办人下拉框
    [MANAGER_SELECT_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef,
                data
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'ManagerSelect'
        }
    },
    // 项目下拉框
    [PROJECT_SEARCH_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef,
                data
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'ProjectSelect'
        }
    },
    // 银行下拉框
    [BANK_SELECT_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef,
                data
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'FinancialInstitutionSelect',
            props: {
                institutionType: INST_TYPE_BANK
            }
        }
    },
    // 银行品牌下拉框
    [BANK_BRAND_SELECT_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef,
                data
            )

            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'FinancialInstitutionBrandSelect',
            props: {
                institutionType: INST_TYPE_BANK
            }
        }
    },
    [AREA_SELECT_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )
            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'AddressSelect'
        },
        validate: []
    },
    [AREA_TREE_SELECT_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )
            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'AreaTreeSelect'
        }
    },
    [CURRENT_AREA_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )
            if (!_.isUndefined(data[field.attributeCode])) {
                normalConfig.value = data[field.attributeCode]
            }
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'CurrentArea'
        },
        validate: []
    },
    // 文件上传
    [FILE_UPLOAD_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            try {
                let normalConfig = normalFieldParser(
                    field,
                    this.validate,
                    column,
                    emits,
                    pageRef
                )

                if (data[field.attributeCode]) {
                    let parseData = JSON.parse(data[field.attributeCode])
                    if (_.isObject(parseData) && !_.isArray(parseData)) {
                        parseData = [parseData]
                    } else if (!Array.isArray(parseData)) {
                        parseData = ''
                    }
                    normalConfig.value = JSON.stringify(parseData)
                } else {
                    normalConfig.value = ''
                }

                return deepMerge(this.config || {}, normalConfig)
            } catch (err) {
                let normalConfig = normalFieldParser(
                    field,
                    this.validate,
                    column,
                    emits,
                    pageRef
                )
                normalConfig.value = ''
                return deepMerge(this.config || {}, normalConfig)
            }
        },
        config: {
            type: 'UploadFile', // 'UploadFile',
            value: ''
        }
    },
    // 图片上传
    [IMAGE_UPLOAD_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            try {
                let normalConfig = normalFieldParser(
                    field,
                    this.validate,
                    column,
                    emits,
                    pageRef
                )

                if (data[field.attributeCode]) {
                    let parseData = JSON.parse(data[field.attributeCode])
                    if (_.isObject(parseData) && !_.isArray(parseData)) {
                        parseData = [parseData]
                    } else if (!Array.isArray(parseData)) {
                        parseData = ''
                    }
                    normalConfig.value = JSON.stringify(parseData)
                } else {
                    normalConfig.value = ''
                }

                return deepMerge(this.config || {}, normalConfig)
            } catch (err) {
                let normalConfig = normalFieldParser(
                    field,
                    this.validate,
                    column,
                    emits,
                    pageRef
                )
                normalConfig.value = ''
                return deepMerge(this.config || {}, normalConfig)
            }
        },
        config: {
            type: 'UploadImage',
            value: ''
        }
    },
    [DESCRIPCTION_TYPE]: {
        format(field = {}, data, column, emits, pageRef) {
            let normalConfig = normalFieldParser(
                field,
                this.validate,
                column,
                emits,
                pageRef
            )
            return deepMerge(this.config || {}, normalConfig)
        },
        config: {
            type: 'CustomDescriptions'
        },
        validate: []
    }
    // [CUSTOM_AUTO_COMPLETE_TYPE]: {
    //     format(field = {}) {
    //         let normalConfig = normalFieldParser(field)
    //         const { queryConditions } =
    //             field.projectTextField || field.enterpriseTextField || {}
    //         normalConfig = deepMerge(normalConfig, {
    //             props: {
    //                 queryConditions,
    //                 list: []
    //             }
    //         })
    //         return deepMerge(this.config || {}, normalConfig)
    //     },
    //     config: {
    //         type: 'FcCustomSelect'
    //     }
    // }
}
