// 输入框 input
export const INPUT_TYPE = 'TXT'
// 大输入框 textarea
export const TEXTAREA_TYPE = 'TXT_AREA'
// 整数数字框
export const INPUT_NUMBER_TYPE = 'NUM'
// 小数点数字框
export const INPUT_DECIMAL_TYPE = 'FLOAT'
// 金额
export const MONEY_TYPE = 'MONEY'
// 统一社会信用代码
export const CNO_TYPE = 'CNO'
// 身份证
export const PID_TYPE = 'PID'
// 座机
export const TEL_TYPE = 'TEL'
// 手机
export const PHONE_TYPE = 'PHONE'
// 邮箱
export const MAIL_TYPE = 'MAIL'
// 网址
export const URL_TYPE = 'URL'
// 富文本
export const RICH_TXT_TYPE = 'RICH_TXT'
// 年月日 日期
export const DATE_PICKER_TYPE = 'DATE'
// 年月
export const MONTH_PICKER_TYPE = 'MONTH'
// 年月日 区间 选择框
export const DATE_RANGE_PICKER_TYPE = 'DATE_RANGE'
// 年月日时分秒 日期
export const DATE_TIME_PICKER_TYPE = 'DATE_TIME'
// 年月日时分秒 区间 选择框
export const DATE_TIME_RANGE_PICKER_TYPE = 'DATE_TIME_RANGE'
// 单选框
export const RADIO_TYPE = 'DICT_RADIO'
// 复选框
export const CHECKBOX_TYPE = 'DICT_CHECKBOX'
// 下拉框
export const SELECT_TYPE = 'DICT_SELECT'
// 地区选择框
export const AREA_SELECT_TYPE = 'ADDRESS'
// 当前地区包含市
export const CURRENT_AREA_TYPE = 'CURRENT_AREA'
// 地区树形选择（省市区）
export const AREA_TREE_SELECT_TYPE = 'AREA_TREE_SELECT'
// 文件上传
export const FILE_UPLOAD_TYPE = 'FILE'
// 图片上传
export const IMAGE_UPLOAD_TYPE = 'IMAGE'

// 必检清单弹窗
export const CHECK_LIST_TYPE = 'CHECK_LIST'
// 自动完成
export const CUSTOM_AUTO_COMPLETE_TYPE = 'CUSTOM_AUTO_COMPLETE'
// 描述
export const DESCRIPCTION_TYPE = 'DESCRIPCTION'

export const AMOUNT_INPUT = 'AMOUNT_INPUT'

// 行业主管单位
export const INDUSTRY_COMPETENT_UNIT = 'INDUSTRY_COMPETENT_UNIT'

// 人社监管单位
export const HUMAN_AND_SOCIAL_SUPERVISION_UNIT = 'HUMAN_AND_SOCIAL_SUPERVISION_UNIT'

// 工资月
export const SALARY_MONTH_SELECT_TYPE = 'SALARY_MONTH'

// 银行
export const BANK_SELECT_TYPE = 'BANK'
// 银行品牌
export const BANK_BRAND_SELECT_TYPE = 'BANK_BRAND'


export const PROJECT_SEARCH_TYPE = 'PROJECT_SEARCH'
export const UNIT_SEARCH_TYPE = 'UNIT_SEARCH'

// 班组人员
export const TEAM_PERSON_SELECT_TYPE = 'TEAM_MEMBER'
export const MANAGER_SELECT_TYPE = 'MANAGER_SELECT'


// 新形文件上传内容
export const FILE_LABEL = 'FILE_LABEL'
export const FILE_KEY = 'FILE_KEY'

// 是/否 选择器
// 值类型为 boolean 类型
export const BOOLEAN_WHETHER_SELECT_TYPE = 'WHETHER_SELECT'
// 值类型为枚举值原始类型
export const ORIGINAL_WHETHER_SELECT_TYPE = 'ORIGINAL_WHETHER_SELECT_TYPE'
