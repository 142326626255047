<template>
    <search-tree :value="checkKeysList" @change="v => (checkKeysList = v)" />
</template>

<script>
import childrenRefreshMixin from '@/mixins/createPage/childrenRefresh'
import getFormData from '@/mixins/createPage/formData'
import eventMixin from '@/mixins/createPage/event'

export default {
    name: 'CustomSearchTree',
    components: {
        SearchTree: () => import('@weights/SearchTree')
    },
    mixins: [childrenRefreshMixin, getFormData, eventMixin],
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            checkKeysList: []
        }
    },
    watch: {
        data: {
            handler(data) {
                try {
                    if (data.checkListString) {
                        this.checkKeysList =
                            data.checkListString.split(',') || []
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            immediate: true
        }
    },
    methods: {
        getFormData() {
            return {
                checkListString: this.checkKeysList.join(',')
            }
        }
    }
}
</script>

<style></style>
