<template>
    <div class="custom-tabs-wrapper">
        <template v-if="isCardMode">
            <a-tabs
                v-model="currentKey"
                type="card"
                class="card_tabbar"
                :animated="animated"
                @change="tabChange"
            >
                <template v-for="(item, index) in list">
                    <a-tab-pane
                        :key="index"
                        :force-render="true"
                        :tab="getTabName(item)"
                    >
                        <create-page
                            :ref="`tabs-${index}`"
                            :rule="rules[index]"
                            :loading="item.loading"
                            :is-global-f-api="true"
                            :now-query-list="item.nowQueryList"
                        />
                    </a-tab-pane>
                </template>
            </a-tabs>
        </template>
        <template v-else>
            <a-tabs :animated="animated" @change="tabChange">
                <template v-for="(item, index) in list">
                    <a-tab-pane
                        :key="index"
                        :tab="getTabName(item)"
                    >
                        <create-page
                            :ref="`tabs-${index}`"
                            :rule="rules[index]"
                            :loading="item.loading"
                            :is-global-f-api="true"
                            :now-query-list="item.nowQueryList"
                        />
                    </a-tab-pane>
                </template>
            </a-tabs>
        </template>
    </div>
</template>

<script>
import { Tabs as ATabs } from 'ant-design-vue'

import { parserPanel } from '@/weights/CreatePage/parse'

// mixins
import childrenRefreshMixin from '@/mixins/createPage/childrenRefresh'
import getFormData from '@/mixins/createPage/formData'
import eventMixin from '@/mixins/createPage/event'
import { EVENT_TYPE_DEFAULT } from '@/constant/enum'

import queryMixin from '@/mixins/createPage/query'

import isBoolean from 'lodash/isBoolean'

// 是否自己渲染页面的样式
const IS_SELT_CONTROL = 1
// 原始tabbar
const DEFAULT_MODE = 1
// 按钮模式tabbar
const CARD_MODE = 2

export default {
    name: 'CustomTabs',
    components: {
        ATabs,
        ATabPane: ATabs.TabPane,
        CreatePage: () => import('@/weights/CreatePage')
    },
    mixins: [
        childrenRefreshMixin, getFormData, eventMixin, queryMixin
    ],
    props: {
        list: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            type: String,
            default: ''
        },
        selfControl: {
            type: Number
        },
        mode: {
            type: Number,
            default: DEFAULT_MODE
        },
        animated: {
            type: Object,
            default: () => ({
                inkBar: true,
                tabPane: false
            })
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        uid: {
            type: String
        },
        stepId: {
            type: String
        },
        rule: {
            type: Array,
            default: () => []
        },
        children: {
            type: Array,
            defualt: () => []
        },
        showTotal: {
            type: Number
        }
    },
    data() {
        return {
            rules: [],
            currentKey: 0, // 仅按钮模式需要控制的key

            requireParams: {}
        }
    },
    computed: {
        isCardMode() {
            return this.mode === CARD_MODE
        }
    },
    watch: {
        list: {
            handler(val) {
                if (val) {
                    this.fisrtTabInit()
                }
            },
            immediate: true
        }
    },
    methods: {
        async tabChange(tabIndex) {
            this.currentKey = tabIndex
            const tab = this.list[tabIndex]
            // 如果是自己控制需要
            if (tab.selfControl === IS_SELT_CONTROL) {
                this.$store.dispatch('deletePageFApi')
                setTimeout(async () => {
                    const ref = this.$refs[`tabs-${tabIndex}`][0]
                    ref.changePageFApi()

                    tab.loading = true
                    // 获取默认加载事件
                    const initEvent = tab.eventList.filter(
                        event => event.eventType === EVENT_TYPE_DEFAULT
                    )[0]

                    // 获取全局参数
                    const { globalQuery: requireParams } = this.getGlobalQuery({})

                    const _params = {}
                    requireParams.forEach(param => {
                        _params[param.fieldCode] = param.defaultValue
                    })

                    // 如果存在事件
                    if (initEvent) {
                        const { eventResult } = await this.triggerEvent(
                            initEvent,
                            {},
                            {
                                uid: this.uid,
                                id: this.stepId,
                                ..._params
                            }
                        )
                        eventResult.props = {
                            uid: this.uid,
                            stepId: this.stepId
                        }
                        // TIP 临时加看效果
                        // eventResult.componentType = 'page_0'
                        const { globalQuery, nowQueryList } = ref.getGlobalQuery(eventResult, {})

                        const formatData = parserPanel(eventResult, globalQuery, _params)

                        tab.nowQueryList = nowQueryList

                        if (formatData?.data?.dataString?.total) {
                            tab.tabName = `${formatData.layoutName}(${formatData.data.dataString.total})`
                        }
                        this.$set(this.rules, tabIndex, [formatData])
                        tab.loading = false
                    } else {
                        // 班组管理 渲染本地组件
                        if (tab.componentType?.includes('teamManagement')) {
                            const formatData = parserPanel(tab)
                            this.$set(this.rules, tabIndex, [formatData])
                            tab.loading = false
                        }
                    }
                }, 10)

            } else {
                if (tab) {

                    const { globalQuery, nowQueryList } = this.getGlobalQuery(tab, {})
                    tab.nowQueryList = nowQueryList


                    tab.props = {
                        uid: this.uid,
                        stepId: this.stepId
                    }

                    const formatData = parserPanel(tab, globalQuery)
                    this.$set(this.rules, tabIndex, [formatData])
                }
            }
        },
        fisrtTabInit() {
            const tabIndex = 0
            this.currentKey = tabIndex
            const tab = this.list[tabIndex]


            // 如果已经有初始化的数据，则直接加载
            if (tab) {

                const { globalQuery, nowQueryList } = this.getGlobalQuery(tab, {})
                tab.nowQueryList = nowQueryList


                tab.props = {
                    uid: this.uid,
                    stepId: this.stepId
                }

                const formatData = parserPanel(tab, globalQuery)
                this.$set(this.rules, tabIndex, [formatData])
            } else {
                // 重新请求数据
                this.tabChange(0)
            }

        },
        /** 按钮模式tabs切换 */
        buttonTabChange(e) {
            this.tabChange(e.target.value)
        },
        getTabName(tab) {
            if (this.showTotal && tab.tabName) {
                return tab.tabName
            }
            if (this.showTotal) {
                if (tab?.data?.dataString?.total) {
                    return `${tab.layoutName}(${tab.data.dataString.total})`
                }
            }

            return `${tab.layoutName}`

        },
        tabDataRefresh(data = {}) {
            const { currentKey } = this
            const tab = this.list[currentKey]
            if (this.showTotal && data.total) {
                tab.tabName = `${tab.layoutName}(${tab.data.dataString.total})`
            }
        },
        vaildatorTabsForm() {
            const length = this.list.length
            const res = {
                valid: true,
                fail: {}
            }
            for (let tabIndex = 0; tabIndex < length; tabIndex++) {
                const ref = this.$refs[`tabs-${tabIndex}`] ? this.$refs[`tabs-${tabIndex}`][0] : null
                if (ref) {
                    const { valid, fail = [] } = ref.vaildatorOnePageFormData(ref.fApi)
                    if (isBoolean(valid) && !valid) {
                        res.valid = valid
                        res.fail = {
                            ...res.fail,
                            ...fail
                        }
                    }
                }
            }

            return res
        },
        getTabsFormData() {
            const length = this.list.length
            let res = {}
            for (let tabIndex = 0; tabIndex < length; tabIndex++) {
                const ref = this.$refs[`tabs-${tabIndex}`] ? this.$refs[`tabs-${tabIndex}`][0] : null
                if (ref) {
                    const data = ref.getOnePageFormData(ref.fApi)
                    res = {
                        ...res,
                        ...data
                    }
                }
            }

            return res
        }
    },
    provide() {
        return {
            tabDataRefresh: this.tabDataRefresh
        }
    }
}
</script>

<style lang="scss">
.ant-tabs-bar {
    background: #fff;
    margin-bottom: 8px;

    .ant-tabs-nav {
        height: 48px;
        margin-left: 12px;

        .ant-tabs-tab {
            padding: 12px;
            margin: 0 12px 0 0;
        }
    }
}

.card_tabbar {
    .ant-tabs-bar {
        margin-bottom: 0;
        margin-left: 0px !important;
        background: transparent;
    }

    .ant-tabs-nav {
        margin-left: 0;
    }

    .ant-tabs-tab {
        border: none !important;
    }
}

.button_tabs {
    .ant-tabs-bar {
        display: none;
    }
}

.button_tabbar {
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
}
</style>
