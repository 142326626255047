<template>
    <search-panel
        :model="form"
        layout="vertical"
        :search-loading="searchLoading"
        @search="handleSearch"
        @refresh="handleRefresh"
    >
        <template v-for="renderItem in renderQueryList">
            <template :slot="renderItem.slotName">
                <template v-for="item in renderItem.queryList">
                    <form-col
                        :key="item.id"
                        :span="6"
                        :label-span="24"
                        :wrapper-span="24"
                        :style="formColStyle"
                        :prop="item.fieldCode"
                        :label="item.fieldName"
                    >
                        <data-entry-component
                            v-model="form[item.fieldCode]"
                            :key="item.fieldCode"
                            :field-name="item.fieldName"
                            :field-code="item.fieldCode"
                            :component-type="item.componentType"
                            :data-tag="item.dataTag"
                            style="width: 100%"
                        />
                    </form-col>
                </template>
            </template>
        </template>
    </search-panel>
</template>

<script>
import FormCol from '@components/FormCol'
import SearchPanel from '@components/SearchPanel'
import DataEntryComponent from '@weights/searchPanel/DataEntryComponent'

import { filterParams } from '@utils/search'
import { DEFAULT_ITEM_COUNT, generateRenderQueryList } from '@weights/searchPanel/renderQueryList'

export default {
    name: 'TableSearchPanel',
    components: {
        FormCol,
        SearchPanel,
        DataEntryComponent
    },
    props: {
        queryList: {
            type: Array,
            default: () => []
        },
        searchLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: {},
            defaultForm: {},
            renderQueryList: []
        }
    },
    computed: {
        renderQueryListLen() {
            return this.renderQueryList.length
        },
        formColStyle() {
            return this.renderQueryList < DEFAULT_ITEM_COUNT ? void 0 : 'padding-bottom: 0'
        }
    },
    watch: {
        queryList: {
            handler(list) {
                this.initForm(list)
                this.renderQueryList = generateRenderQueryList(list)
            },
            immediate: true
        },
        form: {
            handler(form) {
                form && this.$emit('params-change', form)
            },
            immediate: true
        }
    },
    methods: {
        initForm(list = []) {
            // 默认值可以从query里获取
            const query = this.$route.query
            const form = {}
            const defaultForm = {}

            list.forEach(({ fieldCode, defaultValue }) => {
                form[fieldCode] = query[fieldCode] || defaultValue || void 0
                defaultForm[fieldCode] = void 0
            })

            this.form = form
            this.defaultForm = defaultForm
        },
        handleSearch() {
            this.$emit('search', filterParams(this.form))
        },
        handleRefresh() {
            this.$set(this, 'form', {
                ...this.defaultForm
            })
            this.$nextTick(() => this.$emit('refresh'))
        }
    }
}
</script>
