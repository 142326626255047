import store from '@store'

import isNumber from 'lodash/isNumber'

import { message } from 'ant-design-vue'
import { calculator } from '4d-space-bag'
import { tailOff, isString, isFunction } from '@utils'

import Middleware from '@utils/Middleware'

import { IMAGE_UPLOAD_TYPE } from '../parser/types'

import {
    PROJECT_STATUS_TO_BEGIN,

    PAYMENT_TYPE_GENERAL,
    PAYMENT_TYPE_EXEMPTION,
    PAYMENT_TYPE_DEFERREED,
    PAYMENT_TYPE_COMBINATION,

    PAYMENT_METHOD_CASH,
    PAYMENT_METHOD_GUARANTEE,

    QUOTA_MODE_PROPORTIONALLY,

    CONTRACTOR_TYPE_CONSTRUCTION,
    CONTRACTOR_TYPE_CONSTRUCTION_GENERAL_CONTRACTOR,

    MANAGER_AREA_CODE_HEFEI,

    PROJECT_TYPE_YJJH,
    PROJECT_TYPE_EXPRESSWAY,
    PROJECT_TYPE_RAIL_TRAFFIC,

    AUTOMATICALLY_MATCHES_SUPERVISOR_REGION_ENABLED,

    ADVANCE_PAYMENT_APPLICATION_GET_AMOUNT_ENABLED
} from '@constant/enum'

import getMarginDifferentiationService from '@service/project/getMarginDifferentiationService'

const middleware = new Middleware()

async function getMarginDifferentiation(params) {
    try {
        const result = await getMarginDifferentiationService(params)

        return result
    } catch (error) {
        message.error(error.message)
        return
    }
}

function createDelayTask(task, delay = 10) {
    let timer = setTimeout(() => {
        task()

        clearTimeout(timer)
        timer = null
    }, delay)
}

function defineProperties(obj, properties) {
    const _properties = {}

    Object.keys(properties).forEach(key => {
        _properties[key] = {
            value: properties[key],
            writable: true,
            enumerable: true,
            configurable: true
        }
    })

    Object.defineProperties(obj, _properties)
}

function createHiddenFieldsHandler() {
    let preBool = null
    let backupData = null

    return (bool, fields, fApi) => {
        if (preBool === bool) return

        let formData = backupData
        if (bool || formData === null) {
            formData = fApi.formData()
        }

        const processedData = {}
        fields.forEach(field => {
            const isSingle = isString(field)
            const fieldCode = isSingle ? field : field.code
            const newValue = bool ? null : formData[fieldCode]

            processedData[fieldCode] = newValue
            if (!isSingle && isFunction(field.onChange)) {
                field.onChange(newValue, fApi)
            }
        })

        fApi.setValue(processedData)
        fApi.hidden(bool, Object.keys(processedData))

        preBool = bool
        backupData = bool ? formData : null
    }
}

function generateAgentChangeHandler() {
    return (val, pageRef) => {
        const fApi = pageRef.fApi
        const agentDom = fApi.el('agent')

        if (val) {
            const list = agentDom?.list || []
            const person = list.find(person => person.name === val)
            fApi.setValue('agentPhone', person.phone)
        }
    }
}

function generateWhetherSelectChangeHandler(affectFields) {
    const hiddenFields = createHiddenFieldsHandler()

    return (bool, pageRef) => {
        hiddenFields(!bool, affectFields, pageRef.fApi)
    }
}


/**
 * 处理项目相关的联动逻辑处理
 *
 * 具体内容如下：
 * 1. 行业主管类型、主管地区、行业主管单位间的联动
 * 2. 主管地区、人社监管单位间的联动
 * 3. 工程类型、主管地区间的联动
 */
middleware.use(({ emits, vueCtx }, next) => {
    const projectField = [
        'project_10',
        'project_20',
        'project_20_1',
        'project_base_information',
        'project_choose_10'
    ]

    const field = vueCtx.field
    if (projectField.some(_field => field.includes(_field))) {
        const curEmits = {
            competentUnitType: {
                change: (orgType, pageRef) => {
                    const fApi = pageRef.fApi
                    const competentUnitIdDom = fApi.el('competentUnitId')
                    const orgAreaCode = fApi.getValue('competentDepartmentAreaCode')

                    if (orgType && orgAreaCode) {
                        fApi.setValue('competentUnitId', '')
                        competentUnitIdDom && competentUnitIdDom.getList({
                            orgAreaCode,
                            orgType
                        })
                    }
                }
            },

            competentDepartmentAreaCode: {
                change: (orgAreaCode, pageRef) => {
                    const fApi = pageRef.fApi
                    const orgType = fApi.getValue('competentUnitType')
                    const competentUnitIdDom = fApi.el('competentUnitId')

                    if (orgType && orgAreaCode) {
                        fApi.setValue('competentUnitId', '')
                        competentUnitIdDom && competentUnitIdDom.getList({
                            orgAreaCode,
                            orgType
                        })
                    }

                    if (orgAreaCode) {
                        const regulatoryUnitIdDom = fApi.el('regulatoryUnitId')
                        fApi.setValue('regulatoryUnitId', '')
                        regulatoryUnitIdDom && regulatoryUnitIdDom.getList({
                            orgAreaCode
                        })
                    }
                }
            },

            projectType: {
                change: (orgAreaCode, pageRef) => {
                    /**
                     * 合肥农民工新增需求
                     *
                     * `工程类型` 当选择 `引江济淮、轨道交通、高速公路`，对应 `主管地区` 自动变更为 `合肥市`
                    */
                    const competentAreaAutoMatch = store.getters.initInformation.competentAreaAutoMatch
                    if (competentAreaAutoMatch !== AUTOMATICALLY_MATCHES_SUPERVISOR_REGION_ENABLED) return

                    const fApi = pageRef.fApi
                    const projectType = fApi.getValue('projectType')
                    const projectTypeList = [
                        PROJECT_TYPE_YJJH,
                        PROJECT_TYPE_EXPRESSWAY,
                        PROJECT_TYPE_RAIL_TRAFFIC
                    ]

                    if (projectTypeList.some(code => code === projectType)) {
                        const competentDepartmentAreaCodeDom = fApi.el('competentDepartmentAreaCode')
                        competentDepartmentAreaCodeDom && competentDepartmentAreaCodeDom.setAreaValue({
                            areaCode: MANAGER_AREA_CODE_HEFEI
                        })
                    }
                }
            }
        }

        defineProperties(emits, curEmits)

        createDelayTask(() => {
            const fApi = vueCtx.fApi
            const orgAreaCode = fApi.getValue('competentDepartmentAreaCode')

            fApi.trigger('competentDepartmentAreaCode', 'change', orgAreaCode)
            fApi.clearValidateState('competentDepartmentAreaCode')
        })
    }

    return next()
})


middleware.use(({ vueCtx }, next) => {
    const projectEditField = ['project_20', 'project_20_1']

    const field = vueCtx.field
    if (projectEditField.some(_field => field.includes(_field))) {
        createDelayTask(() => {
            const fApi = vueCtx.fApi
            fApi.el('projectStatus')?.setDisabled([PROJECT_STATUS_TO_BEGIN])
        })
    }

    return next()
})


middleware.use(({ vueCtx }, next) => {
    if (vueCtx.field === 'project_10_1') {
        createDelayTask(() => {
            const fApi = vueCtx.fApi
            const globalData = vueCtx.$store.getters.globalData

            fApi.setValue('generalConstructionContractingName', globalData.enterpriseName)
            fApi.setValue('socialCreditCode', globalData.socialCreditCode)
        })
    }

    return next()
})


middleware.use(({ fieldList, vueCtx }, next) => {
    if (vueCtx.field === 'project_document_30_1') {
        const { data } = vueCtx

        if (data.attributeType === IMAGE_UPLOAD_TYPE) {
            fieldList.forEach(field => {
                if (field.fieldCode === 'attributeValue') {
                    field.componentType = IMAGE_UPLOAD_TYPE
                }
            })
        }
    }

    return next()
})


/**
 * 五方责任主体编辑时
 * 根据 承建类型 控制一些字段的隐藏和编辑状态
 */
middleware.use(({ vueCtx }, next) => {
    if (vueCtx.field === 'five_party_responsible_parties_20_1') {
        createDelayTask(() => {
            const fApi = vueCtx.fApi
            const contractorTypeField = 'contractorType'
            const contractorTypeTextField = 'contractorTypeText'

            /**
             * 承建类型（string）
             *
             * 1：施工总包单位，2：建设单位
             * 3: 监理单位，4: 设计单位，5：勘察单位
             */
            const contractorType = fApi.getValue(contractorTypeField)

            const determineContractorTypes = [
                CONTRACTOR_TYPE_CONSTRUCTION_GENERAL_CONTRACTOR,
                CONTRACTOR_TYPE_CONSTRUCTION
            ]
            if (determineContractorTypes.includes(contractorType)) {
                /**
                 * 当承建类型为 施工总包单位、建设单位 时
                 * 1. 展示承建类型文本，隐藏承建类型选择器
                 * 2. 将 单位名称、统一社会信用代码 设为不可编辑
                 */
                fApi.disabled(true, 'participatingUnitName')
                fApi.disabled(true, 'participatingUnitCode')

                fApi.disabled(true, contractorTypeTextField)
                fApi.hidden(true, contractorTypeField)
            } else {
                /**
                 * 当承建类型为 监理单位、设计单位、勘察单位 时
                 * 1. 隐藏承建类型文本
                 */
                fApi.hidden(true, contractorTypeTextField)
            }
        })
    }

    return next()
})


/**
 * 处理预缴申请的联动逻辑处理
 */
middleware.use(({ emits, fieldList, vueCtx }, next) => {
    if (vueCtx.field === 'quato_detail') {
        const curEmits = {
            quotaMode: {
                change: (val, pageRef) => {
                    const fApi = pageRef.fApi

                    if (val === QUOTA_MODE_PROPORTIONALLY) {
                        fApi.hidden(false, 'quotaValue')
                        fApi.setValue('applyPaymentAmount', '')
                        fApi.disabled(true, 'applyPaymentAmount')
                    } else {
                        fApi.hidden(true, 'quotaValue')
                        fApi.setValue('quotaValue', '')
                        fApi.disabled(false, 'applyPaymentAmount')
                    }
                }
            },

            quotaValue: {
                change: (e, pageRef) => {
                    const fApi = pageRef.fApi
                    const val = e.target.value
                    const contractAmount = fApi.getValue('contractAmount')

                    let amount = 0
                    if (isNumber(+val)) {
                        if (val >= 0 && val <= 100) {
                            amount = tailOff(calculator.div(calculator.mul(contractAmount, val), 100))
                            fApi.setValue('applyPaymentAmount', amount)
                        } else {
                            amount = contractAmount
                            fApi.setValue('quotaValue', 100)
                            fApi.setValue('applyPaymentAmount', contractAmount)
                        }

                        const paymentType = fApi.getValue('paymentType')
                        const paymentMethod = fApi.getValue('paymentMethod')

                        if (paymentType === PAYMENT_TYPE_GENERAL) {
                            if (paymentMethod === PAYMENT_METHOD_CASH) { // 现金
                                fApi.setValue('cashPaymentAmount', amount)
                            }

                            if (paymentMethod === PAYMENT_METHOD_GUARANTEE) { // 保函
                                fApi.setValue('guaranteeAmount', amount)
                            }
                        }


                        if (paymentType === PAYMENT_TYPE_COMBINATION) {
                            const guaranteeAmount = fApi.getValue('guaranteeAmount')

                            if (isNumber(+guaranteeAmount)) {
                                const cashPaymentAmount = calculator.sub(amount, guaranteeAmount)

                                if (cashPaymentAmount >= 0) {
                                    fApi.setValue('cashPaymentAmount', cashPaymentAmount)
                                } else {
                                    fApi.setValue('cashPaymentAmount', '0.00')
                                    fApi.setValue('guaranteeAmount', amount)
                                }
                            }
                        }
                    }
                }
            },

            applyPaymentAmount: {
                change: (val, pageRef) => {
                    const fApi = pageRef.fApi
                    const paymentType = fApi.getValue('paymentType')
                    const paymentMethod = fApi.getValue('paymentMethod')

                    if (paymentType === PAYMENT_TYPE_GENERAL) {
                        if (paymentMethod === PAYMENT_METHOD_CASH) { // 现金
                            fApi.setValue('cashPaymentAmount', val)
                        }

                        if (paymentMethod === PAYMENT_METHOD_GUARANTEE) { // 保函
                            fApi.setValue('guaranteeAmount', val)
                        }
                    }
                }
            },

            paymentType: {
                change: (val, pageRef) => {
                    const fApi = pageRef.fApi
                    const applyPaymentAmount = fApi.getValue('applyPaymentAmount')

                    switch (val) {
                        case PAYMENT_TYPE_EXEMPTION: // 免交
                            fApi.hidden(true, 'marginAskStartDateAndEndDate')
                            fApi.hidden(true, 'paymentMethod')
                            fApi.hidden(true, 'deffStartDateAndEndDate')
                            fApi.hidden(true, 'guaranteeAmount')
                            fApi.hidden(true, 'cashPaymentAmount')
                            break
                        case PAYMENT_TYPE_DEFERREED: // 缓交
                            fApi.hidden(true, 'marginAskStartDateAndEndDate')
                            fApi.hidden(true, 'paymentMethod')
                            fApi.hidden(false, 'deffStartDateAndEndDate')
                            fApi.hidden(true, 'guaranteeAmount')
                            fApi.hidden(true, 'cashPaymentAmount')
                            break
                        case PAYMENT_TYPE_GENERAL: // 一般缴纳
                            fApi.hidden(false, 'marginAskStartDateAndEndDate')
                            fApi.hidden(false, 'paymentMethod')
                            fApi.hidden(true, 'deffStartDateAndEndDate')
                            fApi.hidden(true, 'guaranteeAmount')
                            fApi.hidden(false, 'cashPaymentAmount')
                            fApi.disabled(true, 'cashPaymentAmount')
                            fApi.setValue('paymentMethod', PAYMENT_METHOD_CASH)
                            fApi.setValue('cashPaymentAmount', applyPaymentAmount)
                            fApi.mergeRule('paymentMethod', {
                                props: {
                                    disabled: false,
                                    mode: 'default'
                                }
                            })
                            break
                        case PAYMENT_TYPE_COMBINATION: // 组合缴纳
                            fApi.hidden(false, 'marginAskStartDateAndEndDate')
                            fApi.hidden(false, 'paymentMethod')
                            fApi.hidden(true, 'deffStartDateAndEndDate')
                            fApi.hidden(false, 'guaranteeAmount')
                            fApi.hidden(false, 'cashPaymentAmount')
                            fApi.disabled(true, 'cashPaymentAmount')
                            fApi.setValue('paymentMethod', `${PAYMENT_METHOD_CASH}|${PAYMENT_METHOD_GUARANTEE}`)
                            fApi.setValue('cashPaymentAmount', applyPaymentAmount)
                            fApi.setValue('guaranteeAmount', '0.00')
                            fApi.mergeRule('paymentMethod', {
                                props: {
                                    disabled: true,
                                    mode: 'multiple'
                                }
                            })
                    }
                }
            },

            paymentMethod: {
                change: (val, pageRef) => {
                    const fApi = pageRef.fApi
                    const applyPaymentAmount = fApi.getValue('applyPaymentAmount')

                    if (val === PAYMENT_METHOD_CASH) { // 现金
                        fApi.hidden(true, 'guaranteeAmount')
                        fApi.hidden(false, 'cashPaymentAmount')
                        fApi.setValue('cashPaymentAmount', applyPaymentAmount)

                    } else if (val === PAYMENT_METHOD_GUARANTEE) { // 保函
                        fApi.hidden(false, 'guaranteeAmount')
                        fApi.hidden(true, 'cashPaymentAmount')
                        fApi.setValue('guaranteeAmount', applyPaymentAmount)
                    }
                }
            },

            guaranteeAmount: {
                change: (val, pageRef) => {
                    const fApi = pageRef.fApi
                    const paymentType = fApi.getValue('paymentType')
                    const applyPaymentAmount = fApi.getValue('applyPaymentAmount')

                    if (paymentType === PAYMENT_TYPE_COMBINATION && applyPaymentAmount > 0) {
                        if (isNumber(+val)) {
                            const cashPaymentAmount = calculator.sub(applyPaymentAmount, val)

                            if (cashPaymentAmount >= 0) {
                                fApi.setValue('cashPaymentAmount', cashPaymentAmount)
                            } else {
                                fApi.setValue('cashPaymentAmount', '0.00')
                                fApi.setValue('guaranteeAmount', applyPaymentAmount)
                            }
                        }
                    }
                }
            },

            agent: {
                change: generateAgentChangeHandler()
            }
        }

        fieldList.forEach(field => {
            switch (field.fieldCode) {
                case 'quotaValue':
                    field.nullAllowed = false

                    field.validate = [
                        {
                            validator: (rule, value, callback) => {
                                const fApi = vueCtx.fApi
                                const paymentType = fApi.getValue('paymentType')
                                // eslint-disable-next-line eqeqeq
                                if (value == 0 && paymentType !== PAYMENT_TYPE_EXEMPTION) {
                                    callback(new Error(`定额比例不能为0`))
                                    return
                                }

                                callback()
                            }
                        }
                    ]
                    break
                case 'applyPaymentAmount':
                case 'guaranteeAmount':
                case 'cashPaymentAmount':
                    field.validate = [
                        {
                            validator: (rule, value, callback) => {
                                const fApi = vueCtx.fApi
                                const paymentType = fApi.getValue('paymentType')
                                // eslint-disable-next-line eqeqeq
                                if (value == 0 && paymentType !== PAYMENT_TYPE_EXEMPTION) {
                                    callback(new Error(`${field.fieldName}不能为0`))
                                    return
                                }

                                callback()
                            }
                        }
                    ]
                    break
            }
        })

        defineProperties(emits, curEmits)

        createDelayTask(async () => {
            const fApi = vueCtx.fApi
            fApi.setValue('quotaMode', QUOTA_MODE_PROPORTIONALLY)
            // fApi.setValue('paymentMethod', PAYMENT_METHOD_CASH)
            fApi.setValue('paymentType', PAYMENT_TYPE_GENERAL)
            fApi.disabled(true, 'applyPaymentAmount')
            fApi.hidden(true, 'deffStartDateAndEndDate')
            fApi.hidden(true, 'guaranteeAmount')
            fApi.disabled(true, 'cashPaymentAmount')

            const quotaAutoCalc = store.getters.initInformation.quotaAutoCalc

            // 通过接口请求获取 `申请比例、申请金额、申请说明` 初始数据
            if (quotaAutoCalc === ADVANCE_PAYMENT_APPLICATION_GET_AMOUNT_ENABLED) {
                fApi.disabled(true, 'quotaMode')
                fApi.disabled(true, 'paymentType')
                const { projectCode } = vueCtx.$route.query
                const params = {
                    projectCode
                }

                const data = await getMarginDifferentiation(params)

                if (data) {
                    fApi.disabled(true, 'applyReason')
                    fApi.disabled(true, 'quotaValue')
                    fApi.disabled(true, 'applyPaymentAmount')
                    fApi.setValue('applyReason', data.remark)
                    fApi.setValue('quotaValue', data.radio)
                    fApi.setValue('applyPaymentAmount', (+data.amountPayable).toFixed(2))

                    if (isNumber(+data.radio) && +data.radio <= 0) {
                        // 如果预缴值 <= 0,申请类型默认为 `免缴`
                        const paymentTypeDom = fApi.el('paymentType')
                        paymentTypeDom && paymentTypeDom.setSelectValue({
                            value: PAYMENT_TYPE_EXEMPTION
                        })
                    } else {
                        fApi.disabled(true, 'guaranteeAmount')
                        fApi.disabled(true, 'paymentMethod')
                        fApi.setValue('paymentMethod', PAYMENT_METHOD_GUARANTEE)
                        const paymentMethodDom = fApi.el('paymentMethod')
                        paymentMethodDom && paymentMethodDom.setSelectValue({
                            value: PAYMENT_METHOD_GUARANTEE
                        })
                    }
                    fApi.setValue('differentiatedContributionRatio', data.differentiatedContributionRatio)
                    fApi.hidden(true, 'differentiatedContributionRatio')
                }
            }
        })
    }

    return next()
})


middleware.use(({ emits, vueCtx }, next) => {
    const agentField = ['account_cancellation_detail', 'margent_cancellation_detail']

    const field = vueCtx.field
    if (agentField.some(_field => field.includes(_field))) {
        const curEmits = {
            agent: {
                change: generateAgentChangeHandler()
            }
        }

        defineProperties(emits, curEmits)
    }

    return next()
})


middleware.use(({ emits, vueCtx }) => {
    /**
     * 涉及到 是/否选择
     * 表单 fieldCode => [选择器 fieldCode, 受影响的 field 集合]
     *
     * 提示：此处不涉及跨 fApi 间的交互
     * 选择 `否` 时，需要隐藏受影响的 field 集合，选择 `是` 时，则就需要显示
     */
    const involveWhetherSelectMap = new Map(
        [
            // 工程支付款担保编辑
            [
                'project_payment_guarantee_info_20_1',
                [
                    'isExist',
                    [
                        'guaranteeAmount',
                        'guaranteeUnitName',
                        'socialCreditCode',
                        'guaranteeDate',
                        'guaranteeCertificate'
                    ]
                ]
            ],
            // 施工许可证编辑
            [
                'project_working_permit_info_20_1',
                [
                    'isHandle',
                    [
                        'constructionPermitNo',
                        'issueInstitution',
                        'issueDate',
                        'dueDate',
                        'contractPrice',
                        'constructionScale',
                        'permitConstructionFile'
                    ]
                ]
            ],
            // 工伤保险编辑
            [
                'project_injury_insurance_info_20_1',
                ['isHandle', ['handleDate', 'proveMaterial']]
            ]
        ]
    )

    const field = vueCtx.field
    if (involveWhetherSelectMap.has(field)) {
        const [selectField, affectFields] = involveWhetherSelectMap.get(field)

        const curEmits = {
            [selectField]: {
                change: generateWhetherSelectChangeHandler(affectFields)
            }
        }

        defineProperties(emits, curEmits)

        createDelayTask(() => {
            const fApi = vueCtx.fApi
            const selectValue = fApi.getValue(selectField)

            fApi.trigger(selectField, 'change', selectValue)
            fApi.clearValidateState(selectField)
        })
    }

    return emits
})


export default {
    methods: {
        getFieldEmits(fieldList) {
            let emits = this.emits || {}

            const p = middleware.compose({
                emits,
                fieldList,
                vueCtx: this
            })

            p.catch(error => {
                console.error('EmitMixin caught: ' + error)
                this.$captureException(error)
            })

            return emits
        }
    }
}
