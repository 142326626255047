<template>
    <div>
        <custom-modal
            v-bind="item"
            :is="item.modalComponent"
            v-for="(item, index) in modalConfig"
            :id="index"
            :key="index"
            :rule="item.rule"
            :centered="true"
            :width="modalWidthMap.get(item.size) || 600"
            :title="item.title"
            class="custom-mutiple-modal"
            :loading="item.loading"
            :footer="item.buttonList.length ? undefined : null"
            :show-ok-btn="!item.initLoading && !item.pageErrorMsg"
            @on-close="() => handleCloseModal(item, 1)"
            @on-ok="event => handleSuccessModal(item, event)"
        >
            <!-- 加载 -->
            <template v-if="item.initLoading">
                <a-spin tip="正在努力加载，请稍后……">
                    <div style="height: 100px" />
                </a-spin>
            </template>
            <!-- 错误提示 -->
            <template v-else-if="item.pageErrorMsg">
                <div class="page-error-msg">
                    {{ item.pageErrorMsg }}
                </div>
            </template>
            <!-- 实际内容 -->
            <template v-else>
                <custom-upload-modal
                    v-if="item.modalType === MODAL_TYPE_UPLOAD"
                    :ref="`createPage-${item.code}`"
                    :biz-code="item.code"
                    @change="data => handleUploadChange(data, item)"
                />
                <create-pages
                    v-else
                    :ref="`createPage-${item.code}`"
                    :is-global-f-api="true"
                    :rule="item.rule"
                    :children-fields="item.childrenField"
                    :is-root="true"
                    root-type="modal"
                    :now-query-list="item.nowQueryList"
                    :global-params="item.requestParams"
                />
            </template>
            <template #customFooter>
                <default-footer
                    :loading="item.loading"
                    :button-list="item.buttonList"
                />
            </template>
        </custom-modal>
    </div>
</template>

<script>
import { Spin } from 'ant-design-vue'
import CustomModal from '@/components/CustomModal'
import { parserPanel } from '@/weights/CreatePage/parse'
import eventMixin from '@/mixins/createPage/event'
import buttonMixin from '@/mixins/createPage/button'
import queryMixin from '@/mixins/createPage/query'
import DefaultFooter from './DefaultFooter'
import {
    MODAL_TYPE_DEFAULT,
    MODAL_TYPE_UPLOAD,
    MODAL_TYPE_SLEF_CONTROL,
    MODAL_TYPE_STEP_CONTROL,
    MODAL_SIZE_HUGE,
    MODAL_SIZE_LARGE,
    MODAL_SIZE_NORMAL,
    MODAL_SIZE_SMALL,
    INTERFACE_TYPE_DOUBLE_CHECK,
    INTERFACE_TYPE_MODAL,
    LAYOUT_TYPE_FORM,
    LAYOUT_TYPE_TABLE,
    LAYOUT_TYPE_BORDERED_DESCRIPTION,
    QUERY_STATE_NORMAL,
    EVENT_TYPE_NORMAL,
    EVENT_TYPE_CLOSE_REFRESH,
    EVENT_OPERATION_TYPE_IMPORT
} from '@/constant/enum'

import uuid from 'uuid'

const stepEventCodeList = []

export default {
    name: 'CustomMutipleModal',
    components: {
        CustomModal,
        ASpin: Spin,
        CreatePages: () => import('@/weights/CreatePage'),
        CustomUploadModal: () => import('@/weights/UploadFileModal'),
        DefaultFooter
    },
    mixins: [eventMixin, buttonMixin, queryMixin],
    data() {
        return {
            MODAL_TYPE_UPLOAD,
            MODAL_TYPE_SLEF_CONTROL,
            modalConfig: [],

            modalWidthMap: new Map([
                [MODAL_SIZE_HUGE, 1200],
                [MODAL_SIZE_LARGE, 1080],
                [MODAL_SIZE_NORMAL, 824],
                [MODAL_SIZE_SMALL, 400]
            ])
        }
    },
    methods: {
        /**
         * 关闭弹窗
         */
        handleCloseModal(item, closeType, resultData = {}) {
            item.visible = false
            if (!item.pageErrorMsg) {
                this.$store.dispatch('deletePageFApi', 'modal')
            }
            const { eventList } = item

            // 关闭事件，更新内容
            const closeEvent = eventList && eventList.find(event => event.eventType === EVENT_TYPE_CLOSE_REFRESH)
            if (closeEvent) {
                this.handleOutSideRefresh()
            } else if (closeType === 2) {
                this.$emit('on-ok', resultData)
            }

            this.modalConfig.forEach(modal => {
                if (modal.visible) {
                    const pageRef = this.$refs[`createPage-${modal.code}`][0]
                    pageRef.childrenFieldRefresh()
                }
            })

        },
        /**
         * 上传回调
         */
        handleUploadChange(data, item) {
            const {
                file: { status }
            } = data

            switch (status) {
                case 'uploading':
                    item.loading = true
                    break
                default:
                    item.loading = false
            }
        },
        /**
         * 确认事件
         */
        async handleSuccessModal(modalConfig, event) {
            modalConfig.loading = true
            try {
                const { modalType } = modalConfig

                switch (modalType) {
                    case MODAL_TYPE_DEFAULT:
                        // 默认弹框
                        await this.defaultModalSuccessEvent(modalConfig, event)
                        break
                    case MODAL_TYPE_UPLOAD:
                        // 文件上传，没有方法
                        this.handleCloseModal(modalConfig, 2)
                        break
                    case MODAL_TYPE_SLEF_CONTROL:
                        // 自控组件修改内容
                        await this.selfControlModalSuccessEvent(modalConfig)
                        break
                    case MODAL_TYPE_STEP_CONTROL:
                        await this.stepModalSuccessEvent(modalConfig, event)
                        break
                    default:
                        // 自定义的方法，没有方法
                        this.handleCloseModal(modalConfig)
                        break
                }
            } catch (error) {
                console.log(error)
                this.$message.error(error.message)
            } finally {
                modalConfig.loading = false
            }
        },
        /**
         * 添加弹窗
         */
        addModal(code, params = {}, modalType = MODAL_TYPE_DEFAULT) {
            let modalConfigItem = {}
            modalConfigItem = this.getModalConfigItem(code, params, modalType)

            this.setModalConfig(modalConfigItem)
        },
        /**
         * 异步添加弹窗
         */
        async addModalAsync(
            event,
            localData,
            _params
        ) {
            const { eventCode: code } = event
            let modalConfigItem = {}
            // 标准弹框
            modalConfigItem = Object.assign(
                this.getModalConfigItem(code, {}, MODAL_TYPE_DEFAULT),
                {
                    initLoading: true,
                    size: MODAL_SIZE_SMALL
                }
            )

            this.setModalConfig(modalConfigItem)

            try {
                const { result: params, requestParams } = await this.initParams(
                    event,
                    localData,
                    _params
                )

                params.requestParams = requestParams

                const newModalConfigItem = this.getModalConfigItem(
                    code,
                    params,
                    params.modalType
                )

                this.setModalConfig(newModalConfigItem)
            } catch (error) {
                const { message } = error
                // 失败就关闭
                modalConfigItem = Object.assign(
                    this.getModalConfigItem(code, {}),
                    {
                        size: MODAL_SIZE_SMALL,
                        pageErrorMsg:
                            message || '页面加载失败，请关闭弹窗后重试'
                    }
                )
                const modalConfigIndex = this.modalConfig.findIndex(
                    item => item.visible && item.code === modalConfigItem.code
                )
                if (modalConfigIndex > -1) {
                    this.modalConfig.splice(
                        modalConfigIndex,
                        1,
                        modalConfigItem
                    )
                }
                // this.setModalConfig(modalConfigItem)
                // this.closeModalByCode(code)
            }
        },
        /**
         * 创建弹窗参数
         */
        getModalConfigItem(code, params, modalType = MODAL_TYPE_DEFAULT) {
            const { globalQuery } = this.getGlobalQuery(params, params.requestParams)
            const formatData = parserPanel(params, globalQuery, params.requestParams)
            const rule = [formatData]
            const title = formatData.props.extraTitle ? formatData.props.title + this.getSubTitle(formatData.props.extraTitle) : formatData.props.title
            const config = {
                code,
                visible: true,
                title,
                modalComponent: 'CustomModal',
                rule,
                eventList: formatData.props?.eventList,
                childrenField: formatData.props?.childrenField,
                loading: false,
                initLoading: false,
                size: formatData.props?.size ?? MODAL_SIZE_NORMAL,
                modalType,
                layoutCode: formatData.layoutCode,
                autoClose: true,
                nowQueryList: globalQuery,
                extraTitle: formatData.props.extraTitle
            }
            config.buttonList = this.getFooterBtns(config)

            return config
        },
        /**
         * 设置弹窗配置
         */
        setModalConfig(modalConfigItem) {
            const modalConfigIndex = this.modalConfig.findIndex(
                item => item.code === modalConfigItem.code
            )

            // 之前已经打开过就直接替换
            if (modalConfigIndex > -1) {
                this.modalConfig.splice(modalConfigIndex, 1, modalConfigItem)
            } else {
                // 新加弹窗
                this.modalConfig.push(modalConfigItem)
            }
        },
        /**
         * 根据code关闭某个弹窗
         */
        closeModalByCode(code) {
            this.modalConfig = this.modalConfig.filter(
                item => item.code !== code
            )
        },
        changeEventList(modalConfigItem, eventList) {
            const buttonList = this.getButtonList(eventList, EVENT_TYPE_NORMAL)
            buttonList.forEach(event => this.formatButtonConfig(modalConfigItem, event))
            modalConfigItem.buttonList = buttonList
        },
        /** 获取底部所有按钮 */
        getFooterBtns(config) {
            const { rule, initLoading, pageErrorMsg, eventList, modalType, extraTitle } =
                config || {}
            const [template] = rule || []
            const showOkBtn = !initLoading && !pageErrorMsg
            const data =
                template?.data?.dataString ||
                extraTitle?.props?.data ||
                template?.children?.[0]?.data?.dataString ||
            {} // 找到可用datatstring用于获取权限字段

            let isTable = false
            if (template?.children?.length === 1 && template?.children?.[0].type === 'CustomTable') {
                isTable = true
            }
            const buttons = showOkBtn
                ? this.getButtonList(eventList, EVENT_TYPE_NORMAL, data, isTable)
                : [] // 遍历出可用按钮
            buttons.forEach(event => {
                this.formatButtonConfig(config, event)
            }) // 转换按钮参数

            if (modalType === MODAL_TYPE_SLEF_CONTROL) {
                buttons.push({
                    buttonType: 'normal',
                    click: () => this.handleSuccessModal(config, {}),
                    showLoading: true,
                    type: 'primary',
                    buttonText: '确定'
                }) // 固定插入取消按钮
            }
            return buttons
        },
        formatButtonConfig(config, event) {
            Object.assign(event, {
                buttonType: 'normal',
                click: () => this.handleSuccessModal(config, event),
                // confirmMessage: this.confirmMessage,
                showLoading: true, // 是否受loading控制
                type: 'primary',
                buttonText: event.eventName || '确认'
            })
        },
        /**
         * 处于弹窗时的表格操作，需要更新外部数据
         */
        handleOutSideRefresh() {
            const fApiList = this.$store.getters.pageFApiList

            const rootIndex = fApiList.findLastIndex(fApi => {
                const { isRoot } = fApi.options
                return isRoot
            })

            for (let i = rootIndex; i < fApiList.length; i++) {
                const fApi = fApiList[i]
                this.outSidePageRefresh(fApi)
            }
        },
        outSidePageRefresh(fApi) {
            const childrenField = fApi.options.childrenField || []

            // 子组件的更新逻辑
            childrenField.forEach(feild => {
                const vm = fApi.el(feild)
                if (vm && vm.fieldDataRefresh) {
                    vm.fieldDataRefresh()
                }
            })
        },
        /**
         * 初始化弹框参数
         * @param {EventItem} event
         * @param {Object} localData
         * @param {Object} _params
         */
        async initParams(event, localData, _params) {
            const res = await this.triggerEvent(event, localData, _params)
            const { eventResult } = res

            const size = this.calcModalSize(eventResult)

            // 对于步骤条组件进行单独的motal更改
            if (eventResult.componentType?.indexOf('step') > -1) {
                eventResult.modalType = MODAL_TYPE_STEP_CONTROL
                eventResult.eventList = eventResult.children[0]?.eventList || []
            }
            // 重新设置componentType
            eventResult.componentType =
                `modal_${size}` +
                (eventResult.componentType
                    ? `-${eventResult.componentType}`
                    : '')

            return {
                result: eventResult,
                requestParams: res.requestParams
            }
        },
        /**
         * 计算弹框大小
         */
        calcModalSize(eventResult) {
            eventResult ||= {}

            const { layoutType } = eventResult
            // 带边框的 description 布局类型，弹框宽度使用 HUGE 大小
            if (LAYOUT_TYPE_BORDERED_DESCRIPTION === layoutType) {
                return MODAL_SIZE_HUGE
            }

            // table/list 布局类型，根据查询条件的个数决定弹框宽度
            if (LAYOUT_TYPE_TABLE === layoutType) {
                const [firstEvent] = eventResult.eventList || []
                const queryCriteriaList = firstEvent?.queryCriteriaList || []

                // 弹框中的列表查询条件项
                const queryList = queryCriteriaList.filter(
                    item => item.queryState === QUERY_STATE_NORMAL
                )

                // 弹框中的列表查询条件大于两项，弹框宽度使用 HUGE 大小，否则使用 LARGE 大小
                return queryList.length > 2 ? MODAL_SIZE_HUGE : MODAL_SIZE_LARGE
            }

            // 有子内容，需要检查子内容再决定弹框宽度
            const { children } = eventResult
            if (Array.isArray(children) && children.length > 0) {
                const types = [LAYOUT_TYPE_TABLE, LAYOUT_TYPE_BORDERED_DESCRIPTION]
                const bool = children.some(function dfsFn(item) {
                    if (types.includes(item.layoutType)) return true
                    return item.children && item.children.some(dfsFn)
                })

                // 子内容中有表格或有带边框的 description 时，弹框宽度使用 HUGE 大小
                if (bool) return MODAL_SIZE_HUGE
            }

            // form 或 不带边框的 description 布局类型，没有指定组件，弹框宽度使用 NORMAL 大小
            if (LAYOUT_TYPE_FORM === layoutType && !eventResult.componentType) {
                return MODAL_SIZE_NORMAL
            }

            // 指定组件为 form 时，需要自定义参数来决定弹框宽度
            const { componentType } = eventResult
            if (componentType?.includes('form')) {
                const componentTypes = componentType.split('_')
                const componentTypesLen = componentTypes.length

                // 有自定义指定，根据指定的设置弹框宽度
                if (componentTypesLen > 3) {
                    let _size = componentTypes[3]
                    return isNaN(_size) ? MODAL_SIZE_NORMAL : _size
                }

                // 如果表格一行需要展示两项，弹框宽度使用 NORMAL 大小
                if (componentTypesLen > 2 && componentTypes[2] > 1) {
                    return MODAL_SIZE_NORMAL
                }

                // 否则使用 SMALL 大小
                return MODAL_SIZE_SMALL
            }

            // 指定组件为 step 时，弹框宽度使用 LARGE 大小，否则使用 NORMAL 大小
            return componentType?.includes('step') ? MODAL_SIZE_LARGE : MODAL_SIZE_NORMAL
        },
        /**
         * 默认弹框成功事件
         */
        async defaultModalSuccessEvent(modalConfig, event) {
            const { code } = modalConfig
            const pageRef = this.$refs[`createPage-${code}`][0]
            const fApi = pageRef.fApi
            const { valid, fail } = pageRef.vaildatorFromData(fApi)
            if (valid) {
                // 验证通过
                const data = pageRef.getChildrenFormData(fApi, pageRef.childrenField)
                let globalParams = {}

                // 直接使用触发事件的事件
                if (event) {
                    globalParams = this.getGlobalParams(
                        event.queryCriteriaList || []
                    )

                    const params = {
                        ...globalParams,
                        ...data
                    }
                    if (event.interfaceType === INTERFACE_TYPE_DOUBLE_CHECK) {
                        this.$confirm({
                            title: event.eventName,
                            content: event.errorMessage || `确定要${
                                event.eventName || '执行此操作'
                            }吗`,
                            okText: '确认',
                            cancelText: '取消',
                            onOk: async () => {
                                try {
                                    // 请求目标事件
                                    const { result: eventResult } =
                                        await this.triggerEventServer(
                                            event,
                                            params
                                        )
                                    this.handleCloseModal(modalConfig, 2, eventResult)
                                } catch (error) {
                                    this.$message.error(error.message)
                                    console.log(error.message)
                                }
                            }
                        })
                    } else if (event.interfaceType === INTERFACE_TYPE_MODAL) {
                        const uid = uuid()
                        // 编辑计划特殊处理
                        if (stepEventCodeList.includes(event.eventCode)) {
                            // 添加uid
                            params.uid = uid
                        }
                        const { result: eventResult, requestParams } =
                            await this.triggerEventServer(event, params)

                        // 编辑计划特殊处理
                        if (stepEventCodeList.includes(event.eventCode)) {
                            eventResult.props = {
                                uid,
                                stepId: requestParams.id
                            }
                            eventResult.requestParams = requestParams
                        }

                        // 对于步骤条组件进行单独的motal更改
                        if (eventResult.componentType?.indexOf('step') > -1) {
                            eventResult.modalType = MODAL_TYPE_STEP_CONTROL
                            eventResult.eventList =
                                eventResult.children[0]?.eventList || []
                        }

                        eventResult.componentType =
                            `modal_${1}` +
                            (eventResult.componentType
                                ? `-${eventResult.componentType}`
                                : '')

                        const modalType = stepEventCodeList.includes(
                            event.eventCode
                        )
                            ? MODAL_TYPE_STEP_CONTROL
                            : MODAL_TYPE_DEFAULT

                        this.addModal(
                            eventResult.eventCode,
                            eventResult,
                            modalType
                        )
                    } else {
                        if (event.operationType === EVENT_OPERATION_TYPE_IMPORT) {
                            const { isSuccess, importRecordRequestId } = params
                            if (!isSuccess) {
                                this.$message.warn('请选择数据正确的文件')
                                return
                            }

                            if (!importRecordRequestId) {
                                this.$message.warning('请选择文件')
                                return
                            }

                            const { result: eventResult } = await this.triggerImportEventServer(event, params)

                            this.handleCloseModal(modalConfig, 2, eventResult)
                        } else {
                            // 请求目标事件
                            try {
                                // 请求目标事件
                                const { result: eventResult } =

                                await this.triggerEventServer(event, params)

                                this.handleCloseModal(modalConfig, 2, eventResult)
                                this.$message.success(`${event.eventName}成功`)
                                this.JumpTargetPage(event)
                            } catch (error) {
                                this.$message.error(error.message)
                                console.log(error)
                            }
                        }
                    }
                } else {
                    this.handleCloseModal(modalConfig, 2)
                }
            } else {
                // 表单验证失败, 提示第一个验证失败的问题
                let errorMsg = ''
                for (let key in fail) {
                    if (!errorMsg) {
                        errorMsg = fail[key][0].message || ''
                    }
                }
                this.$message.warning(`${errorMsg}`)
            }
        },
        /**
         * 自我控制弹框的成功事件
         */
        selfControlModalSuccessEvent(modalConfig) {
            const { code } = modalConfig
            const pageRef = this.$refs[`createPage-${code}`][0]
            const fApi = pageRef.fApi
            // 自控组件修改内容
            const valid = pageRef.vaildatorFromData(fApi).valid
            if (valid) {
                const data = pageRef.getChildrenFormData(fApi)

                const params = {
                    ...data
                }

                // TIP 目前只针对一个模块进行内容的获取，之后要扩展
                const localData = fApi.el(code).data || fApi.el(code + '_1').data


                this.handleCloseModal(modalConfig, 2, {
                    ...localData,
                    ...params
                })
            } else {
                // 表单验证失败
                console.log(valid)
            }
        },
        stepModalSuccessEvent(modalConfig, event) {
            const { code } = modalConfig
            const pageRef = this.$refs[`createPage-${code}`][0]
            const fApi = pageRef.fApi
            if (modalConfig.layoutCode) {
                const vm = fApi.el(modalConfig.layoutCode)
                if (vm && vm.handleNextStep) {
                    vm.handleNextStep(
                        event,
                        eventList => this.changeEventList(modalConfig, eventList),
                        () => {
                            this.handleCloseModal(modalConfig, 2, {})
                        }
                    )
                }
            }
        },
        // 添加扩展头的支持
        getSubTitle(extraTitle) {
            let res = ''

            if (extraTitle && extraTitle.props) {
                const { data, fieldList } = extraTitle.props
                if (data && fieldList) {
                    fieldList.forEach(item => {
                        res += `${item.fieldName}：${data[item.attributeCode]}`
                    })
                }
            }

            return res ? `（${res}）` : ''
        },
        startLoading() {
            console.log('startLoading')
            this.modalConfig.forEach(item => {
                if (item.visible) {
                    console.log('modalConfig')
                    item.loading = true
                }
            })
        },
        endLoading() {
            console.log('endLoading')
            this.modalConfig.forEach(item => {
                if (item.visible) {
                    item.loading = false
                }
            })
        }
    },
    provide() {
        return {
            handleModalRefresh: this.handleOutSideRefresh, // 用此触发外部表单的数据更新
            startLoading: this.startLoading,
            endLoading: this.endLoading
        }
    }
}
</script>

<style lang="scss">
.custom-mutiple-modal {
    .page-error-msg {
        padding-top: 15px;
        min-height: 100px;
        line-height: 1.5em;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
