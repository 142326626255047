<!-- 单文件上传 -->
<template>
    <div :class="['single-file-upload', { 'has-file': hasFile }]">
        <a-upload-dragger
            :accept="aUploadDraggerAccept"
            :show-upload-list="false"
            :custom-request="handleSelectFile"
        >
            <!-- 图标 -->
            <p class="ant-upload-drag-icon">
                <!-- 具体（icon）插槽，用以设定图标 -->
                <slot name="icon">
                    <a-icon :type="icon" />
                </slot>
            </p>

            <template v-if="hasFile">
                <!-- 拥有文件时，展示文件名 -->
                <!-- 可通过具名（file）插槽进行自定义设定 -->
                <slot name="file" v-bind="file">
                    <p class="ant-upload-text">{{ file.name }}</p>
                </slot>
            </template>

            <template v-else>
                <!-- 未拥有文件时，展示提示（占位）信息 -->
                <!-- 可通过具名（placeholder）插槽进行自定义设定 -->
                <slot name="placeholder">
                    <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>

                    <p class="ant-upload-hint">{{ hintText }}</p>
                    <p class="ant-upload-hint">系统会按照工作表格式读取数据，请保证数据格式正确</p>
                </slot>
            </template>
        </a-upload-dragger>

        <!-- 右上角控制区 -->
        <div class="right-top-control-area">
            <!-- 具体（rightTopControl）插槽，用以设定右上角控制按钮 -->
            <slot name="rightTopControl">
                <!-- 默认自带的 删除 按钮，用以清除当前文件 -->
                <a-button
                    type="link"
                    icon="delete"
                    @click.stop="handleRemoveFile"
                />

                <!-- 具体（rightTopOhterControl）插槽，用以添加其他控制按钮 -->
                <slot name="rightTopOhterControl" />
            </slot>
        </div>

        <!-- 底部自定义放置内容 -->
        <slot />
    </div>
</template>
<script>
// 组件
import {
    Upload as AUpload,
    Icon as AIcon,
    Button as AButton
} from 'ant-design-vue'

import { isFile } from '@utils'

export default {
    name: 'SingleFileUpload',
    components: {
        AIcon,
        AButton,
        AUploadDragger: AUpload.Dragger
    },
    model: {
        prop: 'file',
        event: 'on-change'
    },
    props: {
        // a-icon type
        icon: {
            type: String,
            default: 'inbox'
        },
        // 当前文件
        file: [File, Object],
        // 可接受的文件后缀名（扩展名）
        accept: {
            type: Array,
            default: () => ['.xls', '.xlsx']
        }
    },
    data() {
        return {}
    },
    computed: {
        // 是否拥有文件
        hasFile() {
            return isFile(this.file)
        },
        // 给 a-upload-dragger 的传参 accept
        aUploadDraggerAccept() {
            return this.accept.join(',')
        },
        // hint text
        hintText() {
            return `支持扩展名：${this.accept.join(' ')}`
        }
    },
    methods: {
        emitChange(file = null) {
            this.$emit('on-change', file)
        },
        // 点击文件或把文件拖入指定区域，更新文件
        handleSelectFile({ file }) {
            this.emitChange(file)
        },
        // 清除当前文件，File => null
        handleRemoveFile() {
            this.emitChange(null)
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.single-file-upload {
    position: relative;
    display: inline-block;
    text-align: left;
    width: 520px;

    .right-top-control-area {
        position: absolute;
        top: 3px;
        right: 3px;
        display: none;
    }

    &.has-file {
        &:hover .right-top-control-area {
            display: block;
        }
    }
}
</style>
