import axios from '@service'
import { LOGOUT } from './api'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
export default async function logoutService(payload = {}, config = {}) {
    const param = {
        ...LOGOUT,
        ...config,
        data: payload
    }
    const { data } = await axios(param)
    if (data.message !== HTTP_ERR_SUCCESS_MESSAGE) {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
