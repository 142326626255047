<template>
    <a-layout-header :class="[theme, 'gongbao-header']">
        <img
            src="@assets/images/head-left.jpg"
            alt=""
            style="position: absolute; left: 0; z-index: -1; height: 64px"
        >
        <img
            src="@assets/images/head-right.jpg"
            alt=""
            style="position: absolute; right: 0; z-index: -1; height: 64px"
        >
        <div :class="['gongbao-header--wide', layout]">
            <router-link
                to="/"
                :class="['logo', isMobile ? null : 'pc', theme]"
            >
                <!-- <img v-if="hasLogo" :src="logoSrc"> -->
                <h1 v-if="!isMobile">{{ systemName }}</h1>
                <div v-if="!isMobile" class="dialog">
                    <span>{{ systemTagName }}</span>
                </div>
            </router-link>
            <div v-if="layout === 'head'" class="gongbao-header__menu">
                <i-menu
                    class="gongbao-header__menu"
                    :theme="theme"
                    mode="horizontal"
                    :menu-data="menuData"
                    @on-select="handleMenuSelect"
                />
            </div>
            <div :class="['gongbao-header__right', theme]">
                <div
                    v-if="$slots.headerProject"
                    class="gongbao-header__header-project-slot"
                >
                    <slot name="headerProject" />
                </div>
                <header-avatar class="gongbao-header__item" />
            </div>
        </div>
    </a-layout-header>
</template>
<script>
import { Layout as ALayout } from 'ant-design-vue'
import HeaderAvatar from '@components/HeaderAvatar'
import IMenu from '@components/SiderMenu'

export default {
    name: 'GlobalHeader',
    components: {
        IMenu,
        HeaderAvatar,
        ALayoutHeader: ALayout.Header
    },
    props: {
        menuData: {
            type: Array
        },
        hasLogo: {
            type: Boolean,
            default: false
        },
        logoSrc: {
            type: String,
            default: () => require('@assets/images/logo.png')
        }
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile
        },
        layout() {
            return this.$store.getters.layout
        },
        theme() {
            return this.layout === 'side' ? 'light' : this.$store.getters.theme
        },
        systemName() {
            return this.$store.getters.systemName
        },
        systemTagName() {
            return this.$store.getters.systemTagName
        }
    },
    methods: {
        handleMenuSelect(obj) {
            this.$emit('on-menu-select', obj)
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.gongbao-header {
    padding: 0 24px 0 30px;
    position: relative;
    background: #1877f2;
    // background: linear-gradient(117deg, #1069db 0%, #1877f2 100%);
    // background: linear-gradient(117deg, #1877F2 0%, #1069db 50%, #1877f2 100%);

    &.dark {
        background: #001529;
    }

    &__header-project-slot {
        height: 100%;
    }

    &__trigger {
        font-size: 20px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
            color: #1890ff;
        }
    }

    &--wide {
        display: flex;
        justify-content: space-between;
        &.head {
            max-width: 1400px;
            margin: auto;
        }
        .logo {
            display: flex;
            align-items: center;
            height: 64px;
            cursor: pointer;
            font-size: 20px;

            img {
                display: inline-block;
                vertical-align: middle;
                width: 48px;
                height: 48px;
                margin-right: 10px;
            }

            h1 {
                margin: 0;
                display: inline-block;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #fff;
                line-height: 18px;
                letter-spacing: 1px;
            }

            &.dark h1 {
                color: #fff;
            }
        }

        .gongbao-header__menu {
            display: inline-block;
        }

        .gongbao-header__right {
            display: flex;
            align-items: center;
            float: right;

            &.dark {
                color: #fff;

                i {
                    color: #fff;
                }
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 0 12px;
        height: 100%;
        cursor: pointer;
        vertical-align: middle;

        i {
            font-size: $font-size-md;
            color: rgba(0, 0, 0, 0.65);
        }
    }

    &__menu {
        height: $navbar-height;
        line-height: $navbar-height;
    }
}
</style>
