import axios from '@service'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
import { SAFETY_MFG_URL } from '@config'

import { isEmpty } from '@utils'

let dataRedis = null

const isNotEmptyArray = data => Array.isArray(data) && data.length > 0

function getRedisResultByAreaCode(dataRedis, areaCode) {
    if (isEmpty(areaCode)) return dataRedis

    function dfs(nodeList, areaCode) {
        for (let i = 0, len = nodeList.length; i < len; i++) {
            const { id, children } = nodeList[i]

            if (!areaCode.includes(id)) continue
            return areaCode === id ? children : dfs(children, areaCode)
        }
    }

    return dfs(dataRedis, areaCode)
}

export default async function getAreaTreeService({ areaCode } = {}) {
    if (isNotEmptyArray(dataRedis)) {
        const hit = getRedisResultByAreaCode(dataRedis, areaCode)
        if (isNotEmptyArray(hit)) return hit
    }

    const param = {
        url: `${SAFETY_MFG_URL}/area/tree`,
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: {
            areaCode
        },
        timeout: 30000
    }

    const { data } = await axios(param)

    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        const result = data.result

        dataRedis = result

        return result || []
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
