import cloneDeep from 'lodash/cloneDeep'

import { QUERY_STATE_GLOBAL, EVENT_TYPE_DEFAULT } from '@/constant/enum'

export default {
    methods: {
        getGlobalQuery(template, requireParams) {

            // 获取父级的全局数据
            const fApiList = this.$store.getters.pageFApiList

            let globalParams = {}

            const rootIndex = fApiList.findLastIndex(fApi => {
                const { isRoot } = fApi.options
                return isRoot
            })

            if (rootIndex > -1) {
                globalParams = fApiList[rootIndex].options.globalParams
            }

            // 获取当前的全局数据
            const nowQueryList = []
            const eventList = template.eventList

            if (eventList && eventList.length > 0) {
                const initEvent = eventList.filter(
                    event => event.eventType === EVENT_TYPE_DEFAULT
                )[0]

                if (initEvent && initEvent.queryCriteriaList) {
                    initEvent.queryCriteriaList.forEach(query => {
                        if (query.queryState === QUERY_STATE_GLOBAL) {
                            nowQueryList.push(cloneDeep(query))
                        }

                    })
                    nowQueryList.forEach(query => {
                        if (requireParams[query.fieldCode]) {
                            query.defaultValue = requireParams[query.fieldCode] || globalParams[query.fieldCode] || query.defaultValue
                        }
                    })
                }
            }


            const len = fApiList.length
            const globalQuery = []

            for (let i = len - 1; i >= 0; i--) {
                const fApi = fApiList[i]

                const { isRoot, nowQueryList } = fApi.options

                if (nowQueryList && nowQueryList.length > 0) {
                    nowQueryList.forEach(nowQurey => {
                        if (globalQuery.findIndex(query => query.fieldCode === nowQurey.fieldCode) === -1) {
                            globalQuery.push(nowQurey)
                        }
                    })
                }


                // 遇到根节点结束查询
                if (isRoot) {
                    break
                }
            }


            if (nowQueryList && nowQueryList.length > 0) {
                nowQueryList.forEach(nowQurey => {
                    const index = globalQuery.findIndex(query => query.fieldCode === nowQurey.fieldCode)
                    if (index === -1) {
                        globalQuery.push(nowQurey)
                    } else {
                        globalQuery[index].defaultValue = nowQurey.defaultValue || globalQuery[index].defaultValue
                    }
                })
            }

            return {
                globalQuery,
                nowQueryList
            }

        }
    }
}
