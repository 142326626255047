/**
 * Created by hiro on 2020/07/23.
 * axios 数据拦截
 */
import axios from 'axios'
import store from '@store'

import refreshTokenFunc from '@utils/refreshToken'
import { getToken, getRefreshToken, getGbtToken, setToken, setRefreshToken } from '@utils/auth'

import { SYSTEM_TYPE_ENT_SIDE } from '@constant/enum'
import { HTTP_ERR_UNAUTHORIZED } from '@constant/api_status'

import getRefreshTokenService from '@service/login/getRefreshTokenService'

const axiosInstance = axios.create()

let isLogout = false
function loginOut() {
    if (isLogout) {
        return
    }

    isLogout = true
    store.dispatch('logOut')
}

// 根据服务器返回值自行封装
function is401(response) {
    return response.data.errorCode === HTTP_ERR_UNAUTHORIZED
}

// 这列表接口报401 则退出登录
function getParams(response) {
    return {
        response,
        updateTokens,
        urlList: [
            '/api/margin_auth/ent-auth/login',
            '/api/margin_auth/ent-auth/refreshToken'
        ],
        loginOut
    }
}

// 获取新token后更新
async function updateTokens(responseConfig) {
    const { accessToken, refreshToken } = await getRefreshTokenService({
        refreshToken: getRefreshToken()
    })

    setToken(accessToken)
    setRefreshToken(refreshToken)
    responseConfig.headers['x-authorization-oauth'] = accessToken
}

axiosInstance.interceptors.request.use(function (config) {
    const TOKEN = getToken()
    if (TOKEN) {
        config.headers['x-authorization-oauth'] = TOKEN
    }

    const GBT_TOKEN = getGbtToken()
    if (GBT_TOKEN) {
        config.headers['x-authorization-tong'] = GBT_TOKEN
    }

    config.headers['x-client-type'] = SYSTEM_TYPE_ENT_SIDE
    config.headers['areaCode'] = store.getters.globalData.areaCode

    if (config.useNProgress !== false) {
        window.NProgress.start()
    }

    return config
})

// 劫持响应数据
axiosInstance.interceptors.response.use(
    async function (response) {
        if (response.config.useNProgress !== false) {
            window.NProgress.done()
        }

        if (!is401(response)) return response
        return await refreshTokenFunc(getParams(response))
    },
    function (error) {
        if (error.response.config.useNProgress !== false) {
            window.NProgress.done()
        }

        if (error.response.status === HTTP_ERR_UNAUTHORIZED) {
            loginOut()
            return
        }

        // 对响应错误做点什么
        return Promise.reject(error)
    }
)

export default axiosInstance

