import store from '@store'
import router from './index'

import { message } from 'ant-design-vue'
import { WHITE_LIST } from '@config'
import { getToken } from '@utils/auth'

import Middleware from '@utils/Middleware'

const NOFUND_ROUTE = {
    path: '*',
    redirect: '/404',
    hidden: true
}

const middleware = new Middleware()
const usedAccessKeySet = new Set()

// 处理市民通 App 扫码跳转
middleware.use(async ({ to, store, next: nextRoute }, next) => {
    /**
     * 市民通 App 扫码之后回跳回来时，会携带一个 accessKey，
     * 我们需要使用这个 accessKey 去获取 tokens
     *
     * 提示
     * 这个 accessKey 只能用一次，用完就失效了，
     * 使用 usedAccessKeySet 是为保证路由跳转时，
     * 只触发一次 getTokensByAccessKey dispatch action。
     */
    const { accessKey } = to.query
    if (accessKey && !usedAccessKeySet.has(accessKey)) {
        usedAccessKeySet.add(accessKey)

        await store.dispatch('getTokensByAccessKey', to.query)

        nextRoute('/')
        return
    }

    next()
})

// 处理初始化信息
middleware.use(async ({ store }, next) => {
    // 初始化接口
    if (!store.getters.initInformation) {
        await store.dispatch('getInitInfo')
    }

    return next()
})

// 处理认证相关配置信息
middleware.use(async ({ to, store }, next) => {
    if (to.meta.needAuthProperties && !store.getters.authProperties) {
        await store.dispatch('getAuthProperties')
    }

    return next()
})

// 处理未登陆认证
middleware.use(async ({ to, next: nextRoute }, next) => {
    if (!getToken()) {
        if (WHITE_LIST.includes(to.path)) {
            nextRoute()
            return
        }

        // 跳转至登录页
        nextRoute(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
        return
    }

    return next()
})

// 处理登陆认证后，前往登陆页的重定向
middleware.use(async ({ to, next: nextRoute }, next) => {
    if (to.path === '/login') {
        nextRoute('/')
        return
    }

    return next()
})

// 处理用户相关信息及菜单权限等
middleware.use(async ({ to, store, next: nextRoute }) => {
    const { userInfo, role } = store.getters

    if (userInfo && role) {
        nextRoute()
        return
    }

    try {
        if (!userInfo) {
            await store.dispatch('getUserInfo')
        }

        if (!store.getters.enterpriseInfo) {
            // 没有企业认证，则直接跳到引导页
            nextRoute('/guide')
            window.NProgress.done()
            return
        }

        // dispatah 路由
        let routes = await store.dispatch('generateRoutes')

        // 添加404
        routes = routes.concat(NOFUND_ROUTE)
        // 向路由表中添加动态路由
        routes.forEach(route => router.addRoute(route))

        // eslint-disable-next-line
        nextRoute({ ...to, replace: true })
    } catch (e) {
        message.error(e.message)

        let timer = setTimeout(() => {
            store.dispatch('logOut')

            clearTimeout(timer)
            timer = null
            throw new Error(e)
        }, 500)
    }
})

router.beforeEach(async (to, from, next) => {
    window.NProgress.start()

    try {
        // eslint-disable-next-line
        await middleware.compose({ to, from, next, store })
    } catch (error) {
        console.error('Router permission caught: ' + error)
        this.$captureException(error)
    }
})

router.afterEach(() => {
    window.NProgress.done()
})
