import { Portal } from 'portal-vue'

export default {
    name: 'Portal',
    props: {
        disabled: Boolean,
        name: String,
        order: Number,
        slim: Boolean,
        slotProps: Object,
        tag: String,
        to: String
    },
    render(h) {
        return h(Portal, {
            props: {
                disabled: this.disabled,
                name: this.name,
                order: this.order,
                slim: this.slim,
                slotProps: this.slotProps,
                tag: this.tag,
                to: this.to
            }
        }, [this.$slots.default])
    }
}
