<template>
    <div class="custom-page">
        <weight-page-header v-if="showTitle" :title="title" />
        <div>
            <div v-if="showButton" class="custom-page__button-list">
                <template v-for="(button, index) in extraButton">
                    <a-button
                        :key="index"
                        class="extra-button"
                        type="primary"
                        @click="handleClick(button)"
                    >
                        {{ button.eventName }}
                    </a-button>
                </template>
            </div>

            <slot />
        </div>
    </div>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'

import childrenRefreshMixin from '@/mixins/createPage/childrenRefresh'
import getFormData from '@/mixins/createPage/formData'
import WeightPageHeader from '@/weights/PageHeader'
import buttonMixin from '@/mixins/createPage/button'
import eventMixin from '@/mixins/createPage/event'

import { EVENT_TYPE_NORMAL, BOOLEAN_PATTERN_FALSE } from '@/constant/enum'

export default {
    name: 'CustomPage',
    components: {
        WeightPageHeader,
        AButton
    },
    mixins: [
        childrenRefreshMixin, getFormData, buttonMixin, eventMixin
    ],
    props: {
        title: {
            type: String
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        eventList: {
            type: Array,
            default: () => []
        },
        fieldList: {
            type: Array,
            default: () => []
        },
        showButton: {
            type: Number,
            default: BOOLEAN_PATTERN_FALSE
        }
    },
    computed: {
        extraButton() {
            return this.getButtonList(
                this.eventList || [],
                EVENT_TYPE_NORMAL
            )
        }
    },
    methods: {
        async handleClick(event) {
            try {
                await this.emitEvent({
                    event
                })
            } catch (error) {
                console.log(error.message)
            }
        },
        handleRefresh() {
            // 子节点更新逻辑
            this.childrenFieldRefresh()
        }
    }
}
</script>

<style lang="scss" scoped>
    .custom-page {
        &__button-list {
            margin:8px 0;
        }
    }
</style>
