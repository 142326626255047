/**
 * 项目资料
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'
import PageTemplate from '@weights/PageTemplate/Page'
import DetailTemplate from '@weights/PageTemplate/Detail'

import {
    PROJECT_MANAGE_CODE,
    PROJECT_DETAIL_CODE,
    CASE_INFO_MENU_CODE,
    PROJECT_MANAGE_MENU_CODE,
    CASE_INFO_LIST_MENU_CODE,
    CASE_INFO_DETAIL_MENU_CODE
} from '@constant/menu_code'

import { PROJECT_MANAGE_MENU_SORT } from '@constant/menu_sort'

const routes = [
    {
        path: '/project',
        name: 'Project',
        component: BasicLayout,
        code: PROJECT_MANAGE_MENU_CODE,
        redirect: {
            name: PROJECT_MANAGE_CODE
        },
        meta: {
            title: '项目管理',
            icon: 'xiangmuguanli',
            iconType: 'iconfont'
        },
        sort: PROJECT_MANAGE_MENU_SORT,
        children: [
            {
                path: 'list',
                name: PROJECT_MANAGE_CODE,
                component: PageTemplate,
                code: PROJECT_MANAGE_CODE,
                meta: {
                    title: '项目列表',
                    breadcrumb: false,
                    layoutCode: PROJECT_MANAGE_CODE
                }
            },
            {
                path: 'detail',
                name: PROJECT_DETAIL_CODE,
                component: DetailTemplate,
                code: PROJECT_DETAIL_CODE,
                hidden: true,
                meta: {
                    title: '项目详情',
                    breadcrumb: true,
                    layoutCode: PROJECT_DETAIL_CODE,
                    siderMenuMatchRoute: PROJECT_MANAGE_CODE
                }
            },
            {
                path: 'case',
                name: CASE_INFO_MENU_CODE,
                component: RouteLayout,
                code: CASE_INFO_MENU_CODE,
                redirect: {
                    name: CASE_INFO_LIST_MENU_CODE
                },
                meta: {
                    title: '案件信息'
                },
                children: [
                    {
                        path: 'list',
                        name: CASE_INFO_LIST_MENU_CODE,
                        component: PageTemplate,
                        code: CASE_INFO_LIST_MENU_CODE,
                        hidden: true,
                        meta: {
                            title: '案件信息列表',
                            breadcrumb: false,
                            layoutCode: CASE_INFO_LIST_MENU_CODE
                        }
                    },
                    {
                        path: 'detail',
                        name: CASE_INFO_DETAIL_MENU_CODE,
                        component: DetailTemplate,
                        code: CASE_INFO_DETAIL_MENU_CODE,
                        hidden: true,
                        meta: {
                            title: '案件详情',
                            breadcrumb: true,
                            layoutCode: CASE_INFO_DETAIL_MENU_CODE
                        }
                    }
                ]
            }
        ]
    }
]

export default routes

