import commonService from '@service/commonService'
import deleteProjectFileService from '@service/deleteProjectFileService'
import confirmImportService from '@/service/importData/confirmImportService'

import {
    DYNAMIC_TYPE_ACTIVE,

    EVENT_OPERATION_TYPE_LAYOUT,

    EVENT_TYPE_NORMAL,

    FIELD_TYPE_HIDDEN,
    FIELD_TYPE_HIDDEN_FORM,

    MODAL_TYPE_UPLOAD,

    MODAL_SIZE_SMALL,

    QUERY_STATE_GLOBAL,
    QUERY_STATE_HIDDEN,
    QUERY_STATE_OPTIONAL,
    QUERY_STATE_NORMAL,
    QUERY_STATE_EXTRA,

    INTERFACE_TYPE_DOUBLE_CHECK,
    INTERFACE_TYPE_MODAL,
    INTERFACE_TYPE_NEW_PAGE,
    INTERFACE_TYPE_LINK,
    INTERFACE_TYPE_DEFAULT,
    INTERFACE_TYPE_DIRECT_CONNECT_MODAL
} from '@/constant/enum'

import { DYNAMIC_EVENT_URL, ACTIVE_EVENT_URL, LOAD_LAYOUT_URL } from '@/config'

import { hasOwnProperty } from '@utils'
import openLink from '@/utils/openLink'
import downloadFile from '@/utils/downloadFile'

import { parserPanel } from '@/weights/CreatePage/parse'

import openNewPageEventStrategy from './openNewPageEvent'

export default {
    inject: ['reloadPage'],
    props: {
        eventList: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        /**
         * 获取当前事件
         */
        getEventByEventCode(eventCode) {
            let event = {}
            // this.eventList
            this.eventList.forEach(item => {
                if (item.eventCode === eventCode) {
                    event = item
                }
            })
            return event
        },
        // 获取列中的筛选数据
        getTableRowParams(rowData, event) {
            const { queryCriteriaList } = event
            const params = {}
            queryCriteriaList.forEach(query => {
                // queryState（0：非查询字段，1：隐藏查询字段，2：默认查询字段，3：扩展查询字段）
                switch (query.queryState) {
                    case QUERY_STATE_HIDDEN:
                    case QUERY_STATE_OPTIONAL:
                        // 不展示字段从内容上获取
                        if (rowData[query.fieldCode] || query.defaultValue) {
                            params[query.fieldCode] =
                                rowData[query.fieldCode] || query.defaultValue
                        }
                        break
                    default:
                        break
                }
            })

            return params
        },
        /**
         * 获取事件需要的参数
         * @param {object} nowParams 当前参数
         * @param {object} searchParams 搜索参数
         * @param {object} _params 默认参数
         * @param {QueryCriteria[]>} queryCriteriaList 请求所需的参数列表
         * @return {object}
         */
        getEventParams(
            nowParams = {},
            searchParams = {},
            _params = {},
            queryCriteriaList
        ) {
            // 整理事件需要的参数
            const params = {}
            queryCriteriaList.forEach(query => {
                // queryState（0：非查询字段，1：隐藏查询字段，2：默认查询字段，3：扩展查询字段）
                switch (query.queryState) {
                    case QUERY_STATE_HIDDEN:
                    case QUERY_STATE_OPTIONAL:
                        // 不展示字段从内容或默认值上获取
                        if (nowParams[query.fieldCode] || query.defaultValue) {
                            params[query.fieldCode] =
                                nowParams[query.fieldCode] || query.defaultValue
                        }
                        break
                    case QUERY_STATE_NORMAL:
                    case QUERY_STATE_EXTRA:
                        // 搜索项的字段，搜索项上获取
                        if (
                            searchParams[query.fieldCode] ||
                            query.defaultValue
                        ) {
                            params[query.fieldCode] =
                                searchParams[query.fieldCode] ||
                                query.defaultValue
                        }
                        break

                    default:
                        break
                }
            })

            const globalParams = this.getGlobalParams(queryCriteriaList)

            return {
                ...globalParams,
                ...params,
                ..._params
            }
        },
        /**
         * 获取隐藏的参数
         * @param {object} data 原始数据（使用前需在组件中定义）
         * @param {object} fieldList 字段列表（使用前需在组件中定义）
         * @returns {object}
         */
        getHiddenParams(data = {}) {
            const params = {}
            this.fieldList.forEach(item => {
                if (
                    item.fieldType === FIELD_TYPE_HIDDEN ||
                    item.fieldType === FIELD_TYPE_HIDDEN_FORM
                ) {
                    if (data[item.attributeCode] || this.data[item.attributeCode]) {
                        params[item.fieldCode] =
                        data[item.attributeCode] ||
                        this.data[item.attributeCode]
                    }

                }
            })

            return params
        },
        getGlobalParams(queryCriteriaList = []) {
            const globalData = this.$store.getters.globalData

            const params = {}
            const routeQuery = this.$route.query || {}
            queryCriteriaList.forEach(query => {
                // queryState（0：非查询字段，1：隐藏查询字段，2：默认查询字段，3：扩展查询字段）
                switch (query.queryState) {
                    case QUERY_STATE_HIDDEN:
                    case QUERY_STATE_OPTIONAL:
                    case QUERY_STATE_GLOBAL:
                        // 不展示字段从全局参数或默认值或route参数上获取
                        if (
                            globalData[query.fieldCode] ||
                            query.defaultValue ||
                            routeQuery[query.fieldCode]
                        ) {
                            params[query.fieldCode] =
                                globalData[query.fieldCode] ||
                                query.defaultValue ||
                                routeQuery[query.fieldCode]
                        }
                        break
                }
            })

            return params
        },
        async triggerImportEventServer(event, params) {

            const { isSuccess, importRecordRequestId, bizType } = params
            if (isSuccess && importRecordRequestId) {
                try {

                    const res = await confirmImportService({
                        bizType,
                        importRecordRequestId
                    })

                    this.$message.success('导入成功')

                    return {
                        result: res,
                        requestParams: params
                    }
                } catch (error) {
                    console.log(error)
                    this.$message.warning(error.message)
                }
            } else {
                return {}
            }
        },
        /**
         * 获取事件对应的方法
         * @param {EventItem} event 事件相关数据
         * @param {object} params 请求参数
         */
        async triggerEventServer(event, params) {
            // operationType 1：激活事件，60：加载布局 // dynamicType 动态布局
            if (event.eventCode) {
                let url = ''
                const eventParam = {}

                // 如果是动态模版使用动态接口
                if (event.dynamicType === DYNAMIC_TYPE_ACTIVE) {
                    url = DYNAMIC_EVENT_URL
                    eventParam.eventCode = event.eventCode
                } else {
                    url =
                        event.operationType !== EVENT_OPERATION_TYPE_LAYOUT
                            ? ACTIVE_EVENT_URL
                            : LOAD_LAYOUT_URL

                    if (event.operationType !== EVENT_OPERATION_TYPE_LAYOUT) {
                        eventParam.eventCode = event.eventCode
                    } else {
                        eventParam.layoutCode = event.eventCode
                    }
                }

                const res = await commonService({
                    url,
                    params: {
                        ...eventParam,
                        ...params
                    },
                    method: 'post'
                })
                return {
                    result: res,
                    requestParams: params
                }
            } else {
                this.$message.warn('未找到对应的事件')
            }
        },
        /**
         * 触发事件
         * @param {EventItem} event 按钮code
         * @param {object} localData 当前数据
         * @param {object} _params 默认参数
         * @returns {object} 事件和请求结果
         */
        async triggerEvent(event, localData = {}, _params = {}) {
            // 获取参数
            const params = this.getEventParams(
                localData,
                {},
                _params,
                event.queryCriteriaList || []
            )

            // 请求目标事件
            const { result: eventResult, requestParams } =
                await this.triggerEventServer(event, params)

            return {
                event,
                eventResult,
                requestParams
            }
        },
        /**
         * 触发事件
         * @param {object} param 参数
         * @param {EventItem} param.event event
         * @param {object} param.localData 当前数据
         * @param {object} param._params 默认参数
         * @param {Number} param.modalType 弹框类型
         */
        async emitEvent(param) {
            const {
                event = {},
                localData = {},
                _params = {},
                modalType
            } = param

            const interfaceType = event.interfaceType

            // 如果是自定义的事件
            const isGlobalOperation =
                event.permissionCode.indexOf('globalOperation') > -1

            if (
                isGlobalOperation &&
                event.permissionCode?.split('_').length > 1
            ) {
                this.triggerGlobalEvent(event, localData)
                return
            }
            switch (interfaceType) {
                case INTERFACE_TYPE_DEFAULT: // 默认刷新事件
                    this.triggerDefaultEvent(event, localData, _params)
                    break
                case INTERFACE_TYPE_DOUBLE_CHECK: // 二次确认
                    this.triggerConfirmEvent(event, localData, _params)
                    break
                case INTERFACE_TYPE_MODAL: // 配置化弹框
                    this.triggerModalEvent(event, localData, _params, modalType)
                    break
                case INTERFACE_TYPE_DIRECT_CONNECT_MODAL: // 直连弹框
                    this.triggerDirectConnectModalEvent(event, localData, _params)
                    break
                case INTERFACE_TYPE_NEW_PAGE: // 打开新页面
                    this.triggerOpenNewPageEvent(event, localData, _params)
                    break
                case INTERFACE_TYPE_LINK: // 跳转链接
                    this.triggerOpenLink(event, localData, _params)
                    break
                default:
                    this.$message.warn('未设置对应的事件处理方式')
            }
        },
        /**
         * 触发普通事件
         * @param {EventItem} event
         * @param {object} localData
         * @param {object} _params
         */
        async triggerDefaultEvent(event, localData, _params) {
            try {
                const { eventResult } = await this.triggerEvent(event, localData, _params)
                const dataString = JSON.parse(eventResult.data?.dataString)
                this.handleRefresh(dataString)
            } catch (error) {
                console.log(error)
                this.$message.error(error.message)
            }

        },
        /**
         * 触发二次确认事件
         * @param {EventItem} event
         * @param {object} localData
         * @param {object} _params
         */
        triggerConfirmEvent(event, localData, _params) {
            console.log(event)
            this.$confirm({
                title: event.eventName,
                content: event.errorMessage || `确定要${event.eventName || '执行此操作'}吗`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    try {
                        await this.triggerEvent(event, localData, _params)
                        this.$message.success('操作成功')
                        this.handleRefresh()
                    } catch (error) {
                        this.$message.error(error.message)
                        console.log(error.message)
                    }
                }
            })
        },
        /**
         * 触发直连弹框事件，即显示直连弹框
         *
         * @param {EventItem} event
         * @param {object} localData
         * @param {object} params
         */
        triggerDirectConnectModalEvent(event, localData, params) {
            if (this.handleOpenDirectConnectModal) {
                this.handleOpenDirectConnectModal(event, localData, params)
            }
        },
        /**
         * 触发弹框事件
         * @param {EventItem} event
         * @param {object} localData
         * @param {object} _params
         * @param {String} modalType
         */
        triggerModalEvent(event, localData, _params, modalType) {
            this.$refs['mutiple-modal'].addModalAsync(
                event,
                localData,
                _params,
                modalType
            )
        },
        /**
         * 触发打开新页面事件
         * @param {EventItem} event
         * @param {object} localData
         */
        triggerOpenNewPageEvent(event, localData, _params) {
            const { eventCode } = event

            let openNewPage = openNewPageEventStrategy.default

            if (hasOwnProperty(openNewPageEventStrategy, eventCode)) {
                openNewPage = openNewPageEventStrategy[eventCode]
            }

            openNewPage.apply(this, [event, localData, _params])
        },
        /**
         * 跳转外部链接事件
         * @param {EventItem} event
         * @param {object} localData
         */
        async triggerOpenLink(event, localData, _params) {
            try {
                const { eventResult } = await this.triggerEvent(event, localData, _params)

                const data = parserPanel(eventResult)
                if (data.props.data) {
                    const body = JSON.parse(data.props.data.body)
                    const urlBody = JSON.parse(body.body)
                    const link = urlBody.url
                    // TIP 获取跳转链接
                    if (link) {
                        openLink(link)
                    }
                }
            } catch (error) {
                this.$message.error(error.message)
            }

        },
        /**
         * 触发全局事件（自定义事件或无权限判断的事件）
         * @param {EventItem} event
         * @param {Object} localData
         */
        triggerGlobalEvent(event, localData) {
            const eventCode = event.eventCode || 'default'

            switch (eventCode) {
                case 'project_data':
                case 'special_equipment':
                    // 上传文件
                    this.$refs['mutiple-modal'].addModal(
                        eventCode,
                        {
                            layoutName: '上传文件',
                            props: {
                                size: MODAL_SIZE_SMALL
                            },
                            eventList: [
                                {
                                    operationType: 20,
                                    eventType: EVENT_TYPE_NORMAL,
                                    interfaceType: INTERFACE_TYPE_DEFAULT,
                                    permissionCode: 'globalOperation',
                                    eventCode: '',
                                    eventName: '确定',
                                    isLocal: true
                                }
                            ]
                        },
                        MODAL_TYPE_UPLOAD
                    )
                    break
                case 'download':
                    // 下载文件
                    if (localData.fileUrl) {
                        downloadFile(localData.fileUrl)
                    } else {
                        this.$message.warn('没有对应的下载链接')
                    }
                    break
                case 'delete_file':
                    // 删除文件
                    if (localData.id) {
                        this.$confirm({
                            title: '删除',
                            content: `确定要删除吗`,
                            okText: '确认',
                            cancelText: '取消',
                            onOk: async () => {
                                try {
                                    const globalData = this.getGlobalParams()
                                    await deleteProjectFileService({
                                        ...globalData,
                                        id: localData.id
                                    })
                                    this.handleRefresh()
                                    this.$message.success('操作成功')
                                } catch (error) {
                                    this.$message.error(error.message)
                                    console.log(error)
                                }
                            }
                        })
                    } else {
                        this.$message.success('数据问题')
                    }
            }
        },
        /**
         * 跳转目标页面
         * @param {EventItem} event
         * @param {String} pageUrl
         */
        JumpTargetPage(event, pageUrl) {
            if(pageUrl) {
                this.$router.push(pageUrl)
                return
            }

            switch (event.eventCode) {
                // `新建项目` 情况下
                case 'project_11':
                case 'project_choose_12':
                    // 调用 `App.vue` 页面的 `重载页面方法`
                    this.reloadPage()
                    break
                default:
                    break
            }
        }
    }
}
