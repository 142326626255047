import axios from 'axios'

const getRefreshTokenFunc = function () {
    let refreshedArr = []
    return async function (obj) {

        const { response, updateTokens, urlList, loginOut } = obj

        const responseConfig = response.config
        const { url } = responseConfig

        // 如果刷新token的接口或者登录的接口报401 或者 已经重复请求3次了
        // 就不触发刷新机制 退出登录 避免死循环
        if (urlList.indexOf(url) > -1 || refreshedArr.length > 3) {
            setTimeout(() => {
                loginOut()
            }, 500)
            return response
        }

        refreshedArr.push(url)
        await updateTokens(responseConfig)

        const res = await axios(responseConfig)
        refreshedArr = []

        return res
    }
}
// 接口401 无痛刷新token机制
const refreshTokenFunc = getRefreshTokenFunc()
export default refreshTokenFunc
