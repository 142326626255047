import { calculator } from '4d-space-bag'
import { isEmpty, empty as emptyFilter } from '@utils'
import { timeFilter, ratioFilter, withYuanUnitAmountFilter } from '@utils/filter'

// 保函
export const GUARANTEE_PANEL = 'GUARANTEE_PANEL'
// 现金存缴
export const CASH_PAYMENT_PANEL = 'CASH_PAYMENT_PANEL'
// 免缴
export const EXEMPTION_PAYMENT_PANEL = 'EXEMPTION_PAYMENT_PANEL'
// 缓缴
export const DEFERREED_PAYMENT_PANEL = 'DEFERREED_PAYMENT_PANEL'

// 二级面板组件名称集
export const LOWER_LEVEL_PANELS = [
    GUARANTEE_PANEL,
    CASH_PAYMENT_PANEL,
    EXEMPTION_PAYMENT_PANEL,
    DEFERREED_PAYMENT_PANEL
]

export const LOWER_LEVEL_PANEL_FACTORY = {
    [GUARANTEE_PANEL]: () => import('./GuaranteePanel'),
    [CASH_PAYMENT_PANEL]: () => import('./CashPaymentPanel'),
    [EXEMPTION_PAYMENT_PANEL]: () => import('./ExemptionPayment'),
    [DEFERREED_PAYMENT_PANEL]: () => import('./DeferreedPayment')
}

// 控制显示的字段映射对象
export const SHOW_CONTROL_FIELD_MAPPING = {
    [GUARANTEE_PANEL]: 'guaranteeState',
    [CASH_PAYMENT_PANEL]: 'cashShowState',
    [EXEMPTION_PAYMENT_PANEL]: 'exemptionShowState',
    [DEFERREED_PAYMENT_PANEL]: 'deferredShowState'
}

// 源数据的字段映射对象
export const DATA_SOURCE_FIELD_MAPPING = {
    [GUARANTEE_PANEL]: 'guaranteeVO',
    [CASH_PAYMENT_PANEL]: 'cashPaymentRecordVO',
    [EXEMPTION_PAYMENT_PANEL]: 'exemptionOrderVO',
    [DEFERREED_PAYMENT_PANEL]: 'deferreedOrderVO'
}

// 公共部分（前部分）
const COMMON_FRONT_PART = [
    {
        dataIndex: 'contractAmount',
        label: '施工合同金额',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'quotaValue',
        label: '申请比例',
        customRender: val => ratioFilter(isEmpty(val) ? val : calculator.div(val, 100))
    },
    {
        dataIndex: 'amountPayable',
        label: '保证金应缴金额',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'paymentTypeText',
        label: '申请类型',
        customRender: val => emptyFilter(val)
    }
]

// 公共部分（后部分）
const COMMON_BACK_PART = [
    {
        dataIndex: 'agent',
        label: '经办人',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'agentPhone',
        label: '经办人电话',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'workflowApplyRecordId',
        label: '申请批次号',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'applyEndTime',
        label: '申请完成时间',
        customRender: val => timeFilter(val, 'all')
    },
    {
        dataIndex: 'createDateTime',
        label: '创建时间',
        customRender: val => timeFilter(val, 'all')
    },
    {
        dataIndex: 'filingApplicationStatusText',
        label: '备案状态',
        customRender: val => emptyFilter(val)
    }
]

// 差异部分
const DIFFERENT_PART = {
    // 保函
    [GUARANTEE_PANEL]: [
        {
            dataIndex: 'paymentMethodText',
            label: '缴纳方式',
            sort: 1,
            customRender: val => emptyFilter(val)
        },
        {
            dataIndex: 'guaranteeAmount',
            label: '保函金额',
            sort: 2,
            customRender: val => withYuanUnitAmountFilter(val)
        }
    ],

    // 现金存缴
    [CASH_PAYMENT_PANEL]: [
        {
            dataIndex: 'paymentMethodText',
            label: '缴纳方式',
            sort: 1,
            customRender: val => emptyFilter(val)
        },
        {
            dataIndex: 'cashPaymentAmount',
            label: '现金金额',
            sort: 2,
            customRender: val => withYuanUnitAmountFilter(val)
        }
    ],

    // 免缴
    [EXEMPTION_PAYMENT_PANEL]: [],

    // 缓缴
    [DEFERREED_PAYMENT_PANEL]: [
        {
            dataIndex: 'deferredStartDateAndEndDateText',
            label: '缓缴日期',
            sort: 1,
            customRender: val => emptyFilter(val)
        }
    ]
}

// 根据 `data` 中的值获取差异部分
function getDifferentParts(data = {}) {
    const parts = []
    const map = new Map()

    LOWER_LEVEL_PANELS.forEach(name => {
        if (data[SHOW_CONTROL_FIELD_MAPPING[name]]) {
            const columns = DIFFERENT_PART[name] || []

            columns.forEach(column => {
                if (!map.has(column.dataIndex)) {
                    parts.push(column)
                    map.set(column.dataIndex, true)
                }
            })
        }
    })

    return parts.sort((pre, cur) => pre.sort - cur.sort)
}

// 生成配置
export function generateColumns(data = {}) {
    return [
        ...COMMON_FRONT_PART,
        ...getDifferentParts(data),
        ...COMMON_BACK_PART
    ]
}
