import _ from 'lodash'
import {
    LAYOUT_TYPE_FORM,
    LAYOUT_TYPE_TABLE,
    EVENT_OPERATION_TYPE_LAYOUT,
    EVENT_TYPE_DEFAULT
} from '@/constant/enum'
// function parseTemplate(data) {}

// 格式化页面原始参数
export function formatTemplate(template) {
    // 对数据格式化
    if (template.data && _.isString(template.data)) {
        template.data = JSON.parse(template.data)
    }
    if (template.data?.dataString && _.isString(template.data.dataString)) {
        template.data.dataString = JSON.parse(template.data.dataString)
    }

    if (template.dataList && _.isString(template.dataList)) {
        template.dataList = JSON.parse(template.dataList)
    }

    if (template.fileJson && _.isString(template.fileJson)) {
        template.fileJson = JSON.parse(template.fileJson)
    }

    // 递归条件
    if (template.children && _.isArray(template.children)) {
        template.children.forEach(item => {
            formatTemplate(item)
        })
    }

    return template
}

/**
 * 针对没有children 的page modal card 创造子集的数据
 * @param {Template} template 基础数据
 * @param {object} childrenComponentType 子组件组件类型
 */
export const createChidren = (template, childrenComponentType) => {
    if (!template.children || template.children.length === 0) {
        if (childrenComponentType.originType) {
            template.children = [
                {
                    layoutCode: template.layoutCode,
                    field: template.layoutCode + '_1',
                    componentType: childrenComponentType.originType,
                    data: template.data,
                    eventList: template.eventList
                }
            ]
        } else if (template.layoutType === LAYOUT_TYPE_FORM) {
            // TIP 后面要改掉
            template.children = [
                {
                    componentType: 'form',
                    layoutCode: template.layoutCode,
                    field: template.layoutCode + '_1',
                    children: template.children,
                    data: template.data,
                    eventList: template.eventList
                }
            ]
        } else if (template.layoutType === LAYOUT_TYPE_TABLE) {
            // TIP 后面要改掉
            template.children = [
                {
                    componentType: 'table',
                    layoutCode: template.layoutCode,
                    field: template.layoutCode + '_1',
                    children: template.children,
                    data: template.data,
                    eventList: template.eventList
                }
            ]
        }
    } else {
        // 子集保存功能字段
        template.children.forEach(item => {
            item.props = {
                parentChildren: template.children
            }
        })
    }
}

/**
 * 添加children field集合
 * @param {object} template 模板数据单元
 */
export const getChildrenField = template => {
    if (template.children && template.children.length > 0) {
        // 获取当前子集的field
        const childrenField = template.children.map(
            item => item.field || item.layoutCode
        )
        return childrenField
    }
}

/**
 * 清除需要隐藏的子集
 * @param {object} template 基础数据
 * @returns 返回删除需要隐藏的子集后的数据
 */
export function clearHiddenChild(template) {
    // 递归处理
    if (template && template.children) {
        const children = template.children.filter(item => !item.hidden)
        template.children = children
        template.children.forEach(childTemplate => {
            clearHiddenChild(childTemplate)
        })
    }
    return template
}

/**
 * 添加通用的props
 * @param {Template} template 模板
 */
export function addCommonProps(template) {
    const config = {
        data: template.data?.dataString || {},
        field: template.field,
        eventList: template.eventList,
        fieldList: template.data?.fieldList || [],
        title: template.layoutName,
        showTitle: _.isNumber(+template.showTitle) ? !!template.showTitle : true,
        emits: template.emits || {}
    }
    if (template.props) {
        template.props = {
            ...template.props,
            ...config
        }
    } else {
        template.props = config
    }
}

// layout dataType 10：表单类型，form 20：列表类型，table 30：分组类型 description

/**
 * 处理componentType
 * @param {ComponentType} componentType 原始的componentType字符串
 * @returns {object}
 */
export function getComponentType(componentType) {
    let nowComponentType = {}
    let childrenComponentType = {}
    let originNowComponentType = ''
    let originChildComponentType = ''

    // 如果组件类型
    if (componentType) {
        // 'card-description_2'
        const arr = componentType.split('-') // ['card','description_2']
        if (arr.length > 0) {
            const res = arr[0].split('_') // ['card']
            nowComponentType.type = res[0] // card
            nowComponentType.params = res.slice(1) // []
            nowComponentType.originType = arr[0] // card

            originNowComponentType = arr[0]
        }

        if (arr.length > 1) {
            const res = arr[1].split('_') // ['description','2']
            childrenComponentType.type = res[0] // description
            childrenComponentType.params = res.slice(1) // 2
            childrenComponentType.originType = arr[1] // 'description_2'

            originChildComponentType = arr[1]
        }
    }

    return {
        nowComponentType,
        childrenComponentType,
        originNowComponentType,
        originChildComponentType
    }
}

/**
 * 针对tabs进行处理
 * @param {Tempalte} template
 */
export function renderTabs(template) {
    template.type = 'CustomTabs'
    const list = []
    template.children.forEach((item, index) => {
        if (item.componentType !== 'extraTitle') {
            list.push({
                ...item,
                selfControl: template.props?.selfControl || 2
            })
            item.props = {
                uid: template.props.uid,
                stepId: template.props.stepId
            }
            item.slot = `slot-${index}`
        }
    })
    template.props.list = list
    template.props.title = template.layoutName
    template.props.rule = template.children
}

export function renderStep(template) {
    template.type = 'CustomStep'

    const list = []
    template.children.forEach((item, index) => {
        list.push({
            name: item.layoutName,
            layoutCode: item.layoutCode,
            selfControl: template.props?.selfControl || 2,
            eventList: item.eventList
        })
        item.slot = `slot-${index}`
    })
    template.props.list = list
    template.props.title = template.layoutName
    template.props.rule = template.children
    template.props.requestParams = template.requestParams
}

/**
 * 添加自定义样式参数
 * @param {Template} template
 * @param {Array} componentParams
 * @param {Array<String>} keys
 */
export function formatCustomParams(template, componentParams, keys = []) {
    keys.forEach((key, index) => {
        const value = +componentParams[index]
        if (!Number.isNaN(value)) {
            template.props[key] = value
        }
    })
}

/**
 * 调整fieldList内部分组转化为children
 * @param {Template} template
 */
export function fieldListToChildren(template) {
    const { originNowComponentType,
        originChildComponentType } = getComponentType(
        template.componentType || ''
    )

    const fieldList = template.data?.fieldList

    const children = []

    if (fieldList && fieldList.length > 0) {
        for (let i = 0; i < fieldList.length; i++) {
            const field = fieldList[i]
            if (field.type === '2' && field.list) {
                const child = _.cloneDeep(template)
                child.data.fieldList = field.list
                child.eventList = []
                child.layoutName = field.layoutName
                child.layoutCode = template.layoutCode + '_' + (children.length + 1)
                child.componentType = originChildComponentType
                children.push(child)

                fieldList.splice(i, 1)
                i--
            }
        }
    }

    if (children.length > 0) {
        template.componentType = originNowComponentType

        template.children = [...template.children, ...children]
    }


}

/**
 * 将事件中projectCode传递到children event queryCriteriaList中
 * @param {Template} template
 */
export function addFatherParams(template, globalQuery, globalParams = {}) {

    const initEvent = template.eventList?.filter(
        event => event.eventType === EVENT_TYPE_DEFAULT
    )[0]

    let isLoadLayout = true

    if (initEvent) {
        isLoadLayout = initEvent.operationType === EVENT_OPERATION_TYPE_LAYOUT
    }

    if (template.eventList && template.eventList.length > 0 && !isLoadLayout) {
        template.eventList.forEach(event => {
            if (globalQuery && globalQuery.length > 0) {

                globalQuery.forEach(fQuery => {

                    let sonProjectCodeIndex = -1
                    if (event.queryCriteriaList && event.queryCriteriaList.length > 0) {
                        sonProjectCodeIndex = event.queryCriteriaList.findIndex(query => query.fieldCode === fQuery.fieldCode)
                    }

                    if (sonProjectCodeIndex === -1) {

                        if (event.queryCriteriaList) {
                            event.queryCriteriaList.push(_.cloneDeep(fQuery))
                        } else {
                            event.queryCriteriaList = []
                            event.queryCriteriaList.push(_.cloneDeep(fQuery))
                        }
                    } else {
                        event.queryCriteriaList[sonProjectCodeIndex].defaultValue = event.queryCriteriaList[sonProjectCodeIndex].defaultValue || fQuery.defaultValue || globalParams[event.queryCriteriaList[sonProjectCodeIndex].fieldCode]
                    }
                })
            }
        })
    }

    if (globalQuery && globalQuery.legnth > 0) {

        if (template.children && template.children.length > 0) {
            template.children.forEach(item => {
                if (item.eventList && item.eventList.length > 0) {
                    item.eventList.forEach(event => {

                        globalQuery.forEach(fQuery => {

                            let sonProjectCodeIndex = -1
                            if (event.queryCriteriaList && event.queryCriteriaList.length > 0) {
                                sonProjectCodeIndex = event.queryCriteriaList.findIndex(query => query.fieldCode === fQuery.fieldCode)
                            }

                            if (sonProjectCodeIndex === -1) {

                                if (event.queryCriteriaList) {
                                    event.queryCriteriaList.push(_.cloneDeep(fQuery))
                                } else {
                                    event.queryCriteriaList = []
                                    event.queryCriteriaList.push(_.cloneDeep(fQuery))
                                }
                            } else {

                                event.queryCriteriaList[sonProjectCodeIndex].defaultValue = event.queryCriteriaList[sonProjectCodeIndex].defaultValue || fQuery.defaultValue || globalParams[event.queryCriteriaList[sonProjectCodeIndex].fieldCode]
                            }
                        })


                    })
                    addFatherParams(item, globalQuery, globalParams)
                }
            })
        }

    }
}
