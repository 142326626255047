// 验证邮箱
export const validateEmail = (rule, value, callback) => {
    const reg =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的邮箱地址`
        callback(new Error(errorMsg))
    }
}
// 验证统一社会信用代码
export const validateSocialCreditCode = (rule, value, callback) => {
    const reg = /^[A-Z0-9]{18}$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的统一社会信用代码`
        callback(new Error(errorMsg))
    }
}
// 验证中国手机号有效性
export const validatePhoneNumber = (rule, value, callback) => {
    const reg = /^1[3456789]\d{9}$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的手机号`
        callback(new Error(errorMsg))
    }
}
// 验证url有效性
export const validateUrl = (rule, value, callback) => {
    const reg =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入有效的url地址`
        callback(new Error(errorMsg))
    }
}
// 验证座机有效性
export const validateLandline = (rule, value, callback) => {
    const reg = /^0\d{2,3}-[1-9]\d{6,7}$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的座机号`
        callback(new Error(errorMsg))
    }
}
// 验证身份证有效性
export const validateIDCard = (rule, value, callback) => {
    const reg =
        /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确的身份证号`
        callback(new Error(errorMsg))
    }
}
// 验证整数数字
export const validateInteger = (rule, value, callback) => {
    const reg = /^\d+$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入整数数字`
        callback(new Error(errorMsg))
    }
}
// 验证数字
export const validateNumber = (rule, value, callback) => {
    const reg = /^\d+$|^\d*\.\d+$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入数字`
        callback(new Error(errorMsg))
    }
}

// 验证数字
export const validateMoney = (rule, value, callback) => {
    const reg = /^\d+$|^\d*\.(\d{0,2})$/
    if (!value || reg.test(value)) {
        callback()
    } else {
        // 错误信息
        const errorMsg = `请输入正确金额`
        callback(new Error(errorMsg))
    }
}

// 验证数字范围
export const validatorNumberRange = function (
    rule,
    value,
    callback,
    { small, large }
) {
    if (!value) {
        callback()
        return
    }

    // 最小值和最大值同时存在
    if (small && large) {
        if (value > small && value < large) {
            callback()
            return
        }

        callback(new Error(`输入范围为${small}-${large}`))
        return
    }

    if (small && value <= small) {
        callback(new Error(`输入值不可小于等于${small}`))
        return
    }

    if (large && value >= large) {
        callback(new Error(`输入值不可大于等于${large}`))
        return
    }
    callback()
}
