<template>
    <a-select
        v-model="modelValue"
        show-search
        :loading="loading"
        :disabled="disabled"
        :filter-option="false"
        :allow-clear="allowClear"
        :placeholder="placeholder"
        @search="handleSearch"
        @blur="() => $emit('blur')"
    >
        <div v-if="loading" slot="notFoundContent">
            <a-spin size="small" />
        </div>

        <template v-for="item in options">
            <a-select-option
                :key="item.socialCreditCode"
                :value="item.socialCreditCode"
            >
                {{ item.generalConstructionContractingName }}
            </a-select-option>
        </template>
    </a-select>
</template>
<script>
import { Select, Spin } from 'ant-design-vue'

import lodash from 'lodash'

import getUnitListService from '@service/selectService/getUnitListService'

export default {
    name: 'UnitSelect',
    components: {
        ASpin: Spin,
        ASelect: Select,
        ASelectOption: Select.Option
    },
    props: {
        value: {
            type: [Number, String]
        },
        fieldCode: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowClear: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            params: {},
            options: [],
            loading: false,

            searchFn: lodash.debounce(this.getOptions, 500)
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('change', val)
                this.$emit('input', val)
            }
        }
    },
    created() {
        this.handleSearch()
    },
    methods: {
        async getOptions(params = {}) {
            try {
                this.loading = true

                this.params = {
                    ...this.params,
                    ...params
                }

                this.options = await getUnitListService(this.params) || []
            } catch (e) {
                console.log('-> e', e)
            } finally {
                this.loading = false
            }
        },
        handleSearch(enterprise) {
            // eslint-disable-next-line
            this.searchFn({ enterprise })
        }
    }
}
</script>
