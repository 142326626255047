<template>
    <layout-sider
        v-model="collapsed"
        :class="layoutSideClassName"
        :width="sidebarWidth"
        :collapsible="collapsible"
        :collapsed-width="sidebarCollapsedWidth"
        :trigger="null"
    >
        <div style="height: 48px">
            <img src="@assets/images/decorate.jpg" alt="" width="60px" height="48px">
        </div>
        <div class="menu-list">
            <i-menu
                :theme="theme"
                :collapsed="collapsed"
                :menu-data="menuData"
                @on-select="handleMenuSelect"
            />
            <img v-show="!collapsed" src="@assets/images/check-person.jpg">
        </div>

        <div class="gongbao-sider__footer"  @click="handleToggleCollapse">
            <!-- 展开/关闭按钮 -->
            <a-icon
                style="margin-right: 10px"
                :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            />
            <span v-show="!collapsed">隐藏边栏</span>
        </div>
    </layout-sider>
</template>

<script>
import { Layout, Icon as AIcon } from 'ant-design-vue'
import IMenu from './menu'
import { sidebarWidth, sidebarCollapsedWidth } from '@assets/styles/varibles.scss'

export default {
    name: 'SiderMenu',
    components: {
        IMenu,
        AIcon,
        LayoutSider: Layout.Sider
    },
    props: {
        collapsible: {
            type: Boolean,
            required: false,
            default: false
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        menuData: {
            type: Array,
            required: true
        },
        theme: {
            type: String,
            required: false,
            default: 'light'
        },
        fixed: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        systemName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            sidebarWidth,
            sidebarCollapsedWidth
        }
    },
    computed: {
        layoutSideClassName() {
            const { theme, fixed } = this
            return [theme, 'gongbao-sider', fixed ? 'gongbao-sider--fixed' : null]
        }
    },
    methods: {
        handleMenuSelect(obj) {
            this.$emit('on-menu-select', obj)
        },
        handleToggleCollapse() {
            this.$emit('on-toggle-collapse')
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.gongbao-sider {
    z-index: 8;

    &.light {
        background-color: #fff;
    }

    &.dark {
        background-color: #001529;
    }

    &--fixed {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        border-right: none;
        box-shadow: 0 2px 24px 0 rgba(200, 201, 204, 0.5);

    }
    .menu-list {
        height: calc(100% - 92px);
        overflow: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img{
          width: 200px;
          height: 204px;
          padding-top: 70px;
        }
        .ant-menu-item {
            margin: 0;
        }
    }

    &__footer {
        border-top: 1px solid #f0f0f0;
        padding: 12px 22px;
        // font-size: 14px;
        color: #323233;
        line-height: 20px;
    }

    &__icon {
        &.anticon {
            vertical-align: 0;

            &:before {
                display: inline-block;
            }
        }
    }

    .ant-menu-inline-collapsed {
        width: $sidebar-collapsed-width;

        & > .ant-menu-item,
        & > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
        &
            > .ant-menu-item-group
            > .ant-menu-item-group-list
            > .ant-menu-submenu
            > .ant-menu-submenu-title,
        & > .ant-menu-submenu > .ant-menu-submenu-title {
            padding: 0 22px !important;
        }
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right: none;
    }
    .ant-menu-item > a {
        color: #323233;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: $base-color;
        transition: all .5;
    }
    .ant-menu-item-selected > a,
    .ant-menu-item-selected > a:hover {
        color: #fff;
    }
    // 一级+二级菜单hover 背景色
    .ant-menu-item-active,.ant-menu-submenu-active{
        background: #F4F5F7;
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        border-right: none;
        color: #323233;
    }


    // 一级菜单的字体颜色
    .ant-menu-submenu-title,.ant-menu-submenu-title:hover{
        color:#323233
    }
    // 右边箭头的颜色
    .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
        background: #fff;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
    }


    // 一级菜单选中时 icon+文本颜色
    .ant-menu-submenu-selected .iconfont,
    .ant-menu-submenu-selected .ant-menu-submenu-title{
        color: #1890ff;
    }

}
</style>
