import axios from '@service'
import { GET_MARGIN_DIFFERENTIATION_INFO } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

// 差异化缴存信息查询
export default async function getMarginDifferentiationService(payload = {}, config = {}) {
    const param = {
        ...GET_MARGIN_DIFFERENTIATION_INFO,
        ...config,
        params: payload
    }
    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
