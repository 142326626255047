import checkbox from './checkbox'
import radio from './radio'
import select from './select'
import datePicker from './datePicker'
import hidden from './hidden'
import input from './input'
import timePicker from './timePicker'
import tree from './tree'
import row from './row'

export default [
    checkbox,
    datePicker,
    hidden,
    input,
    timePicker,
    tree,
    radio,
    select,
    row
]
