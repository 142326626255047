import { EMPTY_VALUE_PLACEHOLDER as emptyValuePlaceholder } from '@config'
import { BOOLEAN_PATTERN_TRUE } from '@constant/enum'

import { calculator } from '4d-space-bag'

/**
 * @description 获取实际数据类型
 *
 * @param {*} obj
 *
 * @returns {*}
 */
export function typeOf(obj) {
    const typeFactory = {
        '[object Null]': 'null',
        '[object Undefined]': 'undefined',
        '[object String]': 'string',
        '[object Number]': 'number',
        '[object Boolean]': 'boolean',
        '[object Symbol]': 'symbol',

        '[object Object]': 'object',
        '[object Array]': 'array',
        '[object Function]': 'function',
        '[object AsyncFunction]': 'async function',
        '[object RegExp]': 'regExp',
        '[object Date]': 'date',
        '[object Math]': 'math',
        '[object File]': 'file',
        '[object Set]': 'set',
        '[object Map]': 'map'
    }

    const type = typeFactory[Object.prototype.toString.call(obj)]

    // 如果数据类型为 number 则判断其是不是 NaN
    if (type === 'number') {
        return isNaN(obj) ? 'NaN' : type
    }

    return type
}

/**
 * @description 判断是否是某种数据类型
 *
 * @param {*} type
 */
export function isType(type) {
    return function (obj) {
        return typeOf(obj) === type
    }
}

/**
 * @description 判断 obj 的数据类型是否为 null
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 null 则返回 true，否则返回 false
 */
export function isNull(obj) {
    return isType('null')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 undefined
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 undefined 则返回 true，否则返回 false
 */
export function isUndefined(obj) {
    return isType('undefined')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 string
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 string 则返回 true，否则返回 false
 */
export function isString(obj) {
    return isType('string')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 number
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 number 则返回 true，否则返回 false
 */
export function isNumber(obj) {
    return isType('number')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 boolean
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 boolean 则返回 true，否则返回 false
 */
export function isBoolean(obj) {
    return isType('boolean')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 symbol
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 symbol 则返回 true，否则返回 false
 */
export function isSymbol(obj) {
    return isType('symbol')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 object（普通对象）
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 object（普通对象） 则返回 true，否则返回 false
 */
export function isPlainObject(obj) {
    return isType('object')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 array
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 array 则返回 true，否则返回 false
 */
export function isArray(obj) {
    return isType('array')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 map
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 map 则返回 true，否则返回 false
 */
export function isMap(obj) {
    return isType('map')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 function
 *
 * 不区分 Function 与 AsyncFunction
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 function 则返回 true，否则返回 false
 */
export function isFunction(obj) {
    return typeof obj === 'function'
}

/**
 * @description 判断 obj 的数据类型是否为 async function
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 async function 则返回 true，否则返回 false
 */
export function isAsyncFunction(obj) {
    return isType('function')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 date
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 date 则返回 true，否则返回 false
 */
export function isDate(obj) {
    return isType('date')(obj)
}

/**
 * @description 判断 obj 的数据类型是否为 file
 *
 * @param {*} obj
 *
 * @returns {Boolean} obj 的数据类型为 file 则返回 true，否则返回 false
 */
export function isFile(obj) {
    return isType('file')(obj)
}

/**
 * @description 判断 obj 是否为空对象
 * 提示：此处控对象并非 null，而是自身不带任何属性（property）的对象
 *
 * @param {Object} obj
 *
 * @returns {Boolean} obj 为 则返回 true，否则返回 false
 */
export function isEmptyObject(obj = {}) {
    return Object.keys(obj).length === 0
}

/**
 * @description 判断 path 是否为外部链接
 *
 * @param path
 *
 * @returns {Boolean} path 是外部链接则返回 true，否则返回 false
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @description 判断 value 是否为空值
 * 提示：这里的空值指的是 空字符串（''）、null、undefined
 *
 * @param {*} value 被检测的值
 *
 * @returns 是否为空值
 */
export function isEmpty(value) {
    return value === void 0 || value === null || value === ''
}

/**
 * @description 判断 property 是否为对象 obj 的自有属性
 *
 * @param {Object} obj
 * @param {String} property
 *
 * @returns property 为对象 obj 的自有属性 则返回 true，否则返回 false
 */
export function hasOwnProperty(obj, property) {
    return Object.prototype.hasOwnProperty.call(obj, property)
}

/**
 * 判断 v 是否等于 BOOLEAN_PATTERN_TRUE
 *
 * 即判断 v 是否等于布尔值枚举格式的 `true`
 *
 * @param {*} v
 *
 * @returns v 等于布尔值枚举格式的 `true` 则返回 true，否则返回 false
 */
export function isTrueBooleanPattern(v) {
    return BOOLEAN_PATTERN_TRUE === v
}

/**
 * @description 从 url（文件访问地址） 中提取其中包含的文件信息
 *
 * @param {String} url（文件访问地址）
 *
 * @returns {Object} 文件信息对象（访问地址、文件类型、文件名等）
 */
export function extractFileInfoFromUrl(url) {
    url += ''

    const strArr = url.split('/')
    const name = strArr[strArr.length - 1] + ''
    const typeStrArr = name.split('.')
    const fileType = typeStrArr[typeStrArr.length - 1]

    return {
        url,
        fileType,
        name
    }
}

/**
 * @description 区间重叠校验
 *
 * @param intervals 区间集合
 *
 * @returns {number} 重叠区间个数
 */
export function validateOverlapIntervals(intervals) {
    if (!intervals.length) {
        return 0
    }

    intervals = [...intervals]

    intervals.sort((a, b) => a[1] - b[1])

    const n = intervals.length
    let right = intervals[0][1]
    let ans = 1

    for (let i = 1; i < n; ++i) {
        if (intervals[i][0] >= right) {
            ++ans
            right = intervals[i][1]
        }
    }

    return n - ans
}

/**
 * @description 千分位转换
 *
 * @param num
 *
 * @returns {*}
 */
export function thousandBit(num) {
    const reg = /\d{1,3}(?=(\d{3})+(\.\d*)?$)/g
    if (!isNaN(num) && num !== null) {
        let value = num + ''
        return value.replace(reg, '$&,')
    }
    return num
}

/**
 * @description
 *
 * 如果 value 为 空值，展示 placeholder 值
 *
 * 如果 value 非 空值，展示 value 值
 *
 * 提示：这里的空值指的是 空字符串（''）、null、undefined
 *
 * @param {*} value 被检测的值
 *
 * @param {string} unit 单位。需要在后追加单位时，可通过其指定
 *
 * @param {string} placeholder 当 value 为 空值时，可通过其指定展示内容，
 * 默认值为全局配置 EMPTY_VALUE_PLACEHOLDER 值
 *
 * @returns 最终展示的值
 */
export function empty(value, unit, placeholder = emptyValuePlaceholder) {
    if (isEmpty(value)) return placeholder
    return isEmpty(unit) ? value : `${value} ${unit}`
}

/**
 * @description 排序方法
 *
 * @param name
 * @param direction
 *
 * @returns {Function}
 */
export function sortBy(name, direction) {
    'use strict'

    return function (o, p) {
        let a, b, d
        if (typeof o === 'object' && typeof p === 'object' && o && p) {
            a = o[name]
            b = p[name]

            if (a === b) {
                return 0
            }

            const m = typeof a
            const n = typeof b

            if (m === n) {
                d = a < b ? -1 : 1
            } else {
                d = m < n ? -1 : 1
            }

            return direction ? d : -d
        } else {
            throw 'error'
        }
    }
}

/**
 * @description 替换字符串中的字段
 *
 * @author zhuz
 *
 * @param {String} str 模版字符串
 * @param {Object} o json data
 * @param {RegExp} [regexp] 匹配字符串的正则表达式
 * @param {function} mathFunc 替代匹配
 *
 * @return {String}
 */
export function substitute(str, o, regexp, mathFunc) {
    // 如果 regexp 不存在 但 mathFunc 存在时
    if (Object.prototype.toString.call(regexp) === '[object Function]') {
        mathFunc = regexp
        regexp = null
    }

    return str.replace(regexp || /\\?\{{([^{}]+)\}}/g, function (match, name) {
        if (mathFunc) {
            return mathFunc(o, match, name)
        } else {
            return o[name] === undefined || o[name] === null ? '' : o[name]
        }
    })
}

/**
 * @description  过滤对象，使对象只拥有 keys 中指定的属性
 *
 * @param {Object} origin 需要被过滤的对象
 * @param {Array} keys 用以指定过滤后的对象所拥有的属性
 * @returns {Object} filteredObject 过滤后的对象
 */
export function filterObjectByKeys(origin = {}, keys = []) {
    const filteredObject = {}

    keys.forEach(key => {
        filteredObject[key] = origin[key]
    })

    return filteredObject
}

/**
 * @description 将 options（下拉选项）数据 转换为 mapping（映射对象）
 * 一般在 Select 选择器中，label 作为选择之后要显示的，value 作为选择之后的数据值（需要传给后台）
 * 当 label 和 value 不同，且需要在前台通过 value 来显示对应 label 内容，就可应用该方法
 *
 * 提示：label 和 value 并不是固定的，可通过 config 字段配置传参来进行灵活配置
 *
 * @param {Array} options options（下拉选项）
 * @param {Object} config 字段配置
 *
 * @returns {Object} mappingObject mapping（映射对象）
 * 提示：mappingObject 只是普通对象，而非 ES6 中的 Map对象
 */
export function transformOptionsToMappingObject(options, config) {
    if (config === void 0) {
        config = {
            label: 'label',
            value: 'value'
        }
    }

    const _mappingObject = {}

    options.forEach(({ [config.label]: label, [config.value]: value }) => {
        _mappingObject[value] = label
    })

    return _mappingObject
}

/**
 * @description 检查勾选行数据中指定字段集的一致性
 *
 * @param {Array} selectedRows 勾选行数据
 * @param {Array} fields 指定字段集
 *
 * @returns {Boolean} 是一致的则返回 true，否则返回 false
 */
export function checkSelectedRowsConsistency(selectedRows = [], fields = []) {
    const set = new Set()

    selectedRows.forEach(row => {
        const values = fields.map(field => row[field])
        set.add(values.join('_'))
    })

    return set.size === 1
}

// info为数据值  form为表单对象 info的值回显给form
export function initForm(form, info) {
    Object.keys(form).forEach(key => {
        form[key] = info[key]
    })
}

// 获取相对协议
export function getRelativeAgreementUrl(url) {
    // if (isString(url)) {
    //     return url.replace(/http[s]?:\/\//g, '//')
    // }
    return url
}

// 数字截尾操作
export function tailOff(number, tailLen = 2) {
    const digitsNubmer = Math.pow(10, tailLen)
    return calculator.div(Math.floor(calculator.mul(number, digitsNubmer)), digitsNubmer)
}
