// 接口地址
export const BASE_URL = '/api/ares_base' // 动态页面渲染接口
export const SAFETY_AUTH_URL = '/api/margin_auth' // 登录权限相关接口
export const SAFETY_MFG_URL = '/api/margin' // 业务相关接口

/** 动态模版请求地址 */
export const DYNAMIC_EVENT_URL = '/global/dynamicEvent'
/** 事件触发地址 */
export const ACTIVE_EVENT_URL = '/global/activeEvent'
/** 加载页面布局的地址 */
export const LOAD_LAYOUT_URL = '/global/loadLayout'

// 接口版本号
export const API_VERSION = '1.0.0'

// 媒体查询枚举
export const MediaQueryEnum = {
    'screen-xs': {
        maxWidth: 575
    },
    'screen-sm': {
        minWidth: 576,
        maxWidth: 767
    },
    'screen-md': {
        minWidth: 768,
        maxWidth: 991
    },
    'screen-lg': {
        minWidth: 992,
        maxWidth: 1199
    },
    'screen-xl': {
        minWidth: 1200,
        maxWidth: 1599
    },
    'screen-xxl': {
        minWidth: 1600
    }
}

// 系统免鉴权白名单
export const WHITE_LIST = [
    '/login', '/registration', '/404', '/401', '/500'
]

// 默认跳转路径
export const DEFAULT_REDIRECT_PATH = '/'

// 系统token key 名称
// export const TOKEN_KEY = 'Admin-Token'
export const TOKEN_KEY = 'Client-Token'
export const REFRESH_TOKEN_KEY = 'Regulatory-Refresh-Token'
export const TOKEN_GBT_KEY = 'Admin-GBT-Token'

// token 有效期 （天）
export const TOKEN_TIME = 1

// 默认table 分页数量
export const DEFAULT_PAGES_SIZE = 10
// 默认 table pageSize 选项
export const DEFAULT_PAGE_SIZE_OPTIONS = [
    DEFAULT_PAGES_SIZE + '',
    DEFAULT_PAGES_SIZE * 2 + '',
    DEFAULT_PAGES_SIZE * 3 + '',
    DEFAULT_PAGES_SIZE * 4 + ''
]

export const WINDOW_WIDTH = 1920
export const WINDOW_HEIGHT = 1080

// 客户端id
export const CLIENT_ID = 'cms'
// 客户端秘钥
export const CLIENT_SECRET = 'cms_secret'

/**
 * 数据值为空（'' 或 null 或 undefined）时占位内容
 *
 * 例如：表格中一些字段，后台返回为空，可以用此进行占位展示
 */
export const EMPTY_VALUE_PLACEHOLDER = '-'

// 工保通传递信息加密公钥
export const GONGBAOTONG_PUBLIC_KEY =
    '-----BEGIN PUBLIC KEY-----\n' +
    'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBALAzzRnm4aLnGo2YdL6NSP1GUxCl3yqV\n' +
    'kg6Lw6UqatUmCy0hfBbd7sNCJUahC8pxbFgSxQAB1tsRWetGKXmsigcCAwEAAQ==\n' +
    '-----END PUBLIC KEY-----'

// 工保通传递信息加密私钥
export const GONGBAOTONG_PRIVATE_KEY =
    '-----BEGIN PRIVATE KEY-----\n' +
    'MIIBUwIBADANBgkqhkiG9w0BAQEFAASCAT0wggE5AgEAAkEAsDPNGebhoucajZh0\n' +
    'vo1I/UZTEKXfKpWSDovDpSpq1SYLLSF8Ft3uw0IlRqELynFsWBLFAAHW2xFZ60Yp\n' +
    'eayKBwIDAQABAkB7dSAtGN9Zw7GQKpUJ+nZ8t6x1yrLN5JS8ymLftBO8mcym6Uzr\n' +
    'U9dbnZwErm2f2rDHQUD/Qzro5hNhWsWGp0rBAiEA2IYhOKILKWUz9IT8fhwxnsJ5\n' +
    'sDL0FxvjTOcPVB86VuECIQDQU7uKftk784+x6V+iFtt/xLIYU71ck85jJIxtdc3F\n' +
    '5wIgRYyYATPzt+E9Fg7dd7igq6k2I0XKp92aXmpdvxykxaECIEIo5V1VbY3hitf4\n' +
    'gnuKgMjx4ZFiPrgA5MGNoN8+U0wvAiA2I9NpFz5nLeR0Sf8GvTSO4R7rDi+DTOYF\n' +
    'v2MpI88BQA==\n' +
    '-----END PRIVATE KEY-----'
export const CELL_MAX_WIDTH = 220

// 输入框最大
export const INPUT_MAX_LENGTH = 50
// 文本框最大
export const INPUT_AREA_MAX_LENGTH = 150

export const echartsColor = [
    '#47D2F4',
    '#377FE3',
    '#32ACFB',
    '#1BD2A3',
    '#0FC37B',
    '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'
]
