<template>
    <search-panel
        :model="form"
        :show-reset="false"
        search-text="获取事故详情"
        @search="handleSearch"
        @refresh="handleRefresh"
    >
        <form-col
            v-for="item in queryCriteriaList"
            :key="item.id"
            :span="8"
            :label="item.fieldName"
            :prop="item.fieldCode"
        >
            <data-entry-component
                v-model="form[item.fieldCode]"
                :key="item.fieldCode"
                :field-code="item.fieldCode"
                :component-type="item.componentType"
            />
        </form-col>
    </search-panel>
</template>

<script>
import FormCol from '@components/FormCol'
import SearchPanel from '@components/SearchPanel'
import DataEntryComponent from '@weights/searchPanel/DataEntryComponent'

import _ from 'lodash'

import { filterParams } from '@utils/search'

export default {
    name: 'TableSearchPanel',
    components: {
        FormCol,
        SearchPanel,
        DataEntryComponent
    },
    props: {
        queryCriteriaList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            form: {},
            defaultForm: {}
        }
    },
    watch: {
        queryCriteriaList: {
            handler(list) {
                const form = {}

                list.forEach(({ fieldCode, defaultValue }) => {
                    form[fieldCode] = defaultValue || void 0
                })

                this.form = form
                this.defaultForm = _.cloneDeep(form)
            },
            immediate: true
        }
    },
    methods: {
        handleSearch() {
            this.$emit('search', filterParams(this.form))
        },
        handleRefresh() {
            this.$set(this, 'form', this.defaultForm)
            this.$nextTick(() => this.$emit('refresh'))
        }
    }
}
</script>
