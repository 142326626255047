import axios from '../index'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
import { GET_FINANCIAL_INSTITUTION_BRAND_LIST } from './api'

// 获取机构品牌下拉选项
export default async function getFinancialInstitutionBrandListService(params, config) {
    const param = {
        ...GET_FINANCIAL_INSTITUTION_BRAND_LIST,
        ...config,
        data: params
    }
    const { data } = await axios(param)
    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
