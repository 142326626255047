import axios from './index'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
import { SAFETY_MFG_URL } from '@config'

import { isEmptyObject } from '@utils'

const isProduction = process.env.OPERATING_ENV === 'production'

const SYSTEM_INIT_INFO = isProduction ? process.env.SYSTEM_INIT_INFO : undefined // 非产线环境不读取默认初始化配置

export default async function getInitService(params) {
    if (!isEmptyObject(SYSTEM_INIT_INFO)) {
        return SYSTEM_INIT_INFO
    }

    const param = {
        url: `${SAFETY_MFG_URL}/initialization/enterprise`,
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        params,
        timeout: 30000,
        useNProgress: false
    }

    const { data } = await axios(param)

    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
