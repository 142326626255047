import axios from '@service'

import { HTTP_ERR_SUCCESS_MESSAGE } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

import { GET_TOKENS_BY_ACCESS_KEY } from './api'


/**
 * 根据 accessKey 获取 tokens
 *
 * 提示：
 * tokens 包含 token、accessToken 和 refreshToken
 *
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function getTokensByAccessKeyService(payload = {}, config = {}) {
    const param = {
        ...GET_TOKENS_BY_ACCESS_KEY,
        ...config,
        params: payload
    }

    const { data } = await axios(param)
    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
