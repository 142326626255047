<template>
    <div class="team-management-panel__statistics-item">
        <div class="team-management-panel__statistics-sign" :class="[type]">
            <span class="team-management-panel__statistics-sign--inner" />
        </div>
        <span>{{ typeText }}({{ num }})</span>
    </div>
</template>
<script>
const mapping = {
    enter: '进场',
    leave: '退场'
}

export default {
    name: 'StatisticsItem',
    props: {
        num: Number,
        type: {
            type: String,
            default: val => Object.keys(mapping).includes(val)
        }
    },
    computed: {
        typeText() {
            return mapping[this.type]
        }
    }
}
</script>
