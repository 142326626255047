
<!-- 保证金管理 / 保证金缴纳详情 -->
<template>
    <page-layout>
        <div class="margin-payment-detail">
            <!-- 项目信息 -->
            <project-info-panel :card-bordered="false" :workflow-apply-record-id="workflowApplyRecordId" />

            <!-- 定额保证金额 -->
            <fixed-deposit-panel :id="id" />
        </div>
    </page-layout>
</template>
<script>
import PageLayout from '@layout/PageLayout'

import ProjectInfoPanel from './ProjectInfoPanel'
import FixedDepositPanel from './FixedDepositPanel'

export default {
    name: 'MarginPaymentDetail',
    components: {
        PageLayout,
        ProjectInfoPanel,
        FixedDepositPanel
    },
    computed: {
        id() {
            return this.$route.query.id
        },
        workflowApplyRecordId() {
            return this.$route.query.workflowApplyRecordId
        }
    }
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.margin-payment-detail {
    .ant-card {
        &.has-margin-top {
            margin-top: $space-sm;
        }

        .ant-descriptions-item-label {
            width: $descriptions-item-label-width;
        }
    }
}
</style>
