import { validateSocialCreditCode, validateIDCard, validateEmail, validateLandline, validatePhoneNumber, validateUrl, validateInteger, validateNumber } from './validates'

/**
 * 前后端约定验证
 */
// 统一社会信用编码
export const VALIDATE_SOCIAL_CREDIT_CODE = 0
// 验证身份证有效性
export const VALIDATE_ID_CARD_CODE = 1
// 验证邮箱
export const VALIDATE_EMAIL_TYPE = 2
// 座机
export const VALIDATE_LAND_LINE = 3
// 验证手机号有效性
export const VALIDATE_PHONE_NUMBER = 4
// 验证网站
export const VALIDATE_URL = 5

/**
 * 前端验证
 */
// 验证整数数字
export const VALIDATE_INTEGER = 100
// 验证数字
export const VALIDATE_NUMBER = 101

export const VALIDATES = {
    [VALIDATE_SOCIAL_CREDIT_CODE]: validateSocialCreditCode,
    [VALIDATE_ID_CARD_CODE]: validateIDCard,
    [VALIDATE_EMAIL_TYPE]: validateEmail,
    [VALIDATE_LAND_LINE]: validateLandline,
    [VALIDATE_PHONE_NUMBER]: validatePhoneNumber,
    [VALIDATE_URL]: validateUrl,
    [VALIDATE_INTEGER]: validateInteger,
    [VALIDATE_NUMBER]: validateNumber
}

