import moment from 'moment'

export default function formatDate(date = new Date(), type = 'default') {
    /**
     * @description 日期格式化方法
     *
     * @param date
     * @param type
     *
     * @returns {String}
     */
    const formatFactory = {
        all: 'YYYY-MM-DD HH:mm:ss',
        cnAll: 'YYYY年MM月DD日 HH:mm:ss',
        cnWeekAll: 'YYYY年MM月DD日 dddd HH:mm:ss',
        time: 'HH:mm:ss',
        YM: 'YYYY-MM',
        MD: 'MM-DD',
        Hm: 'HH:mm',
        MDHm: 'MM-DD HH:mm',
        MDHms: 'MM-DD HH:mm:ss',
        DHm: 'DD HH:mm',
        DHms: 'DD HH:mm:ss',
        YMDHm: 'YYYY-MM-DD HH:mm',
        default: 'YYYY-MM-DD'
    }

    return moment(date).format(formatFactory[type] ?? type)
}
