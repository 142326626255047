<template>
    <div class="team-management-panel__statistics">
        <!-- 进场 -->
        <statistics-item type="enter" :num="enterNum" />
        <!-- 退场 -->
        <statistics-item type="leave" :num="leaveNum" />
    </div>
</template>
<script>
import StatisticsItem from './Item.vue'

export default {
    name: 'StatisticsList',
    components: {
        StatisticsItem
    },
    props: {
        // 进场人数
        enterNum: {
            type: Number,
            default: 0
        },
        // 退场人数
        leaveNum: {
            type: Number,
            default: 0
        }
    }
}
</script>
