<template>
    <div class="create-page__custom-file-upload-list-wrapper">
        <!-- COMBINATION_COMPONENT_TYPE_CARD -->
        <template v-if="needCard">
            <custom-card v-bind="cardNeedProps">
                <file-upload-list ref="form" v-bind="fileUploadListNeedProps" title="" />
            </custom-card>
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_PAGE -->
        <template v-else-if="needPageHeader">
            <weight-page-header v-bind="pageHeaderNeedProps" :title="title" />
            <file-upload-list ref="form" v-bind="fileUploadListNeedProps" title="" />
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_NOMAL -->
        <template v-else>
            <file-upload-list ref="form" v-bind="fileUploadListNeedProps" />
        </template>
    </div>
</template>
<script>
import CustomCard from '@weights/CustomCard'
import WeightPageHeader from '@weights/PageHeader'
import FileUploadList from '@weights/FileUploadList'

import childrenRefreshMixin from '@mixins/createPage/childrenRefresh'
import getFormData from '@mixins/createPage/formData'
import eventMixin from '@mixins/createPage/event'
import buttonMixin from '@mixins/createPage/button'

import { getPropFields, getPropsInNeed, getPropsInCardNeed, getPropsInPageHeaderNeed } from '@utils/pageCreate'

import { COMBINATION_COMPONENT_TYPE_CARD, COMBINATION_COMPONENT_TYPE_PAGE, BOOLEAN_PATTERN_TRUE } from '@constant/enum'

const FILE_UPLOAD_LIST_PROP_FIELDS = getPropFields(FileUploadList)

export default {
    name: 'CreatePageCustomFileUploadList',
    components: {
        CustomCard,
        FileUploadList,
        WeightPageHeader
    },
    mixins: [
        childrenRefreshMixin, getFormData, eventMixin, buttonMixin
    ],
    props: {
        combinationComponentType: {
            type: Number,
            default: COMBINATION_COMPONENT_TYPE_CARD
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        eventList: {
            type: Array,
            default: () => []
        },
        extraTitle: {
            type: Object,
            default: () => ({})
        },
        // 展示边框
        needBordered: {
            type: Number,
            default: BOOLEAN_PATTERN_TRUE
        },
        fieldList: {
            type: Array,
            default: () => []
        },
        field: {
            type: String,
            default: ''
        },
        columns: {
            type: Number,
            default: 1
        },
        options: {
            type: Object,
            default() {
                return {
                    submitBtn: false,
                    resetBtn: false
                }
            }
        },
        emits: {
            type: Object,
            default: () => ({})
        },
        childrenField: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        needCard() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_CARD
        },
        needPageHeader() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_PAGE
        },
        cardNeedProps() {
            return this.needCard ? getPropsInCardNeed(this.$props) : null
        },
        pageHeaderNeedProps() {
            return this.needPageHeader ? getPropsInPageHeaderNeed(this.$props) : null
        },
        fileUploadListNeedProps() {
            return getPropsInNeed(this.$props, FILE_UPLOAD_LIST_PROP_FIELDS)
        }
    },
    methods: {
        getChildFApi() {
            return this.$refs['form']?.getChildFApi()
        },
        getFormData() {
            return this.$refs['form'].getFormData()
        },
        vaildatorForm(cb) {
            this.$refs['form'].vaildatorForm(cb)
        }
    }
}
</script>
