/**
 * 首页
 */
import BasicLayout from '@layout/BasicLayout'

import { HOME_MANAGE_MENU_CODE } from '@constant/menu_code'

import { HOME_MANAGE_MENU_SORT } from '@constant/menu_sort'

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: BasicLayout,
        code: HOME_MANAGE_MENU_CODE,
        redirect: '/welcome',
        sort: HOME_MANAGE_MENU_SORT,
        meta: {
            title: '首页',
            icon: 'shouye',
            breadcrumb: false,
            iconType: 'iconfont'
        }
    }
]

export default routes
