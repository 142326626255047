<template>
    <a-select
        v-model="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        @select="handleSelect"
        @blur="handleBlur"
    >
        <a-select-option v-for="item in list" :key="item.id">
            {{ item.orgName }}
        </a-select-option>
    </a-select>
</template>

<script>
import { Select } from 'ant-design-vue'

import getOrgListService from '@service/selectService/getOrgListService'

export default {
    name: 'EnterpriseSelect',
    components: {
        ASelect: Select,
        ASelectOption: Select.Option
    },
    props: {
        value: {
            // type: String
            type: [Number, String]
        },
        fieldCode: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            list: []
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
                this.$emit('blur')
            }
        }
    },
    created() {
        // this.getList()
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        async getList(params) {
            try {
                this.list = await getOrgListService({
                    ...params,
                    orgType: 4
                })
                if (this.list[0]) {
                    this.modelValue = this.list[0].id
                }
            } catch (e) {
                console.log('-> e', e)
            }
        },
        handleSelect(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style lang="scss"></style>
