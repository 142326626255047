<template>
    <div>
        <a-row class="align-center">
            <single-file-upload v-model="file" />
            <div class="batch-import__button">
                <a-button v-if="!isSuccess" type="link" @click="downloadFailFile">原因导出</a-button>
                <a-button type="link" @click="downloadTemplate">下载模板</a-button>
            </div>
        </a-row>
        <!-- <a-button @click="handleImport">确认导入</a-button> -->
    </div>
</template>

<script>
import { Button as AButton, Row as ARow } from 'ant-design-vue'
import SingleFileUpload from '@/components/SingleFileUpload'
import downloadFile from '@/utils/downloadFile'
import { getRelativeAgreementUrl } from '@utils'
import { downloadResponseBlob } from '@/utils/file'
import getFormData from '@/mixins/createPage/formData'

import importValidationService from '@/service/importData/importValidationService'
import fileImportOperationService from '@/service/importData/fileImportOperationService'
import exportFailDataService from '@/service/importData/exportFailDataService'
import confirmImportService from '@/service/importData/confirmImportService'


// 考勤导入 bizType=1
// 工资导入 bizType=2

// 1595585177487781889
export default {
    name: 'BatchImport',
    components: {
        AButton,
        ARow,
        SingleFileUpload
    },
    mixins: [getFormData],
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        childrenField: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            file: null,
            isSuccess: true,
            importRecordRequestId: '',
            bizType: ''
        }
    },
    watch: {
        file: {
            handler(file) {
                console.log(file)
                if (file) {
                    this.importValidation(file)
                } else {
                    this.importRecordRequestId = '',
                    this.isSuccess = true
                }

            }
        },
        immediate: true,
        data: {
            handler(data) {
                this.bizType = data.bizType
            },
            immediate: true
        }
    },
    methods: {
        async downloadTemplate() {
            try {
                const { bizType, projectCode } = this.data
                const file = await fileImportOperationService({
                    bizType,
                    projectCode
                })
                downloadFile(getRelativeAgreementUrl(file))
            } catch (error) {
                this.$message.warn(error.message)
            }

        },
        getFormData() {
            return {
                bizType: this.bizType,
                importRecordRequestId: this.importRecordRequestId,
                isSuccess: this.isSuccess
            }
        },
        async downloadFailFile() {
            const { importRecordRequestId, bizType } = this
            try {
                const res = await exportFailDataService({
                    importRecordRequestId,
                    bizType
                })
                downloadResponseBlob(res.data, res.headers)
            } catch (error) {
                console.log(error)
                this.$message.error(error.message)
            }
        },
        async handleImport() {
            const { isSuccess, importRecordRequestId, bizType } = this
            if (isSuccess && importRecordRequestId) {
                try {

                    await confirmImportService({
                        bizType,
                        importRecordRequestId
                    })

                    this.$message.success('导入成功')
                } catch (error) {
                    console.log(error)
                    this.$message.warning(error.message)
                }
            }
        },
        async importValidation(file) {
            const { projectCode, busiId, bizType } = this.data

            if (projectCode && bizType && busiId) {
                try {
                    const form = new FormData()

                    form.append('file', file)
                    form.append('projectCode', projectCode)
                    form.append('busiId', busiId)
                    form.append('bizType', bizType)

                    const res = await importValidationService(form)
                    this.importRecordRequestId = res.importRecordRequestId
                    // 有失败数据
                    if (res.failList.length > 0) {
                        this.$message.warning('文件校验失败')
                        this.isSuccess = false
                    } else {
                        this.$message.success('文件校验成功')
                        this.isSuccess = true
                    }
                } catch (error) {
                    console.log(error)
                    this.$message.warning(error.message)
                }
            } else {
                console.log(this.data, '缺少参数')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.batch-import__button {
    display: inline-flex;
    flex-direction: column;
    vertical-align: bottom;
}
.align-center {
    text-align: center;
}
</style>
