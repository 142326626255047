import { Button as AButton, Popconfirm as APopconfirm } from 'ant-design-vue'

import PropTypes from 'ant-design-vue/es/_util/vue-types'
import { getComponentFromProp } from 'ant-design-vue/es/_util/props-util'

import { isUndefined } from '@utils'

export default {
    name: 'PopconfirmButton',
    props: {
        // 气泡框位置
        placement: String,
        // 自定义弹出气泡 Icon 图标
        icon: PropTypes.any,
        // 按钮 key 值
        buttonKey: String,
        // 按钮文案
        buttonText: String,
        // 按钮类型
        buttonType: String,
        // 取消按钮文字
        cancelText: String,
        // 确认按钮文字
        okText: String,
        // 确认按钮类型
        okType: String,
        // 确认框的描述
        title: String,
        // 是否禁用按钮
        disabled: PropTypes.bool,
        // 设置按钮载入状态
        loading: PropTypes.bool,
        // 用于手动控制浮层显隐
        // 提示：有传入时，表示浮层显隐全由它进行控制
        visible: PropTypes.bool,
        // 隐藏后是否销毁 tooltip
        destroyTooltipOnHide: PropTypes.bool
    },
    methods: {
        // 渲染按钮
        renderButton(h) {
            if (this.$slots.button) {
                return this.$slots.button
            }

            return h(AButton, {
                key: this.buttonKey,
                props: {
                    type: this.buttonType,
                    loading: this.loading,
                    disabled: this.disabled
                }
            }, [this.buttonText])
        },
        // 声明自定义事件
        emitEvent(eventName, param) {
            this.$emit(`on-${eventName}`, param)
        },
        // 点击取消的回调
        handleCancel(e) {
            this.emitEvent('cancel', e)
        },
        // 点击确认的回调
        handleConfirm(e) {
            this.emitEvent('confirm', e)
        },
        // 显示隐藏的回调
        handleVisibleChange(visible) {
            this.emitEvent('visible-change', visible)
        }
    },
    render(h) {
        const _popconfirmProps = {
            okType: this.okType,
            placement: this.placement,
            disabled: this.disabled || this.loading,
            destroyTooltipOnHide: this.destroyTooltipOnHide,
            icon: getComponentFromProp(this, 'icon'), // 气泡 Icon 图标
            title: getComponentFromProp(this, 'title'), // 确认框的描述
            okText: getComponentFromProp(this, 'okText'), // 确认按钮文字
            cancelText: getComponentFromProp(this, 'cancelText') // 取消按钮文字
        }

        if (!isUndefined(this.visible)) {
            _popconfirmProps.visible = this.visible
        }

        return h(APopconfirm, {
            props: _popconfirmProps,
            on: {
                cancel: this.handleCancel,
                confirm: this.handleConfirm,
                visibleChange: this.handleVisibleChange
            }
        }, [this.renderButton(h)])
    }
}
