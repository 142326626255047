
export const theme = state => state.theme
export const layout = state => state.layout
export const isMobile = state => state.isMobile
export const multipage = state => state.multipage
export const fixedHeader = state => state.fixedHeader
export const fixedSidebar = state => state.fixedSidebar


export const systemName = state => state.systemName
export const systemTagName = state => state.systemTagName


export const copyright = state => state.copyright
export const licenseNumber = state => state.licenseNumber


export const role = state => state.role
export const routes = state => state.routes
export const buttonRole = state => state.buttonRole


export const userInfo = state => state.userInfo
export const enterpriseInfo = state => state.enterpriseInfo


export const globalData = state => state.globalData
export const projectList = state => state.projectList
export const projectInfo = state => state.projectInfo


export const initInformation = state => state.initInformation
export const authProperties = state => state.authProperties


export const pageFApi = state => state.pageFApi
export const pageFApiList = state => state.pageFApiList
