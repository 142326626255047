/* eslint-disable object-curly-newline */
import { Descriptions } from 'ant-design-vue'

import PropTypes from 'ant-design-vue/es/_util/vue-types'
import { isFunction } from '@utils'

export default {
    name: 'BodyPanel',
    props: {
        dataSource: PropTypes.object.def({}), // 源数据
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                dataIndex: PropTypes.string,

                label: PropTypes.string,
                span: PropTypes.number,

                customRender: PropTypes.func,
                renderLabel: PropTypes.func
            }).loose
        ).isRequired, // 字段配置

        bordered: PropTypes.bool.def(true),
        size: PropTypes.string,
        layout: PropTypes.string,
        colon: PropTypes.bool,
        // `a-descriptions` 的 `column` 传参
        itemColumn: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
    },
    methods: {
        renderDescriptionItem(column, index) {
            let h = this.$createElement

            const dataSource = this.dataSource
            const value = dataSource[column.dataIndex]

            let label = column.label
            const customRenderLabel = column.renderLabel
            if (isFunction(customRenderLabel)) {
                label = customRenderLabel(value, dataSource, index, column, h)
            }

            let kid = value
            const customRender = column.customRender
            if (isFunction(customRender)) {
                kid = customRender(value, dataSource, index, column, h)
            }

            const props = {
                label,
                span: column.span
            }
            return h(Descriptions.Item, { props }, [kid])
        }
    },
    render(h) {
        const props = {
            props: {
                size: this.size,
                colon: this.colon,
                layout: this.layout,
                bordered: this.bordered,
                column: this.itemColumn
            }
        }
        const kids = this.columns.map(
            (column, index) => this.renderDescriptionItem(column, index)
        )

        return h(Descriptions, props, kids)
    }
}
