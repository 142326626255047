import { EVENT_TYPE_NORMAL } from '@/constant/enum'

import { hasOwnProperty } from '@utils'
import { getHasOperationButtonList } from '@/utils/getButtonList'

const OPERATION_ENUM = {
    OPERATION: 'operation', // 普通按钮
    DETAIL_OPERATION: 'detailOperation', // 详情按钮
    GLOBAL_OPERATION: 'globalOperation', // 全局按钮
    WORKFLOW_OPERATION: 'workflowOperation' // 工作流按钮
}

const STRATEGY = {
    [OPERATION_ENUM.OPERATION]: (categorize, event) => {
        categorize[OPERATION_ENUM.OPERATION].push(event)
    },
    [OPERATION_ENUM.DETAIL_OPERATION]: (categorize, event) => {
        categorize[OPERATION_ENUM.DETAIL_OPERATION].push(event)
    },
    [OPERATION_ENUM.GLOBAL_OPERATION]: (categorize, event) => {
        categorize[OPERATION_ENUM.GLOBAL_OPERATION].push(event)
    },
    [OPERATION_ENUM.WORKFLOW_OPERATION]: (categorize, event) => {
        categorize[OPERATION_ENUM.WORKFLOW_OPERATION].push(event)
    }
}

class OperationButtonTools {
    // 按钮集
    buttonList = []

    // 分类
    categorize = {
        [OPERATION_ENUM.OPERATION]: [],
        [OPERATION_ENUM.DETAIL_OPERATION]: [],
        [OPERATION_ENUM.GLOBAL_OPERATION]: [],
        [OPERATION_ENUM.WORKFLOW_OPERATION]: []
    }

    static getButtonList(options = {}) {
        const data = options?.data || {}

        const tools = new OperationButtonTools()
        tools.doCategorizeButtonList(options?.eventList, options?.eventType)

        tools.appendGlobalButton()
        tools.appendNormalButton(data)
        tools.appendWorkflowButton(data)
        tools.appendDetailButton(data)

        // 排序
        tools.sort()

        return tools.doExcludeNomalButton(options?.excludeNomalButton)
    }

    // 给按钮分类
    doCategorizeButtonList(eventList, eventType) {
        for (let i = 0, len = eventList.length; i < len; i++) {
            const event = eventList[i] || {}

            if (eventType !== event.eventType || !event.permissionCode) {
                continue
            }

            const [permission] = event.permissionCode.split('_')
            if (hasOwnProperty(STRATEGY, permission)) {
                STRATEGY[permission](this.categorize, event)
            }
        }
    }

    // 处理多地方展示同一事件的问题
    doExcludeNomalButton(excludeNomalButton) {
        if (!excludeNomalButton) return this.buttonList
        return this.buttonList.filter(btn => EVENT_TYPE_NORMAL !== btn.eventType)
    }

    // 添加全局按钮
    appendGlobalButton() {
        const operation = OPERATION_ENUM.GLOBAL_OPERATION
        const btnList = this.categorize[operation] || []

        this.buttonList.push(...btnList)
    }

    // 添加工作流按钮
    appendWorkflowButton(data) {
        const operation = OPERATION_ENUM.WORKFLOW_OPERATION
        const btnList = this.categorize[operation] || []

        if (btnList.length === 0) return

        if (!data.workflowOperation) {
            this.buttonList.push(...btnList)
            return
        }

        this.buttonList.push(...getHasOperationButtonList(data.workflowOperation, btnList))
    }

    // 添加普通按钮
    appendNormalButton(data) {
        const operation = OPERATION_ENUM.OPERATION
        const btnList = this.categorize[operation] || []

        if (btnList.length === 0) return

        if (!data.operation) {
            this.buttonList.push(...btnList)
            return
        }

        this.buttonList.push(...getHasOperationButtonList(data.operation, btnList))
    }

    // 添加详情按钮
    appendDetailButton(data) {
        const operation = OPERATION_ENUM.DETAIL_OPERATION
        const btnList = this.categorize[operation] || []

        if (btnList.length === 0) return

        if (!data.detailOperation) {
            this.buttonList.push(...btnList)
            return
        }

        this.buttonList.push(...getHasOperationButtonList(data.detailOperation, btnList))
    }
    // 排序
    sort() {
        this.buttonList.sort((button1, button2) => button1.sort - button2.sort)
    }
}

export default {
    methods: {
        getButtonList(eventList = [], eventType, data = {}, excludeNomalButton) {
            return OperationButtonTools.getButtonList({
                data,
                eventList,
                eventType,
                excludeNomalButton
            })
        }
    }
}
