/**
 * 项目列表 / 项目详情 / 班组管理
 */
import { SAFETY_MFG_URL } from '@config'

// 单位
export const GET_UNIT_BY_PROJECT_CODE = {
    url: `${SAFETY_MFG_URL}/subcontracting-unit/inside/getSubcontractingUnitByProjectCode`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 班组
export const GET_TEAM_DATA = {
    url: `${SAFETY_MFG_URL}/team-information/inside/teamStatistics`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 人员
export const GET_MEMBERT_ROSTER_BY_TEAM_ID = {
    url: `${SAFETY_MFG_URL}/member-roster/inside/getMembertRosterByTeamId/page`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


export const GET_MEMBERT_ROSTER_LIST = {
    url: `${SAFETY_MFG_URL}/member-roster/inside/list`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000
}

// 移除人员
export const REMOVE_MEMBERT_ROSTER = {
    url: `${SAFETY_MFG_URL}/member-roster/inside/delete`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}
