<template>
    <div>
        <div class="check-list-number">
            <a-button type="link" @click="showModal">{{ valueNumber }}类</a-button>
        </div>
        <custom-modal
            :visible="visible"
            :width="800"
            :centered="true"
            title="必检清单"
            :show-ok-btn="!isReadonly"
            @on-close="handleCancel"
            @on-ok="handleOk"
        >
            <search-tree
                :disabled="isReadonly"
                :value="checkKeysList"
                @change="handleChangeCheckList"
            />
        </custom-modal>
    </div>
</template>

<script>
import CustomModal from '@/components/CustomModal'
import { Button as AButton } from 'ant-design-vue'
import SearchTree from '@weights/SearchTree'
export default {
    name: 'CheckListModal',
    components: {
        CustomModal,
        AButton,
        SearchTree
    },
    props: {
        value: {
            type: [String],
            default: ''
        },
        isReadonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            checkKeysList: this.value ? this.value.split(',') : []
        }
    },
    computed: {
        valueNumber() {
            return this.value ? this.value?.split?.(',')?.length : 0
        }
    },
    methods: {
        showModal() {
            this.visible = true
        },
        handleCancel() {
            this.checkKeysList = this.value ? this.value.split(',') : []
            this.visible = false
        },
        handleOk() {
            this.$emit('input', this.checkKeysList.join(','))
            this.visible = false
        },
        handleChangeCheckList(val) {
            this.checkKeysList = val
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@assets/styles/varibles.scss';

.check-list-number {
    button {
        padding: 0;
        height: auto;
    }
}
</style>
