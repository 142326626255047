<template>
    <div class="custorm-table-title">
        <div>{{ title }}{{ subTitle }}</div>
        <div><slot name="extra" /></div>
    </div>
</template>

<script>
export default {
    name: 'CustomTableTitle',
    props: {
        title: {
            type: String
        },
        button: {
            type: Object,
            default: () => ({
                type: 'primary',
                text: '新增'
            })
        },
        extraTitle: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        subTitle() {
            let res = ''
            const { extraTitle } = this
            if (extraTitle && extraTitle.props) {
                const { data, fieldList } = extraTitle.props
                if (data && fieldList) {
                    fieldList.forEach(item => {
                        res += `${item.fieldName}：${data[item.attributeCode]}`
                    })
                }
            }

            return res ? `（${res}）` : ''
        }
    }
}
</script>

<style lang="postcss" scoped>
.custorm-table-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
