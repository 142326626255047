<template>
    <a-select
        v-model="modelValue"
        v-bind="$attrs"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="handleBlur"
    >
        <a-select-option v-for="item in list" :key="item">
            {{ item }}
        </a-select-option>
    </a-select>
</template>

<script>
import { Select } from 'ant-design-vue'
import getSalaryMonthService from '@service/getSalaryMonthService'

const dictStringField = {
    hiddenDangerLevel: 'hiddenDangerLevelText'
}

export default {
    name: 'DictSelect',
    components: {
        ASelect: Select,
        ASelectOption: Select.Option
    },
    props: {
        value: {
            // type: String
            type: [Number, String]
        },
        fieldCode: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            list: []
        }
    },
    computed: {
        modelValue: {
            get() {
                return String(this.value ?? '') || undefined
            },
            set(v) {
                this.$emit('input', v)
                this.$emit('change', v)
                if (dictStringField[this.fieldCode]) {
                    const label = this.getLabelByValue(v)
                    this.$emit(
                        'add-field',
                        dictStringField[this.fieldCode],
                        label
                    )
                }
                this.$emit('blur')
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        async getList() {
            const projectCode = this.$route.query.projectCode
            try {
                this.list = await getSalaryMonthService({
                    projectCode
                })
                console.log(this.list)
            } catch (e) {
                console.log('-> e', e)
            }
        },
        getLabelByValue(value) {
            let label = ''
            this.list.forEach(item => {
                if (item.dataValue === value) {
                    label = item.dataName
                }
            })

            return label
        }
    }
}
</script>

<style lang="scss"></style>
