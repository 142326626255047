import { MARGIN_PAYMENT_DETAIL_CODE, MARGIN_UTILIZATION_DETAIL_CODE } from '@/constant/menu_code'

const MARGIN_PAYMENT_LIST_TO_DETAIL_EVENT_CODE = 'margin_detail'
const MARGIN_UTILIZATION_LIST_TO_DETAIL_EVENT_CODE = 'bail_employ_info_detail'

function copiedByQueryCriteriaList(data, queryCriteriaList) {
    if (queryCriteriaList === null) {
        return data
    }

    const reduceFn = (newData, { fieldCode }) => {
        newData[fieldCode] = data[fieldCode]
        return newData
    }
    return queryCriteriaList.reduce(reduceFn, {})
}

const strategy = {
    // 保证金缴纳列表 <跳转> 保证金缴纳详情
    [MARGIN_PAYMENT_LIST_TO_DETAIL_EVENT_CODE](event, localData) {
        this.$router.push({
            name: MARGIN_PAYMENT_DETAIL_CODE,
            query: {
                id: localData.id,
                projectCode: localData.projectCode,
                workflowApplyRecordId: localData.workflowApplyRecordId
            }
        })
    },

    // 保证金动用列表 <跳转> 保证金动用详情
    [MARGIN_UTILIZATION_LIST_TO_DETAIL_EVENT_CODE](event, localData) {
        this.$router.push({
            name: MARGIN_UTILIZATION_DETAIL_CODE,
            query: {
                id: localData.id,
                projectCode: localData.projectCode
            }
        })
    },

    default(event, localData, params) {
        const { eventCode } = event

        this.$router.push({
            name: eventCode,
            query: {
                layoutCode: eventCode,
                dynamicType: event.dynamicType,
                operationType: event.operationType,
                ...copiedByQueryCriteriaList(params ?? localData, event.queryCriteriaList)
            }
        })
    }
}

export default strategy
