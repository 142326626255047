<template>
    <a-back-top
        v-model="$props"
        :visibility-height="visibilityHeight"
        class="custom-back-up"
    >
        <div class="custom-back-up__icon" />
    </a-back-top>
</template>

<script>
import { BackTop as ABackTop } from 'ant-design-vue'

export default {
    name: 'CustomBackTop',
    components: {
        ABackTop
    },
    data() {
        return {
            visibilityHeight: 400 // 可视区域高度
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.custom-back-up {
    right: 16px;
    bottom: 40px;

    &__icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $base-color;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
            content: '';
            width: 24px;
            height: 24px;
            display: block;
            background: url('~@/assets/images/icon/back-top-icon.png');
            background-size: cover;
        }

        &::after {
            content: '回到顶部';
            font-size: 12px;
            color: $color-white;
            display: none;
            width: 28px;
            text-align: center;
        }

        &:hover {
            &::before {
                display: none;
            }

            &::after {
                display: block;
            }
        }
    }
}
</style>
