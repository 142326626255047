<template>
    <a-card :bordered="false" title="班组管理" class="team-management-panel">
        <div class="team-management-panel__scroll">
            <!-- 单位列表 -->
            <unit-panel
                :read-only="!!readOnly"
                :project-code="projectCode"
                :team-portal-target="teamPortalTarget"
                :personnel-portal-target="personnelPortalTarget"
            />

            <!-- 班组列表 -->
            <custom-portal-target :name="teamPortalTarget" :slim="true" />

            <!-- 班组详情 -->
            <custom-portal-target :name="personnelPortalTarget" :slim="true" />
        </div>
    </a-card>
</template>
<script>
import { Card as ACard } from 'ant-design-vue'
import CustomPortalTarget from '@components/CustomPortalVue/PortalTarget'

import UnitPanel from './UnitPanel'

import './style.scss'

export default {
    name: 'TeamManagementPanel',
    components: {
        ACard,
        UnitPanel,
        CustomPortalTarget
    },
    props: {
        readOnly: [Boolean, Number]
    },
    data() {
        const generatePortalTarget = name => `${this._uid}_${name}`

        return {
            teamPortalTarget: generatePortalTarget('teamPanel'),
            personnelPortalTarget: generatePortalTarget('personnelPanel')
        }
    },
    computed: {
        projectCode() {
            return this.$route.query.projectCode
        }
    }
}
</script>
