<template>
    <div class="search-tree">
        <div class="left">
            <div class="head">
                <a-checkbox
                    :checked="allTreeIsChecked"
                    :disabled="disabled"
                    @change="allTreeChange"
                />
                <span class="head-title"
                >待选合集({{ awaitListLength }}项)</span
                >
            </div>
            <div class="content">
                <a-input-search
                    style="margin-bottom: 8px"
                    placeholder="请输入关键词选择"
                    :disabled="disabled"
                    @search="onSearch"
                />
                <a-tree
                    v-model="leftTargetKeys"
                    checkable
                    :expanded-keys="expandedKeys"
                    :auto-expand-parent="autoExpandParent"
                    :tree-data="leftData"
                    :replace-fields="replaceFields"
                    class="scroll-content"
                    default-expand-all
                    @expand="onExpand"
                >
                    <template slot="title" slot-scope="{ dataName }">
                        <span v-if="dataName.indexOf(searchValue) > -1">
                            {{
                                dataName.substr(
                                    0,
                                    dataName.indexOf(searchValue)
                                )
                            }}
                            <span style="color: #f50; float: left">{{
                                searchValue
                            }}</span>
                            {{
                                dataName.substr(
                                    dataName.indexOf(searchValue) +
                                        searchValue.length
                                )
                            }}
                        </span>
                        <span v-else>{{ dataName }}</span>
                    </template>
                </a-tree>
            </div>
        </div>

        <div class="center">
            <div class="top" @click="handleRight">
                <button
                    :disabled="disabled || disabledRight"
                    type="button"
                    class="ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only"
                >
                    <i aria-label="图标: right" class="anticon anticon-right">
                        <svg
                            viewBox="64 64 896 896"
                            data-icon="right"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class=""
                        >
                            <path
                                d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"
                            />
                        </svg>
                    </i>
                </button>
            </div>
            <div @click="handleLeft">
                <button
                    type="button"
                    class="ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only"
                    :disabled="disabled || !rightTargetKeys.length"
                >
                    <i aria-label="图标: left" class="anticon anticon-left">
                        <svg
                            viewBox="64 64 896 896"
                            data-icon="left"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class=""
                        >
                            <path
                                d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                            />
                        </svg>
                    </i>
                </button>
            </div>
        </div>

        <tree-table
            ref="treeTable"
            :disabled="disabled"
            :list="rightData || []"
            @select="keys => (rightTargetKeys = keys)"
        />
    </div>
</template>

<script>
import { Input, Tree, Checkbox } from 'ant-design-vue'
import getDictTreeByLevel from '@service/getDictTreeByLevel'
export default {
    name: 'SearchTree',
    components: {
        ATree: Tree,
        AInputSearch: Input.Search,
        ACheckbox: Checkbox,
        TreeTable: () => import('./table')
    },
    props: {
        value: {
            type: [String, Array],
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            leftData: [], // 左侧树数据
            leftTargetKeys: [], // 左侧选中keys
            expandedKeys: [], // 左侧展开的父级keys
            searchValue: '', // 左侧搜索的值
            autoExpandParent: true, // 是否展开父级
            leftDataMap: new Map(), // 左侧树值键值对
            replaceFields: {
                title: 'dataName',
                key: 'id'
            },
            // rightData: [], // 右侧数据
            rightTargetKeys: [], // 右侧选中keys
            parentIds: [], // 父级id缓存
            childrenList: [] // 子节点缓存
        }
    },
    computed: {
        awaitListLength() {
            return this.childrenList.filter(item => !item.disabled).length
        },
        disabledRight() {
            if (!this.leftData.length) {
                return false
            }
            return !this.leftTargetKeys.filter(
                item => !this.leftDataMap.get(item).disabled
            ).length
        },
        allTreeIsChecked() {
            return (
                this.leftTargetKeys.length ===
                this.childrenList.length + this.parentIds.length
            )
        },
        rightData() {
            if (!this.leftData.length) {
                return []
            }
            return this.value.map(key => {
                const { parent = {}, ...other } =
                    this.leftDataMap.get(key) || {}
                return {
                    ...other,
                    parentName: parent.dataName || ''
                }
            })
        }
    },
    created() {
        this.leftTargetKeys = [].concat(this.value)
        this.initTree()
    },

    methods: {
        allTreeChange(e) {
            const v = e.target.checked
            if (v) {
                this.leftTargetKeys = this.childrenList
                    .map(item => item.id)
                    .concat(this.parentIds)
            } else {
                this.leftTargetKeys = this.childrenList
                    .filter(item => item.disabled)
                    .map(item => item.id)
            }
        },
        handleRight() {
            const rightData = []
            this.leftTargetKeys.forEach(item => {
                const mapItem = this.leftDataMap.get(item)

                if (mapItem.level === 2) {
                    const { parent, ...o } = mapItem
                    rightData.push({
                        ...o,
                        parentName: parent.dataName
                    })
                }

                if (!mapItem.disabled) {
                    this.$refs.treeTable.selectedRowKeys =
                        this.$refs.treeTable.selectedRowKeys.filter(
                            rItem => rItem !== item
                        ) // 设置右边默认不选中
                }

                mapItem.disabled = true
            })

            this.$emit(
                'change',
                rightData.map(item => item.id),
                rightData
            )
        },
        handleLeft() {
            const rightData = this.rightData.filter(
                item => !this.rightTargetKeys.includes(item.id)
            )
            this.rightTargetKeys.forEach(item => {
                const mapItem = this.leftDataMap.get(item)
                mapItem.disabled = false

                mapItem.parent.disabled = mapItem.parent.children.every(
                    item => item.disabled
                )
                // 过滤子集和父级不选中
                this.leftTargetKeys = this.leftTargetKeys.filter(
                    mItem => item !== mItem &&
                        this.leftDataMap.get(item).parent.id !== mItem
                )
            })

            this.$emit(
                'change',
                rightData.map(item => item.id),
                rightData
            )
            this.rightTargetKeys = []
        },
        onExpand(expandedKeys) {
            this.expandedKeys = expandedKeys
            this.autoExpandParent = false
        },
        onSearch(value) {
            let expandedKeys = []
            this.childrenList.forEach(item => {
                if (
                    item.dataName.indexOf(value) > -1 &&
                    this.leftDataMap.get(item.id)
                ) {
                    expandedKeys.push(this.leftDataMap.get(item.id).parent.id)
                }
            })

            Object.assign(this, {
                expandedKeys,
                searchValue: value,
                autoExpandParent: true
            })
        },
        async initTree() {
            let sort = 0
            const data =
                await getDictTreeByLevel({
                    level: 2
                }) || []
            this.leftData = data.map(item => {
                const { id, children, dataName } = item
                const itemRes = {
                    dataName,
                    id,
                    level: 1,
                    children: [],
                    disabled: this.disabled || false
                }

                itemRes.children = (children || []).map(child => {
                    const childRes = {
                        dataName: child.dataName,
                        id: child.id,
                        level: 2,
                        parent: itemRes,
                        sort: sort++,
                        disabled: this.disabled || false
                    }
                    this.childrenList.push(child)
                    this.leftDataMap.set(child.id, childRes)
                    return childRes
                })

                this.parentIds.push(item.id)
                this.leftDataMap.set(id, itemRes)
                return itemRes
            })
            if (this.leftTargetKeys.length) {
                this.handleRight()
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.search-tree {
    display: flex;
    justify-content: center;
    // align-items: center;
    .left {
        width: 330px;
        height: 538px;
        background: #fff;
        border-radius: 2px;
        border: 1px solid #dcdee0;
    }

    .head {
        padding: 6px 12px;
        border-bottom: 1px solid #dcdee0;
    }

    .head-title {
        width: 120px;
        height: 20px;
        font-size: $font-size;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323233;
        line-height: 20px;
        margin-left: 6px;
    }
    .content {
        padding: 8px 12px;
    }
    .scroll-content {
        height: 450px;
        overflow-y: auto;
    }

    .center {
        text-align: center;
        width: 64px;
        .top {
            margin-top: 230px;
            margin-bottom: 16px;
        }
    }
}
</style>
