import { constantRouterMap } from '@router/routes'

import { setDocumentTitle } from '@utils/dom'

import {
    SET_IS_MOBILE,
    SET_THEME,
    SET_LAYOUT,
    SET_MULTIPAGE,
    SET_FIXED_HEADER,
    SET_FIXED_SIDEBAR,
    SET_REVIEW_OPERATION,

    SET_SYSTEM_NAME,
    SET_COPYRIGHT,
    SET_LICENSE_NUMBER,

    SET_ROLE,
    SET_ROUTES,
    SET_BUTTON_ROLE,

    SET_TOKEN,
    SET_USER_INFO,
    SET_ENTERPRISE_INFO,

    SET_GLOBAL_DATA,
    SET_PROJECT_INFO,
    SET_PROJECT_LIST,

    SET_INIT_INFORMATION,
    SET_AUTH_PROPERTIES,

    SET_PAGE_F_API,
    ADD_PAGE_F_API,
    DELETE_PAGE_F_API,
    CLEAR_PAGE_F_API
} from './mutation-types'

export default {
    [SET_IS_MOBILE](state, isMobile) {
        state.isMobile = isMobile
    },
    [SET_THEME](state, theme) {
        state.theme = theme
    },
    [SET_LAYOUT](state, layout) {
        state.layout = layout
    },
    [SET_MULTIPAGE](state, multipage) {
        state.multipage = multipage
    },
    [SET_FIXED_HEADER](state, fixedHeader) {
        state.fixedHeader = fixedHeader
    },
    [SET_FIXED_SIDEBAR](state, fixedSidebar) {
        state.fixedSidebar = fixedSidebar
    },
    [SET_REVIEW_OPERATION](state, info) {
        state.reviewOperation = info
    },


    [SET_SYSTEM_NAME](state, systemName) {
        state.systemName = systemName
        setDocumentTitle(systemName)
    },


    [SET_COPYRIGHT](state, copyright) {
        state.copyright = copyright
    },
    [SET_LICENSE_NUMBER](state, licenseNumber) {
        state.licenseNumber = licenseNumber
    },


    [SET_ROLE](state, role) {
        state.role = role
    },
    [SET_ROUTES](state, routes) {
        state.addRouters = routes
        state.routes = constantRouterMap.concat(routes)
    },
    [SET_BUTTON_ROLE](state, buttonRole) {
        state.buttonRole = buttonRole
    },


    [SET_TOKEN](state, token) {
        state.token = token
    },
    [SET_USER_INFO](state, userInfo) {
        state.userInfo = userInfo
    },
    [SET_ENTERPRISE_INFO](state, enterpriseInfo) {
        state.enterpriseInfo = enterpriseInfo
    },


    [SET_GLOBAL_DATA](state, globalData) {
        state.globalData = globalData
    },
    [SET_PROJECT_INFO](state, projectInfo) {
        state.projectInfo = projectInfo
    },
    [SET_PROJECT_LIST](state, projectList) {
        state.projectList = projectList
    },


    [SET_INIT_INFORMATION](state, initInformation) {
        state.initInformation = initInformation
    },
    [SET_AUTH_PROPERTIES](state, authProperties) {
        state.authProperties = authProperties
    },


    [SET_PAGE_F_API](state, pageFApi) {
        state.pageFApi = pageFApi
    },
    [ADD_PAGE_F_API](state, fApi) {
        state.pageFApi = fApi
        state.pageFApiList.push(fApi)
    },
    [DELETE_PAGE_F_API](state, deleteRootType) {
        const pageFApiList = state.pageFApiList
        let index = pageFApiList.findLastIndex(fApi => {
            const { isRoot, rootType } = fApi.options

            if (deleteRootType) {
                return isRoot && deleteRootType === rootType
            } else {
                return isRoot
            }
        })

        if (!deleteRootType) {
            index++
        }

        state.pageFApiList = pageFApiList.slice(0, index)
        state.pageFApi = state.pageFApiList[state.pageFApiList.length - 1]
    },
    [CLEAR_PAGE_F_API](state) {
        state.pageFApi = {}
        state.pageFApiList = []

        console.log('clear_pageFApi')
    }
}
