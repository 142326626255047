import { Message } from 'ant-design-vue'
// socket实例
let Socket = null
// ws连接地址
let wsUrl = ''
let id = ''
// 连接成功，执行回调函数
let messageCallback = null
// 连接失败，执行回调函数
let errorCallback = null
// 重连定时器
let setIntervalWebsocketTimer = null
// 重连次数
let tryTime = 0
// 最大重连次数
let limitReconnectionNum = 6

// 接收ws后端返回的数据
function websocketOnmessage(e) {
    messageCallback(e.data)
}

// 建立ws连接
function websocketOpen(e) {
    console.log('ws连接成功', e)
}

/**
 * 发起websocket连接
 * @param {Object} id 需要向后台传递的参数数据
 */
function websocketSend(id) {
    // 加延迟是为了尽量让ws连接状态变为OPEN
    setTimeout(() => {
        // 添加状态判断，当为OPEN时，发送消息
        if (Socket.readyState === Socket.OPEN) { // Socket.OPEN = 1
            // 发给后端的数据需要字符串化
            // console.log(id, 'unionId')
            Socket.send(id)
        }
        if (Socket.readyState === Socket.CLOSED) { // Socket.CLOSED = 3
            console.log('Socket.readyState=3')
            errorCallback()
        }
    }, 500)
}

// 关闭ws连接
function websocketClose(e) {
    if (e && e.code !== 1000) {
        errorCallback()
        setIntervalWebsocketTimer && clearTimeout(setIntervalWebsocketTimer)
        // 如果需要设置异常重连则可替换为下面的代码，自行进行测试
        if (tryTime < limitReconnectionNum) {
            setIntervalWebsocketTimer = setTimeout(function () {
                Socket = null
                tryTime++
                initWebSocket()
                console.log(`第${tryTime}次重连`)
                websocketSend(id)
            }, tryTime * 1000)
        } else {
            setIntervalWebsocketTimer && clearTimeout(setIntervalWebsocketTimer)
            tryTime = 0
            Message.error('重连失败！请稍后重试')
        }
    }
}


// 初始化websocket
function initWebSocket() {
    if (typeof WebSocket === 'undefined') {
        Message.error('您的浏览器不支持WebSocket，无法获取数据')
        return false
    }

    // ws请求地址
    Socket = new WebSocket(wsUrl)

    Socket.onmessage = function (e) {
        websocketOnmessage(e)
    }
    Socket.onopen = function (e) {
        websocketOpen(e)
    }
    Socket.onerror = function (e) {
        errorCallback(e)
    }
    Socket.onclose = function (e) {
        websocketClose(e)
    }
}

/**
 * 发起websocket请求函数
 * @param {string} url ws连接地址
 * @param {Object} unionId 传给后台的参数
 * @param {function} successCallback 接收到ws数据，对数据进行处理的回调函数
 * @param {function} errCallback ws连接错误的回调函数
 */
export function connectWebsocket(url, unionId, successCallback, errCallback) {
    wsUrl = url
    id = unionId
    initWebSocket()
    messageCallback = successCallback
    errorCallback = errCallback
    websocketSend(unionId)
}

/**
 * 关闭websocket函数
 */
export function closeWebsocket() {
    if (Socket) {
        Socket.close(1000) // 关闭websocket
        // Socket.onclose() // 关闭websocket
    }
}
