<template>
    <div class="amount-num">
        <a-input
            v-bind="$attrs"
            v-model.trim="modelValue"
            :disabled="disabled"
            :placeholder="placeholder"
            :suffix="suffix"
        />
        <div class="amount-num__result">{{ amountNum }}万</div>
    </div>
</template>

<script>
import { Input as AInput } from 'ant-design-vue'

import { calculator } from '4d-space-bag'
import { isEmpty } from '@utils'

import { EMPTY_VALUE_PLACEHOLDER } from '@config'

const { div } = calculator

export default {
    name: 'AmountInput',
    components: {
        AInput
    },
    props: {
        value: {
            // type: String
            type: [Number, String]
        },
        fieldCode: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        suffix: {
            type: String,
            default: ''
        }
    },
    computed: {
        modelValue: {
            get() {
                return String(this.value ?? '') || undefined
            },
            set(v) {
                this.$emit('input', v)
                this.$emit('change', v)
            }
        },
        amountNum() {
            try {
                return isEmpty(this.value) ? EMPTY_VALUE_PLACEHOLDER : div(this.value, 1e4)
            } catch (error) {
                console.log(error)
            }

            return EMPTY_VALUE_PLACEHOLDER
        }
    }
}
</script>

<style lang="scss" scoped>
.amount-num {
    position: relative;
    &__result {
        position: absolute;
        width: 100%;
        top: 32px;
        text-align: right;
    }
}
</style>
