<template>
    <a-card class="custom-card" :bordered="bordered">
        <slot />
        <template v-if="showTitle" slot="title">
            <div>
                {{ title }} <span class="subTitle">{{ subTitle }}</span>
            </div>
        </template>
        <template slot="extra">
            <extra-buttons
                :buttons="extraButton"
                @on-event-trigger="handleEventTrigger"
            />
        </template>
        <custom-mutiple-modal ref="mutiple-modal" @on-ok="handleModalOk" />
        <component
            :is="directConnectModalComponent"
            v-bind="directConnectModalProp"
            @on-ok="handleModalOk"
            @on-close="handleCloseDirectConnectModal"
        />
    </a-card>
</template>

<script>
/* eslint-disable eqeqeq */
import { Card as ACard } from 'ant-design-vue'
import CustomMutipleModal from '@weights/CustomMutipleModal'

import ExtraButtons from './ExtraButtons'

import { debounce } from 'lodash'
import { isTrueBooleanPattern } from '@utils'

import childrenRefreshMixin from '@mixins/createPage/childrenRefresh'
import queryMixin from '@mixins/createPage/query'
import getFormData from '@mixins/createPage/formData'
import eventMixin from '@mixins/createPage/event'
import buttonMixin from '@mixins/createPage/button'
import directConnectModalMixin from '@/mixins/createPage/event/directConnectModal'

import { EVENT_TYPE_NORMAL, EVENT_TYPE_DEFAULT, BOOLEAN_PATTERN_FALSE } from '@constant/enum'

export default {
    name: 'CustomCard',
    components: {
        ACard,
        ExtraButtons,
        CustomMutipleModal
    },
    mixins: [
        childrenRefreshMixin,
        getFormData,
        eventMixin,
        buttonMixin,
        queryMixin,
        directConnectModalMixin
    ],
    props: {
        needBordered: {
            type: Number,
            default: BOOLEAN_PATTERN_FALSE
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        eventList: {
            type: Array,
            default: () => []
        },
        extraTitle: {
            type: Object,
            default: () => ({})
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        tableType: {
            type: Number
        },
        fieldList: {
            type: Array,
            default() {
                return []
            }
        },
        showTitle: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            localData: [],
            localExtraTitle: {}
        }
    },
    computed: {
        bordered() {
            return isTrueBooleanPattern(this.needBordered)
        },
        extraButton() {
            return this.getButtonList(
                this.eventList || [],
                EVENT_TYPE_NORMAL,
                this.localExtraTitle?.props?.data || this.localData
            )
        },
        subTitle() {
            let res = ''
            const { localExtraTitle } = this
            if (localExtraTitle && localExtraTitle.props) {
                const { data, fieldList } = localExtraTitle.props

                if (data && fieldList) {
                    fieldList.forEach((item, index) => {
                        if (item.componentType === 'extraTitle') {
                            res +=
                                (index > 0 ? '，' : '') +
                                `${item.fieldName}：${data[item.fieldCode]}`
                        }
                    })
                }
            }

            return res ? `（${res}）` : ''
        }
    },
    watch: {
        data: {
            handler(val) {
                this.localData = val
            },
            immediate: true,
            deep: true
        },
        extraTitle: {
            handler(val) {
                this.localExtraTitle = val
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        async handleEventTrigger(event, params = {}) {
            try {
                const _params = {
                    ...this.getHiddenParams(this.localData),
                    ...params
                }
                await this.emitEvent({
                    event,
                    localData: this.localData,
                    _params
                })
            } catch (error) {
                console.log(error.message)
            }
        },
        async dataRefresh(data) {
            try {
                this.fieldDataRefresh(data)
                this.$emit('data-refresh', data)
            } catch (error) {
                console.log(error.message)
                this.$message.error(error.message)
            }
        },
        changeLocalData(data) {
            this.localData = data
        },
        handleModalOk() {
            this.dataRefresh()
        },
        handleRefresh(data) {
            this.dataRefresh(data)
        },
        /** 更新方法 */
        fieldDataRefresh: debounce(function (data) {
            if (data) {
                this.localdata = data
            }
            // 子节点更新逻辑
            this.childrenFieldRefresh(data)

            // 扩展头更新
            this.extraTitleDataRefresh()
        }, 100),
        /** 更新扩展头 */
        // TIP debounce 防止子子组件利用 cardExtraTitleDataRefresh 时导致重复更新
        extraTitleDataRefresh: debounce(async function () {
            const { localExtraTitle } = this
            const { eventList = [] } = localExtraTitle.props || []
            if (localExtraTitle) {
                const event = eventList.filter(
                    event => event.eventType === EVENT_TYPE_DEFAULT
                )[0]
                if (event) {
                    try {
                        const { globalQuery } = this.getGlobalQuery({})
                        const params = this.getGlobalParams(globalQuery)
                        // 如果有事件，则进行内容上的更新
                        const { result } = await this.triggerEventServer(event, params)
                        const dataString =
                        JSON.parse(result?.data?.dataString) || {}
                        this.localExtraTitle.props.data = dataString
                    } catch (error) {
                        this.$message.warn(error.message)
                    }

                }
            }
        }, 100)
    },
    provide() {
        return {
            cardExtraTitleDataRefresh: this.extraTitleDataRefresh, // 用于子组件反向更新拓展头
            cardDataRefresh: this.fieldDataRefresh
        }
    }
}
</script>

<style lang="scss">
.custom-card {
    width: 100%;
    margin: 0 auto 8px;
    border-radius: 4px;

    .subTitle {
        font-size: 14px;
        color: #6c6c6c;
    }

    &-subtitle {
        margin-top: 24px;
        margin-top: 16px;
        font-size: 15px;
    }

    .ant-card-extra {
        padding: 12px 0;
    }

    .extra-button {
        margin-left: 15px;
    }
}
</style>
