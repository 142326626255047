<template>
    <div
        class="insure-write-container__form"
        :class="{ 'need-fixed-width': needFixedWidth }"
    >
        <!-- <div @click="getFormData">提交</div> -->
        <form-create
            v-model="fApi"
            :rule="rule"
            :option="options"
            @change="changeHandle"
        />
    </div>
</template>

<script>
/* eslint-disable no-template-curly-in-string */
import formCreate from '@form-create/ant-design-vue'
import {
    FormModel,
    Row,
    Col,
    Button,
    Rate,
    Input,
    Select,
    Cascader,
    InputNumber,
    Radio,
    Checkbox
} from 'ant-design-vue'
import CheckListModal from '@/weights/CheckListModal'
import CustomDescriptions from '@/weights/CustomDescriptions'
import UploadFile from './components/UploadFile'
import UploadImage from './components/UploadImage'
import Address from './components/Address'
import AreaTreeSelect from './components/AreaTreeSelect'
import DictSelect from './components/Select'
import CustomRadio from './components/Radio'
import DateRangePicker from './components/DateRangePicker'
import DatePicker from './components/DatePicker'
import MonthPicker from './components/MonthPicker'
import ReadonlyItem from './components/ReadonlyItem'
import CurrentArea from './components/CurrentArea'
import TeamPersonSelect from './components/TeamPersonSelect'
import ManagerSelect from './components/ManagerSelect'
import FinancialInstitutionSelect from './components/FinancialInstitutionSelect'
import FinancialInstitutionBrandSelect from './components/FinancialInstitutionBrandSelect'


import HACSupervisionUnitSelect from './components/HACSupervisionUnitSelect'
import IndustryCompetentUnit from './components/IndustryCompetentUnit'

import SalaryMonthSelect from './components/SalaryMonthSelect'
import WhetherSelect from './components/WhetherSelect'
import ProjectSelect from './components/ProjectSelect'
import UintSearch from './components/UnitSelect'

import AmountInput from './components/AmountInput' // 万元展示

// formCreate.component('gUpload',AppUpload)

import childrenRefreshMixin from '@/mixins/createPage/childrenRefresh'
import getFormData from '@/mixins/createPage/formData'
import eventMixin from '@/mixins/createPage/event'
import emitsMixin from './mixins/emitMixin'
import commonMixin from './mixins'

import { isEmpty } from '@utils'
import { mergeFieldOfFile } from './utils'

import moment from 'moment'

import { FIELD_TYPE_SHOW } from '@/constant/enum'

formCreate.component('AFormModel', FormModel)
formCreate.component('AFormModelItem', FormModel.Item)
formCreate.component('ARow', Row)
formCreate.component('ACol', Col)
formCreate.component('AButton', Button)
formCreate.component('ARate', Rate)
formCreate.component('AInput', Input)
formCreate.component('ASelect', Select)
formCreate.component('ACascader', Cascader)
formCreate.component('ADatePicker', DatePicker)
formCreate.component('ARangePicker', DateRangePicker)
formCreate.component('AInputNumber', InputNumber)
formCreate.component('ATextarea', Input.TextArea)
formCreate.component('ARadio', Radio)
formCreate.component('ARadioGroup', Radio.Group)
formCreate.component('ACheckbox', Checkbox)
formCreate.component('ACheckboxGroup', Checkbox.Group)
formCreate.component('CheckListModal', CheckListModal)
formCreate.component('UploadFile', UploadFile)
formCreate.component('UploadImage', UploadImage)
formCreate.component('AddressSelect', Address)
formCreate.component('DictSelect', DictSelect)
formCreate.component('CustomDescriptions', CustomDescriptions)
formCreate.component('CustomRadio', CustomRadio)
formCreate.component('ReadonlyItem', ReadonlyItem)
formCreate.component('AmountInput', AmountInput)
formCreate.component('AMonthPicker', MonthPicker)
formCreate.component('AreaTreeSelect', AreaTreeSelect)
formCreate.component('CurrentArea', CurrentArea)
formCreate.component('ManagerSelect', ManagerSelect)
formCreate.component('ProjectSelect', ProjectSelect)
formCreate.component('UintSearch', UintSearch)

formCreate.component('HACSupervisionUnitSelect', HACSupervisionUnitSelect) // 行业主管单位
formCreate.component('IndustryCompetentUnit', IndustryCompetentUnit) // 人社监管单位

formCreate.component('SalaryMonthSelect', SalaryMonthSelect)
formCreate.component('TeamPersonSelect', TeamPersonSelect)
formCreate.component('WhetherSelect', WhetherSelect)
formCreate.component('FinancialInstitutionSelect', FinancialInstitutionSelect)
formCreate.component('FinancialInstitutionBrandSelect', FinancialInstitutionBrandSelect)

const NONE_FIXED_WIDTH_FORM_FIELDS = []

export default {
    name: 'FormCreateComponents',
    components: {
        FormCreate: formCreate.$form()
    },
    mixins: [
        childrenRefreshMixin, getFormData, eventMixin, commonMixin, emitsMixin
    ],
    props: {
        field: {
            type: String,
            default: ''
        },
        data: {
            type: [Object, Array],
            default: () => ({})
        },
        fieldList: {
            type: Array,
            default() {
                return []
            }
        },
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        options: {
            type: Object,
            default() {
                return {
                    submitBtn: false,
                    resetBtn: false
                }
            }
        },
        columns: {
            type: Number,
            default: 1
        },
        emits: {
            type: Object,
            default: () => ({})
        },
        childrenField: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            fApi: {},
            rule: [],
            relatetions: []
        }
    },
    computed: {
        needFixedWidth() {
            return !NONE_FIXED_WIDTH_FORM_FIELDS.includes(this.field)
        }
    },
    watch: {
        fieldList: {
            handler(fieldList) {
                try {
                    const emits = this.getFieldEmits(fieldList)

                    const renderField = fieldList.filter(
                        item => item.fieldType === FIELD_TYPE_SHOW
                    )


                    // 合并新的文件field
                    mergeFieldOfFile(renderField)

                    const res = this.parserPanel(
                        renderField,
                        this.data,
                        this.columns,
                        emits
                    )

                    fieldList.forEach(item => {
                        if (item.formula) {
                            const relate = this.getRelated(item)
                            this.relatetions.push({
                                formula: item.formula,
                                field: item.fieldCode,
                                ...relate
                            })
                        }
                    })
                    this.rule = res
                } catch (error) {
                    console.log(error)
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        /**
         * 返回表单数据
         */
        getFormData() {
            const formData = this.fApi.formData()
            const hiddenParams = this.getHiddenParams(this.data)

            return {
                ...hiddenParams,
                ...formData
            }
        },
        vaildatorForm(cb) {
            if (cb) {
                return this.fApi?.validate((vaild, fail) => {
                    cb(vaild, fail)
                })
            }

            return {
                valid: true,
                fail: []
            }
        },
        changeHandle() {
            // 获取当前表单数据
            const data = this.getFormData()
            const res = {}
            this.relatetions.forEach(relatetion => {
                const { relatedKeys, originRelatedKeys, formula, field } =
                    relatetion

                // 时间区间计算
                if (
                    relatedKeys.length === 1 &&
                    this.fApi.getRule(relatedKeys[0]).type === 'RangePicker'
                ) {
                    const value = this.fApi.getValue(relatedKeys[0])
                    if (value) {
                        const time = value.split('|')
                        const day = parseInt(
                            (moment(time[1]) - moment(time[0])) /
                                1000 /
                                60 /
                                60 /
                                24,
                            10
                        ) + 1 // 日期计算 产品约定 + 1
                        res[field] = day
                    }
                } else if (
                    relatedKeys.filter(key => !!data[key]).length ===
                    relatedKeys.length
                ) {
                    // 普通数值计算
                    // 所有参数都有赋值 则进行计算
                    let calcStr = formula
                    originRelatedKeys.forEach((item, index) => {
                        calcStr = calcStr.replace(
                            item,
                            data[relatedKeys[index]]
                        )
                    })

                    try {
                        // eslint-disable-next-line no-eval
                        res[field] = eval(calcStr)
                        this.fApi.setValue()
                    } catch (error) {
                        console.log(error)
                    }
                }
            })

            // 表单赋值
            this.fApi.setValue(res)
        },
        getRelated(item) {
            const res = {}
            const reg = /\$\{([^}]+)\}/g
            res.originRelatedKeys = item.formula.match(reg) || []
            res.relatedKeys = res.originRelatedKeys.map(item => item.replace(reg, RegExp.$1))
            return res
        },
        fieldDataRefresh(data) {
            // TIP 表单内容更新
            const { fApi } = this
            for (let key in data) {
                if (!isEmpty(data[key])) {
                    fApi.setValue(key, data[key])
                }
            }
        }
    }
}
</script>

<style lang="scss">
.insure-write-container__form {
    .ant-row {
        display: flex;
        flex-wrap: wrap;
    }

    .ant-input[disabled] {
        cursor: normal;
    }

    .ant-form-item-children {
        display: inline-block;
        width: 100%;

        .ant-calendar-picker {
            width: 100%;
        }
    }

    .ant-form-item-label {
        overflow: initial;

        .ant-form-item-required {
            margin-left: -10px;
        }
    }

    &.need-fixed-width {
        .ant-input {
            max-width: 352px;
        }
        .ant-form-item-control {
            max-width: 352px;
        }
        .ant-form-item-children {
            max-width: 352px;
        }
    }
}
</style>
