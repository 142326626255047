import { empty as emptyFilter } from '@utils'
import { thousandBitFilter, withYuanUnitAmountFilter } from '@utils/filter'

export const columns = [
    {
        dataIndex: 'projectName',
        label: '工程名称',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'externalProjectCode',
        label: '统一项目编号',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'projectCode',
        label: '工程编号',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'projectDetailAddress',
        label: '工程地点',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'projectAreaCodeText',
        label: '工程地区',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'projectTypeText',
        label: '工程类型',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'contractAmount',
        label: '施工合同金额',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'contractStartEndDateText',
        label: '计划建设日期',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'projectDuration',
        label: '工期',
        customRender: val => thousandBitFilter(val, '天')
    },
    {
        dataIndex: 'generalConstructionContractingName',
        label: '施工总包单位',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'socialCreditCode',
        label: '总包单位统一社会信用代码',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'constructionUnitName',
        label: '建设单位',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'constructionUnitCode',
        label: '建设单位统一社会信用代码',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'contractSigningDate',
        label: '施工合同签订日',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'salaryPaymentDate',
        label: '工资发放日',
        customRender: val => `${emptyFilter(val)} 日`
    },
    {
        dataIndex: 'projectManagerName',
        label: '项目经理',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'projectManagerPhone',
        label: '项目经理电话',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'laborManagerName',
        label: '劳资专管员',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'laborManagerPhone',
        label: '劳资专管员电话',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'competentUnitTypeText',
        label: '行业主管类型',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'competentDepartmentAreaCodeText',
        label: '主管地区',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'competentUnitName',
        label: '行业主管单位',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'regulatoryUnitName',
        label: '人社监管单位',
        customRender: val => emptyFilter(val)
    }
]
