import _ from 'lodash'
export default function (str) {
    try {
        if (!str) {
            return []
        }
        let res = []
        if (_.isString(str)) {
            res = JSON.parse(str)
        } else {
            res = str
        }

        if (_.isObject(res) && !_.isArray(res)) {
            res = [res]
        } else if (!_.isArray(res)) {
            res = []
        }
        return res
    } catch (error) {
        console.log(error)
        return []
    }
}
