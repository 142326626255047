import { PortalTarget } from 'portal-vue'

export default {
    name: 'PortalTarget',
    props: {
        multiple: Boolean,
        name: String,
        slim: Boolean,
        slotProps: Object,
        tag: String,
        transition: [String, Object, Function]
    },
    render(h) {
        return h(PortalTarget, {
            props: {
                multiple: this.multiple,
                name: this.name,
                slim: this.slim,
                slotProps: this.slotProps,
                tag: this.tag,
                transition: this.transition
            }
        })
    }
}
