<template>
    <a-month-picker
        v-model="localValue"
        :format="format"
        :show-time="showTime"
    />
</template>

<script>
import { DatePicker } from 'ant-design-vue'

import moment from 'moment'
export default {
    name: 'DatePicker',
    components: {
        AMonthPicker: DatePicker.MonthPicker
    },
    props: {
        value: {
            type: [String, Object],
            default: ''
        },
        showTime: {
            type: Boolean,
            default: true
        },
        format: {
            type: String,
            default: 'YYYY-MM'
        }
    },
    computed: {
        localValue: {
            get() {
                return this.value && moment(this.value)
            },
            set(value) {
                if (value) {
                    this.$emit('input', value.format(this.format))
                    this.$emit('change', value.format(this.format))
                } else {
                    this.$emit('input', '')
                    this.$emit('change', '')
                }
            }
        }
    }
}
</script>

<style></style>
