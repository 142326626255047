export const getButtonList = function (str, list) {
    const res = []
    const len = str.length - 1
    list.forEach((item, index) => {
        +str.charAt(len - index) && res.push(item)
    })

    return res
}

export const getHasOperationButtonList = function (str, list) {
    const res = []
    const len = str.length

    list.forEach(item => {
        if (item.permissionCode) {
            const [, order] = item.permissionCode.split('_')
            return +str.charAt(len - order) && res.push(item)
        } else {
            return res.push(item)
        }
    })

    return res
}
// getButtonList("0000000000000110", operation)
// getButtonList("0000000000000001", workflowOperation)
