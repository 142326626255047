/**
 * 首页
 * @namespace 菜单排序 `sort` 值配置 */
export const HOME_MANAGE_MENU_SORT = 1

/**
 * 项目管理
 * @namespace 菜单排序 `sort` 值配置 */
export const PROJECT_MANAGE_MENU_SORT = 2

/**
 * 保证金管理
 * @namespace 菜单排序 `sort` 值配置 */
export const MARGIN_MANAGE_MENU_SORT = 3

/**
 * 申请管理
 * @namespace 菜单排序 `sort` 值配置 */
export const APPROVAL_MANAGE_MENU_SORT = 4

/**
 * 保函管理
 * @namespace 菜单排序 `sort` 值配置 */
export const GUARANTEE_MANAGE_MENU_SORT = 5
