import {
    formatTemplate,
    clearHiddenChild,
    getComponentType,
    addCommonProps,
    formatCustomParams,
    renderTabs,
    createChidren,
    getChildrenField,
    renderStep,
    fieldListToChildren,
    addFatherParams
} from './utils'
import _ from 'lodash'
import {
    EVENT_TYPE_NORMAL,
    LAYOUT_TYPE_FORM,
    LAYOUT_TYPE_TABLE
} from '@/constant/enum'

let extraTitle = null

// 组件数据格式化map
const parsers = {
    page: {
        format(template, nowComponentType, childrenComponentType) {
            if (
                childrenComponentType.type === 'tabs' &&
                template.children &&
                template.children.length > 1
            ) {
                renderTabs(template)
            } else {
                template.type = 'CustomPage'
                createChidren(template, childrenComponentType)
            }
        },
        customParams: ['showTitle', 'showButton']
    },
    modal: {
        format(template, nowComponentType, childrenComponentType) {
            // 处理嵌套结构的component配置向
            if (childrenComponentType.type) {
                formatCustomParams(
                    template,
                    childrenComponentType.params,
                    parsers[childrenComponentType.type].customParams
                )
            }
            if (
                childrenComponentType.type === 'tabs' &&
                template.children &&
                template.children.length > 1
            ) {
                renderTabs(template, childrenComponentType)
            } else if (
                childrenComponentType.type === 'step' &&
                template.children &&
                template.children.length > 1
            ) {
                renderStep(template)
            } else {
                template.type = 'CustomPage'
                template.props.showTitle = false
                createChidren(template, childrenComponentType)

                // 本用于暂时屏蔽不可选字段 等下个版本没问题直接删除掉注释
                if (template.children && template.children.length > 1) {
                    template.children.forEach((item, index) => {
                        if (index < template.children.length - 1) {
                            item.componentType =
                                item.layoutType === 10 && !item.componentType
                                    ? 'description'
                                    : item.componentType || ''
                        }
                    })
                }
            }
        },
        customParams: ['size', 'autoClose']
    },
    card: {
        format(template, nowComponentType, childrenComponentType) {
            template.type = 'CustomCard'
            createChidren(template, childrenComponentType)
        },
        customParams: ['needBordered']
    },
    form: {
        format(template) {
            template.type = 'FormCreate'
        },
        customParams: ['combinationComponentType', 'columns']
    },
    table: {
        format(template) {
            template.type = 'CustomTable'
        },
        customParams: [
            'combinationComponentType', 'tableType', 'showTableTitle', 'needBordered'
        ]
    },
    description: {
        format(template) {
            template.type = 'CustomDescriptions'
        },
        customParams: [
            'combinationComponentType', 'column', 'needBordered', 'align', 'fixed', 'colon', 'needCardBordered'
        ]
    },
    descriptionList: {
        format(template) {
            template.type = 'CustomDescriptionList'

            if (template.props?.data?.length === 0) {
                template.hidden = true
            }
        },
        customParams: [
            'combinationComponentType', 'column', 'needBordered', 'align', 'fixed', 'colon', 'needCardBordered'
        ]
    },
    list: {
        format(template) {
            template.type = 'CustomList'
        }
    },
    extraTitle: {
        format(template) {
            extraTitle = template
        }
    },
    tabs: {
        format(template) {
            renderTabs(template)
        },
        customParams: [
            'combinationComponentType', 'selfControl', 'mode', 'showTotal', 'needBordered'
        ]
    },
    step: {
        format(template) {
            renderStep(template)
        }
    },
    checkList: {
        format(template) {
            template.type = 'CustomSearchTree'
        }
    },
    batchImport: {
        format(template) {
            template.type = 'BatchImport'
        }
    },
    teamManagement: {
        format(template) {
            template.type = 'TeamManagement'
            // template.props.readOnly = false
        },
        customParams: ['readOnly']
    },
    fileList: {
        format(template) {
            template.type = 'FileUploadList'
        },
        customParams: ['combinationComponentType', 'columns', 'needBordered']
    },
    fileDescriptionList: {
        format(template) {
            template.type = 'FileUploadDescriptionList'
        },
        customParams: [
            'combinationComponentType', 'column', 'needBordered', 'align', 'fixed', 'colon', 'needCardBordered'
        ]
    }
}

/**
 * 递归进行Template解析
 * @param {Template} template
 */
function parse(template, globalQuery, globalParams) {
    // TIP 怎么保证field的唯一性
    template.field = template.field || template.layoutCode

    // 将fieldList分组转成children
    fieldListToChildren(template)

    // componentType解析
    const { nowComponentType, childrenComponentType } = getComponentType(
        template.componentType || ''
    )

    const componentParams = nowComponentType.params || []

    // 对于扩展头部进行特殊处理
    if (extraTitle) {
        if (!template.props) {
            template.props = {
                extraTitle
            }
        } else {
            template.props.extraTitle = extraTitle
        }
        extraTitle.hidden = true
        extraTitle = null
    }

    // 将事件中projectCode传递到children event queryCriteriaList中
    addFatherParams(template, globalQuery, globalParams)

    // 添加基础的props
    addCommonProps(template)

    // 针对不同组件进行不同的解析
    if (parsers[nowComponentType.type]) {
        // 处理自定义样式相关的参数
        formatCustomParams(
            template,
            componentParams,
            parsers[nowComponentType.type].customParams
        )

        // 格式化数据
        parsers[nowComponentType.type].format(
            template,
            nowComponentType,
            childrenComponentType
        )
    } else {
        // 临时兼容没有componentType 但需要基础展示的情况
        if (template.layoutType === LAYOUT_TYPE_FORM) {
            template.type = 'FormCreate'
        } else if (template.layoutType === LAYOUT_TYPE_TABLE) {
            template.type = 'CustomTable'
        } else {
            template.type = 'div'
        }
    }

    template.props.childrenField = getChildrenField(template)

    // 递归处理
    if (template && template.children) {
        let hasExtraTitle = false
        template.children.forEach(childTemplate => {
            // 对于扩展头部相关的内容进行隐藏处理
            if (childTemplate?.componentType?.indexOf('extraTitle') > -1) {
                childTemplate.hidden = true
                template.props.extraTitle = childTemplate
                template.props.eventList.push(
                    ...childTemplate.eventList.filter(
                        event => event.eventType === EVENT_TYPE_NORMAL
                    )
                )
                hasExtraTitle = true
            }

            if (hasExtraTitle) {
                childTemplate.showTitle = false
            }

            parse(childTemplate, globalQuery, globalParams)
        })
    }
}

/**
 * 将template -> rule
 * @param {object} template 基础模版
 * @returns 处理完成的数据 rule
 */
export function parserPanel(template, globalQuery, globalParams) {
    const copyTemplate = _.cloneDeep(template)
    const data = formatTemplate(copyTemplate)
    parse(data, globalQuery, globalParams)
    return clearHiddenChild(data)
}
