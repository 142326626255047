/* eslint-disable object-curly-newline */
import objectWithoutProperties from 'babel-runtime/helpers/objectWithoutProperties'

import { Card, Spin, Empty } from 'ant-design-vue'

import PanelBody from './BodyPanel'

import PropTypes from 'ant-design-vue/es/_util/vue-types'
import { getOptionProps, getComponentFromProp } from 'ant-design-vue/es/_util/props-util'

import { isUndefined } from '@utils'

// 信息展示面板组件
export default {
    name: 'InformationPanel',
    props: {
        // 加载中
        loading: PropTypes.bool.def(false),

        size: PropTypes.string, // 尺寸
        title: PropTypes.any, // 面板标题（设置到 `Card` 标题上）
        cardBordered: PropTypes.bool, // 面板 `Card` 是否有边框
        headStyle: PropTypes.object, // 自定义面板 `Card` 标题区域样式
        bodyStyle: PropTypes.object, // 自定义面板 `Card` 内容区域样式

        columns: PropTypes.array, // 字段配置
        dataSource: PropTypes.object, // 源数据
        bordered: PropTypes.bool, // 面板内容 `Descriptions` 是否有边框
        layout: PropTypes.string, // 面板内容 `Descriptions` 布局
        colon: PropTypes.bool, // 面板内容 `Descriptions` `colon` 配置
        itemColumn: PropTypes.number.def(2) // 面板内容 `Descriptions` `column` 配置
    },
    methods: {
        /**
         * 处理 `字段配置`，将 `scopedSlots` 转换成对应的指定方法
         *
         * @param {Array} cols 字段配置
         *
         * @returns {Array} 处理后的字段配置
         */
        updateColumns(cols) {
            const columns = []
            const $scopedSlots = this.$scopedSlots

            for (let i = 0, len = cols.length; i < len; i++) {
                const col = cols[i]

                const colScopedSlots = col.scopedSlots
                const scopedSlots = isUndefined(colScopedSlots) ? {} : colScopedSlots
                const restProps = objectWithoutProperties(col, ['slots', 'scopedSlots'])

                const column = { ...restProps }

                Object.keys(scopedSlots).forEach(key => {
                    const name = scopedSlots[key]

                    if (isUndefined(column[name]) && $scopedSlots[name]) {
                        column[key] = $scopedSlots[name]
                    }
                })

                columns.push(column)
            }

            return columns
        },
        renderBody() {
            const h = this.$createElement

            if (this.columns.length === 0) {
                let borderStyle = {}

                if (this.bordered !== false) {
                    borderStyle = {
                        border: '1px solid #e8e8e8',
                        borderRadius: '2px',
                        padding: '12px 0'
                    }
                }

                const emptySlot = getComponentFromProp(this, 'emptySlot')

                if (emptySlot) return emptySlot

                return h(Empty, {
                    style: {
                        margin: 0,
                        ...borderStyle
                    }
                })
            }

            return h(PanelBody, {
                props: {
                    ...getOptionProps(this),
                    columns: this.updateColumns(this.columns)
                }
            })
        },
        renderSpinWrapper() {
            const h = this.$createElement

            const spinProps = {
                spinning: this.loading
            }
            const kids = [
                getComponentFromProp(this, 'bodyTop'),
                getComponentFromProp(this, 'addonBefore'),
                this.renderBody(),
                getComponentFromProp(this, 'addonAfter')
            ]
            return h(Spin, { props: spinProps }, kids)
        }
    },
    render(h) {
        const cardProps = {
            size: this.size,
            headStyle: this.headStyle,
            bodyStyle: this.bodyStyle,
            bordered: this.cardBordered,
            extra: getComponentFromProp(this, 'extra'),
            title: getComponentFromProp(this, 'title')
        }
        return h(Card, { props: cardProps }, [this.renderSpinWrapper()])
    }
}
