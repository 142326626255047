export default function getConfig() {
    return {
        form: {
            hideRequiredMark: false,
            layout: 'horizontal',
            labelAlign: 'right',
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 21
            },
            validateOnRuleChange: true
        },
        row: {
            gutter: 0
        }
    }
}
