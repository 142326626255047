import { Button as AButton } from 'ant-design-vue'

import { hasOwnProperty } from '@utils'

const strategy = {
    default(h, button, idx) {
        return h(AButton, {
            key: idx,
            props: {
                type: 'primary'
            },
            class: 'extra-button',
            on: {
                click: () => this.emitEvent(button)
            }
        }, [button.eventName])
    }
}

export default {
    name: 'CustomCardExtraButtons',
    props: {
        buttons: Array
    },
    methods: {
        emitEvent(button, params = {}) {
            this.$emit('on-event-trigger', button, params)
        },
        renderButton(h, button, idx) {
            const { eventCode } = button

            if (!hasOwnProperty(strategy, eventCode)) {
                return strategy.default.call(this, h, button, idx)
            }

            return strategy[eventCode].call(this, h, button, idx)
        },
        renderButtons(h) {
            return this.buttons.map((button, idx) => this.renderButton(h, button, idx))
        }
    },
    render(h) {
        return h('span', {}, [this.renderButtons(h)])
    }
}
