<!-- 项目信息面板 -->
<template>
    <project-info-panel :load-data="loadDataFn" :card-bordered="cardBordered" />
</template>
<script>
import ProjectInfoPanel from '@weights/marginManagement/ProjectInfoPanel'

import getProjectInfoService from '@service/marginManagement/marginPaymentDetail/getProjectInfoService'

export default {
    name: 'MarginPaymentProjectInfoPanel',
    components: {
        ProjectInfoPanel
    },
    props: {
        workflowApplyRecordId: String,
        cardBordered: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        async loadDataFn() {
            // eslint-disable-next-line
            return await getProjectInfoService({ workflowApplyRecordId: this.workflowApplyRecordId })
        }
    }
}
</script>
