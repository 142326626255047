<template>
    <custom-card :title="title">
        <custom-descriptions
            v-for="(item, index) in data"
            :key="index"
            :need-bordered="needBordered"
            :data="item"
            :field-list="fieldList"
            :column="column"
        />
    </custom-card>
</template>

<script>
import CustomDescriptions from '../CustomDescriptions'
import CustomCard from '../CustomCard'

import { BOOLEAN_PATTERN_TRUE } from '@constant/enum'

export default {
    name: 'CustomDescriptionList',
    components: {
        CustomDescriptions,
        CustomCard
    },
    props: {
        // 展示边框
        needBordered: {
            type: Number,
            default: BOOLEAN_PATTERN_TRUE
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            type: String,
            default: ''
        },
        fieldList: {
            type: Array,
            default: () => []
        },
        column: {
            type: Number,
            default: 1
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        align: Number,
        colon: Number,
        fixed: Number
    }
}
</script>

<style></style>
