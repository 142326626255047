<template>
    <div class="create-page__custom-discription-wrapper">
        <!-- COMBINATION_COMPONENT_TYPE_CARD -->
        <template v-if="needCard">
            <custom-card v-bind="cardNeedProps" ref="card" @data-refresh="handleDataRefresh">
                <custom-descriptions ref="contentItem" v-bind="descriptionNeedProps" title="" />
            </custom-card>
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_PAGE -->
        <template v-else-if="needPageHeader">
            <weight-page-header v-bind="pageHeaderNeedProps" :title="title" />
            <custom-descriptions ref="contentItem" v-bind="descriptionNeedProps" title="" />
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_NOMAL -->
        <template v-else>
            <custom-descriptions ref="contentItem" v-bind="descriptionNeedProps" />
        </template>
    </div>
</template>
<script>
import CustomCard from '@weights/CustomCard'
import WeightPageHeader from '@weights/PageHeader'
import CustomDescriptions from '@weights/CustomDescriptions'

import childrenRefreshMixin from '@mixins/createPage/childrenRefresh'
import getFormData from '@mixins/createPage/formData'
import eventMixin from '@mixins/createPage/event'
import buttonMixin from '@mixins/createPage/button'

import { getPropFields, getPropsInNeed, getPropsInCardNeed, getPropsInPageHeaderNeed } from '@utils/pageCreate'

import { COMBINATION_COMPONENT_TYPE_CARD, COMBINATION_COMPONENT_TYPE_PAGE, BOOLEAN_PATTERN_TRUE } from '@constant/enum'

const DESCRIPTIONS_PROP_FIELDS = getPropFields(CustomDescriptions)

export default {
    name: 'CreatePageCustomDiscription',
    components: {
        CustomCard,
        WeightPageHeader,
        CustomDescriptions
    },
    mixins: [
        childrenRefreshMixin, getFormData, eventMixin, buttonMixin
    ],
    props: {
        combinationComponentType: {
            type: Number,
            default: COMBINATION_COMPONENT_TYPE_CARD
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        eventList: {
            type: Array,
            default: () => []
        },
        extraTitle: {
            type: Object,
            default: () => ({})
        },
        // 展示边框
        needBordered: {
            type: Number,
            default: BOOLEAN_PATTERN_TRUE
        },
        fieldList: {
            type: Array,
            default: () => []
        },
        column: {
            type: Number,
            default: 2
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        align: Number,
        colon: Number,
        fixed: Number,
        needCardBordered: Number
    },
    computed: {
        needCard() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_CARD
        },
        needPageHeader() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_PAGE
        },
        cardNeedProps() {
            return this.needCard ? getPropsInCardNeed(this.$props) : null
        },
        pageHeaderNeedProps() {
            return this.needPageHeader ? getPropsInPageHeaderNeed(this.$props) : null
        },
        descriptionNeedProps() {
            return getPropsInNeed(this.$props, DESCRIPTIONS_PROP_FIELDS)
        }
    },
    methods: {
        async handleDataRefresh(data) {
            const res = await this.fieldDataRefresh(data)
            this.$refs['card']?.changeLocalData(res)
        },
        async fieldDataRefresh(data) {
            return await this.$refs['contentItem'].fieldDataRefresh(data)
        },
        getFormData() {
            return this.$refs['contentItem'].getFormData()
        }
    }
}
</script>
