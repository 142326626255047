<template>
    <div class="team-management-panel__unit-panel">
        <a-list
            :split="false"
            :loading="loading"
            :data-source="unitList"
            class="team-management-panel__unit-list"
        >
            <template #header>
                <div
                    :class="{ checked: isChecked() }"
                    class="team-management-panel__unit-list-item"
                    @click.stop="handleUnitItemClick()"
                >
                    <a-icon type="unordered-list" />
                    <span>全部({{ totalTeamNum }})</span>
                </div>
            </template>

            <template #renderItem="item">
                <li
                    :key="item[rowKey]"
                    :class="{ checked: isChecked(item) }"
                    class="team-management-panel__unit-list-item"
                    @click.stop="handleUnitItemClick(item)"
                >
                    <div class="team-management-panel__dot" />
                    <span>{{ item.participatingUnitName }}({{ item.teamNum || 0 }})</span>
                </li>
            </template>
        </a-list>

        <custom-portal :to="teamPortalTarget">
            <team-panel
                :unit-id="unitId"
                :read-only="readOnly"
                :project-code="projectCode"
                :pre-team-num="checkedTeamNum"
                :personnel-portal-target="personnelPortalTarget"
                @on-num-change="handleTeamNumChange"
            />
        </custom-portal>
    </div>
</template>
<script>
import { Icon as AIcon, List as AList } from 'ant-design-vue'
import CustomPortal from '@components/CustomPortalVue/Portal'

import TeamPanel from '../TeamPanel'

import getUnitService from '@service/teamManagement/getUnitService'

const ROW_KEY = 'id'
const ROW_KEY_ALL = 'all_id'

export default {
    name: 'UnitPanel',
    components: {
        AIcon,
        AList,
        TeamPanel,
        CustomPortal
    },
    props: {
        readOnly: Boolean,
        projectCode: String,
        teamPortalTarget: String,
        personnelPortalTarget: String
    },
    data() {
        return {
            // 单位项的 `rowKey` 字段
            rowKey: ROW_KEY,
            // 获取单位列表中
            loading: false,
            // 单位列表
            unitList: [],
            // 当前选中单位项
            checkedItem: {}
        }
    },
    computed: {
        // 单位列表中所有项的班组数量之和
        totalTeamNum() {
            return this.unitList.reduce((pre, cur) => pre + +(cur?.teamNum || 0), 0)
        },
        // 当前选中单位项的 `rowKey`
        checkedKey() {
            return this.checkedItem[ROW_KEY]
        },
        // 当前选择的是否为 `全部`
        isCheckedAll() {
            return this.checkedKey === ROW_KEY_ALL
        },
        // 需要传入 `班组面板` 的 `unitId` 传参
        unitId() {
            return this.isCheckedAll ? undefined : this.checkedKey
        },
        // 当前选中单位项的班组数量
        checkedTeamNum() {
            return this.isCheckedAll ? this.totalTeamNum : +this.checkedItem.teamNum || 0
        }
    },
    watch: {
        projectCode: {
            handler(projectCode) {
                this.getUnitList(projectCode)
                this.$nextTick(() => this.checkUnitItem())
            },
            immediate: true
        }
    },
    methods: {
        // 获取单位列表数据
        async getUnitList(projectCode) {
            try {
                this.loading = true

                // eslint-disable-next-line
                this.unitList = await getUnitService({ projectCode })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        /**
         * 处理 `班组面板` `on-team-num` 班组数量改变事件
         * 将拿到的最新班组数量更新至对应单位的（班组数量）统计值上
         * 提示：此处更新只针对当前已选中的单位，未选中的单位需要手动刷新
         */
        handleTeamNumChange(newTeamNum = 0) {
            this.$set(this.checkedItem, 'teamNum', newTeamNum)
        },
        // 点击单位项
        // eslint-disable-next-line
        handleUnitItemClick(item = { [ROW_KEY]: ROW_KEY_ALL }) {
            if (this.checkedKey !== item[ROW_KEY]) {
                this.checkUnitItem(item)
            }
        },
        // 选中单位
        // eslint-disable-next-line
        checkUnitItem(item = { [ROW_KEY]: ROW_KEY_ALL }) {
            this.checkedItem = item
        },
        // 判断当前单位项是否被选中
        // eslint-disable-next-line
        isChecked(item = { [ROW_KEY]: ROW_KEY_ALL }) {
            return this.checkedKey === item[ROW_KEY]
        }
    }
}
</script>
