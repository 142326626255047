import { SAFETY_MFG_URL } from '@config'

/**
 * 导入校验
 *  **/
export const IMPORT_VALIDATION = {
    url: `${SAFETY_MFG_URL}/fileImport_operation/import`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 模板下载
 *  **/
export const IMPORT_OPERATION_DOWNLOAD = {
    url: `${SAFETY_MFG_URL}/fileImport_operation/template`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 失败数据导出
 *  **/
export const IMPORT_FAIL_DOWNLOAD = {
    url: `${SAFETY_MFG_URL}/fileImport_operation/exportFailData`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    responseType: 'blob',
    timeout: 30000
}


/**
 * 导入确认
 *  **/

export const IMPORT_CONFIRM = {
    url: `${SAFETY_MFG_URL}/fileImport_operation/confirmImport`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


