import axios from '../index'
import { GET_PROPERTIES } from './api'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

import { isEmptyObject } from '@utils'

const SYSTEM_AUTH_ROPERTIES = process.env.SYSTEM_AUTH_ROPERTIES

/**
 * 获取认证相关配置信息
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function getAuthPropertiesService(payload = {}, config = {}) {
    if (!isEmptyObject(SYSTEM_AUTH_ROPERTIES)) {
        return SYSTEM_AUTH_ROPERTIES
    }

    const param = {
        ...GET_PROPERTIES,
        ...config,
        data: payload
    }

    const { data } = await axios(param)

    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
