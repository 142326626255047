<template>
    <div class="team-management-panel__team-search-panel">
        <a-input-search
            v-model="searchText"
            placeholder="班组名称"
            @search="handleSearch"
        >
            <template #enterButton>
                <a-button>查询</a-button>
            </template>
        </a-input-search>

        <template v-if="showAppend">
            <a-button @click.stop="handleAppend">新建班组</a-button>
        </template>
        <custom-mutiple-modal ref="mutiple-modal" @on-ok="handleModalOk" />
    </div>
</template>
<script>
import { Input, Button } from 'ant-design-vue'
import CustomMutipleModal from '@/weights/CustomMutipleModal'

import { EVENT_TYPE_NORMAL, INTERFACE_TYPE_MODAL } from '@/constant/enum'

import eventMixin from '@/mixins/createPage/event'

export default {
    name: 'SearchPanel',
    components: {
        AButton: Button,
        AInputSearch: Input.Search,
        CustomMutipleModal
    },
    mixins: [eventMixin],
    props: {
        showAppend: Boolean,
        unitId: String,
        projectCode: String
    },
    data() {
        return {
            searchText: undefined
        }
    },
    methods: {
        handleSearch(text) {
            this.$emit('on-search', text)
        },
        handleAppend() {
            // 新建班组事件
            const event = {
                operationType: 20,
                eventType: EVENT_TYPE_NORMAL,
                interfaceType: INTERFACE_TYPE_MODAL,
                permissionCode: 'globalOperation',
                eventCode: 'team_10',
                eventName: '新建班组'
            }

            this.emitEvent({
                event,
                _params: {
                    affiliationUnitId: this.unitId,
                    projectCode: this.projectCode
                }
            })
        },
        handleModalOk() {
            this.$emit('on-after-append')
        }
    }
}
</script>
