import { SAFETY_MFG_URL, SAFETY_AUTH_URL } from '@config'

/**
 * 监管机构列表查询
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_KEY_PERSONNEL_LIST = {
    url: `${SAFETY_MFG_URL}/key_personnel/stakeholder/list`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 企业列表查询
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_ENTERPRISE_LIST = {
    url: `${SAFETY_MFG_URL}/enterprise/inside/list`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000
}

/**
 * 组织列表查询
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_ORG_LIST = {
    url: `${SAFETY_AUTH_URL}/org/list`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000
}


/**
 * 获取项目列表
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_PRO_LIST = {
    url: `${SAFETY_MFG_URL}/project/inside/getProjectSelectList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取总包单位列表
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_UNIT_LIST = {
    url: `${SAFETY_MFG_URL}/project/inside/getEnterpriseSelectList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取机构下拉选项
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_FINANCIAL_INSTITUTION_LIST = {
    url: `${SAFETY_MFG_URL}/financial_institution/active/list`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000
}

/**
 * 获取机构品牌下拉选项
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_FINANCIAL_INSTITUTION_BRAND_LIST = {
    url: `${SAFETY_MFG_URL}/financial_institution_brand/list`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000
}
