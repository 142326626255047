<template>
    <div class="page-layout">
        <!-- header -->
        <page-header
            v-if="showHeader"
            class="page-layout__header"
            :title="headerTitle"
            :breadcrumb="breadcrumb"
        />

        <!-- content -->
        <div class="page-layout__content">
            <div class="page-layout__inner">
                <template v-if="showLoading">
                    <a-spin :spinning="loading">
                        <slot />
                    </a-spin>
                </template>
                <template v-else>
                    <slot />
                </template>
            </div>
        </div>

        <custom-back-top />
    </div>
</template>

<script>
import { Spin as ASpin } from 'ant-design-vue'
import PageHeader from '@/components/PageHeader'
import CustomBackTop from '@/components/CustomBackTop'

export default {
    name: 'PageLayout',
    components: {
        ASpin,
        CustomBackTop,
        PageHeader
    },
    props: {
        showHeader: {
            type: Boolean,
            default: true
        },
        header: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        full: {
            type: Boolean,
            default: false
        },
        showLoading: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        route() {
            return this.$route
        },
        headerTitle() {
            const { title, route, showTitle } = this
            const { meta } = route
            const { title: metaTitle } = meta || {}
            return showTitle ? title || metaTitle || '' : ''
        },
        breadcrumb() {
            return this.$route.matched
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.page-layout {
    position: relative;

    &__content {
        box-sizing: border-box;
        padding: 16px 24px;
    }

    // &__inner {
    //     background: $component-background;
    // }

    &__page-title {
        width: 100%;
    }
}
</style>
