<template>
    <page-layout>
        <a-tabs v-model="activedTabKey">
            <template v-for="item in tabList">
                <a-tab-pane :key="item.type" :tab="item.label" />
            </template>
        </a-tabs>

        <div class="margin-utilization-detail-content">
            <component :is="activedTabKey" />
        </div>
    </page-layout>
</template>
<script>
import { Tabs as ATabs } from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'

const UTILIZATION_DETAIL_TYPE = 'UtilizationDetail'
const PAYMENT_DETAIL_TYPE = 'PaymentDetail'

const TAB_LIST = [
    {
        type: UTILIZATION_DETAIL_TYPE,
        label: '动用详情'
    },
    {
        type: PAYMENT_DETAIL_TYPE,
        label: '支付明细'
    }
]

const dynamicComponent = {
    [UTILIZATION_DETAIL_TYPE]: () => import('./UtilizationDetail'),
    [PAYMENT_DETAIL_TYPE]: () => import('./PaymentDetail')
}

export default {
    name: 'MarginUtilizationDetail',
    components: {
        ATabs,
        PageLayout,
        ATabPane: ATabs.TabPane,
        ...dynamicComponent
    },
    data() {
        return {
            activedTabKey: UTILIZATION_DETAIL_TYPE,
            tabList: Object.freeze(TAB_LIST)
        }
    }
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.margin-utilization-detail {
    &-content {
        .ant-card {
            &.has-margin-top {
                margin-top: $space-sm;
            }

            .ant-descriptions-item-label {
                width: $descriptions-item-label-width;
            }
        }
    }
}
</style>
