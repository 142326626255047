// TIP 菜单 `code`

/**
 * 首页
 * @namespace 首页模块 */
export const HOME_MANAGE_MENU_CODE = 'HOME_MANAGE_MENU_CODE'


/**
 * 项目管理
 * @namespace 项目管理模块 */
export const PROJECT_MANAGE_MENU_CODE = 'PROJECT_MANAGE_MENU_CODE'
/**
 * 项目管理 - 列表
 * @namespace 项目管理模块 */
export const PROJECT_MANAGE_CODE = 'project_40'
/**
 * 项目管理 - 详情
 * @namespace 项目管理模块 */
export const PROJECT_DETAIL_CODE = 'project_detail'
/**
 * 案件信息
 * @namespace 项目管理模块 */
export const CASE_INFO_MENU_CODE = 'CASE_INFO_MENU_CODE'
/**
 * 案件信息 - 列表
 * @namespace 项目管理模块 */
export const CASE_INFO_LIST_MENU_CODE = 'case_management_40'
/**
 * 案件信息 - 详情
 * @namespace 项目管理模块 */
export const CASE_INFO_DETAIL_MENU_CODE = 'case_info_detail_300'


/**
 * 申请管理
 * @namespace 申请管理模块 */
export const APPROVAL_MANAGEMENT_MENU_CODE = 'APPROVAL_MANAGEMENT_MENU_CODE'
/**
 * 专户注销申请
 * @namespace 申请管理模块 */
export const SPECIAL_ACCOUNT_CANCELLATION_APPLICATION_CODE = 'special_account_cancellation_application_40'
/**
 * 定额申请
 * @namespace 申请管理模块 */
export const QUATO_APPLYCATION_CODE = 'quato_apply_ent_40'
/**
 * 保证金注销申请
 * @namespace 申请管理模块 */
export const MARGENT_CANCELLATION_CODE = 'ent_margent_cancellation_40'
/**
 * 备案申请
 * @namespace 申请管理模块 */
export const FILLING_APPLICATION_CODE = 'ent_fillings_record_400'


/**
 * 保函管理
 * @namespace 保函管理模块 */
export const GUARANTEE_MANAGEMENT_MENU_CODE = 'GUARANTEE_MANAGEMENT_MENU_CODE'
/**
 * 订单管理
 * @namespace 保函管理模块 */
export const GUARANTEE_ORDER_CODE = 'order_ent_40'
/**
* 保函管理 - 列表
* @namespace 保函管理模块 */
export const GUARANTEE_LIST_CODE = 'guarantee_ent_40'


/**
 * 保证金管理
 * @namespace 保证金管理模块 */
export const MARGIN_MANAGEMENT_MENU_CODE = 'MARGIN_MANAGEMENT_MENU_CODE'
/**
 * 保证金缴纳（即保证金管理二级菜单项）
 * @namespace 保证金管理模块 */
export const MARGIN_PAYMENT_MENU_CODE = 'MARGIN_PAYMENT_MENU_CODE'
/**
 * 保证金缴纳 - 列表
 * @namespace 保证金管理模块 */
export const MARGIN_PAYMENT_LIST_CODE = 'margin_ent_40'
/**
 * 保证金缴纳 - 详情
 * @namespace 保证金管理模块 */
export const MARGIN_PAYMENT_DETAIL_CODE = 'MARGIN_MANAGEMENT_DETAIL_CODE'
/**
 * 保证金动用
 * @namespace 保证金管理模块 */
export const MARGIN_UTILIZATION_MENU_CODE = 'MARGIN_UTILIZATION_MENU_CODE'
/**
 * 保证金动用 - 列表
 * @namespace 保证金管理模块 */
export const MARGIN_UTILIZATION_LIST_CODE = 'bail_employ_info_40'
/**
 * 保证金动用 - 详情
 * @namespace 保证金管理模块 */
export const MARGIN_UTILIZATION_DETAIL_CODE = 'bail_employ_info_detail'
