<template>
    <div class="custom-descriptions-wrapper">
        <search-panel
            v-if="showSearchPanel"
            :query-criteria-list="searchPannel"
            @search="handleSearch"
        />
        <a-descriptions
            :title="title"
            :column="column"
            :colon="needColon"
            :bordered="bordered"
            :class="customClassName"
            class="custom-descriptions"
        >
            <template v-for="(item, index) in filteredList">
                <a-descriptions-item
                    :key="index"
                    :label="getDescriptionsLabel(item)"
                    :span="getDescriptionsSpan(item)"
                >
                    <readonly-item
                        v-bind="item"
                        is-description
                        :value="localData[item.attributeCode]"
                    />
                </a-descriptions-item>
            </template>
        </a-descriptions>
    </div>
</template>

<script>
import { Descriptions } from 'ant-design-vue'
import SearchPanel from './SearchPanel'
import ReadonlyItem from '@weights/CreateForm/components/ReadonlyItem'

import { isTrueBooleanPattern } from '@utils'
import { clearHiddenField } from '@utils/pageCreate'

import childrenRefreshMixin from '@mixins/createPage/childrenRefresh'
import getFormData from '@mixins/createPage/formData'
import eventMixin from '@mixins/createPage/event'

import {
    FIELD_TYPE_EXIST,
    FIELD_TYPE_SHOW,
    FIELD_TYPE_HIDDEN_FORM,

    QUERY_STATE_GLOBAL,
    QUERY_STATE_HIDDEN,
    QUERY_STATE_OPTIONAL,

    EVENT_TYPE_DEFAULT,

    BOOLEAN_PATTERN_TRUE
} from '@constant/enum'

const ALIGN_LEFT_TYPE = 1
const ALIGN_RIGHT_TYPE = 2
const ALIGN_MAPPING = {
    [ALIGN_LEFT_TYPE]: 'left',
    [ALIGN_RIGHT_TYPE]: 'right'
}

export default {
    name: 'CustomDescriptions',
    components: {
        SearchPanel,
        ReadonlyItem,
        ADescriptions: Descriptions,
        ADescriptionsItem: Descriptions.Item
    },
    mixins: [childrenRefreshMixin, getFormData, eventMixin],
    props: {
        // 展示边框
        needBordered: {
            type: Number,
            default: BOOLEAN_PATTERN_TRUE
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default: ''
        },
        fieldList: {
            type: Array,
            default: () => []
        },
        column: {
            type: Number,
            default: 2
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        colon: {
            type: Number,
            default: BOOLEAN_PATTERN_TRUE
        },
        align: {
            type: Number,
            default: ALIGN_RIGHT_TYPE
        },
        fixed: {
            type: Number,
            default: BOOLEAN_PATTERN_TRUE
        }
    },
    data() {
        return {
            localData: {},
            searchParams: {},
            localFieldList: []
        }
    },
    computed: {
        bordered() {
            return isTrueBooleanPattern(this.needBordered)
        },
        needColon() {
            return isTrueBooleanPattern(this.colon)
        },
        fixedState() {
            return isTrueBooleanPattern(this.fixed)
        },
        customClassName() {
            if (!this.bordered) return
            return [
                'custom-descriptions--' + ALIGN_MAPPING[this.align],
                {
                    'custom-descriptions--fixed': this.fixed
                }
            ]
        },
        list() {
            const { localFieldList } = this
            return clearHiddenField(localFieldList)
        },
        filteredList() {
            return this.list.filter(item => this.getFieldVisible(item, this.localData))
        },
        // 更新事件
        refreshEvent() {
            let event = null
            this.eventList.forEach(item => {
                if (item.eventType === EVENT_TYPE_DEFAULT) {
                    event = item
                }
            })
            return event
        },
        // 请求参数
        queryCriteriaList() {
            const { refreshEvent } = this

            return refreshEvent?.queryCriteriaList || []
        },
        showSearchPanel() {
            const { searchPannel } = this
            return searchPannel && searchPannel.length
        },
        searchPannel() {
            const { queryCriteriaList } = this
            const searchPannel = queryCriteriaList.filter(
                item => item.queryState !== QUERY_STATE_HIDDEN &&
                    item.queryState !== QUERY_STATE_OPTIONAL && item.queryState !== QUERY_STATE_GLOBAL
            )
            return searchPannel
        }
    },
    watch: {
        data: {
            handler(val) {
                if (val) {
                    this.localData = val
                }
            },
            immediate: true,
            deep: true
        },
        fieldList: {
            handler(val) {
                if (val) {
                    this.localFieldList = val
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        /**
         * 获取列表数据
         */
        async getData() {
            try {
                const { refreshEvent, queryCriteriaList } = this

                if (refreshEvent) {
                    // 整合参数
                    const globalConfig = this.getGlobalParams(
                        queryCriteriaList
                    )
                    const params = {
                        ...globalConfig,
                        ...this.searchParams
                    }

                    // 请求目标事件
                    const { result: eventResult } =
                        await this.triggerEventServer(refreshEvent, params)

                    const dataString = JSON.parse(eventResult.data.dataString)

                    // 设置本地数据
                    this.localData = dataString
                    this.localFieldList = eventResult.data.fieldList

                    this.cardDataRefresh?.(dataString)
                    return dataString
                } else {
                    this.$message.error('没有找到对应更新事件')
                }
            } catch (error) {
                console.log(error)
                this.$message.error(error.message)
            }
        },
        async fieldDataRefresh(data) {
            let res = {}
            if (data) {
                // 组件本身的更新逻辑
                this.localData = data
            } else {
                // 组件本身的更新逻辑
                res = await this.getData()
            }

            // 子节点更新逻辑
            this.childrenFieldRefresh(data)
            return res
        },
        getDescriptionsLabel({ fieldName }) {
            if (!this.bordered) return fieldName
            return this.needColon ? fieldName + ':' : fieldName
        },
        getDescriptionsSpan({ span }) {
            const { column } = this
            return parseInt(column / (span || column), 10)
        },
        getFieldVisible(field, data) {
            // 隐藏值为0且存在内容展示字段
            if (
                field.fieldType === FIELD_TYPE_EXIST &&
                    data[field.attributeCode] && +data[field.attributeCode] !== 0 ||
                field.fieldType === FIELD_TYPE_SHOW
            ) {
                return true
            }
            return false
        },
        handleSearch(params) {
            this.searchParams = params
            this.getData()
        },
        getFormData() {
            const { localData } = this
            const res = {}
            this.localFieldList.forEach(field => {
                const code = field.fieldCode
                // fieldType = 4 不展示但是要返回
                if (
                    localData[field.attributeCode] &&
                    field.fieldType === FIELD_TYPE_HIDDEN_FORM
                ) {
                    res[code] = localData[field.attributeCode]
                }
            })

            return res
        }
    },
    inject: {
        cardDataRefresh: {
            default: () => {}
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.custom-descriptions {
    margin-bottom: 8px;

    .ant-descriptions-item-label {
        color: #646566;
    }

    &.ant-descriptions-bordered {
        .ant-descriptions-view > table {
            table-layout: fixed;
        }
    }

    &--right {
        &.ant-descriptions-bordered {
            .ant-descriptions-item-label {
                text-align: right;
            }
        }
    }

    &--fixed {
        &.ant-descriptions-bordered {
            .ant-descriptions-item-label {
                width: $descriptions-item-label-width;
            }
        }
    }
}
</style>
