/**
 * 项目资料
 */
import BasicLayout from '@layout/BasicLayout'
import PageTemplate from '@/weights/PageTemplate/Page'
// import DetailTemplate from '@/weights/PageTemplate/Detail'

import {
    QUATO_APPLYCATION_CODE,
    FILLING_APPLICATION_CODE,
    MARGENT_CANCELLATION_CODE,
    APPROVAL_MANAGEMENT_MENU_CODE,
    SPECIAL_ACCOUNT_CANCELLATION_APPLICATION_CODE
} from '@constant/menu_code'

import { APPROVAL_MANAGE_MENU_SORT } from '@constant/menu_sort'

const routes = [
    {
        path: '/approval',
        name: 'Approval',
        component: BasicLayout,
        code: APPROVAL_MANAGEMENT_MENU_CODE,
        redirect: {
            name: SPECIAL_ACCOUNT_CANCELLATION_APPLICATION_CODE
        },
        meta: {
            title: '申请管理',
            icon: 'shenpiguanli',
            iconType: 'iconfont'
        },
        sort: APPROVAL_MANAGE_MENU_SORT,
        children: [
            {
                path: 'quota_application',
                name: QUATO_APPLYCATION_CODE,
                component: PageTemplate,
                code: QUATO_APPLYCATION_CODE,
                meta: {
                    title: '定额申请',
                    layoutCode: QUATO_APPLYCATION_CODE
                }
            },
            {
                path: 'filling_application',
                name: FILLING_APPLICATION_CODE,
                component: PageTemplate,
                code: FILLING_APPLICATION_CODE,
                meta: {
                    title: '备案申请',
                    layoutCode: FILLING_APPLICATION_CODE
                }
            },
            {
                path: 'margent_cancellation',
                name: MARGENT_CANCELLATION_CODE,
                component: PageTemplate,
                code: MARGENT_CANCELLATION_CODE,
                meta: {
                    title: '保证金注销申请',
                    layoutCode: MARGENT_CANCELLATION_CODE
                }
            },
            {
                path: 'special_account_cancellation_application',
                name: SPECIAL_ACCOUNT_CANCELLATION_APPLICATION_CODE,
                component: PageTemplate,
                code: SPECIAL_ACCOUNT_CANCELLATION_APPLICATION_CODE,
                meta: {
                    title: '专户注销申请',
                    layoutCode: SPECIAL_ACCOUNT_CANCELLATION_APPLICATION_CODE
                },
                sort: 3
            }
        ]
    }
]

export default routes


