<template>
    <a-modal
        centered
        destroy-on-close
        class="custom-modal"
        :title="title"
        :width="width"
        :footer="footer"
        :visible="visible"
        :mask-closable="maskClosable"
        :body-style="innerBodyStyle"
        v-bind="$attrs"
        @cancel="handleCancel"
    >
        <template slot="footer">
            <slot name="customFooter">
                <a-button key="reset" @click="handleCancel">
                    {{ cancelText }}
                </a-button>

                <template v-if="showOkBtn">
                    <template v-if="needConfirmation">
                        <popconfirm-button
                            ok-text="确定"
                            placement="top"
                            cancel-text="取消"
                            button-key="submit"
                            :loading="loading"
                            :button-text="okText"
                            :button-type="okType"
                            @on-confirm="handleOk"
                        >
                            <template slot="title">
                                <p>{{ confirmMessage || '确认要执行此操作吗？' }}</p>
                            </template>
                        </popconfirm-button>
                    </template>

                    <template v-else>
                        <a-button
                            key="submit"
                            :type="okType"
                            :loading="loading"
                            @click="handleOk"
                        >
                            {{ okText }}
                        </a-button>
                    </template>
                </template>
            </slot>
        </template>

        <slot />
    </a-modal>
</template>

<script>
import { Modal as AModal, Button as AButton } from 'ant-design-vue'
import PopconfirmButton from '@components/PopconfirmButton'

import { isNull } from '@utils'

export default {
    name: 'CustomModal',
    components: {
        AModal,
        AButton,
        PopconfirmButton
    },
    props: {
        footer: [String, Object],
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: [Number, String],
            default: 520
        },
        okType: {
            type: String,
            default: 'primary'
        },
        okText: {
            type: String,
            default: '确认'
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        needConfirmation: {
            type: Boolean,
            default: false
        },
        confirmMessage: {
            type: String,
            default: ''
        },
        showOkBtn: {
            type: Boolean,
            default: true
        },
        bodyStyle: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            maskClosable: false
        }
    },
    computed: {
        innerBodyStyle() {
            let clientHeight = document.body.clientHeight

            clientHeight -= 40

            // ant-modal header 高度 55px
            clientHeight -= 55

            // ant-modal footer 高度 53px
            if (!isNull(this.footer)) {
                clientHeight -= 53
            }

            if (clientHeight < 400) {
                clientHeight = 400
            }

            return {

                maxHeight: clientHeight + 'px',
                overflowY: 'auto',
                ...this.bodyStyle
            }
        }
    },
    methods: {
        // 提交按钮事件
        handleOk() {
            this.$emit('on-ok')
        },
        // 关闭弹框
        handleCancel() {
            this.$emit('on-close')
        }
    }
}
</script>

<style lang="scss">
.custom-modal {

    .ant-modal-header {
        background: #f7f8fa;
    }

    .ant-modal-footer {
        display: flex;
        justify-content: flex-end;
        text-align: center;
        background-color: #fdfdfe;
    }
}
</style>
