export default {
    props: {
        childrenField: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        childrenFieldRefresh(data) {
            const fApi = this.$store.getters.pageFApi
            // 子组件的更新逻辑
            this.childrenField.forEach(feild => {
                const vm = fApi.el(feild)
                if (vm && vm.fieldDataRefresh) {
                    vm.fieldDataRefresh(data)
                }
            })
        }
    }
}
