import Cookies from 'js-cookie'
import { TOKEN_KEY, TOKEN_GBT_KEY, TOKEN_TIME, REFRESH_TOKEN_KEY } from '@config'

/**
 * 获取token
 * @returns {*}
 */
export function getToken() {
    return Cookies.get(TOKEN_KEY)
}
export function getRefreshToken() {
    return Cookies.get(REFRESH_TOKEN_KEY)
}

/**
 * 设置token
 * @param token
 * @returns {*}
 */
export function setToken(token) {
    return Cookies.set(TOKEN_KEY, token, {
        expires: TOKEN_TIME
    })
}
export function setRefreshToken(token) {
    return Cookies.set(REFRESH_TOKEN_KEY, token, {
        expires: TOKEN_TIME
    })
}
// 工保通 token
export function getGbtToken() {
    return Cookies.get(TOKEN_GBT_KEY)
}
export function setGbtToken(token) {
    return Cookies.set(TOKEN_GBT_KEY, token, {
        expires: TOKEN_TIME
    })
}
/**
 * 删除token
 * @returns {*}
 */
export function removeToken() {
    Cookies.remove(TOKEN_KEY)
    Cookies.remove(TOKEN_GBT_KEY)
    Cookies.remove(REFRESH_TOKEN_KEY)
}
