<template>
    <a-select
        v-model="modelValue"
        v-bind="$attrs"
        :disabled="disabled"
        :placeholder="placeholder"
        :mode="mode"
        @blur="handleBlur"
    >
        <a-select-option v-for="item in showList" :key="item.dataValue" :disabled="item.disabled">
            {{ item.dataName }}
        </a-select-option>
    </a-select>
</template>

<script>
import { Select } from 'ant-design-vue'
import getDictListService from '@service/getDictListService'
import getDictListByTagService from '@service/getDictListByTagService'

import { isTrueBooleanPattern } from '@utils'

const dictStringField = {
    hiddenDangerLevel: 'hiddenDangerLevelText'
}

export default {
    name: 'DictSelect',
    components: {
        ASelect: Select,
        ASelectOption: Select.Option
    },
    props: {
        value: {
            // type: String
            type: [Number, String]
        },
        fieldCode: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'default'
        },
        dataTag: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            list: [],
            disabledValues: []
        }
    },
    computed: {
        modelValue: {
            get() {
                // 多选参数转换
                if (this.value && this.mode === 'multiple') {
                    if (!Array.isArray(this.value)) {
                        return this.value.split('|')
                    }

                    return this.value
                }
                return String(this.value ?? '') || undefined
            },
            set(value) {
                let v = value


                // 多选参数转化
                if (Array.isArray(v) && this.mode === 'multiple') {
                    v = v.join('|')
                }
                this.$emit('input', v)
                this.$emit('change', v)
                if (dictStringField[this.fieldCode]) {
                    const label = this.getLabelByValue(v)
                    this.$emit(
                        'add-field',
                        dictStringField[this.fieldCode],
                        label
                    )
                }
                this.$emit('blur')
            }
        },
        showList() {
            const { list, disabledValues } = this
            return list.map(item => {
                if (disabledValues.findIndex(disabledValue => disabledValue === item.dataValue) > -1) {
                    item.disabled = true
                }
                return item
            })
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        async getList(params = {}) {
            try {
                const { dataTag } = this
                let list = []

                if (dataTag) {
                    list = await getDictListByTagService({
                        dataTag,
                        dataType: this.fieldCode,
                        ...params
                    })
                } else {
                    list = await getDictListService({
                        dataType: this.fieldCode,
                        ...params
                    })
                }
                // 只获取激活的枚举
                this.list = list.filter(item => isTrueBooleanPattern(item.state))
            } catch (e) {
                console.log('-> e', e)
            }
        },
        getLabelByValue(value) {
            let label = ''
            this.list.forEach(item => {
                if (item.dataValue === value) {
                    label = item.dataName
                }
            })

            return label
        },
        setDisabled(values) {
            this.disabledValues = values
        },
        setSelectValue({ value }) {
            this.modelValue = value
        }
    }
}
</script>

<style lang="scss"></style>
