<template>
    <a-config-provider :locale="zh_CN">
        <div id="app">
            <router-view v-if="isRouterAlive"/>
        </div>
        <template slot="renderEmpty">
            <custom-no-data text="暂无数据" />
        </template>
    </a-config-provider>
</template>
<script>
/* eslint-disable camelcase */
import { ConfigProvider as AConfigProvider } from 'ant-design-vue'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import CustomNoData from './components/CustomNoData/index.vue'

import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

export default {
    name: 'App',
    provide() {
        return {
            reloadPage: this.reload
        }
    },
    components: {
        AConfigProvider,
        CustomNoData
    },
    data() {
        return {
            zh_CN,
            isRouterAlive: true
        }
    },
    methods: {
        /**
         * 重新加载页面
         */
        reload() {
            this.isRouterAlive = false
            this.$nextTick(() => {
                this.isRouterAlive = true
            })
        }
    }
}
</script>
