import { SAFETY_MFG_URL, SAFETY_AUTH_URL } from '@config'

/** 基础查询接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/* 获取项目列表*/
export const GET_PROJECT_LIST = {
    url: `${SAFETY_MFG_URL}/project/list`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000
}

export const GET_PROJECT_LIST_BY_SEARCH = {
    url: `${SAFETY_MFG_URL}/project/supervision/list`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000
}

/* 获取项目列表 */
export const DELETE_PROJECT_FILE = {
    url: `${SAFETY_MFG_URL}/project_file/inside/delete`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/* 获取工作台数据 */
export const GET_WORKBENCH_INFO = {
    url: `${SAFETY_MFG_URL}/workbench-information/inside/compose/list`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000
}


/* 获取项目列表*/
export const GET_ENTERPRISE = {
    url: `${SAFETY_AUTH_URL}/ent-auth/getEnterprise`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
