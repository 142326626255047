const DYNAMIC_COMPONENTS = {
    ApprovalFillingApplicationModal: () => import('@weights/directConnectModals/approval/FillingApplicationModal'),
    ProjectChooseProjectModal: () => import('@weights/directConnectModals/project/ChooseProjectModal')
}

// 备案申请详情
const FILLING_APPLICATION_DETAILS = 'ent_fillings_record_detail'
// 项目列表-选择项目
const CHOOSE_PROJECT = 'project_choose_10'

const HANDLE_OPEN_MODAL_STRATEGY = {
    // 备案申请详情
    [FILLING_APPLICATION_DETAILS](event, record) {
        this.directConnectModalComponent = 'ApprovalFillingApplicationModal'

        this.changeDirectConnectModalProp(true, {
            record,
            isDetailModal: true
        })
    },
    // 选择项目
    [CHOOSE_PROJECT](event, record) {
        this.directConnectModalComponent = 'ProjectChooseProjectModal'

        this.changeDirectConnectModalProp(true, {
            record
        })
    }
}

export default {
    components: {
        ...DYNAMIC_COMPONENTS
    },
    data() {
        return {
            // 直连弹框组件
            directConnectModalComponent: undefined,
            // 直连弹框组件传参
            directConnectModalProp: {
                visible: false
            }
        }
    },
    methods: {
        // 打开直连弹框组件
        handleOpenDirectConnectModal(event, localData, params) {
            const eventCode = event.eventCode
            HANDLE_OPEN_MODAL_STRATEGY[eventCode].call(this, event, localData, params)
        },
        // 关闭直连弹框组件
        handleCloseDirectConnectModal() {
            this.$set(this.directConnectModalProp, 'visible', false)
        },
        // 更改直连弹框组件传参值
        changeDirectConnectModalProp(visible, extraProp = {}) {
            this.directConnectModalProp = {
                visible,
                ...extraProp
            }
        }
    }
}
