<template>
    <a-select
        v-model="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        @select="handleSelect"
        @blur="handleBlur"
    >
        <a-select-option v-for="item in list" :key="item.name">
            {{ item.name }}
        </a-select-option>
    </a-select>
</template>

<script>
import { Select } from 'ant-design-vue'

import getMemberListService from '@service/teamManagement/getMemberListService'

export default {
    name: 'EnterpriseSelect',
    components: {
        ASelect: Select,
        ASelectOption: Select.Option
    },
    props: {
        value: {
            // type: String
            type: [Number, String]
        },
        fieldCode: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            list: []
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(v) {
                console.log(v)
                this.$emit('input', v)
                this.$emit('blur')
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        async getList() {
            try {
                const params = {
                    projectCode: this.data.projectCode,
                    personnelType: 1
                }
                const list = await getMemberListService(params)
                this.$set(this, 'list', list)
                // this.list = list
            } catch (e) {
                console.log('-> e', e)
            }
        },
        handleSelect(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style lang="scss"></style>
