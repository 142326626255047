import getConfig from './config'
import mergeProps from '@form-create/utils/lib/mergeprops'
import is, { hasProperty } from '@form-create/utils/lib/type'
import extend from '@form-create/utils/lib/extend'

function isTooltip(info) {
    return info.type === 'tooltip'
}

function tidy(props, name) {
    if (!hasProperty(props, name)) return
    if (is.String(props[name])) {
        props[name] = {
            [name]: props[name],
            show: true
        }
    }
}

function isFalse(val) {
    return val === false
}

function tidyBool(opt, name) {
    if (hasProperty(opt, name) && !is.Object(opt[name])) {
        opt[name] = {
            show: !!opt[name]
        }
    }
}

export default {
    validate(call) {
        this.form().validate(call)
    },
    validateField(field, call) {
        this.form().validateField(field, call)
    },
    clearValidateState(ctx) {
        const fItem = this.vm.$refs[ctx.wrapRef]
        if (fItem) {
            fItem.validateMessage = ''
            fItem.validateState = ''
        }
    },
    tidyOptions(options) {
        ['info', 'wrap'].forEach(name => {
            tidyBool(options, name)
        })
        return options
    },
    tidyRule({ prop }) {
        tidy(prop, 'title')
        tidy(prop, 'info')
        return prop
    },
    mergeProp(ctx) {
        ctx.prop = mergeProps(
            [
                {
                    info: this.options.info || {},
                    wrap: this.options.wrap || {},
                    col: this.options.col || {}
                },
                ctx.prop
            ],
            {
                info: {
                    type: 'popover',
                    placement: 'topLeft',
                    icon: 'question-circle-o'
                },
                title: {},
                col: {
                    span: 24
                },
                wrap: {}
            },
            {
                normal: [
                    'title', 'info', 'col', 'wrap'
                ]
            }
        )
    },
    getDefaultOptions() {
        return getConfig()
    },
    update() {
        const form = this.options.form
        this.rule = {
            props: {
                ...form
            },

            class: [form.className, form.class, 'form-create'],
            style: form.style,
            type: 'page'
        }
    },
    beforeRender() {
        const { key, ref, $handle } = this
        extend(this.rule, {
            key,
            ref
        })
        extend(this.rule.props, {
            model: $handle.formData,
            rules: $handle.validate()
        })
    },
    render(children) {
        return this.$r(this.rule, children)
    },
    makeWrap(ctx, children) {
        return children
    },
    makeInfo(rule, uni) {
        const titleProp = rule.title
        const infoProp = rule.info
        if (!titleProp.title && !titleProp.native || isFalse(titleProp.show))
            return
        const isTool = isTooltip(infoProp)
        const children = [titleProp.title]

        const titleFn = pop => this.$r(
            mergeProps([
                titleProp,
                {
                    props: titleProp,
                    slot: titleProp.slot || (pop ? 'default' : 'label'),
                    key: `${uni}tit`,
                    type: titleProp.type || 'span'
                }
            ]),
            children
        )

        if (!isFalse(infoProp.show) && (infoProp.info || infoProp.native)) {
            if (infoProp.icon !== false) {
                children[infoProp.align !== 'left' ? 'unshift' : 'push'](
                    this.$r({
                        type: 'icon',
                        props: {
                            type:
                                infoProp.icon === true
                                    ? 'question-circle-o'
                                    : infoProp.icon
                        },
                        key: `${uni}i`
                    })
                )
            }
            const prop = {
                type: infoProp.type || 'popover',
                props: {
                    ...infoProp
                },
                key: `${uni}pop`,
                slot: 'label'
            }

            const field = isTool ? 'title' : 'content'
            if (infoProp.info && !hasProperty(prop.props, field)) {
                prop.props[field] = infoProp.info
            }

            return this.$r(mergeProps([infoProp, prop]), [titleFn(true)])
        }
        return titleFn()
    },
    makeCol(rule, uni, children) {
        const col = rule.col
        return this.$r(
            {
                class: col.class,
                type: 'col',
                props: col || {
                    span: 24
                },
                key: `${uni}col`
            },
            children
        )
    },
    makeRow(children) {
        const row = this.options.row || {}
        return this.$r(
            {
                type: 'row',
                props: row,
                class: row.class,
                key: `${this.key}row`
            },
            children
        )
    }
}
