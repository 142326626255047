<template>
    <div class="custom-viewer">
        <viewer
            ref="viewer"
            :options="viewerOptions"
            :images="images"
            class="image-viewer"
            @inited="inited"
        >
            <template #default="scope">
                <img
                    v-for="src in scope.images"
                    :key="src[options.key || defaultOptions.key]"
                    :src="getRelativeAgreementUrl(src[options.url || defaultOptions.url])"
                >
            </template>
        </viewer>
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from 'v-viewer'
import { getRelativeAgreementUrl } from '@utils'

export default {
    name: 'CustomViewer',
    components: {
        Viewer
    },
    props: {
        images: {
            type: Array,
            default: () => []
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            defaultOptions: {
                url: 'url',
                key: 'url'
            }
        }
    },
    computed: {
        viewerOptions() {
            if (this.images.length > 1) {
                // 图片数量大于1的话 显示navbar
                return {
                    navbar: true,
                    title: false,
                    toolbar: false,
                    rotatable: false,
                    scalable: false,
                    keyboard: false
                }
            } else {
                return {
                    navbar: false,
                    title: false,
                    toolbar: false,
                    zoomable: true,
                    rotatable: false,
                    scalable: false,
                    keyboard: false
                }
            }
        }
    },
    methods: {
        inited(viewer) {
            this.$viewer = viewer
        },
        showViewer() {
            this.$viewer.show()
        },
        getRelativeAgreementUrl(url) {
            return getRelativeAgreementUrl(url)
        }
    }
}
</script>

<style lang="scss">
.custom-viewer {
    .image-viewer {
        img {
            display: none;
        }
    }
}
.viewer-zoom-in {
    display: none;
}
.viewer-zoom-out {
    display: none;
}
.viewer-one-to-one {
    display: none;
}
.viewer-reset {
    display: none;
}
.viewer-play {
    display: none;
}
.viewer-prev {
    margin-right: 10px;
}
.viewer-next {
    margin-left: 10px !important;
}
</style>
