<template>
    <div class="image-view">
        <span v-if="!imageList.length">{{ emptyValuePlaceholder }}</span>

        <template v-else>
            <div class="image-view__list">
                <template v-for="img in imageList">
                    <img
                        :key="img.fileId"
                        :src="getRelativeAgreementUrl(img.fileUrl)"
                        @click="handleClick"
                    >
                </template>
            </div>
        </template>

        <custom-view ref="view" :images="imageList" :options="viewOption" />
    </div>
</template>

<script>
import CustomView from '@/components/CustomViewer'

import { EMPTY_VALUE_PLACEHOLDER } from '@config'
import { getRelativeAgreementUrl } from '@utils'

export default {
    name: 'ImageView',
    components: {
        CustomView
    },
    props: {
        imageList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            emptyValuePlaceholder: EMPTY_VALUE_PLACEHOLDER,

            viewOption: {
                url: 'fileUrl',
                key: 'fileId'
            }
        }
    },
    methods: {
        handleClick() {
            this.$refs['view'].showViewer()
        },
        getRelativeAgreementUrl(url) {
            return getRelativeAgreementUrl(url)
        }
    }
}
</script>

<style lang="scss" scoped>
.image-view {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -15px;

        img {
            width: 80px;
            height: 80px;
            margin-top: 15px;
            margin-right: 15px;
        }
    }
}
</style>
