import axios from './index'
import { DELETE_PROJECT_FILE } from './api'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

/**
 * 删除项目文件
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function deleteProjectFileService(
    payload = {},
    config = {}
) {
    const param = {
        ...DELETE_PROJECT_FILE,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.data
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
