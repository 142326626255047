export function filterParams(obj) {
    let _newPar = {}

    for (let key in obj) {
        let _val = obj[key]

        // 如果对象属性的值不为空，就保存该属性
        if ((_val === 0 || _val === false || _val) && _val.toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
            _newPar[key] = _val
        }
    }

    return _newPar
}
