<template>
    <div>
        <!-- <button @click="handleClick">获取数据</button> -->
        <!-- <button @click="handleVaild">数据校验</button> -->
        <form-create v-model="fApi" :rule="localRule" :option="options" />
    </div>
</template>

<script>
// import Vue from 'vue'
import formCreate from './antd'
import { FormModel } from 'ant-design-vue'
import CustomCard from '../CustomCard'
import CustomPage from '../CustomPage'
import CustomList from '../CustomList'
import CustomDescriptionList from '../CustomDescriptionList'

import CustomDescriptions from './components/CustomDiscription'
import CustomTable from './components/CustomTable'
import FormCreateComponents from './components/CustomForm'
import CustomTabs from './components/CustomTabs'
import CustomStep from '../CustomStep'
import CustomSearchTree from '../CustomSearchTree'
import CustomFileUploadList from './components/CustomFileUploadList'
import CustomFileUploadDescriptionList from './components/CustomFileUploadDescriptionList'


import TeamManagementPanel from '../TeamManagementPanel'

import getFormData from '@/mixins/createPage/formData'
import queryMixin from '@/mixins/createPage/query'

import { parserPanel } from './parse'
import BatchImport from '../BatchImport'

// Vue.use(formCreate)
formCreate.component('CustomTable', CustomTable)
formCreate.component('CustomDescriptions', CustomDescriptions)
formCreate.component('CustomCard', CustomCard)
formCreate.component('CustomTabs', CustomTabs)
formCreate.component('CustomStep', CustomStep)
formCreate.component('CustomSearchTree', CustomSearchTree)
formCreate.component('CustomPage', CustomPage)
formCreate.component('AFormModel', FormModel)
formCreate.component('FormCreate', FormCreateComponents)
formCreate.component('CustomDescriptionList', CustomDescriptionList)
formCreate.component('CustomList', CustomList)
formCreate.component('BatchImport', BatchImport)
formCreate.component('TeamManagement', TeamManagementPanel)
formCreate.component('FileUploadList', CustomFileUploadList)
formCreate.component('FileUploadDescriptionList', CustomFileUploadDescriptionList)
export default {
    name: 'CreatePage',
    components: {
        FormCreate: formCreate.$form()
    },
    mixins: [getFormData, queryMixin],
    props: {
        rule: {
            type: Array,
            default: () => []
        },
        isGlobalFApi: {
            type: Boolean,
            default: false
        },
        template: {
            type: Object,
            default: () => ({})
        },
        isRoot: {
            type: Boolean,
            default: false
        },
        rootType: {
            type: String
        },
        nowQueryList: {
            type: Array,
            default: () => []
        },
        globalParams: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            options: {
                nowQueryList: [],
                globalParams: {},
                childrenField: []
            },
            fApi: {},
            localRule: [],
            childrenField: []
        }
    },
    watch: {
        isGlobalFApi: {
            handler(val) {
                if (val) {
                    setTimeout(() => {
                        this.$store.dispatch('addPageFApi', this.fApi)
                    }, 10)
                }
            },
            immediate: true
        },
        isRoot: {
            handler(val) {
                this.options.isRoot = val
            },
            immediate: true
        },
        rootType: {
            handler(val) {
                this.options.rootType = val
            },
            immediate: true
        },
        globalParams: {
            handler(val) {
                this.options.globalParams = val
            },
            immediate: true
        },
        template: {
            handler(template) {
                if (template && template.layoutCode) {
                    const { globalQuery, nowQueryList } = this.getGlobalQuery(template, this.globalParams)

                    this.options.nowQueryList = nowQueryList

                    const rule = parserPanel(template, globalQuery, this.globalParams)
                    this.localRule = [rule]
                    this.childrenField = rule.props?.childrenField || [rule.field]
                }
            },
            immediate: true
        },
        rule: {
            handler(rule) {
                if (Array.isArray(rule) && rule.length > 0) {
                    this.localRule = rule
                    this.childrenField = rule[0].props.childrenField || [rule[0].field]
                }
            },
            deep: true,
            immediate: true
        },
        nowQueryList: {
            handler(nowQueryList) {
                this.options.nowQueryList = nowQueryList
            },
            immediate: true
        },
        childrenField: {
            handler(childrenField) {
                this.options.childrenField = childrenField
            },
            immediate: true
        }
    },
    methods: {
        handleClick() {
            const data = this.getChildrenFormData(this.fApi, this.childrenField)
            console.log(data, 'handleClick')
        },
        changePageFApi() {
            if (this.isGlobalFApi) {
                setTimeout(() => {
                    this.$store.dispatch('addPageFApi', this.fApi)
                }, 10)
            }
        },
        childrenFieldRefresh(data) {
            const fApi = this.$store.getters.pageFApi
            // 子组件的更新逻辑
            this.childrenField.forEach(feild => {
                const vm = fApi.el(feild)
                if (vm && vm.fieldDataRefresh) {
                    vm.fieldDataRefresh(data)
                }
            })
        },
        handleVaild() {
            const data = this.vaildatorFromData(this.fApi, this.childrenField)
            console.log(data, 'handleClick')
        }

    }
}
</script>

<style></style>
