<script>
import initData from '@mixins/initData'

import { CLEAR_PAGE_F_API } from '@store/mutation-types'

export default {
    name: 'PageTemplate',
    components: {},
    mixins: [initData],
    props: {
        templateData: {
            type: Object
        },
        layoutCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    watch: {
        layoutCode: {
            handler(layoutCode) {
                if (layoutCode) {
                    // 清空过去的内容
                    this.template = {}
                    this.$store.commit(CLEAR_PAGE_F_API)

                    this.getLayout(layoutCode)
                }
            },
            immediate: true
        },
        templateData: {
            handler(templateData) {
                if (templateData) {
                    // NOTICE: Here's local mock operation!
                    this.template = templateData
                    this.loading = false
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>
