import { calculator } from '4d-space-bag'

import { EMPTY_VALUE_PLACEHOLDER } from '@config'

import { isEmpty, isString, thousandBit } from '@utils'
import formatDate from '@utils/formatDate'

/**
 * @description 对 `比例` 的格式处理（过滤器）
 *
 * 如果 `val` 为空值，返回 `EMPTY_VALUE_PLACEHOLDER` 配置值
 *
 * 如果 `val` 不可以转为非 `NaN` 值，则原样返回
 *
 * 如果 `val` 可以转为非 `NaN` 值，做完精度处理后加上 `'%'` 即返回
 *
 * @param {*} val 原始值
 *
 * @param {boolean} needMulHundred 是否需要做乘以 `100` 的处理，默认值为 `true`
 *
 * @example 当 needMulHundred != false 时
 * 0.2552 => '25.52%'
 *
 * @example 当 needMulHundred = false 时
 * 25.52 => '25.52%'
 *
 * @returns 最终展示的值
 */
export function ratioFilter(val, needMulHundred = true) {
    if (isEmpty(val)) return EMPTY_VALUE_PLACEHOLDER
    if (isNaN(val)) return val

    const toPercentage = val => (+val).toFixed(2) + '%'
    return needMulHundred ? toPercentage(calculator.mul(val, 100)) : toPercentage(val)
}

/**
 * @description 对 `日期` 的格式处理（过滤器）
 *
 * 当 `format` 为日期格式时，配置请参考 `moment.js`
 *
 * 当 `format` 为日期格式类型时，配置参考如下：
 *
 * `'all' => 'YYYY-MM-DD HH:mm:ss'`
 *
 * `'cnAll' => 'YYYY年MM月DD日 HH:mm:ss'`
 *
 * `'time' => 'HH:mm:ss'`
 *
 * `'YM' => 'YYYY-MM'`
 *
 * `'MD' => 'MM-DD'`
 *
 * `'Hm' => 'HH:mm'`
 *
 * `'MDHm' => 'MM-DD HH:mm'`
 *
 * `'MDHms' => 'MM-DD HH:mm:ss'`
 *
 * `'DHm' => 'DD HH:mm'`
 *
 * `'DHms' => 'DD HH:mm:ss'`
 *
 * `'YMDHm' => 'YYYY-MM-DD HH:mm'`
 *
 * `'default' => 'YYYY-MM-DD'`
 *
 * 如果 `val` 为空值，格式化为 `EMPTY_VALUE_PLACEHOLDER` 中配置的值
 *
 * 如果 `val` 为非空值，调用 `formatDate` 方法进行日期格式处理
 *
 * @param {*} val 原始值
 *
 * @param {string} format 日期格式/日期格式类型
 *
 * @returns 最终展示的值
 */
export function timeFilter(val, format) {
    return isEmpty(val) ? EMPTY_VALUE_PLACEHOLDER : formatDate(val, format)
}

/**
 * @description 对 `日期/日期时间区间字符串` 的格式处理（过滤器）
 *
 * 如果 `val` 为空值，返回 `EMPTY_VALUE_PLACEHOLDER` 配置值
 *
 * 如果 `val` 为非字符串时，则原样返回
 *
 * 如果 `val` 为字符串时，会以 `split` 值进行分割再以 `join` 连接后返回
 *
 * @param {*} val 原始值
 *
 * @param {string} split 分隔字符，默认值为 `'|'`
 *
 * @param {string} join 连接字符，默认值为 `'至'`
 *
 * @example 当 split = '|' 且 join = '至'
 * '2023-04-20|2023-05-10' => '2023-04-20 至 2023-05-10'
 * '2023-04-20 10:00:00|2023-05-10 12:00:00' => '2023-04-20 10:00:00 至 2023-05-10 12:00:00'
 *
 * @returns 最终展示的值
 */
export function rangeStringFilter(val, split = '|', join = '至') {
    if (isEmpty(val)) return EMPTY_VALUE_PLACEHOLDER
    if (!isString(val)) return val
    return val.split(split || '|').join(` ${join || '至'} `)
}

/**
 * @description 千分位格式处理（过滤器）
 *
 * 如果 `val` 为空值，返回 `EMPTY_VALUE_PLACEHOLDER` 配置值
 *
 * 如果 `val` 不可以转为非 `NaN` 值，则原样返回
 *
 * 如果 `val` 可以转为非 `NaN` 值，返回经 `thousandBit` 处理后的文本
 *
 * @param {*} val 原始值
 *
 * @param {string} unit 单位。需要在后追加单位时，可通过其指定
 *
 * @example
 * 100 > '100'
 * 1000 > '1,000'
 *
 * @returns 最终展示的值
 */
export function thousandBitFilter(val, unit) {
    if (isEmpty(val)) return EMPTY_VALUE_PLACEHOLDER

    const text = thousandBit(val)
    return isEmpty(unit) ? text : `${text} ${unit}`
}

/**
 * @description 对 `金额` 的格式处理（过滤器）
 *
 * 如果 `val` 为空值，返回 `EMPTY_VALUE_PLACEHOLDER` 配置值
 *
 * 如果 `val` 不可以转为非 `NaN` 值，则原样返回
 *
 * 如果 `val` 可以转为非 `NaN` 值，返回经 `thousandBit` 处理并保留两位小数的文本
 *
 * @param {*} val 原始值
 *
 * @param {string} unit 单位。需要在后追加单位时，可通过其指定
 *
 * @example
 * 100 > '100'
 * 1000 > '1,000'
 *
 * 100.50 > '100.50'
 * 1000.50 > '1,000.50'
 *
 * @returns 最终展示的值
 */
export function amountFilter(val, unit) {
    if (isEmpty(val)) return EMPTY_VALUE_PLACEHOLDER
    if (isNaN(val)) return val
    return thousandBitFilter((+val).toFixed(2), unit)
}

/** 对 `金额` 的格式处理，并加上单位 `元` */
export function withYuanUnitAmountFilter(val) {
    return amountFilter(val, '元')
}
