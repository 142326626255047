<template>
    <div class="clearfix upload-image">
        <div class="upload-image__container">
            <a-upload
                list-type="picture-card"
                :disabled="disabled"
                :headers="headers"
                :action="action"
                :multiple="multiple"
                :accept="accept"
                :default-file-list="imageProfile"
                :file-list="imageProfile"
                :data="uploadParams"
                :remove="removeFile"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange"
            >
                <div v-if="stillCanUpload">
                    <a-icon type="plus" />
                    <div class="upload-image__upload-text">本地上传请点击</div>
                </div>
            </a-upload>
            <div v-if="showQrCode" class="upload-image__qr-code">
                <img :src="_qrCodeImg" alt="">
                <div class="upload-image__qr-code__text">手机上传请扫码</div>
            </div>
        </div>
        <div class="upload-image__upload-type">{{ _tipText }}</div>
        <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
        >
            <img alt="example" style="width: 100%" :src="previewImage">
        </a-modal>
    </div>
</template>

<script>
// 组件
import { Upload as AUpload, Icon as AIcon } from 'ant-design-vue'

import { connectWebsocket, closeWebsocket } from './Socket'

import getWebsocketUrlService from '@service/getInitService'

// 公共方法
import { getFileExtFromPath } from '@utils/file'
import { getRelativeAgreementUrl } from '@utils'
import uuid from 'uuid'

// 接口
import getQrCodeService from '@service/getQrCodeService'
import { SAFETY_MFG_URL } from '@config'

import { SYSTEM_TYPE_ENT_SIDE } from '@constant/enum'

import { getToken } from '@utils/auth'
import openLink from '@/utils/openLink'

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}

export default {
    name: 'CustomUploadImage',
    components: {
        AUpload,
        AIcon
    },
    model: {
        prop: 'fileList',
        event: 'on-change'
    },
    props: {
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false
        },
        // 其他额外参数
        params: {
            type: [String, Object],
            default: () => ''
        },
        // 是否支持多文件上传
        multiple: {
            type: Boolean,
            default: false
        },
        maxUploadImageLimit: {
            type: Number,
            default: 5
        },
        // 图片格式
        accept: {
            type: String,
            default: '.jpg,.jpeg,.png,.svg'
        },
        // 文件限制说明
        tipText: {
            type: String,
            default: '支持上传格式jpg、png，单个图片不超过'
        },
        // 允许的上传文件类型的大小
        imageSizeLimit: {
            type: Number,
            default: 5
        },
        // 文件上传地址
        action: {
            type: String,
            // default: 'https://www.mocky.io/v2/5cc8019d300000980a055e76'
            default: `${SAFETY_MFG_URL}/file_operate/upload`
        },
        // 用于展示的图片地址
        fileList: {
            type: [Array, String],
            default() {
                return []
            }
        },
        showQrCode: {
            type: Boolean,
            default: false
        },
        qrCode: {
            type: String,
            default: require('@assets/images/qr-code.png')
        },
        // 上传接口其他参数
        uploadParams: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            previewVisible: false,
            previewImage: '',
            imageProfile: [],
            unionId: null,
            qrCodeImg: '',
            ws: null,
            webSocket: null
        }
    },
    computed: {
        _tipText() {
            return `(${this.tipText} ${this.imageSizeLimit}M)`
        },
        // 配置接口请求头
        headers() {
            const headers = {}
            const TOKEN = getToken()
            if (TOKEN) {
                headers['x-authorization-oauth'] = TOKEN
            }
            headers['x-client-type'] = SYSTEM_TYPE_ENT_SIDE
            return headers
        },
        _qrCodeImg() {
            return this.qrCodeImg || this.qrCode
        },
        initInformation() {
            return this.$store.getters.initInformation
        },
        stillCanUpload() {
            const { maxUploadImageLimit } = this
            return maxUploadImageLimit === - 1 || this.imageProfile.length < maxUploadImageLimit
        }
    },
    watch: {
        fileList: {
            handler(val) {
                this.uploadInfo(val)
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        if (this.showQrCode) {
            this.getQrCode()
        }
    },
    beforeDestroy() {
        // 页面销毁时关闭ws。
        closeWebsocket()
    },
    methods: {
        async getQrCode() {
            this.unionId = uuid()
            const payload = {
                unionId: this.unionId
            }
            const data = await getQrCodeService(payload)
            this.qrCodeImg = data
            // const socketUrl = this.initInformation.webSocketUrl
            const socketUrl = await getWebsocketUrlService()
            connectWebsocket(
                socketUrl.webSocketUrl,
                this.unionId,
                this.wsMessage,
                this.wsError
            )
        },
        wsMessage(data) {
            const dataArray = JSON.parse(data)
            for (let i = 0; i < dataArray.length; i++) {
                if (!this.stillCanUpload) {
                    this.$message.error(
                        `当前限制选择 ${this.maxUploadImageLimit} 个文件，请删除后再上传`
                    )
                    return false
                } else {
                    const image = dataArray[i]
                    const isDeleteItem = this.deleteList.findIndex(
                        deleteImage => deleteImage.fileId === image.fileId
                    )
                    const isHasItem = this.imageProfile.findIndex(
                        item => item.response.result.fileId === image.fileId
                    )
                    if (isHasItem === -1 && isDeleteItem === -1) {
                        this.imageProfile.push({
                            uid: image.fileId,
                            name: image.originalFileName,
                            status: 'done',
                            response: {
                                result: image
                            },
                            url: getRelativeAgreementUrl(image.fileUrl)
                        })
                    }

                    this.emitChange(this.imageProfile)
                }
            }
        },
        wsError(e) {
            console.log(e, 'ewsError')
        },
        // 上传前校验
        beforeUpload(file) {
            this.startLoading?.()
            const extension = getFileExtFromPath(file.name) // 获取文件后缀名
            const isRight = this.accept.indexOf(extension) > -1
            const isPass = file.size / 1024 / 1024 < this.imageSizeLimit // 文件大小
            if (!isRight) {
                this.$message.error(
                    `文件上传类型不正确，请上传后缀名为 ${this.accept} 的文件`
                )
                return Promise.reject('上传失败')
            } else if (!isPass) {
                this.$message.error(
                    `请检查上传文件大小是否超过 ${this.imageSizeLimit}MB`
                )
                return Promise.reject('上传失败')
            } else if (this.multiple && !this.stillCanUpload) {
                // 多文件上传，才限制文件个数
                this.$message.error(
                    `当前限制选择 ${this.maxUploadImageLimit} 个文件，请删除后再上传`
                )
                return Promise.reject('上传失败')
            }
            return isRight
        },
        // 文件移除
        removeFile(file) {
            let index = this.imageProfile.findIndex(
                item => item.uid === file.uid
            )
            if (index >= 0) {
                this.imageProfile.splice(index, 1)
                /**
                 * \"fileId\":\"1516717656862552065\",
                 * \"unionId\":\"119ccb878ddf44e495dda9ca78fc5f50\",
                 * \"originalFileName\":\"39c1494d91784094bd458153568d8240.jpeg\",
                 * \"fileUrl\":\"http://172.16.200.202:9000/safety-mfg/330100/1/2022/04/20/64e3af4bf77147169e88a094d64c628f.jpeg
                 */
                this.emitChange(this.imageProfile)
            } else {
                this.$message.error('文件移除查找 index 下标失败')
            }
        },
        /**
         *  预览图片 关闭
         */
        handleCancel() {
            this.previewVisible = false
        },
        /**
         *  预览图片
         */
        async handlePreview(file) {
            if (file.name && file.name.split('.')[1] === 'pdf') {
                openLink(file.url)
            } else {
                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj)
                }
                this.previewImage = getRelativeAgreementUrl(file.url || file.preview)
                this.previewVisible = true
            }

        },
        /**
         *  图片变更
         */
        handleChange({ file, fileList }) {
            this.imageProfile = fileList
            if (file.status === 'done') {
                if (file.response?.code === 500) {
                    let index = this.imageProfile.findIndex(
                        _item => _item.uid === file.uid
                    )
                    if (index >= 0) {
                        this.imageProfile.splice(index, 1)
                        this.$message.error(`${file.response?.message}`)
                        this.endLoading?.()
                        return
                    }
                }
                this.endLoading?.()
                this.emitChange(fileList)
            }
        },
        /**
         *  数据回传处理
         */
        transformData(imageList) {
            const data = []
            imageList.forEach(item => {
                data.push(item?.response?.result)
            })
            return data
        },
        /*
         * 数据回显
         */
        uploadInfo(imageList) {
            let _imageList = []
            imageList.forEach(item => {
                _imageList.push({
                    uid: item.fileId,
                    name: item.originalFileName || item.fileName,
                    status: 'done',
                    response: {
                        result: item
                    },
                    url: getRelativeAgreementUrl(item.fileUrl)
                })
            })
            this.imageProfile = _imageList
        },
        emitChange(fileList) {
            this.$emit(
                'on-change',
                this.transformData(fileList).map(file => ({
                    fileId: file.fileId,
                    originalFileName: file.originalFileName,
                    fileUrl: file.fileUrl
                }))
            )
        }
    },
    inject: {
        startLoading: {
            default: () => {}
        },
        endLoading: {
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@assets/styles/varibles.scss';

.upload-image {
    &__container {
        display: flex;
        margin-bottom: 9px;
    }
    &__upload-text {
        width: 84px;
        position: absolute;
        top: 84px;
        left: 0;
        font-size: $font-size-sm;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999;
        line-height: 17px;
    }
    &__qr-code {
        display: inline-block;
        width: 84px;
        img {
            width: 84px;
            height: 84px;
        }

        &__text {
            margin-top: 2px;
            font-size: $font-size-sm;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999;
            line-height: 17px;
            white-space: nowrap;
        }
    }
    &__upload-type {
        font-size: $font-size;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #969799;
        line-height: 20px;
    }
    ::v-deep {
        .ant-upload-select-picture-card i {
            font-size: 32px;
            color: #999;
        }
        .ant-upload.ant-upload-select-picture-card > .ant-upload {
            position: relative;
        }

        .ant-upload-select-picture-card .ant-upload-text {
            margin-top: 8px;
            color: #666;
        }
        // .ant-upload-picture-card-wrapper {
        //     display: flex;
        //     width: auto;
        // }
        .ant-upload-list-picture-card-container {
            width: 84px;
            height: 84px;
            margin: 0 12px 12px 0;
        }
        .ant-upload.ant-upload-select-picture-card {
            width: 84px;
            height: 84px;
            margin: 0 12px 12px 0;
            // margin-right: 24px;
        }
        .ant-upload-list {
            // max-width: 290px;
            max-width: 480px;
        }
        .ant-upload-list-picture-card .ant-upload-list-item {
            width: 84px;
            height: 84px;
        }
    }
}
</style>
