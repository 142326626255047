<template>
    <div class="create-page__custom-table-wrapper">
        <!-- COMBINATION_COMPONENT_TYPE_CARD -->
        <template v-if="needCard">
            <custom-card v-bind="cardNeedProps" :show-title="showCardTitle" @data-refresh="handleDataRefresh">
                <custom-table ref="contentItem" v-bind="tableNeedProps" :show-title="false" />
            </custom-card>
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_PAGE -->
        <template v-else-if="needPageHeader">
            <weight-page-header v-bind="pageHeaderNeedProps" :title="title" />
            <custom-table ref="contentItem" v-bind="tableNeedProps" :show-title="false" />
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_NOMAL -->
        <template v-else>
            <custom-table ref="contentItem" v-bind="tableNeedProps" />
        </template>
    </div>
</template>
<script>
import CustomCard from '@weights/CustomCard'
import CustomTable from '@weights/CustomTable'
import WeightPageHeader from '@weights/PageHeader'

import childrenRefreshMixin from '@mixins/createPage/childrenRefresh'
import getFormData from '@mixins/createPage/formData'
import eventMixin from '@mixins/createPage/event'
import buttonMixin from '@mixins/createPage/button'

import { getPropFields, getPropsInNeed, getPropsInCardNeed, getPropsInPageHeaderNeed } from '@utils/pageCreate'

import { CARD_SHOW_TITLE, COMBINATION_COMPONENT_TYPE_CARD, COMBINATION_COMPONENT_TYPE_PAGE } from '@constant/enum'

const TABLE_PROP_FIELDS = getPropFields(CustomTable)

export default {
    name: 'CreatePageCustomTable',
    components: {
        CustomCard,
        CustomTable,
        WeightPageHeader
    },
    mixins: [
        childrenRefreshMixin, getFormData, eventMixin, buttonMixin
    ],
    props: {
        combinationComponentType: {
            type: Number,
            default: COMBINATION_COMPONENT_TYPE_CARD
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        eventList: {
            type: Array,
            default: () => []
        },
        extraTitle: {
            type: Object,
            default: () => ({})
        },
        fieldList: {
            type: Array,
            default: () => []
        },
        // 可选择
        canSelect: {
            type: Boolean,
            default: false
        },
        showTitle: {
            type: Boolean,
            default: false
        },
        showTableTitle: {
            type: Number,
            default: CARD_SHOW_TITLE
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        tableType: {
            type: Number
        },
        uid: {
            type: String
        },
        stepId: {
            type: String
        },
        parentChildren: {
            type: Array,
            default: () => []
        },
        needBordered: {
            type: Number
        }
    },
    computed: {
        showCardTitle() {
            return this.showTableTitle === CARD_SHOW_TITLE
        },
        needCard() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_CARD
        },
        needPageHeader() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_PAGE
        },
        cardNeedProps() {
            return this.needCard ? getPropsInCardNeed(this.$props) : null
        },
        pageHeaderNeedProps() {
            return this.needPageHeader ? getPropsInPageHeaderNeed(this.$props) : null
        },
        tableNeedProps() {
            return getPropsInNeed(this.$props, TABLE_PROP_FIELDS)
        }
    },
    methods: {
        handleDataRefresh(data) {
            this.fieldDataRefresh(data)
        },
        fieldDataRefresh() {
            this.$refs['contentItem'].fieldDataRefresh()
        },
        getTableData() {
            return this.$refs['contentItem'].getTableData()
        }
    }
}
</script>
