import axios from '@service'
import { LOGIN_BY_PASSWORD } from './api'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
/**
 * 用户名 手机号密码方式登录
 * @returns {Promise<*>}
 */
export default async function loginByPasswordService(payload = {}, config = {}) {
    const param = {
        ...LOGIN_BY_PASSWORD,
        ...config,
        data: payload
    }
    const { data } = await axios(param)
    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
