<template>
    <div class="table_slot-action">
        <template v-if="!readOnly">
            <popconfirm-button
                button-type="link"
                button-text="移除"
                style="padding-right: 0"
                :loading="record.loading"
                @on-confirm="handleConfirmRemove"
            >
                <template #title>
                    是否要把该人员从班组中进行移除？<br>
                    <span style="color: #848b91">
                        移除成功后该人员的进场信息将一并被清除！
                    </span>
                </template>
            </popconfirm-button>
            <a-divider type="vertical" />
        </template>
        <a-button type="link" @click.stop="handleViewEnterInfo">进场信息</a-button>
    </div>
</template>
<script>
import { Button as AButton, Divider as ADivider } from 'ant-design-vue'
import PopconfirmButton from '@components/PopconfirmButton'

import removeService from '@service/teamManagement/removeMembertRosterService'

export default {
    name: 'ActionCell',
    components: {
        AButton,
        ADivider,
        PopconfirmButton
    },
    props: {
        rowKey: String,
        record: Object,
        readOnly: Boolean
    },
    methods: {
        // 确认移除
        async handleConfirmRemove() {
            try {
                const { rowKey, record } = this

                this.$set(this.record, 'loading', true)

                // eslint-disable-next-line
                await removeService({ [rowKey]: record[rowKey] })

                this.$emit('on-after-remove')
                this.$message.success('操作成功')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.$set(this.record, 'loading', false)
            }
        },
        // 查看进场信息
        handleViewEnterInfo() {
            this.$emit('on-view-enter-info', this.record)
        }
    }
}
</script>
