/**
 * 保证金管理 / 保证金缴纳详情
 */
import { SAFETY_MFG_URL } from '@config'

// 项目信息
export const GET_PROJECT_INFO = {
    url: `${SAFETY_MFG_URL}/project-his/inside/project_his/detail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 保证金缴纳信息
export const getMarginPaymentInfoApi = systemType => ({
    url: `${SAFETY_MFG_URL}/order/inside/${systemType}/detail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
})

// 现金存缴 / 保证金专户信息 + 办理资料
export const GET_CASH_PAYMENT_RECORD = {
    url: `${SAFETY_MFG_URL}/cash-payment-record/inside/getByParam`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 现金存缴 / 信息填报提交
export const SAVE_CASH_PAYMENT_RECORD = {
    url: `${SAFETY_MFG_URL}/cash-payment-record/inside/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 获取缴纳备案中保证金缴纳信息
export const GET_MARGIN_PAYMENT_INFO_IN_FILLING = {
    url: `${SAFETY_MFG_URL}/workflow/report/ent/apply_report_detail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 确认备案
export const CONFIRM_FILLING = {
    url: `${SAFETY_MFG_URL}/workflow/report/ent/initiate`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 发起退保
export const INITIATE_GUARANTEE_RETURN = {
    url: `${SAFETY_MFG_URL}/margin_return_order/inside/insure/refund`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 确认（发起）续保
export const CONFIRM_RENEWAL = {
    url: `${SAFETY_MFG_URL}/order/inside/renewal`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 取消续保
export const CANCEL_RENEWAL = {
    url: `${SAFETY_MFG_URL}/order/inside/cancel/renewal`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 获取 `投保` 跳转链接
export const GET_INSURED_JUMP_LINK = {
    url: `${SAFETY_MFG_URL}/order/inside/insured`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 刷新余额
export const REFRESH_BALANCE = {
    url: `${SAFETY_MFG_URL}/special_bank_account/inside/refreshBalance`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 获取银行专户信息
export const GET_SPECIAL_BANK_ACCOUNT_INFO = {
    url: `${SAFETY_MFG_URL}/special_bank_account/inside/getByProjectCode`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 申请现金存缴办理
export const APPLY_CASH_PAYMENT_HANDLING = {
    url: `${SAFETY_MFG_URL}/cash-payment-record/inside/process`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 获取（办理）机构信息
export const GET_INSTITUTION_INFO = {
    url: `${SAFETY_MFG_URL}/margin-bank-guarantee-record/inside/process/getInstitutionInfo`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 申请银行保函申请
export const APPLY_BANK_GUARANTEE_HANDLING = {
    url: `${SAFETY_MFG_URL}/margin-bank-guarantee-record/inside/process`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 获取保单上传信息
export const GET_POLICY_UPLOAD_INFO = {
    url: `${SAFETY_MFG_URL}/guarantee/inside/upload/info`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 提交保单上传信息
export const SUBMIT_POLICY_UPLOAD_INFO = {
    url: `${SAFETY_MFG_URL}/guarantee/inside/upload`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 删除保单上传信息
export const DELETE_POLICY_UPLOAD_INFO = {
    url: `${SAFETY_MFG_URL}/guarantee/inside/upload/delete`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
