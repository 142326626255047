<template>
    <div>
        <div v-for="(item, index) in data" :key="index" class="com-list--item">
            <div
                v-for="(field, fieldIndex) in columns"
                :key="fieldIndex"
                class="com-list-view"
                :class="[rightClass(item, field)]"
            >
                {{ item[field.fieldCode] }}
            </div>
            <!--            <a-divider /> -->
        </div>
    </div>
</template>

<script>
import { clearHiddenField } from '@/utils/pageCreate'

export default {
    name: 'CustomList',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        fieldList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {}
    },
    computed: {
        columns() {
            const columns = clearHiddenField(this.fieldList)
            return columns
        }
    },
    methods: {
        rightClass(item, field) {
            const reg = /[1-9]\d*/
            if (field.fieldCode === 'rightValue') {
                if (reg.test(item[field.fieldCode])) {
                    return 'rightRed'
                } else {
                    return 'rightGreen'
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.com-list {
    &--item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e8e8e8;
        padding: 18px 16px;
    }
}

.rightGreen {
    color: #2da641 !important;
}

.rightRed {
    color: #d40000 !important;
}
</style>
