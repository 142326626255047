<template>
    <a-date-picker
        v-model="localValue"
        :format="format"
        :show-time="showTime"
    />
</template>

<script>
import { DatePicker } from 'ant-design-vue'

import moment from 'moment'
export default {
    name: 'DatePicker',
    components: {
        ADatePicker: DatePicker
    },
    props: {
        value: {
            type: [String, Object],
            default: ''
        },
        showTime: {
            type: Boolean,
            default: true
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        }
    },
    computed: {
        localValue: {
            get() {
                return this.value && moment(this.value)
            },
            set(value) {
                this.$emit('input', value)
                this.$emit('change', value)
            }
        }
    }
}
</script>

<style></style>
