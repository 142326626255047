import CustomCard from '@weights/CustomCard'
import PageHeader from '@weights/PageHeader'

import { FIELD_TYPE_HIDDEN, FIELD_TYPE_CHECK } from '@/constant/enum'

import { isMap, isEmpty, isArray, isFunction } from '@utils'

/**
 * 清除 fieldList 中 fieldType 为 FIELD_TYPE_HIDDEN 和 FIELD_TYPE_CHECK 的项
 */
export const clearHiddenField = fieldList => fieldList.filter(
    item => [FIELD_TYPE_HIDDEN, FIELD_TYPE_CHECK].indexOf(item.fieldType) === -1
)


/**
 * 从组件的 option 对象中找到定义的 props 字段集合
 *
 * @param {{ props?: object, mixins: Array<{ props?: object }> }} option 组件的 option 对象
 *
 * @returns {string[]} props 字段集合
 */
export function getPropFields({ props = {}, mixins = [] }) {
    let fields = Object.keys(props || {})

    mixins?.forEach(({ props: mixinProps } = {}) => {
        if (!mixinProps) return
        fields = fields.concat(Object.keys(mixinProps))
    })

    return fields
}


/**
 * 特殊字段映射
 *
 * 1. 有一些字段 a 需要取 $props 的字段 b 值
 *
 * 2. 有一些字段 a 需要通过一段取值逻辑从 $props 中取出需要的值
 *
 * 故需要通过在 map 中定义字段 a 与字段 b 的映射关系
 *
 * @type {{ [key: string]: Map<string, string | (($props: object) => any)> }}
 */
const PROPS_SPECIAL_FIELD_MAP_FACTORY = {
    CARD: new Map([
        [
            'needBordered',
            function ({ needBordered, needCardBordered }) {
                return isEmpty(needCardBordered) ? needBordered : needCardBordered
            }
        ]
    ])
}

/**
 * 从 $props 中拿到组件需要的 props 参数
 *
 * @param {object} $props
 *
 * @param {string[]} propFields 组件的 option 对象中找到定义的 props 字段集合
 *
 * @param {{ map?: Map<string, string | (($props: object) => any)>, ignoreKeys?: string[] }} opts
 * @property opts.map 特殊字段映射
 * @property opts.ignoreKeys 需要忽略的字段
 *
 * @returns {object} 组件需要的 props 参数
 */
export function getPropsInNeed($props, propFields, opts) {
    const { map, ignoreKeys = [] } = opts || {}

    const reduceFn = (needProps, key) => {
        if (isArray(ignoreKeys) && ignoreKeys.includes(key)) {
            return needProps
        }

        let value = $props[key]

        if (isMap(map) && map.has(key)) {
            const field = map.get(key)
            value = isFunction(field) ? field($props) : $props[field]
        }

        if (!isEmpty(value)) {
            needProps[key] = value
        }

        return needProps
    }

    return propFields.reduce(reduceFn, {})
}


/** 组件 CustomCard 定义的 props 字段集合 */
const CARD_PROP_FIELDS = getPropFields(CustomCard)

/**
 * 从 $props 中拿到组件 CustomCard 需要的 props 参数
 *
 * @param {object} $props
 *
 * @returns {object} 组件 CustomCard 需要的 props 参数
 */
export function getPropsInCardNeed($props) {
    // eslint-disable-next-line
    return getPropsInNeed($props, CARD_PROP_FIELDS, { map: PROPS_SPECIAL_FIELD_MAP_FACTORY.CARD })
}


/** 组件 PageHeader 定义的 props 字段集合 */
const PAGE_HEADER_PROP_FIELDS = getPropFields(PageHeader)

/**
 * 从 $props 中拿到组件 PageHeader 需要的 props 参数
 *
 * @param {object} $props
 *
 * @returns {object} 组件 PageHeader 需要的 props 参数
 */
export function getPropsInPageHeaderNeed($props) {
    return getPropsInNeed($props, PAGE_HEADER_PROP_FIELDS)
}
