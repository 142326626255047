<template>
    <a-form-model layout="inline">
        <a-row>
            <a-col :span="12">
                <a-form-model-item label="班组状态">
                    {{ teamStateText }}
                </a-form-model-item>

                <a-form-model-item label="班组长">
                    {{ groupLeader }} {{ groupLeaderPhone }}
                </a-form-model-item>
            </a-col>
            <a-col :span="12">
                <statistics-list :enter-num="enterNum" :leave-num="leaveNum" />
            </a-col>
        </a-row>
    </a-form-model>
</template>
<script>
import { Row as ARow, Col as ACol, FormModel as AFormModel } from 'ant-design-vue'
import StatisticsList from '../../StatisticsList'

import { empty as emptyFilter } from '@utils'

export default {
    name: 'TeamInfo',
    components: {
        ARow,
        ACol,
        AFormModel,
        StatisticsList,
        AFormModelItem: AFormModel.Item
    },
    props: {
        record: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        // 进场人数
        enterNum() {
            return this.record.enterNum || 0
        },
        // 退场人数
        leaveNum() {
            return this.record.leaveNum || 0
        },
        // 班组状态
        teamStateText() {
            return emptyFilter(this.record.teamStateText)
        },
        // 班组长姓名
        groupLeader() {
            return emptyFilter(this.record.groupLeader)
        },
        // 班组长联系方式
        groupLeaderPhone() {
            return emptyFilter(this.record.groupLeaderPhone)
        }
    }
}
</script>
