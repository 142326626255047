/**
 * 项目资料
 */
import BasicLayout from '@layout/BasicLayout'
import PageTemplate from '@/weights/PageTemplate/Page'
// import DetailTemplate from '@/weights/PageTemplate/Detail'

import {
    GUARANTEE_MANAGEMENT_MENU_CODE,
    GUARANTEE_ORDER_CODE,
    GUARANTEE_LIST_CODE
} from '@constant/menu_code'

import { GUARANTEE_MANAGE_MENU_SORT } from '@constant/menu_sort'

const routes = [
    {
        path: '/guarantee',
        name: 'guarantee',
        component: BasicLayout,
        code: GUARANTEE_MANAGEMENT_MENU_CODE,
        redirect: {
            name: GUARANTEE_ORDER_CODE
        },
        meta: {
            title: '保函管理',
            icon: 'baohanguanli',
            iconType: 'iconfont',
            breadcrumb: false
        },
        sort: GUARANTEE_MANAGE_MENU_SORT,
        children: [
            {
                path: 'order',
                name: GUARANTEE_ORDER_CODE,
                component: PageTemplate,
                code: GUARANTEE_ORDER_CODE,
                meta: {
                    title: '订单管理',
                    layoutCode: GUARANTEE_ORDER_CODE
                },
                sort: 2
            },
            {
                path: 'list',
                name: GUARANTEE_LIST_CODE,
                component: PageTemplate,
                code: GUARANTEE_LIST_CODE,
                meta: {
                    title: '保函管理',
                    layoutCode: GUARANTEE_LIST_CODE
                },
                sort: 1
            }
        ]


    }
]

export default routes


