/* eslint-disable */
import _typeof from 'babel-runtime/helpers/typeof'
import _extends from 'babel-runtime/helpers/extends'
import _defineProperty from 'babel-runtime/helpers/defineProperty'
import _toConsumableArray from 'babel-runtime/helpers/toConsumableArray'
import _objectWithoutProperties from 'babel-runtime/helpers/objectWithoutProperties'

import Vue from 'vue'
import classNames from 'classnames'
import shallowEqual from 'shallowequal'

import Spin from 'ant-design-vue/es/spin'
import Icon from 'ant-design-vue/es/icon'
import Pagination from 'ant-design-vue/es/pagination'
import VcTable, { INTERNAL_COL_DEFINE } from '../vc-table'

import Column from 'ant-design-vue/es/table/Column'
import ColumnGroup from 'ant-design-vue/es/table/ColumnGroup'
import SelectionBox from 'ant-design-vue/es/table/SelectionBox'
import createBodyRow from 'ant-design-vue/es/table/createBodyRow'
import FilterDropdown from 'ant-design-vue/es/table/filterDropdown'
import SelectionCheckboxAll from 'ant-design-vue/es/table/SelectionCheckboxAll'

import warning from 'ant-design-vue/es/_util/warning'
import scrollTo from 'ant-design-vue/es/_util/scrollTo'
import BaseMixin from 'ant-design-vue/es/_util/BaseMixin'
import TransButton from 'ant-design-vue/es/_util/transButton'
import defaultLocale from 'ant-design-vue/es/locale-provider/default'
import LocaleReceiver from 'ant-design-vue/es/locale-provider/LocaleReceiver'
import { ConfigConsumerProps } from 'ant-design-vue/es/config-provider/configConsumerProps'
import {
    initDefaultProps,
    mergeProps,
    getOptionProps,
    getListeners,
} from 'ant-design-vue/es/_util/props-util'

import { TableProps } from 'ant-design-vue/es/table/interface'
import { flatArray, treeMap, flatFilter } from 'ant-design-vue/es/table/util'

function noop() {}

function stopPropagation(e) {
    e.stopPropagation()
}

function getRowSelection(props) {
    return props.rowSelection || {}
}

function getColumnKey(column, index) {
    return column.key || column.dataIndex || index
}

function isSameColumn(a, b) {
    if (a && b && a.key && a.key === b.key) {
        return true
    }
    return (
        a === b ||
        shallowEqual(a, b, function (value, other) {
            // https://github.com/ant-design/ant-design/issues/12737
            if (typeof value === 'function' && typeof other === 'function') {
                return value === other || value.toString() === other.toString()
            }
            // https://github.com/ant-design/ant-design/issues/19398
            if (Array.isArray(value) && Array.isArray(other)) {
                return value === other || shallowEqual(value, other)
            }
        })
    )
}

let defaultPagination = {
    onChange: noop,
    onShowSizeChange: noop,
}

/**
 * Avoid creating new object, so that parent component's shouldComponentUpdate
 * can works appropriately。
 */
let emptyObject = {}

let createComponents = function createComponents() {
    let components =
        arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    let bodyRow = components && components.body && components.body.row
    return _extends({}, components, {
        body: _extends({}, components.body, { row: createBodyRow(bodyRow) }),
    })
}

function isTheSameComponents() {
    let components1 =
        arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}
    let components2 =
        arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {}

    return (
        components1 === components2 ||
        ['table', 'header', 'body'].every(function (key) {
            return shallowEqual(components1[key], components2[key])
        })
    )
}

function getFilteredValueColumns(state, columns) {
    return flatFilter(
        columns || (state || {}).columns || [],
        function (column) {
            return typeof column.filteredValue !== 'undefined'
        }
    )
}

function getFiltersFromColumns(state, columns) {
    let filters = {}
    getFilteredValueColumns(state, columns).forEach(function (col) {
        let colKey = getColumnKey(col)
        filters[colKey] = col.filteredValue
    })
    return filters
}

function isFiltersChanged(state, filters) {
    if (Object.keys(filters).length !== Object.keys(state.filters).length) {
        return true
    }
    return Object.keys(filters).some(function (columnKey) {
        return filters[columnKey] !== state.filters[columnKey]
    })
}

export default {
    name: 'Table',
    Column,
    ColumnGroup,
    mixins: [BaseMixin],
    inject: {
        configProvider: {
            default: function _default() {
                return ConfigConsumerProps
            },
        },
    },
    provide: function provide() {
        return { store: this.store }
    },

    props: initDefaultProps(TableProps, {
        dataSource: [],
        useFixedHeader: false,
        // rowSelection: null,
        size: 'default',
        loading: false,
        bordered: false,
        indentSize: 20,
        locale: {},
        rowKey: 'key',
        showHeader: true,
        sortDirections: ['ascend', 'descend'],
        childrenColumnName: 'children',
    }),

    data: function data() {
        let props = getOptionProps(this)
        warning(
            !props.expandedRowRender || !('scroll' in props) || !props.scroll.x,
            '`expandedRowRender` and `scroll` are not compatible. Please use one of them at one time.'
        )
        this.CheckboxPropsCache = {}

        this.store = (this.$root.constructor.observable || Vue.observable)({
            selectedRowKeys: getRowSelection(this.$props).selectedRowKeys || [],
            selectionDirty: false,
        })
        return _extends({}, this.getDefaultSortOrder(props.columns || []), {
            // 减少状态
            sFilters: this.getDefaultFilters(props.columns),
            sPagination: this.getDefaultPagination(this.$props),
            pivot: undefined,
            sComponents: createComponents(this.components),
            filterDataCnt: 0,
        })
    },

    watch: {
        pagination: {
            handler: function handler(val) {
                this.setState(function (previousState) {
                    let newPagination = _extends(
                        {},
                        defaultPagination,
                        previousState.sPagination,
                        val
                    )
                    newPagination.current = newPagination.current || 1
                    newPagination.pageSize = newPagination.pageSize || 10
                    return {
                        sPagination:
                            val !== false ? newPagination : emptyObject,
                    }
                })
            },

            deep: true,
        },
        rowSelection: {
            handler: function handler(val, oldVal) {
                if (val && 'selectedRowKeys' in val) {
                    this.store.selectedRowKeys = val.selectedRowKeys || []
                    let rowSelection = this.rowSelection

                    if (
                        rowSelection &&
                        val.getCheckboxProps !== rowSelection.getCheckboxProps
                    ) {
                        this.CheckboxPropsCache = {}
                    }
                } else if (oldVal && !val) {
                    this.store.selectedRowKeys = []
                }
            },

            deep: true,
        },

        dataSource: function dataSource() {
            this.store.selectionDirty = false
            this.CheckboxPropsCache = {}
        },
        columns: function columns(val) {
            let filteredValueColumns = getFilteredValueColumns(
                { columns: val },
                val
            )
            if (filteredValueColumns.length > 0) {
                let filtersFromColumns = getFiltersFromColumns(
                    { columns: val },
                    val
                )
                let newFilters = _extends({}, this.sFilters)
                Object.keys(filtersFromColumns).forEach(function (key) {
                    newFilters[key] = filtersFromColumns[key]
                })
                if (isFiltersChanged({ filters: this.sFilters }, newFilters)) {
                    this.setState({ sFilters: newFilters })
                }
            }
            this.$forceUpdate()
        },

        components: {
            handler: function handler(val, oldVal) {
                if (!isTheSameComponents(val, oldVal)) {
                    let components = createComponents(val)
                    this.setState({ sComponents: components })
                }
            },

            deep: true,
        },
    },
    updated: function updated() {
        let columns = this.columns,
            sortColumn = this.sSortColumn,
            sortOrder = this.sSortOrder

        if (this.getSortOrderColumns(columns).length > 0) {
            let sortState = this.getSortStateFromColumns(columns)
            if (
                !isSameColumn(sortState.sSortColumn, sortColumn) ||
                sortState.sSortOrder !== sortOrder
            ) {
                this.setState(sortState)
            }
        }
    },

    methods: {
        getCheckboxPropsByItem: function getCheckboxPropsByItem(item, index) {
            let rowSelection = getRowSelection(this.$props)
            if (!rowSelection.getCheckboxProps) {
                return { props: {} }
            }
            let key = this.getRecordKey(item, index)
            // Cache checkboxProps
            if (!this.CheckboxPropsCache[key]) {
                this.CheckboxPropsCache[key] =
                    rowSelection.getCheckboxProps(item)
            }
            this.CheckboxPropsCache[key].props =
                this.CheckboxPropsCache[key].props || {}
            return this.CheckboxPropsCache[key]
        },
        getDefaultSelection: function getDefaultSelection() {
            let _this = this

            let rowSelection = getRowSelection(this.$props)
            if (!rowSelection.getCheckboxProps) {
                return []
            }
            return this.getFlatData()
                .filter(function (item, rowIndex) {
                    return _this.getCheckboxPropsByItem(
                        item,
                        rowIndex
                    ).props.defaultChecked
                })
                .map(function (record, rowIndex) {
                    return _this.getRecordKey(record, rowIndex)
                })
        },
        getDefaultPagination: function getDefaultPagination(props) {
            let pagination =
                _typeof(props.pagination) === 'object' ? props.pagination : {}
            let current = void 0
            if ('current' in pagination) {
                current = pagination.current
            } else if ('defaultCurrent' in pagination) {
                current = pagination.defaultCurrent
            }
            let pageSize = void 0
            if ('pageSize' in pagination) {
                pageSize = pagination.pageSize
            } else if ('defaultPageSize' in pagination) {
                pageSize = pagination.defaultPageSize
            }
            return this.hasPagination(props)
                ? _extends({}, defaultPagination, pagination, {
                      current: current || 1,
                      pageSize: pageSize || 10,
                  })
                : {}
        },
        getSortOrderColumns: function getSortOrderColumns(columns) {
            return flatFilter(columns || this.columns || [], function (column) {
                return 'sortOrder' in column
            })
        },
        getDefaultFilters: function getDefaultFilters(columns) {
            let definedFilters = getFiltersFromColumns(
                { columns: this.columns },
                columns
            )

            let defaultFilteredValueColumns = flatFilter(
                columns || [],
                function (column) {
                    return typeof column.defaultFilteredValue !== 'undefined'
                }
            )

            let defaultFilters = defaultFilteredValueColumns.reduce(function (
                soFar,
                col
            ) {
                let colKey = getColumnKey(col)
                soFar[colKey] = col.defaultFilteredValue
                return soFar
            },
            {})

            return _extends({}, defaultFilters, definedFilters)
        },
        getDefaultSortOrder: function getDefaultSortOrder(columns) {
            let definedSortState = this.getSortStateFromColumns(columns)

            let defaultSortedColumn = flatFilter(
                columns || [],
                function (column) {
                    return column.defaultSortOrder != null
                }
            )[0]

            if (defaultSortedColumn && !definedSortState.sortColumn) {
                return {
                    sSortColumn: defaultSortedColumn,
                    sSortOrder: defaultSortedColumn.defaultSortOrder,
                }
            }

            return definedSortState
        },
        getSortStateFromColumns: function getSortStateFromColumns(columns) {
            // return first column which sortOrder is not falsy
            let sortedColumn = this.getSortOrderColumns(columns).filter(
                function (col) {
                    return col.sortOrder
                }
            )[0]

            if (sortedColumn) {
                return {
                    sSortColumn: sortedColumn,
                    sSortOrder: sortedColumn.sortOrder,
                }
            }

            return {
                sSortColumn: null,
                sSortOrder: null,
            }
        },
        getMaxCurrent: function getMaxCurrent(total) {
            let _sPagination = this.sPagination,
                current = _sPagination.current,
                pageSize = _sPagination.pageSize

            if ((current - 1) * pageSize >= total) {
                return Math.floor((total - 1) / pageSize) + 1
            }
            return current
        },
        getRecordKey: function getRecordKey(record, index) {
            let rowKey = this.rowKey

            let recordKey =
                typeof rowKey === 'function'
                    ? rowKey(record, index)
                    : record[rowKey]
            warning(
                recordKey !== undefined,
                'Table',
                'Each record in dataSource of table should have a unique `key` prop, ' +
                    'or set `rowKey` of Table to an unique primary key, '
            )
            return recordKey === undefined ? index : recordKey
        },
        getSorterFn: function getSorterFn(state) {
            let _ref = state || this.$data,
                sortOrder = _ref.sSortOrder,
                sortColumn = _ref.sSortColumn

            if (
                !sortOrder ||
                !sortColumn ||
                typeof sortColumn.sorter !== 'function'
            ) {
                return
            }

            return function (a, b) {
                let result = sortColumn.sorter(a, b, sortOrder)
                if (result !== 0) {
                    return sortOrder === 'descend' ? -result : result
                }
                return 0
            }
        },
        getCurrentPageData: function getCurrentPageData() {
            let data = this.getLocalData()
            this.filterDataCnt = data.length
            let current = void 0
            let pageSize = void 0
            let sPagination = this.sPagination
            // 如果没有分页的话，默认全部展示
            if (!this.hasPagination()) {
                pageSize = Number.MAX_VALUE
                current = 1
            } else {
                pageSize = sPagination.pageSize
                current = this.getMaxCurrent(sPagination.total || data.length)
            }

            // 分页
            // ---
            // 当数据量少于等于每页数量时，直接设置数据
            // 否则进行读取分页数据
            if (data.length > pageSize || pageSize === Number.MAX_VALUE) {
                data = data.slice((current - 1) * pageSize, current * pageSize)
            }
            return data
        },
        getFlatData: function getFlatData() {
            let childrenColumnName = this.$props.childrenColumnName

            return flatArray(this.getLocalData(null, false), childrenColumnName)
        },
        getFlatCurrentPageData: function getFlatCurrentPageData() {
            let childrenColumnName = this.$props.childrenColumnName

            return flatArray(this.getCurrentPageData(), childrenColumnName)
        },
        getLocalData: function getLocalData(state) {
            let _this2 = this

            let filter =
                arguments.length > 1 && arguments[1] !== undefined
                    ? arguments[1]
                    : true

            let currentState = state || this.$data
            let filters = currentState.sFilters
            let dataSource = this.$props.dataSource

            let data = dataSource || []
            // 优化本地排序
            data = data.slice(0)
            let sorterFn = this.getSorterFn(currentState)
            if (sorterFn) {
                // 使用新数组，避免改变原数组导致无限循环更新
                // https://github.com/vueComponent/ant-design-vue/issues/2270
                data = this.recursiveSort(
                    [].concat(_toConsumableArray(data)),
                    sorterFn
                )
            }
            // 筛选
            if (filter && filters) {
                Object.keys(filters).forEach(function (columnKey) {
                    let col = _this2.findColumn(columnKey)
                    if (!col) {
                        return
                    }
                    let values = filters[columnKey] || []
                    if (values.length === 0) {
                        return
                    }
                    let onFilter = col.onFilter
                    data = onFilter
                        ? data.filter(function (record) {
                              return values.some(function (v) {
                                  return onFilter(v, record)
                              })
                          })
                        : data
                })
            }
            return data
        },
        onRow: function onRow(prefixCls, record, index) {
            let customRow = this.customRow

            let custom = customRow ? customRow(record, index) : {}
            return mergeProps(custom, {
                props: {
                    prefixCls,
                    store: this.store,
                    rowKey: this.getRecordKey(record, index),
                },
            })
        },
        setSelectedRowKeys: function setSelectedRowKeys(
            selectedRowKeys,
            selectionInfo
        ) {
            let _this3 = this

            let selectWay = selectionInfo.selectWay,
                record = selectionInfo.record,
                checked = selectionInfo.checked,
                changeRowKeys = selectionInfo.changeRowKeys,
                nativeEvent = selectionInfo.nativeEvent

            let rowSelection = getRowSelection(this.$props)
            if (rowSelection && !('selectedRowKeys' in rowSelection)) {
                this.store.selectedRowKeys = selectedRowKeys
            }
            let data = this.getFlatData()
            if (!rowSelection.onChange && !rowSelection[selectWay]) {
                return
            }
            let selectedRows = data.filter(function (row, i) {
                return selectedRowKeys.indexOf(_this3.getRecordKey(row, i)) >= 0
            })
            if (rowSelection.onChange) {
                rowSelection.onChange(selectedRowKeys, selectedRows)
            }
            if (selectWay === 'onSelect' && rowSelection.onSelect) {
                rowSelection.onSelect(
                    record,
                    checked,
                    selectedRows,
                    nativeEvent
                )
            } else if (
                selectWay === 'onSelectMultiple' &&
                rowSelection.onSelectMultiple
            ) {
                let changeRows = data.filter(function (row, i) {
                    return (
                        changeRowKeys.indexOf(_this3.getRecordKey(row, i)) >= 0
                    )
                })
                rowSelection.onSelectMultiple(checked, selectedRows, changeRows)
            } else if (
                selectWay === 'onSelectAll' &&
                rowSelection.onSelectAll
            ) {
                let _changeRows = data.filter(function (row, i) {
                    return (
                        changeRowKeys.indexOf(_this3.getRecordKey(row, i)) >= 0
                    )
                })
                rowSelection.onSelectAll(checked, selectedRows, _changeRows)
            } else if (
                selectWay === 'onSelectInvert' &&
                rowSelection.onSelectInvert
            ) {
                rowSelection.onSelectInvert(selectedRowKeys)
            }
        },
        generatePopupContainerFunc: function generatePopupContainerFunc(
            getPopupContainer
        ) {
            let scroll = this.$props.scroll

            let table = this.$refs.vcTable
            if (getPopupContainer) {
                return getPopupContainer
            }
            // Use undefined to let rc component use default logic.
            return scroll && table
                ? function () {
                      return table.getTableNode()
                  }
                : undefined
        },
        scrollToFirstRow: function scrollToFirstRow() {
            let _this4 = this

            let scroll = this.$props.scroll

            if (scroll && scroll.scrollToFirstRowOnChange !== false) {
                scrollTo(0, {
                    getContainer: function getContainer() {
                        return _this4.$refs.vcTable.getBodyTable()
                    },
                })
            }
        },
        isSameColumn: function isSameColumn(a, b) {
            if (a && b && a.key && a.key === b.key) {
                return true
            }
            return (
                a === b ||
                shallowEqual(a, b, function (value, other) {
                    if (
                        typeof value === 'function' &&
                        typeof other === 'function'
                    ) {
                        return (
                            value === other ||
                            value.toString() === other.toString()
                        )
                    }
                })
            )
        },
        handleFilter: function handleFilter(column, nextFilters) {
            let _this5 = this

            let props = this.$props
            let pagination = _extends({}, this.sPagination)
            let filters = _extends(
                {},
                this.sFilters,
                _defineProperty({}, getColumnKey(column), nextFilters)
            )
            // Remove filters not in current columns
            let currentColumnKeys = []
            treeMap(this.columns, function (c) {
                if (!c.children) {
                    currentColumnKeys.push(getColumnKey(c))
                }
            })
            Object.keys(filters).forEach(function (columnKey) {
                if (currentColumnKeys.indexOf(columnKey) < 0) {
                    delete filters[columnKey]
                }
            })

            if (props.pagination) {
                // Reset current prop
                pagination.current = 1
                pagination.onChange(pagination.current)
            }

            let newState = {
                sPagination: pagination,
                sFilters: {},
            }
            let filtersToSetState = _extends({}, filters)
            // Remove filters which is controlled
            getFilteredValueColumns({ columns: props.columns }).forEach(
                function (col) {
                    let columnKey = getColumnKey(col)
                    if (columnKey) {
                        delete filtersToSetState[columnKey]
                    }
                }
            )
            if (Object.keys(filtersToSetState).length > 0) {
                newState.sFilters = filtersToSetState
            }

            // Controlled current prop will not respond user interaction
            if (
                _typeof(props.pagination) === 'object' &&
                'current' in props.pagination
            ) {
                newState.sPagination = _extends({}, pagination, {
                    current: this.sPagination.current,
                })
            }

            this.setState(newState, function () {
                _this5.scrollToFirstRow()
                _this5.store.selectionDirty = false
                _this5.$emit.apply(
                    _this5,
                    ['change'].concat(
                        _toConsumableArray(
                            _this5.prepareParamsArguments(
                                _extends({}, _this5.$data, {
                                    sSelectionDirty: false,
                                    sFilters: filters,
                                    sPagination: pagination,
                                })
                            )
                        )
                    )
                )
            })
        },
        handleSelect: function handleSelect(record, rowIndex, e) {
            let _this6 = this

            let checked = e.target.checked
            let nativeEvent = e.nativeEvent
            let defaultSelection = this.store.selectionDirty
                ? []
                : this.getDefaultSelection()
            let selectedRowKeys =
                this.store.selectedRowKeys.concat(defaultSelection)
            let key = this.getRecordKey(record, rowIndex)
            let pivot = this.$data.pivot

            let rows = this.getFlatCurrentPageData()
            let realIndex = rowIndex
            if (this.$props.expandedRowRender) {
                realIndex = rows.findIndex(function (row) {
                    return _this6.getRecordKey(row, rowIndex) === key
                })
            }
            if (
                nativeEvent.shiftKey &&
                pivot !== undefined &&
                realIndex !== pivot
            ) {
                let changeRowKeys = []
                let direction = Math.sign(pivot - realIndex)
                let dist = Math.abs(pivot - realIndex)
                let step = 0

                let _loop = function _loop() {
                    let i = realIndex + step * direction
                    step += 1
                    let row = rows[i]
                    let rowKey = _this6.getRecordKey(row, i)
                    let checkboxProps = _this6.getCheckboxPropsByItem(row, i)
                    if (!checkboxProps.disabled) {
                        if (selectedRowKeys.includes(rowKey)) {
                            if (!checked) {
                                selectedRowKeys = selectedRowKeys.filter(
                                    function (j) {
                                        return rowKey !== j
                                    }
                                )
                                changeRowKeys.push(rowKey)
                            }
                        } else if (checked) {
                            selectedRowKeys.push(rowKey)
                            changeRowKeys.push(rowKey)
                        }
                    }
                }

                while (step <= dist) {
                    _loop()
                }

                this.setState({ pivot: realIndex })
                this.store.selectionDirty = true
                this.setSelectedRowKeys(selectedRowKeys, {
                    selectWay: 'onSelectMultiple',
                    record,
                    checked,
                    changeRowKeys,
                    nativeEvent,
                })
            } else {
                if (checked) {
                    selectedRowKeys.push(this.getRecordKey(record, realIndex))
                } else {
                    selectedRowKeys = selectedRowKeys.filter(function (i) {
                        return key !== i
                    })
                }
                this.setState({ pivot: realIndex })
                this.store.selectionDirty = true
                this.setSelectedRowKeys(selectedRowKeys, {
                    selectWay: 'onSelect',
                    record,
                    checked,
                    changeRowKeys: undefined,
                    nativeEvent,
                })
            }
        },
        handleRadioSelect: function handleRadioSelect(record, rowIndex, e) {
            let checked = e.target.checked
            let nativeEvent = e.nativeEvent
            let key = this.getRecordKey(record, rowIndex)
            let selectedRowKeys = [key]
            this.store.selectionDirty = true
            this.setSelectedRowKeys(selectedRowKeys, {
                selectWay: 'onSelect',
                record,
                checked,
                changeRowKeys: undefined,
                nativeEvent,
            })
        },
        handleSelectRow: function handleSelectRow(
            selectionKey,
            index,
            onSelectFunc
        ) {
            let _this7 = this

            let data = this.getFlatCurrentPageData()
            let defaultSelection = this.store.selectionDirty
                ? []
                : this.getDefaultSelection()
            let selectedRowKeys =
                this.store.selectedRowKeys.concat(defaultSelection)
            let changeableRowKeys = data
                .filter(function (item, i) {
                    return !_this7.getCheckboxPropsByItem(
                        item,
                        i
                    ).props.disabled
                })
                .map(function (item, i) {
                    return _this7.getRecordKey(item, i)
                })

            let changeRowKeys = []
            let selectWay = 'onSelectAll'
            let checked = void 0
            // handle default selection
            switch (selectionKey) {
                case 'all':
                    changeableRowKeys.forEach(function (key) {
                        if (selectedRowKeys.indexOf(key) < 0) {
                            selectedRowKeys.push(key)
                            changeRowKeys.push(key)
                        }
                    })
                    selectWay = 'onSelectAll'
                    checked = true
                    break
                case 'removeAll':
                    changeableRowKeys.forEach(function (key) {
                        if (selectedRowKeys.indexOf(key) >= 0) {
                            selectedRowKeys.splice(
                                selectedRowKeys.indexOf(key),
                                1
                            )
                            changeRowKeys.push(key)
                        }
                    })
                    selectWay = 'onSelectAll'
                    checked = false
                    break
                case 'invert':
                    changeableRowKeys.forEach(function (key) {
                        if (selectedRowKeys.indexOf(key) < 0) {
                            selectedRowKeys.push(key)
                        } else {
                            selectedRowKeys.splice(
                                selectedRowKeys.indexOf(key),
                                1
                            )
                        }
                        changeRowKeys.push(key)
                        selectWay = 'onSelectInvert'
                    })
                    break
                default:
                    break
            }

            this.store.selectionDirty = true
            // when select custom selection, callback selections[n].onSelect
            let rowSelection = this.rowSelection

            let customSelectionStartIndex = 2
            if (rowSelection && rowSelection.hideDefaultSelections) {
                customSelectionStartIndex = 0
            }
            if (
                index >= customSelectionStartIndex &&
                typeof onSelectFunc === 'function'
            ) {
                return onSelectFunc(changeableRowKeys)
            }
            this.setSelectedRowKeys(selectedRowKeys, {
                selectWay,
                checked,
                changeRowKeys,
            })
        },
        handlePageChange: function handlePageChange(current) {
            let props = this.$props
            let pagination = _extends({}, this.sPagination)
            if (current) {
                pagination.current = current
            } else {
                pagination.current = pagination.current || 1
            }

            for (
                var _len = arguments.length,
                    otherArguments = Array(_len > 1 ? _len - 1 : 0),
                    _key = 1;
                _key < _len;
                _key++
            ) {
                otherArguments[_key - 1] = arguments[_key]
            }

            pagination.onChange.apply(
                pagination,
                [pagination.current].concat(_toConsumableArray(otherArguments))
            )

            let newState = { sPagination: pagination }
            // Controlled current prop will not respond user interaction
            if (
                props.pagination &&
                _typeof(props.pagination) === 'object' &&
                'current' in props.pagination
            ) {
                newState.sPagination = _extends({}, pagination, {
                    current: this.sPagination.current,
                })
            }
            this.setState(newState, this.scrollToFirstRow)

            this.store.selectionDirty = false
            this.$emit.apply(
                this,
                ['change'].concat(
                    _toConsumableArray(
                        this.prepareParamsArguments(
                            _extends({}, this.$data, {
                                sSelectionDirty: false,
                                sPagination: pagination,
                            })
                        )
                    )
                )
            )
        },
        handleShowSizeChange: function handleShowSizeChange(current, pageSize) {
            let pagination = this.sPagination
            pagination.onShowSizeChange(current, pageSize)
            let nextPagination = _extends({}, pagination, {
                pageSize,
                current,
            })
            this.setState(
                { sPagination: nextPagination },
                this.scrollToFirstRow
            )
            this.$emit.apply(
                this,
                ['change'].concat(
                    _toConsumableArray(
                        this.prepareParamsArguments(
                            _extends({}, this.$data, {
                                sPagination: nextPagination,
                            })
                        )
                    )
                )
            )
        },
        toggleSortOrder: function toggleSortOrder(column) {
            let sortDirections = column.sortDirections || this.sortDirections
            let sortOrder = this.sSortOrder,
                sortColumn = this.sSortColumn
            // 只同时允许一列进行排序，否则会导致排序顺序的逻辑问题

            let newSortOrder = void 0
            // 切换另一列时，丢弃 sortOrder 的状态
            if (isSameColumn(sortColumn, column) && sortOrder !== undefined) {
                // 按照sortDirections的内容依次切换排序状态
                let methodIndex = sortDirections.indexOf(sortOrder) + 1
                newSortOrder =
                    methodIndex === sortDirections.length
                        ? undefined
                        : sortDirections[methodIndex]
            } else {
                newSortOrder = sortDirections[0]
            }
            let newState = {
                sSortOrder: newSortOrder,
                sSortColumn: newSortOrder ? column : null,
            }

            // Controlled
            if (this.getSortOrderColumns().length === 0) {
                this.setState(newState, this.scrollToFirstRow)
            }
            this.$emit.apply(
                this,
                ['change'].concat(
                    _toConsumableArray(
                        this.prepareParamsArguments(
                            _extends({}, this.$data, newState),
                            column
                        )
                    )
                )
            )
        },
        hasPagination: function hasPagination(props) {
            return (props || this.$props).pagination !== false
        },
        isSortColumn: function isSortColumn(column) {
            let sortColumn = this.sSortColumn

            if (!column || !sortColumn) {
                return false
            }
            return getColumnKey(sortColumn) === getColumnKey(column)
        },

        // Get pagination, filters, sorter
        prepareParamsArguments: function prepareParamsArguments(state, column) {
            let pagination = _extends({}, state.sPagination)
            // remove useless handle function in Table.onChange
            delete pagination.onChange
            delete pagination.onShowSizeChange
            let filters = state.sFilters
            let sorter = {}
            let currentColumn = column
            if (state.sSortColumn && state.sSortOrder) {
                currentColumn = state.sSortColumn
                sorter.column = state.sSortColumn
                sorter.order = state.sSortOrder
            }

            if (currentColumn) {
                sorter.field = currentColumn.dataIndex
                sorter.columnKey = getColumnKey(currentColumn)
            }

            let extra = { currentDataSource: this.getLocalData(state) }

            return [pagination, filters, sorter, extra]
        },
        findColumn: function findColumn(myKey) {
            let column = void 0
            treeMap(this.columns, function (c) {
                if (getColumnKey(c) === myKey) {
                    column = c
                }
            })
            return column
        },
        recursiveSort: function recursiveSort(data, sorterFn) {
            let _this8 = this

            let _childrenColumnName = this.childrenColumnName,
                childrenColumnName =
                    _childrenColumnName === undefined
                        ? 'children'
                        : _childrenColumnName

            return data.sort(sorterFn).map(function (item) {
                return item[childrenColumnName]
                    ? _extends(
                          {},
                          item,
                          _defineProperty(
                              {},
                              childrenColumnName,
                              _this8.recursiveSort(
                                  [].concat(
                                      _toConsumableArray(
                                          item[childrenColumnName]
                                      )
                                  ),
                                  sorterFn
                              )
                          )
                      )
                    : item
            })
        },
        renderExpandIcon: function renderExpandIcon(prefixCls) {
            let h = this.$createElement

            return function (_ref2) {
                let expandable = _ref2.expandable,
                    expanded = _ref2.expanded,
                    needIndentSpaced = _ref2.needIndentSpaced,
                    record = _ref2.record,
                    onExpand = _ref2.onExpand

                if (expandable) {
                    return h(
                        LocaleReceiver,
                        {
                            attrs: {
                                componentName: 'Table',
                                defaultLocale: defaultLocale.Table,
                            },
                        },
                        [
                            function (locale) {
                                let _classNames

                                return h(TransButton, {
                                    class: classNames(
                                        prefixCls + '-row-expand-icon',
                                        ((_classNames = {}),
                                        _defineProperty(
                                            _classNames,
                                            prefixCls + '-row-collapsed',
                                            !expanded
                                        ),
                                        _defineProperty(
                                            _classNames,
                                            prefixCls + '-row-expanded',
                                            expanded
                                        ),
                                        _classNames)
                                    ),
                                    on: {
                                        click: function click(event) {
                                            onExpand(record, event)
                                        },
                                    },
                                    attrs: {
                                        'aria-label': expanded
                                            ? locale.collapse
                                            : locale.expand,
                                        noStyle: true,
                                    },
                                })
                            },
                        ]
                    )
                }

                if (needIndentSpaced) {
                    return h('span', {
                        class:
                            prefixCls +
                            '-row-expand-icon ' +
                            prefixCls +
                            '-row-spaced',
                    })
                }

                return null
            }
        },
        renderPagination: function renderPagination(
            prefixCls,
            paginationPosition
        ) {
            let h = this.$createElement

            // 强制不需要分页
            if (!this.hasPagination()) {
                return null
            }
            let size = 'default'
            let pagination = this.sPagination

            if (pagination.size) {
                size = pagination.size
            } else if (this.size === 'middle' || this.size === 'small') {
                size = 'small'
            }
            let position = pagination.position || 'bottom'
            let total = pagination.total || this.filterDataCnt

            let cls = pagination['class'],
                style = pagination.style,
                // onChange = pagination.onChange,
                // onShowSizeChange = pagination.onShowSizeChange,
                restProps = _objectWithoutProperties(pagination, [
                    'class',
                    'style',
                    'onChange',
                    'onShowSizeChange',
                ]) // eslint-disable-line

            let paginationProps = mergeProps({
                key: 'pagination-' + paginationPosition,
                class: classNames(cls, prefixCls + '-pagination'),
                props: _extends({}, restProps, {
                    total,
                    size,
                    current: this.getMaxCurrent(total),
                }),
                style,
                on: {
                    change: this.handlePageChange,
                    showSizeChange: this.handleShowSizeChange,
                },
            })
            return total > 0 &&
                (position === paginationPosition || position === 'both')
                ? h(Pagination, paginationProps)
                : null
        },
        renderSelectionBox: function renderSelectionBox(type) {
            let _this9 = this

            let h = this.$createElement

            return function (_, record, index) {
                let rowKey = _this9.getRecordKey(record, index) // 从 1 开始
                let props = _this9.getCheckboxPropsByItem(record, index)
                let handleChange = function handleChange(e) {
                    type === 'radio'
                        ? _this9.handleRadioSelect(record, index, e)
                        : _this9.handleSelect(record, index, e)
                }
                let selectionBoxProps = mergeProps(
                    {
                        props: {
                            type,
                            store: _this9.store,
                            rowIndex: rowKey,
                            defaultSelection: _this9.getDefaultSelection(),
                        },
                        on: { change: handleChange },
                    },
                    props
                )

                return h('span', { on: { click: stopPropagation } }, [
                    h(SelectionBox, selectionBoxProps),
                ])
            }
        },
        renderRowSelection: function renderRowSelection(_ref3) {
            let _this10 = this

            let prefixCls = _ref3.prefixCls,
                locale = _ref3.locale,
                getPopupContainer = _ref3.getPopupContainer
            let h = this.$createElement
            let rowSelection = this.rowSelection

            let columns = this.columns.concat()
            if (rowSelection) {
                let data = this.getFlatCurrentPageData().filter(function (
                    item,
                    index
                ) {
                    if (rowSelection.getCheckboxProps) {
                        return !_this10.getCheckboxPropsByItem(item, index)
                            .props.disabled
                    }
                    return true
                })
                let selectionColumnClass = classNames(
                    prefixCls + '-selection-column',
                    _defineProperty(
                        {},
                        prefixCls + '-selection-column-custom',
                        rowSelection.selections
                    )
                )
                let selectionColumn = _defineProperty(
                    {
                        key: 'selection-column',
                        customRender: this.renderSelectionBox(
                            rowSelection.type
                        ),
                        className: selectionColumnClass,
                        fixed: rowSelection.fixed,
                        width: rowSelection.columnWidth,
                        title: rowSelection.columnTitle,
                    },
                    INTERNAL_COL_DEFINE,
                    { class: prefixCls + '-selection-col' }
                )
                if (rowSelection.type !== 'radio') {
                    let checkboxAllDisabled = data.every(function (
                        item,
                        index
                    ) {
                        return _this10.getCheckboxPropsByItem(item, index).props
                            .disabled
                    })
                    selectionColumn.title =
                        selectionColumn.title ||
                        h(SelectionCheckboxAll, {
                            attrs: {
                                store: this.store,
                                locale,
                                data,
                                getCheckboxPropsByItem:
                                    this.getCheckboxPropsByItem,
                                getRecordKey: this.getRecordKey,
                                disabled: checkboxAllDisabled,
                                prefixCls,

                                selections: rowSelection.selections,
                                hideDefaultSelections:
                                    rowSelection.hideDefaultSelections,
                                getPopupContainer:
                                    this.generatePopupContainerFunc(
                                        getPopupContainer
                                    ),
                            },
                            on: { select: this.handleSelectRow },
                        })
                }
                if ('fixed' in rowSelection) {
                    selectionColumn.fixed = rowSelection.fixed
                } else if (
                    columns.some(function (column) {
                        return column.fixed === 'left' || column.fixed === true
                    })
                ) {
                    selectionColumn.fixed = 'left'
                }
                if (columns[0] && columns[0].key === 'selection-column') {
                    columns[0] = selectionColumn
                } else {
                    columns.unshift(selectionColumn)
                }
            }
            return columns
        },
        renderColumnsDropdown: function renderColumnsDropdown(_ref4) {
            let _this11 = this

            let prefixCls = _ref4.prefixCls,
                dropdownPrefixCls = _ref4.dropdownPrefixCls,
                columns = _ref4.columns,
                locale = _ref4.locale,
                getPopupContainer = _ref4.getPopupContainer
            let h = this.$createElement
            let sortOrder = this.sSortOrder,
                filters = this.sFilters

            return treeMap(columns, function (column, i) {
                let _classNames3

                let key = getColumnKey(column, i)
                let filterDropdown = void 0
                let sortButton = void 0
                let customHeaderCell = column.customHeaderCell
                let isSortColumn = _this11.isSortColumn(column)
                if (
                    (column.filters && column.filters.length > 0) ||
                    column.filterDropdown
                ) {
                    let colFilters = key in filters ? filters[key] : []
                    filterDropdown = h(FilterDropdown, {
                        attrs: {
                            _propsSymbol: Symbol(),
                            locale,
                            column,
                            selectedKeys: colFilters,
                            confirmFilter: _this11.handleFilter,
                            prefixCls: prefixCls + '-filter',
                            dropdownPrefixCls:
                                dropdownPrefixCls || 'ant-dropdown',
                            getPopupContainer:
                                _this11.generatePopupContainerFunc(
                                    getPopupContainer
                                ),
                        },
                        key: 'filter-dropdown',
                    })
                }
                if (column.sorter) {
                    let sortDirections =
                        column.sortDirections || _this11.sortDirections
                    let isAscend = isSortColumn && sortOrder === 'ascend'
                    let isDescend = isSortColumn && sortOrder === 'descend'
                    let ascend =
                        sortDirections.indexOf('ascend') !== -1 &&
                        h(Icon, {
                            class:
                                prefixCls +
                                '-column-sorter-up ' +
                                (isAscend ? 'on' : 'off'),
                            attrs: {
                                type: 'caret-up',
                                theme: 'filled',
                            },
                            key: 'caret-up',
                        })

                    let descend =
                        sortDirections.indexOf('descend') !== -1 &&
                        h(Icon, {
                            class:
                                prefixCls +
                                '-column-sorter-down ' +
                                (isDescend ? 'on' : 'off'),
                            attrs: {
                                type: 'caret-down',
                                theme: 'filled',
                            },
                            key: 'caret-down',
                        })

                    sortButton = h(
                        'div',
                        {
                            attrs: { title: locale.sortTitle },
                            class: classNames(
                                prefixCls + '-column-sorter-inner',
                                ascend &&
                                    descend &&
                                    prefixCls + '-column-sorter-inner-full'
                            ),
                            key: 'sorter',
                        },
                        [ascend, descend]
                    )
                    customHeaderCell = function customHeaderCell(col) {
                        let colProps = {}
                        // Get original first
                        if (column.customHeaderCell) {
                            colProps = _extends(
                                {},
                                column.customHeaderCell(col)
                            )
                        }
                        colProps.on = colProps.on || {}
                        // Add sorter logic
                        let onHeaderCellClick = colProps.on.click
                        colProps.on.click = function () {
                            _this11.toggleSortOrder(column)
                            if (onHeaderCellClick) {
                                onHeaderCellClick.apply(undefined, arguments)
                            }
                        }
                        return colProps
                    }
                }
                return _extends({}, column, {
                    className: classNames(
                        column.className,
                        ((_classNames3 = {}),
                        _defineProperty(
                            _classNames3,
                            prefixCls + '-column-has-actions',
                            sortButton || filterDropdown
                        ),
                        _defineProperty(
                            _classNames3,
                            prefixCls + '-column-has-filters',
                            filterDropdown
                        ),
                        _defineProperty(
                            _classNames3,
                            prefixCls + '-column-has-sorters',
                            sortButton
                        ),
                        _defineProperty(
                            _classNames3,
                            prefixCls + '-column-sort',
                            isSortColumn && sortOrder
                        ),
                        _classNames3)
                    ),
                    title: [
                        h(
                            'span',
                            {
                                key: 'title',
                                class: prefixCls + '-header-column',
                            },
                            [
                                h(
                                    'div',
                                    {
                                        class: sortButton
                                            ? prefixCls + '-column-sorters'
                                            : undefined,
                                    },
                                    [
                                        h(
                                            'span',
                                            {
                                                class:
                                                    prefixCls + '-column-title',
                                            },
                                            [
                                                _this11.renderColumnTitle(
                                                    column.title
                                                ),
                                            ]
                                        ),
                                        h(
                                            'span',
                                            {
                                                class:
                                                    prefixCls +
                                                    '-column-sorter',
                                            },
                                            [sortButton]
                                        ),
                                    ]
                                ),
                            ]
                        ),
                        filterDropdown,
                    ],
                    customHeaderCell,
                })
            })
        },
        renderColumnTitle: function renderColumnTitle(title) {
            let _$data = this.$data,
                filters = _$data.sFilters,
                sortOrder = _$data.sSortOrder,
                sortColumn = _$data.sSortColumn
            // https://github.com/ant-design/ant-design/issues/11246#issuecomment-405009167

            if (title instanceof Function) {
                return title({
                    filters,
                    sortOrder,
                    sortColumn,
                })
            }
            return title
        },
        renderTable: function renderTable(_ref5) {
            let _classNames4,
                _this12 = this

            let prefixCls = _ref5.prefixCls,
                renderEmpty = _ref5.renderEmpty,
                dropdownPrefixCls = _ref5.dropdownPrefixCls,
                contextLocale = _ref5.contextLocale,
                contextGetPopupContainer = _ref5.getPopupContainer,
                transformCellText = _ref5.transformCellText
            let h = this.$createElement

            let _getOptionProps = getOptionProps(this),
                showHeader = _getOptionProps.showHeader,
                locale = _getOptionProps.locale,
                getPopupContainer = _getOptionProps.getPopupContainer,
                restProps = _objectWithoutProperties(_getOptionProps, [
                    'showHeader',
                    'locale',
                    'getPopupContainer',
                ])

            let data = this.getCurrentPageData()
            let expandIconAsCell =
                this.expandedRowRender && this.expandIconAsCell !== false

            // use props.getPopupContainer first
            let realGetPopupContainer =
                getPopupContainer || contextGetPopupContainer

            // Merge too locales
            let mergedLocale = _extends({}, contextLocale, locale)
            if (!locale || !locale.emptyText) {
                mergedLocale.emptyText = renderEmpty(h, 'Table')
            }

            let classString = classNames(
                ((_classNames4 = {}),
                _defineProperty(
                    _classNames4,
                    prefixCls + '-' + this.size,
                    true
                ),
                _defineProperty(
                    _classNames4,
                    prefixCls + '-bordered',
                    this.bordered
                ),
                _defineProperty(
                    _classNames4,
                    prefixCls + '-empty',
                    !data.length
                ),
                _defineProperty(
                    _classNames4,
                    prefixCls + '-without-column-header',
                    !showHeader
                ),
                _classNames4)
            )

            let columnsWithRowSelection = this.renderRowSelection({
                prefixCls,
                locale: mergedLocale,
                getPopupContainer: realGetPopupContainer,
            })
            let columns = this.renderColumnsDropdown({
                columns: columnsWithRowSelection,
                prefixCls,
                dropdownPrefixCls,
                locale: mergedLocale,
                getPopupContainer: realGetPopupContainer,
            }).map(function (column, i) {
                let newColumn = _extends({}, column)
                newColumn.key = getColumnKey(newColumn, i)
                return newColumn
            })

            let expandIconColumnIndex =
                columns[0] && columns[0].key === 'selection-column' ? 1 : 0
            if ('expandIconColumnIndex' in restProps) {
                expandIconColumnIndex = restProps.expandIconColumnIndex
            }
            let vcTableProps = {
                key: 'table',
                props: _extends(
                    { expandIcon: this.renderExpandIcon(prefixCls) },
                    restProps,
                    {
                        customRow: function customRow(record, index) {
                            return _this12.onRow(prefixCls, record, index)
                        },
                        components: this.sComponents,
                        prefixCls,
                        data,
                        columns,
                        showHeader,
                        expandIconColumnIndex,
                        expandIconAsCell,
                        emptyText: mergedLocale.emptyText,
                        transformCellText,
                    }
                ),
                on: getListeners(this),
                class: classString,
                ref: 'vcTable',
            }
            return h(VcTable, vcTableProps)
        },
    },

    render: function render() {
        let _this13 = this

        let h = arguments[0]
        let customizePrefixCls = this.prefixCls,
            customizeDropdownPrefixCls = this.dropdownPrefixCls,
            customizeTransformCellText = this.transformCellText

        let data = this.getCurrentPageData()
        let _configProvider = this.configProvider,
            getContextPopupContainer = _configProvider.getPopupContainer,
            tct = _configProvider.transformCellText

        let getPopupContainer =
            this.getPopupContainer || getContextPopupContainer
        let transformCellText = customizeTransformCellText || tct
        let loading = this.loading
        if (typeof loading === 'boolean') {
            loading = { props: { spinning: loading } }
        } else {
            loading = { props: _extends({}, loading) }
        }
        let getPrefixCls = this.configProvider.getPrefixCls
        let renderEmpty = this.configProvider.renderEmpty

        let prefixCls = getPrefixCls('table', customizePrefixCls)
        let dropdownPrefixCls = getPrefixCls(
            'dropdown',
            customizeDropdownPrefixCls
        )

        let table = h(LocaleReceiver, {
            attrs: {
                componentName: 'Table',
                defaultLocale: defaultLocale.Table,
                children: function children(locale) {
                    return _this13.renderTable({
                        prefixCls,
                        renderEmpty,
                        dropdownPrefixCls,
                        contextLocale: locale,
                        getPopupContainer,
                        transformCellText,
                    })
                },
            },
        })

        // if there is no pagination or no data,
        // the height of spin should decrease by half of pagination
        let paginationPatchClass =
            this.hasPagination() && data && data.length !== 0
                ? prefixCls + '-with-pagination'
                : prefixCls + '-without-pagination'
        let spinProps = _extends({}, loading, {
            class:
                loading.props && loading.props.spinning
                    ? paginationPatchClass + ' ' + prefixCls + '-spin-holder'
                    : '',
        })
        return h('div', { class: classNames(prefixCls + '-wrapper') }, [
            h(Spin, spinProps, [
                this.renderPagination(prefixCls, 'top'),
                table,
                this.renderPagination(prefixCls, 'bottom'),
            ]),
        ])
    },
}
