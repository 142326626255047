// 系统名称
const SYSTEM_NAME = process.env.VUE_APP_SYSTEM_NAME
// 版权申明
const COPYRIGHT = process.env.VUE_APP_COPYRIGHT
// 备案编号
const LICENSE_NUMBER = process.env.VUE_APP_LICENSE_NUMBER

export default {
    isMobile: false,
    theme: 'light',
    layout: 'side',
    multipage: true,
    fixedHeader: true,
    fixedSidebar: true,
    reviewOperation: {},


    systemName: SYSTEM_NAME, // 系统名称
    systemTagName: '企业端',


    copyright: COPYRIGHT, // 版权申明
    licenseNumber: LICENSE_NUMBER, // 备案编号


    role: null, // 用户菜单权限
    routes: [], // 菜单
    buttonRole: null, // 菜单按钮权限


    token: null,
    userInfo: null, // 用户信息
    enterpriseInfo: {},


    globalData: {}, // 全局参数
    projectList: [],
    projectInfo: {},


    initInformation: null, // 初始化信息
    authProperties: null, // 认证相关配置信息


    pageFApi: {}, // 页面绑定fApi
    pageFApiList: []
}
