<template>
    <div class="file-upload-description-list-wrapper">
        <custom-descriptions
            v-bind="$props"
            ref="contentItem"
            :data="localData"
            :field-list="localFieldList"
        />
    </div>
</template>

<script>
import CustomDescriptions from '@weights/CustomDescriptions'

import cloneDeep from 'lodash/cloneDeep'

import { BOOLEAN_PATTERN_TRUE } from '@constant/enum'
import { FILE_LABEL, FILE_KEY, FILE_UPLOAD_TYPE } from '@weights/CreateForm/parser/types'

export default {
    name: 'FileUploadDescriptionList',
    components: {
        CustomDescriptions
    },
    props: {
        // 展示边框
        needBordered: {
            type: Number,
            default: BOOLEAN_PATTERN_TRUE
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            type: String,
            default: ''
        },
        fieldList: {
            type: Array,
            default: () => []
        },
        column: {
            type: Number,
            default: 2
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        align: Number,
        colon: Number,
        fixed: Number
    },
    data() {
        return {
            localFieldList: [],
            labelKey: '',
            key: '',
            keyField: {},
            localData: {}
        }
    },
    watch: {
        fieldList: {
            handler(fieldList) {
                // 合并新的文件field
                // this.localFieldList = mergeFieldOfFile(fieldList)
                fieldList.forEach(field => {
                    if (field.componentType === FILE_LABEL) {
                        this.labelKey = field.fieldCode
                    }

                    if (field.componentType === FILE_KEY) {
                        this.key = field.fieldCode
                        this.keyField = field
                    }
                })

                this.formatData()
            },
            immediate: true
        },
        data: {
            handler() {
                this.formatData()
            }
        }
    },
    methods: {
        formatData() {
            const { data, keyField, key, labelKey } = this
            const fieldList = []
            const localData = {}
            data.forEach(item => {
                const field = cloneDeep(keyField)
                field.componentType = item.attributeType || FILE_UPLOAD_TYPE
                // field.defaultValue = item[key] ? JSON.parse(item[key]) : ''
                field.fieldCode = item['attributeCode']
                field.attributeCode = item['attributeCode']
                field.fieldName = item[labelKey]
                field.nullAllowed = !item.required
                field.props = {
                    fileNumber: item.fileNumber
                }
                fieldList.push(field)
                localData[item['attributeCode']] = item[key]
            })

            this.localData = localData
            this.localFieldList = fieldList
        },
        async fieldDataRefresh(data) {
            return await this.$refs['contentItem'].fieldDataRefresh(data)
        },
        getFormData() {
            return this.$refs['contentItem'].getFormData()
        }
    }
}
</script>
