<template>
    <div>
        <div class="custom-page-header">
            <div class="custom-page-header__title">{{ title }}</div>
            <div class="custom-page-header__extra">
                <template v-for="(button, index) in extraButton">
                    <a-button :key="index" @click="handleClick(button)">
                        {{ button.eventName }}
                    </a-button>
                </template>
            </div>
        </div>
        <slot />
        <custom-mutiple-modal ref="mutiple-modal" @on-ok="handleModalOk" />
    </div>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomMutipleModal from '@weights/CustomMutipleModal'

import childrenRefreshMixin from '@mixins/createPage/childrenRefresh'
import getFormData from '@mixins/createPage/formData'
import eventMixin from '@mixins/createPage/event'
import buttonMixin from '@mixins/createPage/button'

import { EVENT_TYPE_NORMAL } from '@constant/enum'

export default {
    name: 'WeightPageHeader',
    components: {
        AButton,
        CustomMutipleModal
    },
    mixins: [
        childrenRefreshMixin,
        getFormData,
        eventMixin,
        buttonMixin
    ],
    props: {
        title: {
            type: String,
            default: ''
        },
        data: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        eventList: {
            type: Array,
            default: () => []
        },
        extraTitle: {
            type: Object,
            default: () => ({})
        },
        childrenField: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        extraButton() {
            return this.getButtonList(this.eventList || [], EVENT_TYPE_NORMAL)
        }
    },
    methods: {
        async handleClick(event) {
            try {
                await this.emitEvent({
                    event,
                    localData: this.localData
                })
            } catch (error) {
                console.log(error.message)
            }
        },
        async dataRefresh(data) {
            try {
                this.fieldDataRefresh(data)
                this.$emit('data-refresh', data)
            } catch (error) {
                console.log(error.message)
                this.$message.error(error.message)
            }
        },
        handleModalOk(data) {
            this.dataRefresh(data)
        },
        /** 更新方法 */
        fieldDataRefresh(data) {
            // 子节点更新逻辑
            this.childrenFieldRefresh(data)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@assets/styles/varibles.scss';

.custom-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    background: #f7f8fa;
    border-radius: 4px 4px 0 0;

    &__title {
        font-size: $font-size-md;
        font-weight: bold;
        color: #323233;
        line-height: 16px;
    }
}
</style>
