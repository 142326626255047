import axios from '@service'
import { GET_UNIT_BY_PROJECT_CODE } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

export default async function getUnitService(payload = {}, config = {}) {
    const param = {
        ...GET_UNIT_BY_PROJECT_CODE,
        ...config,
        params: payload
    }

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || []
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
