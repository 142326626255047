<template>
    <a-select
        v-model="modelState"
        :disabled="disabled"
        :allow-clear="allowClear"
        :placeholder="placeholder"
        @blur="e => $emit('blur', e)"
    >
        <template v-for="item in options">
            <a-select-option :key="item.value" :value="item.value">
                {{ item.label }}
            </a-select-option>
        </template>
    </a-select>
</template>
<script>
import { Select } from 'ant-design-vue'
import { WHETHER_SELECT_LIST, WHETHER_SELECTED_YES, WHETHER_SELECTED_NO } from '@constant/enum'

import { isEmpty } from '@utils'

export default {
    name: 'WhetherSelect',
    components: {
        ASelect: Select,
        ASelectOption: Select.Option
    },
    props: {
        value: [Boolean, Number],
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowClear: {
            type: Boolean,
            default: false
        },
        useOriginalValue: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            options: Object.freeze(WHETHER_SELECT_LIST)
        }
    },
    computed: {
        modelState: {
            get() {
                const { value } = this
                if (isEmpty(value)) return
                if (this.useOriginalValue) return value
                return value ? WHETHER_SELECTED_YES : WHETHER_SELECTED_NO
            },
            set(v) {
                if (!isEmpty(v) && !this.useOriginalValue) {
                    v = v === WHETHER_SELECTED_YES
                }

                this.$emit('input', v)
                this.$emit('change', v)
            }
        }
    }
}
</script>
