/**
 * 项目资料
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import PageTemplate from '@weights/PageTemplate/Page'
import MarginPaymentDetail from '@weights/marginManagement/MarginPaymentDetail'
import MarginUtilizationDetail from '@weights/marginManagement/MarginUtilizationDetail'

import { SYSTEM_TYPE_ENT_SIDE } from '@constant/enum'

import {
    MARGIN_PAYMENT_MENU_CODE,
    MARGIN_PAYMENT_LIST_CODE,
    MARGIN_PAYMENT_DETAIL_CODE,
    MARGIN_MANAGEMENT_MENU_CODE,
    MARGIN_UTILIZATION_MENU_CODE,
    MARGIN_UTILIZATION_LIST_CODE,
    MARGIN_UTILIZATION_DETAIL_CODE
} from '@constant/menu_code'

import { MARGIN_MANAGE_MENU_SORT } from '@constant/menu_sort'

const routes = [
    {
        path: '/margin_management',
        name: 'MarginManagement',
        component: BasicLayout,
        code: MARGIN_MANAGEMENT_MENU_CODE,
        redirect: {
            name: MARGIN_PAYMENT_MENU_CODE
        },
        meta: {
            title: '保证金管理',
            icon: 'baozhengjinguanli',
            iconType: 'iconfont'
        },
        sort: MARGIN_MANAGE_MENU_SORT,
        children: [
            {
                path: 'payment',
                name: MARGIN_PAYMENT_MENU_CODE,
                component: RouteLayout,
                code: MARGIN_PAYMENT_MENU_CODE,
                redirect: {
                    name: MARGIN_PAYMENT_LIST_CODE
                },
                meta: {
                    title: '保证金管理'
                },
                children: [
                    {
                        path: 'list',
                        name: MARGIN_PAYMENT_LIST_CODE,
                        component: PageTemplate,
                        code: MARGIN_PAYMENT_LIST_CODE,
                        hidden: true,
                        meta: {
                            title: '保证金管理列表',
                            breadcrumb: false,
                            layoutCode: MARGIN_PAYMENT_LIST_CODE
                        }
                    },
                    {
                        path: 'detail',
                        name: MARGIN_PAYMENT_DETAIL_CODE,
                        component: MarginPaymentDetail,
                        code: MARGIN_PAYMENT_DETAIL_CODE,
                        hidden: true,
                        meta: {
                            title: '保证金详情',
                            breadcrumb: true,
                            systemType: SYSTEM_TYPE_ENT_SIDE
                        }
                    }
                ]
            },
            {
                path: 'utilization',
                name: MARGIN_UTILIZATION_MENU_CODE,
                component: RouteLayout,
                code: MARGIN_UTILIZATION_MENU_CODE,
                redirect: {
                    name: MARGIN_UTILIZATION_LIST_CODE
                },
                meta: {
                    title: '保证金动用'
                },
                children: [
                    {
                        path: 'list',
                        name: MARGIN_UTILIZATION_LIST_CODE,
                        component: PageTemplate,
                        code: MARGIN_UTILIZATION_LIST_CODE,
                        hidden: true,
                        meta: {
                            title: '保证金动用列表',
                            breadcrumb: false,
                            layoutCode: MARGIN_UTILIZATION_LIST_CODE
                        }
                    },
                    {
                        path: 'detail',
                        name: MARGIN_UTILIZATION_DETAIL_CODE,
                        component: MarginUtilizationDetail,
                        code: MARGIN_UTILIZATION_DETAIL_CODE,
                        hidden: true,
                        meta: {
                            title: '保证金动用详情',
                            breadcrumb: true,
                            systemType: SYSTEM_TYPE_ENT_SIDE
                        }
                    }
                ]
            }
        ]
    }
]

export default routes
