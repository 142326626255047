<template>
    <a-dropdown class="app-header-avatar">
        <span class="app-header-avatar__head">
            <span>{{ userInfo && userInfo.realName || globalData.enterpriseName }}</span>
            <a-icon class="app-header-avatar__icon-down" type="down" />
        </span>
        <a-menu slot="overlay" style="width: 150px">
            <a-menu-item @click="handleLogOutClick">
                <span style="color: #D40000">退出登录</span>
            </a-menu-item>
        </a-menu>
    </a-dropdown>
</template>

<script>
import {
    Icon as AIcon,
    Dropdown as ADropdown,
    Menu as AMenu
} from 'ant-design-vue'
import logoutService from '@service/login/logoutService'


export default {
    name: 'HeaderAvatar',
    components: {
        AIcon,
        ADropdown,
        AMenu,
        AMenuItem: AMenu.Item
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {}
        },
        avatar() {
            const { userInfo } = this
            return userInfo && userInfo.avatar
        },
        globalData() {
            return this.$store.getters.globalData
        }
    },
    methods: {
        async handleLogOutClick() {
            try {
                await logoutService()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
            this.$store.dispatch('logOut')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@assets/styles/varibles.scss';

.app-header-avatar {
    height: 100%;
    display: flex;
    align-items: center;

    &__head {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        line-height: 20px;
        cursor: pointer;

        &.ant-dropdown-open {
            background: #0969E4;
            transition: .4s;
        }
    }

    .avatar {
        margin: 20px 8px 20px 0;
        color: #1890ff;
        background: hsla(0, 0%, 100%, 0.85);
        vertical-align: middle;
    }

    &__icon-down {
        color: #fff;
        margin-left: $space-row-base;
    }
}

.ant-dropdown-menu-item {
    line-height: 30px;
    font-size: 14px;
}

.ant-dropdown-menu-item:hover {
    // color: #1877F2;
    background: #F4F5F7;
}

.ant-dropdown-menu {
    padding: 0;
}

</style>
