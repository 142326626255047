// 此文件设计一些数据结构转换的方法

// tree to map tree(过滤children为null)
export function toMapAndTree(data, $name = 'label', $value = 'value') {
    const map = new Map()

    function fn(data, $name, $value, parentId) {
        const tree = []
        data.forEach(item => {
            let obj = {}

            const { [$name]: label, [$value]: value, children } = item
            const mapItem = {
                parentId,
                parent: parentId && map.get(parentId),
                [$name]: label,
                [$value]: value,
                children
            }
            map.set(value, mapItem)
            if (children && children.length) {
                fn(children, $name, $value, value)

                obj = {
                    label: item[$name],
                    value: item[$value],
                    children: recursionToTree(item.children, $name, $value)
                }
            } else {
                obj = {
                    label: item[$name],
                    value: item[$value]
                }
            }
            tree.push(obj)
        })
        return tree
    }

    const tree = fn(data, $name, $value, null)
    return {
        map,
        tree
    }
}

// 递归树形 过滤children为null字段
export const recursionToTree = function (arr, label = 'name', value = 'id') {
    let res = []
    for (let i = 0; i < arr.length; i++) {
        let obj = {}
        const item = arr[i]
        if (!item.children) {
            obj = {
                label: item[label],
                value: item[value]
                // children: null
            }
        } else {
            obj = {
                label: item[label],
                value: item[value],
                children: recursionToTree(item.children, label, value)
            }
        }
        res.push(obj)
    }
    return res
}

// tree to map
export const toMap = function (data, $name = 'label', $value = 'value') {
    const map = new Map()

    function fn(data, $name, $value, parentId) {
        data.forEach(item => {
            const { [$name]: label, [$value]: value, children } = item
            const mapItem = {
                parentId,
                parent: parentId && map.get(parentId),
                [$name]: label,
                [$value]: value,
                children
            }
            map.set(value, mapItem)
            if (children && children.length) {
                fn(children, $name, $value, value)
            }
        })
    }

    fn(data, $name, $value, null)
    return map
}
