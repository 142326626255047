<template>
    <section class="custom-table-wrapper">
        <table-search-panel
            v-if="showSearchPanel"
            :query-list="searchPannel"
            :search-loading="loading"
            @search="handleSearch"
            @refresh="handleRefresh"
            @params-change="handleSearchParamsChange"
        />
        <custom-table
            :row-key="
                canSelect ? canSelect.attributeCode : (record, index) => index
            "
            :loading="loading"
            class="custom-table"
            :columns="columns"
            :data-source="records"
            :scroll="scrollConfig"
            :row-selection="
                canSelect
                    ? {
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange,
                    }
                    : null
            "
            :pagination="pagination"
            @change="handleTableChange"
        >
            <template slot="title">
                <table-header
                    v-if="showTitle"
                    :title="title"
                    :extra-title="extraTitle"
                >
                    <template #extra>
                        <template v-for="(button, index) in extraButton">
                            <a-button
                                :key="index"
                                style="margin-left: 15px"
                                @click="handleExtraClick(button)"
                            >
                                {{ button.eventName }}
                            </a-button>
                        </template>
                    </template>
                </table-header>
            </template>
            <custom-button-space v-if="showFooter" slot="footer">
                <a-button
                    v-for="(item, idx) in footerButton"
                    :key="idx"
                    @click="handleFooterButton(item)"
                >
                    {{ item.eventName }}
                </a-button>
            </custom-button-space>
            <template slot="action" slot-scope="text, action, index">
                <div class="action-wrap">
                    <span v-if="!getButton(action).length">{{ emptyValuePlaceholder }}</span>
                    <span
                        v-for="(item, idx) in getButton(action)"
                        v-else
                        :key="idx"
                    >
                        <a-button
                            type="link"
                            style="padding-right: 0"
                            @click="() => handleButtonClick(item, text, index)"
                        >
                            {{ item.eventName }}
                        </a-button>
                        <a-divider
                            v-if="idx < getButton(action).length - 1"
                            type="vertical"
                        />
                    </span>
                </div>
            </template>
        </custom-table>
        <custom-mutiple-modal ref="mutiple-modal" @on-ok="handleModalOk" />

        <component
            :is="directConnectModalComponent"
            v-bind="directConnectModalProp"
            @on-ok="handleModalOk"
            @on-close="handleCloseDirectConnectModal"
        />
    </section>
</template>

<script>
/* eslint-disable eqeqeq */
import { Button as AButton, Divider as ADivider } from 'ant-design-vue'
import CustomTable from '@components/CustomTable'
import CustomButtonSpace from '@components/CustomButtonSpace'
import CustomMutipleModal from '@weights/CustomMutipleModal'

import TableHeader from './Title'
import TableSearchPanel from './TableSearchPanel'

import { isEmpty } from '@utils'
import { clearHiddenField } from '@utils/pageCreate'

import childrenRefreshMixin from '@mixins/createPage/childrenRefresh'
import eventMixin from '@mixins/createPage/event'
import getFormData from '@mixins/createPage/formData'
import tableShowTotal from '@mixins/tableShowTotal'
import buttonMixin from '@mixins/createPage/button'
import directConnectModalMixin from '@/mixins/createPage/event/directConnectModal'

import { CELL_MAX_WIDTH, DEFAULT_PAGES_SIZE, EMPTY_VALUE_PLACEHOLDER } from '@config'
import { customRender } from './config'

import {
    EVENT_TYPE_DETAIL,
    EVENT_TYPE_NORMAL,
    EVENT_TYPE_DEFAULT,
    EVENT_TYPE_CHECK,
    EVENT_TYPE_CHECK_ALL,

    QUERY_STATE_HIDDEN,
    QUERY_STATE_OPTIONAL,
    QUERY_STATE_GLOBAL,

    LAYOUT_TYPE_FORM,

    EVENT_OPERATION_TYPE_TABLE,
    EVENT_OPERATION_TYPE_LIST,

    FIELD_TYPE_CHECK
} from '@constant/enum'

const LIST_PAGE_SIZE = 999

export default {
    name: 'CreatePageTable',
    components: {
        AButton,
        ADivider,
        CustomTable,
        TableHeader,
        TableSearchPanel,
        CustomButtonSpace,
        CustomMutipleModal
    },
    mixins: [
        childrenRefreshMixin,
        getFormData,
        eventMixin,
        tableShowTotal,
        buttonMixin,
        directConnectModalMixin
    ],
    props: {
        fieldList: {
            type: Array,
            default: () => []
        },
        data: {
            type: [Object, Array],
            default: () => ({})
        },
        title: {
            type: String
        },
        eventList: {
            type: Array,
            default: () => []
        },
        extraTitle: {
            type: Object,
            default: () => ({})
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        tableType: {
            type: Number,
            default: 1
        },
        uid: {
            type: String,
            default: ''
        },
        stepId: {
            type: String
        },
        parentChildren: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            emptyValuePlaceholder: EMPTY_VALUE_PLACEHOLDER,
            size: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            scrollConfig: {
                x: 800,
                y: false
            },

            // 搜索参数
            searchParams: {},
            selectedRowKeys: [],
            records: [],
            loading: false
        }
    },
    computed: {
        canSelect() {
            return this.fieldList.filter(
                item => item.fieldType === FIELD_TYPE_CHECK
            )[0]
        },
        columns() {
            const { eventList } = this
            const columns = clearHiddenField(this.fieldList).map(item => {
                const { fieldName, fieldCode, componentType } = item
                return {
                    title: fieldName,
                    key: fieldCode,
                    dataIndex: fieldCode,
                    customRender: customRender(componentType),
                    width: CELL_MAX_WIDTH
                }
            })

            // 根据是否有详情事件来展示操作列
            const hasDetailEvent = eventList.findIndex(
                item => item.eventType === EVENT_TYPE_DETAIL
            )
            if (
                hasDetailEvent > -1
            ) {
                columns.push({
                    title: '操作',
                    scopedSlots: {
                        customRender: 'action'
                    },
                    customHeaderCell: column => {
                        column.align = 'right'
                        return column
                    },
                    fixed: 'right'
                    // width: 200
                })
            }
            return columns
        },
        // 更新事件
        refreshEvent() {
            let event = null
            this.eventList.forEach(item => {
                if (item.eventType === EVENT_TYPE_DEFAULT) {
                    event = item
                }
            })
            return event
        },
        // 请求参数
        queryCriteriaList() {
            const { refreshEvent } = this

            return refreshEvent?.queryCriteriaList || []
        },
        showSearchPanel() {
            const { searchPannel } = this
            return searchPannel && searchPannel.length
        },
        searchPannel() {
            const { queryCriteriaList } = this
            const searchPannel = queryCriteriaList.filter(
                item => item.queryState !== QUERY_STATE_HIDDEN &&
                    item.queryState !== QUERY_STATE_OPTIONAL && item.queryState !== QUERY_STATE_GLOBAL
            )
            return searchPannel
        },
        // 展示左小角 按钮组合
        showFooter() {
            return this.canSelect && this.records.length > 0
        },
        pagination() {
            const { total, size, current, tableShowTotal } = this
            if (size === LIST_PAGE_SIZE) {
                return false
            }
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: size,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        extraButton() {
            return this.getButtonList(this.eventList || [], EVENT_TYPE_NORMAL)
        },
        // 左下角按钮
        footerButton() {
            const { eventList } = this
            const list = eventList.filter(
                event => [EVENT_TYPE_CHECK, EVENT_TYPE_CHECK_ALL].indexOf(
                    event.eventType
                ) !== -1
            )
            return list
        }
    },
    watch: {
        data: {
            handler(data) {
                if (data) {
                    if (data.records) {
                        this.total = data.total
                        this.records = data.records
                        // console.log(this.searchPannel, 'dataChange')
                    } else {
                        this.total = 0
                        this.records = data
                    }
                }
            },
            deep: true,
            immediate: true
        },
        eventList: {
            handler(eventList) {
                if (eventList.length > 0) {
                    const event = eventList.filter(
                        event => event.eventType === EVENT_TYPE_DEFAULT
                    )[0]
                    if (event) {
                        // 如果是列表则隐藏分页器
                        if (event.operationType === EVENT_OPERATION_TYPE_LIST) {
                            this.size = LIST_PAGE_SIZE
                        }
                    } else {
                        // 没有刷新事件隐藏分页
                        this.size = LIST_PAGE_SIZE
                    }
                }
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        getButton({
            operation = '000000000000000', // '1111111111111111',
            workflowOperation = '0000000000000000' // '1111111111111111'
        }) {
            return this.getButtonList(this.eventList || [], EVENT_TYPE_DETAIL, {
                operation,
                workflowOperation
            })
        },
        handleSearch(params) {
            this.searchParams = params
            this.current = 1
            this.getList()
        },
        handleRefresh() {
            this.current = 1
            this.getList()
            this.cardDataRefresh?.()
        },
        // 可选择 操作的事件
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.size = pageSize
            this.getList()
        },
        // 点击左下角按钮
        async handleFooterButton(event) {
            try {
                if (event.eventType === EVENT_TYPE_CHECK) {
                    if (this.selectedRowKeys.length > 0) {
                        await this.triggerEventServer(event, {
                            checkItem: this.selectedRowKeys,
                            uid: this.uid,
                            id: this.stepId
                        })
                        this.selectedRowKeys = []
                    } else {
                        this.$message.warn('请选择勾选项')
                        return
                    }
                }

                if (event.eventType === EVENT_TYPE_CHECK_ALL) {
                    // 根据查询条件全部勾选
                    await this.triggerEventServer(event, {
                        ...this.searchParams,
                        uid: this.uid,
                        id: this.stepId
                    })
                }

                // 当弹窗中的表格触发左下角按钮操作，需要更新外部数据（风险管理 -> 添加重点工程）
                this.handleModalRefresh?.()

                this.handleSearch()
            } catch (error) {
                console.log(error.message)
                this.$message.error(error.message)
            }
        },
        /**
         * 获取列表数据
         */
        async getList() {
            try {
                const { current, size, uid, refreshEvent, queryCriteriaList } = this

                if (refreshEvent) {
                    this.loading = true

                    const globalConfig = this.getGlobalParams(queryCriteriaList)

                    let id = ''
                    if (queryCriteriaList) {
                        const index = queryCriteriaList.findIndex(
                            query => query.fieldCode === 'id'
                        )

                        if (index > -1) {
                            // 有id字段，但是默认表单外层没有id字段，所以要从相邻的layout中获取id
                            this.parentChildren.forEach(child => {
                                if (child.layoutType === LAYOUT_TYPE_FORM) {
                                    id = child.props?.data?.id || id
                                }
                            })
                        }
                    }
                    const params = {
                        ...globalConfig,
                        ...this.searchParams,
                        uid,
                        id: id || this.stepId,
                        current,
                        size
                    }
                    // 请求目标事件
                    const { result: eventResult } =
                        await this.triggerEventServer(refreshEvent, params)

                    const dataString = JSON.parse(eventResult.data.dataString)

                    // 设置本地数据
                    if (refreshEvent.operationType === EVENT_OPERATION_TYPE_TABLE) {
                        // 表格数据处理
                        Object.assign(this, {
                            total: dataString.total,
                            records: dataString.records
                        })
                    } else {
                        // 无分页的数据处理
                        Object.assign(this, {
                            total: 0,
                            records: dataString
                        })
                    }

                    // 更新tab上的总数
                    this.tabDataRefresh?.(dataString)
                } else {
                    this.$message.error('没有找到对应更新事件')
                }
            } catch (error) {
                console.log(error)
                this.$message.error(error.message)
            } finally {
                this.loading = false
            }
        },
        fieldDataRefresh() {
            this.getList()
        },
        async handleButtonClick(button, record) {
            try {

                // 对提交的默认参数进行调整 attributeCode -> fieldCode
                const _params = {}
                this.fieldList.forEach(field => {
                    if (!isEmpty(record[field.attributeCode])) {
                        _params[field.fieldCode] = record[field.attributeCode]
                    }
                })
                await this.emitEvent({
                    event: button,
                    localData: record,
                    _params: {
                        ...record,
                        ..._params
                    }
                })
            } catch (e) {
                console.log(e)
                this.$message.error(e.message)
            }
        },
        /**
         * 获取table内的数据
         * @returns {{data: String, number: Number}} 返回table的数据 data：table数据转字符串结果，number：table数据数量
         */
        getTableData() {
            const res = []
            this.records.forEach(record => {
                const _params = {}
                // 转换一次 attributeCode -> fieldCode
                this.fieldList.forEach(field => {
                    if (!isEmpty(record[field.attributeCode])) {
                        _params[field.fieldCode] = record[field.attributeCode]
                    }
                })
                res.push(_params)
            })

            return {
                data: JSON.stringify(res),
                number: res.length
            }
        },
        async handleExtraClick(event) {
            try {
                await this.emitEvent({
                    event
                })
            } catch (error) {
                console.log(error.message)
            }
        },
        handleModalOk(value = {}) {
            this.fieldDataRefresh(value)
        },
        handleSearchParamsChange(data) {
            this.searchParams = {}
            for (let key in data) {
                if (!isEmpty(data[key])) {
                    this.searchParams[key] = data[key]
                }
            }
        }
    },
    inject: {
        cardDataRefresh: {
            default: () => {}
        },
        handleModalRefresh: {
            default: () => {} // 当弹窗中的表格触发左下角按钮操作，需要更新外部数据（风险管理 -> 添加重点工程）
        },
        tabDataRefresh: {
            default: () => {}
        }
    }
}
</script>

<style lang="scss">
.custom-table {
    margin-bottom: 20px;

    .ant-table-thead > tr > th {
        padding: 0 16px !important;
        height: 48px;
    }

    .ant-table-tbody > tr > td {
        padding: 0 16px !important;
        height: 56px;
    }

    .ant-table-footer {
        position: absolute;
        background: #fff;
        border: none !important;
    }

    .action-wrap {
        white-space: nowrap;
        text-align: right;
    }

    .ant-table-pagination.ant-pagination {
        margin: 16px 0 0;
    }
}
</style>
