<template>
    <div class="team-management-panel__personnel-panel">
        <a-spin :spinning="loading">
            <team-info :record="teamInfo" />

            <custom-table
                :row-key="rowKey"
                :loading="tableLoading"
                :scroll="scrollConfig"
                :columns="tableColumns"
                :data-source="tableData"
                :pagination="pagination"
                @change="handleTableChange"
            >
                <template #title>
                    <span>进场人员</span>

                    <template v-if="!readOnly && !isEmptyTeamId">
                        <!-- 添加人员 -->
                        <a-button @click.stop="handleAppendMember">
                            添加人员
                        </a-button>

                        <!-- 批量导入 -->
                        <a-button @click.stop="handleBatchImport">
                            批量导入
                        </a-button>

                        <!-- 编辑班组长 -->
                        <a-button @click.stop="handleEditLeader">
                            编辑班组长
                        </a-button>
                    </template>
                </template>

                <template #action="text, record">
                    <action-cell
                        :record="record"
                        :row-key="rowKey"
                        :read-only="readOnly"
                        @on-view-enter-info="() => handleViewEnterInfo(text)"
                        @on-after-remove="() => getMemberData('tableLoading')"
                    />
                </template>
            </custom-table>
        </a-spin>

        <custom-mutiple-modal ref="mutiple-modal" @on-ok="() => getMemberData()" />

        <component
            :is="directConectModalName"
            v-bind="directConectModalProp"
            @on-refresh="() => getMemberData()"
            @on-close="handleDirectConectModal"
        />
    </div>
</template>
<script>
import { Spin as ASpin, Button as AButton } from 'ant-design-vue'
import CustomTable from '@components/CustomTable'
import CustomMutipleModal from '@/weights/CustomMutipleModal'

import TeamInfo from './TeamInfo'
import ActionCell from './ActionCell'

import tableShowTotal from '@mixins/tableShowTotal'

import { isEmpty } from '@utils'

import { DEFAULT_PAGES_SIZE } from '@config'
import { ROW_KEY, tableColumns } from './config'

import { EVENT_TYPE_NORMAL, INTERFACE_TYPE_MODAL } from '@/constant/enum'

import eventMixin from '@/mixins/createPage/event'

import getMemberService from '@service/teamManagement/getMemberService'

export default {
    name: 'PersonnelPanel',
    components: {
        ASpin,
        AButton,
        TeamInfo,
        ActionCell,
        CustomTable,
        CustomMutipleModal,
        BatchImportModal: () => import('./BatchImportModal')
    },
    mixins: [tableShowTotal, eventMixin],
    props: {
        preMemberNum: Number,
        timestamp: Number,
        teamId: String,
        readOnly: Boolean,
        projectCode: String
    },
    data() {
        return {
            // 表格列配置
            tableColumns,

            // 表格分页相关状态
            total: 0,
            current: 1,
            limit: DEFAULT_PAGES_SIZE,

            // 表格行主键
            rowKey: ROW_KEY,

            // 是否正在获取数据（整个数据）
            loading: false,
            // 是否正在获取数据（仅表格中的数据）
            tableLoading: false,

            // 班组信息（班组长、状态等）
            teamInfo: {},
            // 入场人员列表数据
            tableData: [],
            scrollConfig: {
                x: 1000,
                y: 500
            },

            modalType: '',

            // 直连弹框相关状态
            directConectModalName: '',
            directConectModalProp: {
                visible: false
            }
        }
    },
    computed: {
        // 班组 id 是否为空
        isEmptyTeamId() {
            return isEmpty(this.teamId)
        },
        // 表格的分页传参
        pagination() {
            return {
                total: +this.total,
                pageSize: this.limit,
                current: this.current,
                showLessItems: true,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => this.tableShowTotal(total, range)
            }
        }
    },
    watch: {
        timestamp() {
            if (this.isEmptyTeamId) {
                this.changeMemberData()
            } else {
                this.getMemberData()
            }
        }
    },
    methods: {
        // 获取入场人员列表及相关数据
        async getMemberData(loadingType = 'loading') {
            try {
                this.$set(this, loadingType, true)

                const _params = {
                    teamId: this.teamId,
                    size: this.limit,
                    current: this.current
                }

                this.changeMemberData(await getMemberService(_params))
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.$set(this, loadingType, false)
            }
        },
        // 添加人员
        handleAppendMember() {
            // 添加人员事件
            const event = {
                operationType: 20,
                eventType: EVENT_TYPE_NORMAL,
                interfaceType: INTERFACE_TYPE_MODAL,
                permissionCode: 'globalOperation',
                eventCode: 'member_roster_10',
                eventName: '添加人员'
            }

            this.modalType = 'addMember'

            this.emitEvent({
                event,
                _params: {
                    id: this.teamId,
                    projectCode: this.projectCode
                }
            })

        },
        // 批量导入
        handleBatchImport() {
            this.directConectModalName = 'BatchImportModal'
            this.directConectModalProp = {
                visible: true,
                teamId: this.teamId,
                projectCode: this.projectCode
            }
        },
        // 编辑班组长
        handleEditLeader() {
            const event = {
                operationType: 20,
                eventType: EVENT_TYPE_NORMAL,
                interfaceType: INTERFACE_TYPE_MODAL,
                permissionCode: 'globalOperation',
                eventCode: 'group_leader_20',
                eventName: '编辑班组长'
            }

            this.modalType = 'editLeader'

            this.emitEvent({
                event,
                _params: {
                    id: this.teamId,
                    projectCode: this.projectCode
                }
            })
        },
        // 查看入场信息
        handleViewEnterInfo(person) {
            let eventCode = 'mobilization_information_300'
            if (this.readOnly) {
                eventCode = 'gov_mobilization_information_300'
            }
            const event = {
                operationType: 60,
                eventType: EVENT_TYPE_NORMAL,
                interfaceType: INTERFACE_TYPE_MODAL,
                permissionCode: 'globalOperation',
                eventCode,
                eventName: '查看入场信息'
            }

            this.modalType = 'viewEnterInfo'

            this.emitEvent({
                event,
                _params: {
                    ...person,
                    teamId: this.teamId
                }
            })
        },
        // 处理表格 `change` 事件
        handleTableChange(pagination) {
            this.current = pagination.current
            this.limit = pagination.pageSize

            this.getMemberData('tableLoading')
        },
        // 更改入场人员列表及相关数据
        changeMemberData(result = {}) {
            const { page, ...teamInfo } = result || {}
            const { total, records } = page || {}

            this.total = +total || 0
            this.teamInfo = teamInfo
            this.tableData = records || []

            if (this.preMemberNum !== this.total) {
                this.$emit('on-num-change', this.total)
            }
        },
        handleDirectConectModal() {
            this.$set(this.directConectModalProp, 'visible', false)
        }
    }
}
</script>
