<template>
    <div>
        <div class="custom-step">
            <a-steps :current="step">
                <a-step v-for="item in list" :key="item.layoutCode">
                    <template slot="title">
                        {{ item.name }}
                    </template>
                </a-step>
            </a-steps>
        </div>

        <div>
            <template v-for="(item, index) in list">
                <create-page
                    v-if="step === index"
                    :ref="`pageRef-${index}`"
                    :key="index"
                    :rule="rules[index]"
                    :loading="item.loading"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { Steps as ASteps } from 'ant-design-vue'

import { parserPanel } from '@/weights/CreatePage/parse'

// mixins
import childrenRefreshMixin from '@/mixins/createPage/childrenRefresh'
import getFormData from '@/mixins/createPage/formData'
import evetnMixin from '@/mixins/createPage/event'

import { INTERFACE_TYPE_DEFAULT, EVENT_TYPE_NORMAL } from '@/constant/enum'

import uuid from 'uuid'

// 是否自己渲染页面的样式
const IS_SELT_CONTROL = 1
export default {
    name: 'AddPlan',
    components: {
        ASteps,
        AStep: ASteps.Step,
        CreatePage: () => import('@/weights/CreatePage')
    },
    mixins: [childrenRefreshMixin, getFormData, evetnMixin],
    props: {
        list: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        },
        selfControl: {
            type: Number
        },
        mode: {
            type: Number,
            default: 0
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        uid: {
            type: String
        },
        rule: {
            type: Array,
            default: () => []
        },
        requestParams: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            rules: [],
            step: 0,
            localUid: '',
            defaultParams: {},
            formData: [] // 保存的数据
        }
    },
    watch: {
        uid: {
            handler(uid) {
                if (uid) {
                    this.localUid = uid
                } else {
                    this.localUid = uuid()
                }
                this.initFirstPane()
                // this.stepChange(this.step)
            },
            immediate: true
        },
        requestParams: {
            handler(params) {
                if (params.id) {
                    this.defaultParams.id = params.id
                }
            },
            immediate: true
        }
    },
    mounted() {
        // this.uid = uuid()
        // this.stepChange(this.step)
    },
    methods: {
        async stepChange(tabIndex) {
            const { localUid: uid, defaultParams } = this

            this.currentKey = tabIndex
            const tab = this.list[tabIndex]
            // 如果是自己控制需要
            tab.loading = true
            // 获取默认加载事件
            const initEvent = tab.eventList.filter(
                event => event.eventType === IS_SELT_CONTROL
            )[0]

            // 如果存在事件
            if (initEvent) {
                const { eventResult } = await this.triggerEvent(
                    initEvent,
                    {},
                    {
                        uid,
                        ...defaultParams
                    }
                )
                eventResult.props = {
                    uid,
                    setpId: defaultParams.id
                }

                // 将本地数据反写
                if (this.formData[tabIndex]) {
                    eventResult.data.dataString = this.formData[tabIndex]
                }
                const formatData = parserPanel(eventResult)
                this.$set(this.rules, tabIndex, [formatData])
                tab.loading = false
            } else {
                this.$message('没有初始化事件')
            }
        },
        async handleNextStep(event, changeEventList, onOk) {
            try {
                const { step, list, localUid: uid, defaultParams } = this
                const pageRef = this.$refs[`pageRef-${step}`][0]
                const fApi = pageRef.fApi

                if (event.eventName === '下一步') {
                    // 如果不是本地写进事件，则调用服务端接口
                    if (!event.isLocal) {
                        const valid = pageRef?.vaildatorFromData(fApi).valid
                        if (valid) {
                            const data = pageRef.getChildrenFormData(fApi, pageRef.childrenField)

                            const params = {
                                uid,
                                ...defaultParams,
                                ...data
                            }

                            // 将值存到内容上去
                            this.formData[step] = data
                            console.log(this.formData)

                            await this.triggerEventServer(event, params)
                        } else {
                            return
                        }
                    }

                    // 切换到下一步
                    this.step += 1
                    if (this.step === list.length) {
                        onOk()
                    } else {
                        await this.stepChange(this.step)
                        changeEventList(this.getGlobalEventList())
                    }
                } else if (event.eventName === '上一步') {
                    this.step -= 1
                    if (this.step >= 0) {
                        // await this.stepChange(this.step)
                        // 将本地数据反写
                        if (this.formData[this.step]) {
                            this.rules[this.step][0].data.dataString =
                                // this.formData[this.step]
                                this.$set(
                                    this.rules[this.step][0].props,
                                    'data',
                                    this.formData[this.step]
                                )
                        }
                        changeEventList(this.getGlobalEventList())
                    }
                } else {
                    const valid = pageRef?.vaildatorFromData(fApi).valid
                    if (valid) {
                        const data = pageRef.getChildrenFormData(fApi)

                        const params = {
                            uid,
                            ...defaultParams,
                            ...data
                        }

                        await this.triggerEventServer(event, params)

                        if (this.step === list.length - 1) {
                            onOk()
                        }
                    } else {
                        return
                    }
                }
            } catch (error) {
                console.log(error)
                this.$message.error(error.message)
            }
        },
        /**
         * 更新弹框底部按钮的逻辑
         */
        getGlobalEventList() {
            const { step, list } = this
            const localEventList = []

            // 添加上一步事件
            if (step !== 0) {
                localEventList.push({
                    operationType: 20,
                    eventType: EVENT_TYPE_NORMAL,
                    interfaceType: INTERFACE_TYPE_DEFAULT,
                    permissionCode: 'globalOperation',
                    eventCode: '',
                    eventName: '上一步',
                    isLocal: true
                })
            }

            // 添加下一步自定义按钮
            let hasNextStep =
                this.rules[this.step][0]?.eventList.filter(
                    event => event.eventName === '下一步'
                ).length > 0

            if (!hasNextStep && step + 1 < list.length) {
                localEventList.push({
                    operationType: 20,
                    eventType: EVENT_TYPE_NORMAL,
                    interfaceType: INTERFACE_TYPE_DEFAULT,
                    permissionCode: 'globalOperation',
                    eventCode: '',
                    eventName: '下一步',
                    isLocal: true
                })
            }
            return [...localEventList, ...this.rules[this.step][0]?.eventList]
        },
        initFirstPane() {
            const { localUid: uid } = this
            const res = this.rule[0]
            res.props = {
                uid
            }
            const formatData = parserPanel(res)

            this.$set(this.rules, 0, [formatData])
        }
    }
}
</script>

<style lang="scss">
.custom-step {
    margin-bottom: 20px;
}
</style>
