import axios from './index'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
import { BASE_URL } from '@config'

/**
 * 通用请求
 * @param {String} url 请求地址
 * @param {String} method 请求方式 get post put delete
 * @param {String} contentType 请求头
 * @param {Object} params 请求参数
 * @returns {Promise<*>}
 */
export default async function commonService({
    url,
    params,
    method,
    contentType
}) {
    const param = {
        url: `${BASE_URL}${url}`,
        method: method || 'post',
        headers: {
            'Content-Type':
                contentType ||
                // 'application/x-www-form-urlencoded' ||
                'application/json'
        },
        data: params,
        timeout: 30000
    }
    const res = await axios(param)
    const data = res.data
    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
