import axios from '../index'
import { IMPORT_CONFIRM } from './api'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

/**
 * 导入确认
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function importValidationService(
    payload = {},
    config = {}
) {
    const param = {
        ...IMPORT_CONFIRM,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
