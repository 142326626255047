/**
 * 响应成功
 */
export const HTTP_ERR_SUCCESS = 200
/**
 * 响应成功message
 */
export const HTTP_ERR_SUCCESS_MESSAGE = '成功'

/**
 * 请求失败
 * @type {string}
 */
export const HTTP_ERR_FAIL = '0'

/**
 * 授权失效
 */
export const HTTP_ERR_UNAUTHORIZED = '401'
