import BasicLayout from '@layout/BasicLayout'
import GuideLayout from '@layout/GuideLayout'

/**
 * 初始路由表
 * @type {Route[]}
 */
const routes = [
    {
        path: '/',
        hidden: true,
        redirect: '/welcome'
    },
    {
        path: '/login',
        name: 'Login',
        hidden: true,
        component: () => import('@views/Login'),
        meta: {
            needAuthProperties: true
        }
    },
    {
        path: '/404',
        hidden: true,
        component: () => import('@views/errorPage/404')
    },
    {
        path: '/500',
        hidden: true,
        component: () => import('@views/errorPage/500')
    },
    {
        path: '/guide',
        name: 'Guide',
        component: GuideLayout,
        redirect: '/guide/index',
        hidden: true,
        meta: {
            needAuthProperties: true
        },
        children: [
            {
                path: 'index',
                name: 'GuidePage',
                component: () => import('@views/Guide')
            }
        ]
    },
    {
        path: '/welcome',
        name: 'Welcome',
        hidden: true,
        component: BasicLayout,
        redirect: '/welcome/index',
        meta: {
            title: '欢迎页',
            icon: 'home',
            iconType: 'iconfont'
        },
        children: [
            {
                path: 'index',
                name: 'WelcomePage',
                hidden: true,
                component: () => import('@views/Welcome')
            }
        ]
    }
]

// 非生产环境时才可有 test 页面
if (process.env.OPERATING_ENV !== 'production') {
    routes.push({
        path: '/test',
        hidden: true,
        component: () => import('@views/Test')
    })
}


export const constantRouterMap = routes
