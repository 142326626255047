import {
    INPUT_TYPE,
    TEXTAREA_TYPE,
    INPUT_NUMBER_TYPE,
    INPUT_DECIMAL_TYPE,
    DESCRIPCTION_TYPE,
    FILE_UPLOAD_TYPE,
    IMAGE_UPLOAD_TYPE,
    CHECK_LIST_TYPE,
    SELECT_TYPE,
    MONEY_TYPE,
    DATE_PICKER_TYPE,
    DATE_RANGE_PICKER_TYPE,
    DATE_TIME_RANGE_PICKER_TYPE
} from './types'
import { VALIDATES, VALIDATE_INTEGER, VALIDATE_NUMBER } from '../validate'

import { validatorNumberRange } from '../validate/validates'

import parsers from './parsers'

/**
 * 解析功能
 * @params
 */
export function parserPanel(fieldList, data, column = 1, emits = {}) {
    const res = []
    let description = []
    // 第一步，分类 默认没有事件来做
    for (let i = 0; i < fieldList.length; i++) {
        let item = fieldList[i]
        if (item.componentType?.indexOf('description') > -1) {
            const config = item.componentType.split('_')

            if (config.length > 1) {
                item.span = +config[1] || 1
            }
            description.push(item)
        } else {
            if (description.length > 0) {
                res.push(description)
                description = []
            }

            res.push(item)
        }
    }

    if (description.length > 0) {
        res.push(description)
    }

    return res.map(item => {
        if (Array.isArray(item)) {
            const res = {
                props: {
                    data,
                    fieldList: item
                },
                componentType: DESCRIPCTION_TYPE
            }
            if (parsers[res.componentType]) {
                item = parsers[res.componentType].format(
                    res,
                    data,
                    column,
                    emits
                )
            }
        } else {
            if (parsers[item.componentType]) {
                item = parsers[item.componentType].format(
                    item,
                    data,
                    column,
                    emits
                )
            }
        }

        return item
    })
}

/**
 * 通用表单项解析器
 * @param field
 * @returns {{}}
 */
export function normalFieldParser(
    field,
    typeValidate = [],
    column = 2,
    emits,
    pageRef,
    data
) {
    const {
        fieldName,
        unit,
        length,
        fieldCode,
        componentType,
        props = {},
        nullAllowed,
        allowEditing,
        maxLength,
        dataTag,
        fileNumber,
        validate: defalutValidate
    } = field
    // 栅格尺寸 默认为一栏
    const span = 24 / (length || column)

    // 验证规则
    const validate = []

    // 添加前端验证
    addValidates(validate, field, nullAllowed)

    // 默认的验证规则
    if (Array.isArray(defalutValidate)) {
        validate.push(...defalutValidate)
    }

    // 默认验证
    if (Array.isArray(typeValidate)) {
        validate.push(...typeValidate)
    }

    const _emits = emits[fieldCode] || {}

    // 给事件添加pageRef
    for (let target in _emits) {
        const fn = _emits[target]
        _emits[target] = value => fn(value, pageRef)
    }

    const disabled = !allowEditing

    const config = {
        title: fieldName,
        field: fieldCode,
        validate,
        col: {
            span
        },
        props: {
            disabled, //  _.isBoolean(allowEditing) ? !allowEditing : false,
            suffix: unit,
            fieldCode,
            componentType,
            maxLength,
            data,
            dataTag,
            fileNumber,
            ...props
        },
        wrap: {
            labelCol: {
                span: 24
            },
            wrapperCol: {
                span: 24
            }
        },
        on: {
            'add-field': (key, value) => {
                const fApi = pageRef.fApi
                if (!fApi.getRule(key)) {
                    fApi.append({
                        type: 'input',
                        title: '',
                        field: key,
                        value
                    })
                } else {
                    fApi.setValue(key, value)
                }

                setTimeout(() => {
                    fApi.hidden(true, key)

                }, 10)
            },
            ..._emits
        }
    }

    if ([
        FILE_UPLOAD_TYPE,
        IMAGE_UPLOAD_TYPE,
        CHECK_LIST_TYPE,
        SELECT_TYPE,
        MONEY_TYPE,
        DATE_PICKER_TYPE,
        DATE_RANGE_PICKER_TYPE,
        DATE_TIME_RANGE_PICKER_TYPE
    ].includes(componentType)) {
        if (disabled) config.type = 'ReadonlyItem' // 强制设置为只读组件
    }


    return config
}

/**
 * 添加验证
 * @params
 * type: 表单类型
 */
export function addValidates(validate, field, nullAllowed) {
    const { fieldName, componentType, smallLength, bigLength } = field
    // 必填判断
    if (!nullAllowed) {
        validate.push({
            required: true,
            message: fieldName + '不能为空'
        })
    }

    switch (componentType) {
        // 整数数字输入框
        case INPUT_NUMBER_TYPE: {
            validate.push(
                {
                    validator: VALIDATES[VALIDATE_INTEGER]
                },
                {
                    validator: (rule, value, callback) => validatorNumberRange(rule, value, callback, {
                        small: smallLength,
                        large: bigLength
                    })
                }
            )

            break
        }
        // 数字输入框
        case INPUT_DECIMAL_TYPE: {
            validate.push(
                {
                    validator: VALIDATES[VALIDATE_NUMBER]
                },
                {
                    validator: (rule, value, callback) => validatorNumberRange(rule, value, callback, {
                        small: smallLength,
                        large: bigLength
                    })
                }
            )

            break
        }
        // 普通的输入框
        case INPUT_TYPE:
        case TEXTAREA_TYPE: {
            // 必填添加空格判断
            // if (!nullAllowed) {
            //     validate.push({
            //         required: true,
            //         message: '不能输入空格或特殊字符',
            //         whitespace: true
            //     })
            // }

            // 添加最小长度的规则
            if (smallLength) {
                validate.push({
                    min: smallLength,
                    message: `不足最短字符长度${smallLength}`
                })
            }

            // 添加最长长度的规则
            if (bigLength) {
                validate.push({
                    max: bigLength,
                    message: `超出最长输入字符${bigLength}`
                })
            }
            break
        }
    }
}
