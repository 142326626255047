import { SAFETY_AUTH_URL, SAFETY_MFG_URL } from '@config'

/**
 * 获取手机验证码（工保通，注册用）
 */
export const GET_PHONE_CODE = {
    url: `${SAFETY_AUTH_URL}/ent-auth/send/code`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

/**
 * 获取手机验证码（非工保通，登录用）
 */
export const GET_VERIFY_CODE = {
    url: `${SAFETY_MFG_URL}/sms/sendVerifyCode`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

/**
 * 手机号注册
 */
export const PHONE_REGISTER = {
    url: `${SAFETY_AUTH_URL}/ent-auth/register`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}
/**
 * 手机号+密码登录
 */
export const LOGIN_BY_PASSWORD = {
    url: `${SAFETY_AUTH_URL}/ent-auth/login`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

/**
 * 退出登录
 */
export const LOGOUT = {
    url: `${SAFETY_AUTH_URL}/ent-auth/logout`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

/**
 * 获取用户信息
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_INFO = {
    url: `${SAFETY_AUTH_URL}/ent-auth/user/token`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取用户menu
 */
export const GET_USER_MENU = {
    url: `${SAFETY_AUTH_URL}/user-center/getMenu`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取更新后的token
 */
export const GET_REFRESH_TOKEN = {
    url: `${SAFETY_AUTH_URL}/ent-auth/refreshToken`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000
}

/**
 * 根据 accessKey 获取 tokens
 *
 * 提示：
 * tokens 包含 token、accessToken 和 refreshToken
 */
export const GET_TOKENS_BY_ACCESS_KEY = {
    url: `${SAFETY_AUTH_URL}/ent-auth/access/token`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取认证相关配置信息
 */
export const GET_PROPERTIES = {
    url: `${SAFETY_AUTH_URL}/ent-auth/properties`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
