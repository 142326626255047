<template>
    <div>
        <template v-for="(item, index) in buttonList">
            <a-button
                v-if="'normal' === item.buttonType"
                :key="index"
                :type="item.type"
                :loading="item.showLoading && loading"
                @click="item.click"
            >
                {{ item.buttonText }}
            </a-button>

            <template v-if="'confirm' === item.buttonType">
                <popconfirm-button
                    :key="index"
                    placement="top"
                    ok-text="确定"
                    cancel-text="取消"
                    button-key="submit"
                    :button-type="item.type"
                    :button-text="item.buttonText"
                    :loading="item.showLoading && loading"
                    @on-confirm="item.click"
                >
                    <template slot="title">
                        <p>{{ item.confirmMessage || '确认要执行此操作吗？' }}</p>
                    </template>
                </popconfirm-button>
            </template>
        </template>
    </div>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import PopconfirmButton from '@components/PopconfirmButton'

export default {
    name: 'DefaultFooter',
    components: {
        AButton,
        PopconfirmButton
    },
    props: {
        buttonList: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    }
}
</script>
