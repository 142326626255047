import parsers from '../parser/parsers'

import { DESCRIPCTION_TYPE } from '../parser/types'

export default {
    methods: {
        parserPanel(fieldList, data, column = 1, emits = {}) {
            const res = []
            let description = []
            // 第一步，分类 默认没有事件来做
            for (let i = 0; i < fieldList.length; i++) {
                let item = fieldList[i]
                if (item.componentType?.indexOf('description') > -1) {
                    const config = item.componentType.split('_')

                    if (config.length > 1) {
                        item.span = +config[1] || 1
                    }
                    description.push(item)
                } else {
                    if (description.length > 0) {
                        res.push(description)
                        description = []
                    }

                    res.push(item)
                }
            }

            if (description.length > 0) {
                res.push(description)
            }

            return res.map(item => {
                if (Array.isArray(item)) {
                    const res = {
                        props: {
                            data,
                            fieldList: item
                        },
                        componentType: DESCRIPCTION_TYPE
                    }
                    if (parsers[res.componentType]) {
                        item = parsers[res.componentType].format(
                            res,
                            data,
                            column,
                            emits,
                            this
                        )
                    }
                } else {
                    if (parsers[item.componentType]) {
                        item = parsers[item.componentType].format(
                            item,
                            data,
                            column,
                            emits,
                            this
                        )
                    }
                }
                return item
            })
        }
    }
}
