<template>
    <div>
        <template v-if="length">
            <a @click.stop="handleClick">{{ length }}</a>
        </template>
        <template v-else>
            {{ emptyValuePlaceholder }}
        </template>

        <custom-view ref="view" :images="images" :options="viewOption" />
    </div>
</template>
<script>
import CustomView from '@components/CustomViewer'

import { EMPTY_VALUE_PLACEHOLDER } from '@config'

export default {
    name: 'TableIamgeCell',
    components: {
        CustomView
    },
    props: {
        images: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            viewOption: {
                url: 'fileUrl',
                key: 'fileId'
            },

            emptyValuePlaceholder: EMPTY_VALUE_PLACEHOLDER
        }
    },
    computed: {
        length() {
            return this.images.length
        }
    },
    methods: {
        handleClick() {
            if (this.length > 0) {
                this.$refs['view'].showViewer()
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
