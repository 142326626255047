<template>
    <div class="create-page__custom-tabs-wrapper">
        <!-- COMBINATION_COMPONENT_TYPE_CARD -->
        <template v-if="needCard">
            <custom-card v-bind="cardNeedProps">
                <custom-tabs ref="contentItem" v-bind="tabsNeedProps">
                    <template v-for="(item, index) in list" :slot="`slot-${index}`">
                        <slot :name="`slot-${index}`" />
                    </template>
                </custom-tabs>
            </custom-card>
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_PAGE -->
        <template v-else-if="needPageHeader">
            <weight-page-header v-bind="pageHeaderNeedProps" :title="title" />
            <custom-tabs ref="contentItem" v-bind="tabsNeedProps">
                <template v-for="(item, index) in list" :slot="`slot-${index}`">
                    <slot :name="`slot-${index}`" />
                </template>
            </custom-tabs>
        </template>

        <!-- COMBINATION_COMPONENT_TYPE_NOMAL -->
        <template v-else>
            <custom-tabs ref="contentItem" v-bind="tabsNeedProps">
                <template v-for="(item, index) in list" :slot="`slot-${index}`">
                    <slot :name="`slot-${index}`" />
                </template>
            </custom-tabs>
        </template>
    </div>
</template>
<script>
import CustomTabs from '@weights/CustomTabs'
import CustomCard from '@weights/CustomCard'
import WeightPageHeader from '@weights/PageHeader'

import childrenRefreshMixin from '@mixins/createPage/childrenRefresh'
import getFormData from '@mixins/createPage/formData'
import eventMixin from '@mixins/createPage/event'
import buttonMixin from '@mixins/createPage/button'

import { getPropFields, getPropsInNeed, getPropsInCardNeed, getPropsInPageHeaderNeed } from '@utils/pageCreate'

import { COMBINATION_COMPONENT_TYPE_CARD, COMBINATION_COMPONENT_TYPE_PAGE, BOOLEAN_PATTERN_TRUE } from '@constant/enum'

const TABS_PROP_FIELDS = getPropFields(CustomTabs)

export default {
    name: 'CreatePageCustomTabs',
    components: {
        CustomTabs,
        CustomCard,
        WeightPageHeader
    },
    mixins: [
        childrenRefreshMixin, getFormData, eventMixin, buttonMixin
    ],
    props: {
        combinationComponentType: {
            type: Number,
            default: COMBINATION_COMPONENT_TYPE_CARD
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        eventList: {
            type: Array,
            default: () => []
        },
        extraTitle: {
            type: Object,
            default: () => ({})
        },
        // 展示边框
        needBordered: {
            type: Number,
            default: BOOLEAN_PATTERN_TRUE
        },
        fieldList: {
            type: Array,
            default: () => []
        },
        column: {
            type: Number,
            default: 2
        },
        list: {
            type: Array,
            default() {
                return []
            }
        },
        selfControl: {
            type: Number
        },
        mode: {
            type: Number
        },
        childrenField: {
            type: Array,
            default: () => []
        },
        uid: {
            type: String
        },
        stepId: {
            type: String
        },
        rule: {
            type: Array
        },
        showTotal: {
            type: Number
        }
    },
    computed: {
        needCard() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_CARD
        },
        needPageHeader() {
            return this.combinationComponentType === COMBINATION_COMPONENT_TYPE_PAGE
        },
        cardNeedProps() {
            return this.needCard ? getPropsInCardNeed(this.$props) : null
        },
        pageHeaderNeedProps() {
            return this.needPageHeader ? getPropsInPageHeaderNeed(this.$props) : null
        },
        tabsNeedProps() {
            return getPropsInNeed(this.$props, TABS_PROP_FIELDS)
        }
    },
    methods: {
        vaildatorTabsForm() {
            return this.$refs['contentItem'].vaildatorTabsForm()
        },
        getTabsFormData() {
            return this.$refs['contentItem'].getTabsFormData()
        }
    }
}
</script>
