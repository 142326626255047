<template>
    <div class="file-view">
        <span v-if="!files.length">{{ emptyValuePlaceholder }}</span>

        <template v-else>
            <template v-for="file in files">
                <a
                    :key="file.fileId"
                    target="_blank"
                    class="file-view__file"
                    :href="getRelativeAgreementUrl(file.fileUrl)"
                    :download="file.originalFileName || file.fileName "
                >
                    {{ file.originalFileName || file.fileName }}
                </a>
            </template>
        </template>
    </div>
</template>
<script>
import { EMPTY_VALUE_PLACEHOLDER } from '@config'
import { getRelativeAgreementUrl } from '@utils'

export default {
    name: 'FileView',
    props: {
        files: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            emptyValuePlaceholder: EMPTY_VALUE_PLACEHOLDER
        }
    },
    methods: {
        getRelativeAgreementUrl(url) {
            return getRelativeAgreementUrl(url)
        }
    }
}
</script>

<style lang="scss" scoped>
.file-view {
    &__file {
        display: block;
        // margin-bottom: 15px;
    }
}
</style>
