import axios from '@service'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
import { SAFETY_MFG_URL } from '@config'

import { isEmpty } from '@utils'

let dataRedis = null

const isNotEmptyArray = data => Array.isArray(data) && data.length > 0

export default async function getAreaListService({ areaCode } = {}) {
    if (isNotEmptyArray(dataRedis)) {
        if (isEmpty(areaCode)) return dataRedis

        const hit = dataRedis.filter(({ value }) => value?.includes(areaCode))
        if (isNotEmptyArray(hit)) return hit
    }

    const param = {
        url: `${SAFETY_MFG_URL}/area/allList`,
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        timeout: 30000
    }

    const { data } = await axios(param)

    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        const result = data.result

        dataRedis = result

        return result || []
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
