import { isFunction } from './index'

const EXPORT_FILE_TYPE_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

export const isImg = function (ext) {
    return [
        'png',
        'jpg',
        'jpeg',
        'bmp',
        'gif',
        'webp',
        'psd',
        'svg',
        'tiff'
    ].includes(ext)
}
export const isPdf = function (ext) {
    return ext === 'pdf'
}
export const isExcel = function (ext) {
    return ext === 'xlsx'
}
export const isZip = function (ext) {
    return ext === 'zip'
}

// 从路径中获取文件后缀名
export const getFileExtFromPath = function (path) {
    const index = path.lastIndexOf('.')
    return path.substr(index + 1)
}

// 从路径中获取文件名
export const getFileNameFromPath = function (path) {
    const index = path.lastIndexOf('.')
    return path.substr(0, index)
}

// 从 content-disposition 中获取文件名
export const getFileNameFromDisposition = function (disposition) {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/

    let matches = filenameRegex.exec(decodeURIComponent(disposition))

    if (matches !== null && matches[1]) {
        return matches[1].replace(/['"]/g, '')
    }

    return ''
}

// 手动下载 Blob 格式文件
export function downloadFileBlob(res, type, filename, callback) {
    let blob = res

    if (blob || !blob.type !== type) {
        // eslint-disable-next-line
        blob = new Blob([blob], { type })
    }

    if (window.navigator && isFunction(window.navigator.msSaveOrOpenBlob)) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
    } else if (window.URL && isFunction(window.URL.createObjectURL)) {
        const link = document.createElement('a')
        const href = window.URL.createObjectURL(blob)
        // 下载链接
        link.href = href
        link.download = filename
        link.click()
        window.URL.revokeObjectURL(href)
    } else {
        console.error(`
            Cannot use window.navigator.msSaveOrOpenBlob
            and window.URL( createObjectURL、revokeObjectURL )！
        `)
    }

    isFunction(callback) && callback()
}

/**
 * @description 下载接口返回的 blob
 *
 * @param {*} responseBlob 接口返回的 blob
 * @param {*} responseHeaders  接口响应头部
 */
export function downloadResponseBlob(responseBlob, responseHeaders) {
    downloadFileBlob(
        responseBlob,
        responseHeaders['content-type'] || EXPORT_FILE_TYPE_EXCEL,
        getFileNameFromDisposition(responseHeaders['content-disposition'])
    )
}


// 预览文件
export function previewFile(fileType, url) {
    const fileTypes = [
        'png',
        'jpeg',
        'image/png',
        'image/jpeg'
    ]

    if (fileTypes.indexOf(fileType) > -1) {
        let newWindow = window.open('')
        newWindow.document.body.innerHTML = '<img src="' + url + '" alt="">'
    } else {
        window.open(url)
    }
}
