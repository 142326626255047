import axios from './index'
import { HTTP_ERR_SUCCESS_MESSAGE } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
import { SAFETY_MFG_URL } from '@config'

export default async function getDictTreeByLevel(params) {
    // http://phoenix-dev.gateway.gongbaow.com/api/safety_mfg/dict/safety_standard_library/getDictTreeByLevel?level=1
    const config = {
        url: `${SAFETY_MFG_URL}/dict/safety_standard_library/getDictTreeByLevel`,
        method: 'get',
        headers: {
            'Content-Type':
                // 'application/x-www-form-urlencoded' ||
                'application/json'
        },
        params,
        timeout: 30000
    }
    const res = await axios(config)
    const data = res.data
    if (data.message === HTTP_ERR_SUCCESS_MESSAGE) {
        return data.result
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
