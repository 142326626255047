import { Input } from 'ant-design-vue'

import PropTypes from 'ant-design-vue/es/_util/vue-types'

import { isFunction } from '@utils'

import { COMPONENTS, STRATEGY } from './config'

export default {
    name: 'DataEntryComponent',
    components: COMPONENTS,
    props: {
        value: PropTypes.any,
        fieldName: PropTypes.any,
        fieldCode: PropTypes.string,
        componentType: PropTypes.string,
        dataTag: PropTypes.string
    },
    methods: {
        emitChange(value) {
            this.$emit('input', value)
        }
    },
    render(h) {
        const fn = STRATEGY[this.componentType]

        if (!isFunction(fn)) {
            return h(Input, {
                props: {
                    value: this.value,
                    placeholder: `请输入${this.fieldName}`
                },
                on: {
                    input: e => this.emitChange(e.target.value)
                }
            })
        }

        const component = fn.call(this)
        return h(component.Component, {
            attrs: component.attrs,
            props: {
                value: this.value,
                ...component.props
            },
            on: component.listeners || {
                input: val => this.emitChange(val)
            }
        })
    }
}
