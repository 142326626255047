
export const DEFAULT_ITEM_COUNT = 4

export function generateRenderQueryList(list = []) {
    if (list.length <= DEFAULT_ITEM_COUNT) {
        return [
            {
                queryList: list,
                slotName: 'default'
            }
        ]
    }

    return [
        {
            slotName: 'default',
            queryList: list.slice(0, DEFAULT_ITEM_COUNT)
        },
        {
            slotName: 'expansion',
            queryList: list.slice(DEFAULT_ITEM_COUNT)
        }
    ]
}
